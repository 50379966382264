<template>
  <div class="full-height pa-16 ma-16">
    <p v-if="processing" class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 text-center font-weight-black">
      Validando dirección de correo      
      <Loader v-if="loading" :size="64" />
    </p>
    <div v-else>
      <div v-if="correct">
        <p class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 text-center font-weight-black">
          Validado correctamente
          <br>
        (<router-link to="/login/" tag="b" class="cta-red cursor-mouse"> iniciar sesión  aquí</router-link>  )
        </p>
        <v-row>
          <v-col>            
              <v-img 
                  :aspect-ratio="16/9"
                  class="mx-5 justify-center aling-center" 
                  :src="'/images/emailValidation.jpeg'" 
                  max-height="300px"
                  alt="emptystate" 
                  contain
              />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <p class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 text-center font-weight-black">
          Existe un problema con tu correo
        </p>      
      </div>
    </div>

  </div>
</template>

<script>
import Loader from '@/components/adminSide/Loader';
import {mapState} from 'vuex'
import EventBus from '@/services/events';

export default {
  data(){
    return{
      processing: true,
      correct: false,
      id: ''
    }
  },
  created(){
    if(this.processing){
      this.$store.dispatch('frontUserModule/validateEmail',this.$route.params.salesman).then(() =>{
        this.processing = false;
      });
    }
  },
  mounted(){
    EventBus.$on('validate-email-response',(payload) => {
      this.correct = payload;
    });
  },
  components:{
    Loader
  },
  computed:{
    ...mapState({
				loading: state => state.adminSalesModule.loadingStatus,
			}),
  }
}
</script>

<style>

</style>