<template>
  <div>
    <AdminHeader
      color="black"
      icon="desktop-mac-dashboard"
      title="Panel de control Vendedor"
      :showHelp="true"
      :content="content"
    />
    <Loader v-if="loadingHorses" :size="64" />
    <v-row v-if="!loadingHorses" justify="center">
      <v-col cols="6" sm="6" md="3" :key="item.ranch"  v-if="!ranch.default">
        <v-card class="mx-auto" height="100%" >
          <v-row justify="center" no-gutters dense align="center">
            <v-col cols="12">
              <v-img
                max-height="150"
                class="align-center"
                :src="ranch.image"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3">
        <v-card class="mx-auto" height="100%" color="blue lighten-2" dark>
          <v-card-text>
            <p class="text-subtitle-2 text-sm-subtitle-1 text-md-h6 text-lg-h5 text-center">
              Ofertas recibidas
            </p>
            <p class="text-h5 text-center">
              <v-progress-circular
                    v-if="myBids.length < 0"
                    :width="3"
                    :value="value2"
                  ></v-progress-circular>
                  <span v-else>
                    {{ myBids.length }}
                  </span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3">
        <v-card class="mx-auto" height="100%" color="blue lighten-2" dark>
          <v-card-text>
            <p class="text-subtitle-2 text-sm-subtitle-1 text-md-h6 text-lg-h5 text-center">
              Caballos registrados
            </p>
            <p class="text-h5 text-center">
              <v-progress-circular
                v-if="loadingHorses"
                :width="3"
                color="white"
                indeterminate
              ></v-progress-circular>
              <span v-else>
                {{ docs.length }}
              </span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3">
        <v-card class="mx-auto" height="100%" color="blue lighten-2" dark>
          <v-card-text>
            <p class="text-subtitle-2 text-sm-subtitle-1 text-md-h6 text-lg-h5 text-center">
              Inscripciones disponibles
            </p>
            <p class="text-h5 text-center">
              <v-progress-circular
                v-if="loadingCredits"
                :width="3"
                color="white"
                indeterminate
              ></v-progress-circular>
              <span v-else>
                {{ itemCredits.credits ? itemCredits.credits : 0 }}
              </span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card height="550px">
          <v-card-title class="mx-auto justify-center aling-center">
            <div
              class="text-subtitle-2 text-sm-subtitle-1 text-md-h6 text-lg-h5 text-center"
            >
              {{ Date() | date }}
            </div>
          </v-card-title>
          <v-sheet height="500">
            <v-calendar
              ref="calendar"
              v-model="value"
              :weekdays="weekday"
              :type="type"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              @change="getEvents"
            ></v-calendar>
          </v-sheet>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-row>
          <v-col cols="6">
            <v-card class="mx-auto" height="100%">
              <v-card-text>
                <p class="text-subtitle-2 text-sm-subtitle-1 text-md-h6 text-lg-h5 text-center">
                  Mejor Oferta
                </p>
                <p class="text-h5 text-center">
                  <v-progress-circular
                    v-if="myBids.length <= 0"
                    :width="3"
                    :value="value2"
                  ></v-progress-circular>
                  <span v-else>
                     {{ myBids[0].bid || 0 | money }}
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="mx-auto" height="100%">
              <v-card-text>
                <p class="text-subtitle-2 text-sm-subtitle-1 text-md-h6 text-lg-h5 text-center">
                  Amonestaciones
                </p>
                <p class="text-h5 text-center">
                  <v-progress-circular
                    :width="3"
                    :value="value2"
                  ></v-progress-circular>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-card class="mx-auto" height="100%">
              <v-card-text>
                <p class="text-subtitle-2 text-sm-subtitle-1 text-md-h6 text-lg-h5 text-center">
                  Usuarios Activos
                </p>
                <v-sheet color="transparent">
                  <v-sparkline
                    :key="String(avg)"
                    :smooth="16"
                    color="blue lighten-2"
                    :line-width="3"
                    :value="heartbeats"
                    auto-draw
                    stroke-linecap="round"
                  ></v-sparkline>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "../services/events";
import AdminHeader from "@/components/adminSide/AdminHeader";
import Loader from "@/components/adminSide/Loader";

const exhale = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export default {
  components: {
    AdminHeader,
    Loader,
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data: () => ({
    type: "month",
    interval: {},
    value2: 0,
    mode: "stack",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: "",
    events: [],
    colors: ["blue", "indigo", "deep-purple", "cyan"],
    Message: "",
    heartbeats: [],
    content: {
      title: "Panel de control",
      subtitle:
        "Aquí encontrarás toda la información relacionada a tus movimientos y transacciones a través de eHorseSale.",
      sections: [
        {
          title: "Panel de control",
          info: [
            {
              title: "Eventos programados",
              description:
                "Cantidad de eventos que tienes programados en este mes.",
            },
            {
              title: "Usuarios interesados",
              description:
                "Compradores que realizarón una oferta a tus caballos en subasta durante este mes.",
            },
            {
              title: "Caballos registrados",
              description: "Caballos en proceso de captura.",
            },
            {
              title: "Inscripciones disponibles",
              description:
                "Inscripciones con las que cuentas para registrar nuevos caballos.",
            },
            {
              title: "Calendario",
              description: "Eventos programados este mes",
            },
            {
              title: "Mejor Oferta",
              description:
                "Oferta más alta recibida durante un evento este mes",
            },
            {
              title: "Amonestaciones",
              description:
                "Alertas por parte del equipo de eHorseSale por incumplimiento de reglamento",
            },
            {
              title: "Usuarios activos",
              description: "Compradores que visitan tus eventos publicados",
            },
          ],
        },
        {
          title: "Opciones Menu",
          info: [
            {
              title: "Panel de control",
              description: "Información relevante del mes",
            },
            {
              title: "Mi Perfil",
              description: "Panel de administración de datos de vendedor",
            },
            {
              title: "Eventos",
              description: "Programación y estatus de subastas",
            },
            {
              title: "Registro de caballo",
              description:
                "Catálogo de caballos capturados por parte del vendedor",
            },
            {
              title: "Mis Inscripciones",
              description: "Inscripciones disponibles para registro de caballos",
            },
          ],
        },
      ],
    },
  }),
  async mounted() {
    window.scrollTo(0, 0);
    await this.$store.dispatch(
      "salesmenModule/fetchItem",
      (this.salesman = this.$session.get("salesman"))
    );
    // this.$store.dispatch('dashboardSalesState/getData');
    await this.$store.dispatch("homeState/fetchTopEvents").then(() => {
      this.getEvents();
    });
    await this.$store.dispatch(
      "creditsModule/fetchItem",
      this.$session.get("salesman")
    );
    await this.$store.dispatch(
      "horsesModule/setMaster",
      this.$session.get("salesman")
    );
    await this.$store.dispatch("horsesModule/fetchData", {
      perPage: 10,
      page: 1,
    });
    EventBus.$on("events-set", () => {
      this.getEvents();
    });
    this.interval = setInterval(() => {
      if (this.value2 === 100) {
        return (this.value2 = 0);
      }
      this.value2 += 10;
    }, 2000);
    if (this.item.ranch) {
      await this.$store.dispatch("ranchsModule/fetchItem", this.item.ranch);
    }
  },
  async created() {
    this.takePulse(false);
    if (this.item.ranch) {
      await this.$store.dispatch("ranchsModule/fetchItem", this.item.ranch);
    }
  },
  methods: {
    getEvents() {
      const events = [];
      for (let i = 0; i < this.topEvents.length; i++) {
        const allDay = this.rnd(0, 3) === 0;
        events.push({
          name: this.topEvents[i].horse.name,
          start: new Date(this.topEvents[i].initialDate),
          end: new Date(this.topEvents[i].finishDate),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }
      this.events = events;
      if(this.topEvents.length > 1)
        this.getBids(this.topEvents[0]._id) 
    },
    getBids(id){
      this.$store.dispatch('homeState/fetchBidByEvents',id);
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    submit(msg) {
      this.$socket.client.emit("chat-message", msg);
    },
    heartbeat() {
      return 0;
    },
    async takePulse(inhale = true) {
      this.checking = true;

      inhale && (await exhale(1000));

      this.heartbeats = Array.from({ length: 20 }, this.heartbeat);

      this.checking = false;
    },
  },
  computed: {
    ...mapState({
      loadingCredits: (state) => state.creditsModule.loadingStatus,
      loadingHorses: (state) => state.horsesModule.loadingStatus,
      topEvents: (state) => state.homeState.topEvents,
      itemCredits: (state) => state.creditsModule.singleItem,
      docs: (state) => state.horsesModule.data,
      item: (state) => state.salesmenModule.singleItem,
      ranch: (state) => state.ranchsModule.singleItem,
      myBids: state => state.homeState.myBids,
    }),
    avg() {
      const sum = this.heartbeats.reduce((acc, cur) => acc + cur, 0);
      const length = this.heartbeats.length;

      if (!sum && !length) return 0;

      return Math.ceil(sum / length);
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: calc(1em + 1vw);
  line-height: 1em;
  padding: 1em;
  margin: 1em;
}
</style>