<template>
    <div>
        <AdminHeader color="black" icon="format-list-bulleted-triangle" :title="'Estados de ' + master.name " :backButton="true"/>
        <Loader v-if="loading" :size="64" />
        <Paginador :dataLength="dataLength" :pages="pages" :CurrentPage="page" @next="next"/>
        <v-card
            elevation="5"
            height="500px"
            class="mb-10"
        >
            <v-card-title>
                <h6>Catálogo de locaciones nivel Estado</h6>
                <v-spacer></v-spacer>                
                <v-btn
                    @click="create()"
                    dark
                    small
                >
                <v-icon left>
                    mdi-plus
                </v-icon>
                    Capturar
                </v-btn>
            </v-card-title>   
            <template v-if="dataLength > 0">
                <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Nombre
                        </th>
                        <th class="text-left">
                            Estatus
                        </th>
                        <th class="text-left">
                            
                        </th>
                         <th class="text-left">
                            
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in getSub"
                            :key="item._id"
                        >
                            <td>{{ item.name }}</td>
                            <td>
                                <v-chip v-if="item.status"
                                    color="green"
                                    text-color="white"
                                >
                                    Activo
                                </v-chip>
                                <v-chip v-else
                                    color="red"
                                    text-color="white"
                                >
                                    Inactivo
                                </v-chip>
                            </td>
                            <td>
                                <v-row 
                                    aling="right"
                                >
                                    <v-icon v-on:click="setItemToStore(item._id)">mdi-lead-pencil</v-icon>
                                    <div class="mx-2"></div>
                                    <v-icon v-on:click="setItemToDelete(item._id)">mdi-trash-can</v-icon>

                                </v-row>
                            </td>
                            <td>
                                <v-icon> mdi-file-tree</v-icon>
                                <a @click="detalle(item._id)"> Ciudades </a>                                
                            </td>
                        </tr>
                    </tbody>
                </template>
                </v-simple-table>
            </template>
             <v-row dense class="mx-auto justify-center aling-center" v-else>
                <EmptyState :white="true"/>
            </v-row>
                    
        </v-card>
        
        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card>
                <v-toolbar
                    dark
                >
                    <v-btn
                    icon
                    dark
                    @click="dialog = false"
                    >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Nueva Estado</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <v-btn
                        dark
                        text
                        @click="guardar();"
                        color="amber"
                    >
                        {{ label }}
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-list
                    three-line
                    subheader
                >
                    <v-subheader>Crear nueva Estado</v-subheader>
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Formulario para registrar un nueva Estado</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-content>
                        <form>
                            <v-text-field
                                v-model="newOne.name"
                                :counter="100"
                                label="Nombre"
                                required
                            ></v-text-field>                           
                        </form>    
                    </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
                v-model="confirm"
                persistent
                max-width="400"
            >
                <v-card>
                <v-card-title class="headline">
                    ¿Deseas eliminar {{newOne.name}}?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    @click="cancelDelete()"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="red"
                        text
                        @click="guardar()"
                    >
                    Eliminar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        
        
    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import EmptyState from '@/components/EmptyState';
    import Loader from '@/components/adminSide/Loader';
    import AdminHeader from '@/components/adminSide/AdminHeader';
    import Paginador from '@/components/adminSide/Paginador';
    
    export default {
        data(){
            return{
                itemsPerPage: 10,
                dialog: false,
                confirm: false,
                newOne: this.createFreshObj(),
                show1: false,
                page: 1,
                eventCrud: 0 // 0 for new, 1 for edit, 2 for delete                
            }
        },
        components:{
            EmptyState,
            AdminHeader,
            Loader,
            Paginador
        },
        created(){
            this.init();
            this.$store.dispatch('statesModule/setMaster',this.$route.params.country);
        },
        beforeMount(){
            this.init();
            this.$store.dispatch('statesModule/setMaster',this.$route.params.country);
        },
        mounted(){
            this.init();
            this.$store.dispatch('statesModule/setMaster',this.$route.params.country);
        },
        methods:{
            create(){
                this.createFreshObj();
                this.dialog = true;
                this.eventCrud = 0;
            },
            createFreshObj(){
                this.validateMaster();
                return {
                    name: '',
                    country: this.master,
                    status: true
                }
            },
            init(){
                this.$store.dispatch('statesModule/fetchData',{perPage: this.itemsPerPage,page: this.page});
                this.newOne.name = "";
            },
            guardar(){    
                if(this.newOne.country === null){
                    this.newOne.country = this.$route.params.country;
                }
                this.validateMaster();    
                   
                if(this.eventCrud === 0){// CREATE
                    this.dialog = false;
                    this.newOne.country = this.master._id;
                    this.$store.dispatch('statesModule/createItem',this.newOne).then(() => {                        
                        this.newOne = this.createFreshObj();                         
                        this.$store.dispatch('statesModule/fetchData',{perPage: this.itemsPerPage,page: this.pages});                    
                    });
                }else if(this.eventCrud ===1){ //EDIT
                    this.$store.dispatch('statesModule/updateItem',this.newOne).then(() =>{
                        this.$store.dispatch('statesModule/fetchData',{perPage: this.itemsPerPage,page: this.page});
                        this.dialog = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }else{
                    this.$store.dispatch('statesModule/deleteItem',this.newOne._id).then(() =>{
                        this.$store.dispatch('statesModule/fetchData',{perPage: this.itemsPerPage,page: this.page});
                        this.confirm = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }
                
            },
            next(param){
                this.page = param.page;
                this.$store.dispatch('statesModule/fetchData',{perPage: param.itemsPerPage,page: param.page});
            },
            setItemToStore(id){                
                this.eventCrud = 1;
                this.$store.dispatch('statesModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                    this.dialog = true;
                });
            },
            setItemToDelete(id){
                this.eventCrud = 2;
                this.confirm = true;
                this.$store.dispatch('statesModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                });
            },
            cancelDelete(){
                this.eventCrud = 0;
                this.confirm = false;
                this.newOne = this.createFreshObj(); 
            },
            regresar(){
                this.$router.go(-1);
            },
            validateMaster(){
                this.$store.dispatch('statesModule/setMaster',this.$route.params.country);
            },
            detalle(id){
                this.$store.dispatch('statesModule/fetchItem',id).then(() =>{
                    this.$router.push({ name: 'cities', params: {state: id}});
                });
            }
        },
        computed: {
            label(){
                return this.eventCrud === 0 ? 'Guardar': (this.eventCrud === 1 ? 'Editar' : 'Eliminar' );
            },
            ...mapGetters('statesModule',['dataLength','getSub']),
            ...mapState({
                user: state => state.user,
                loading: state => state.statesModule.loadingStatus,
                pages: state => state.statesModule.pages,
                docs: state => state.statesModule.data,
                item: state => state.statesModule.singleItem,
                master: state => state.countriesModule.singleItem
            })
        }
    }
</script>