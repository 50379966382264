import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import VueCryptojs from "vue-cryptojs";
import i18n from "./plugins/i18n";


import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";

import VueSession from "vue-session";
import DateTimeFilter from "./services/datetime";
import DateFilter from "./services/date";
import HourFilter from "./services/hour";
import MoneyFilter from "./services/money";
import YesNoFilter from "./services/yesno";
import TwoDigitsFilter from "./services/two_digits";
import wysiwyg from "vue-wysiwyg";
import { VueMaskDirective } from "v-mask";
import "./plugins/vuetify-money.js";

const URL = process.env.VUE_APP_BACKEND_URL;

//CSS FOR WYSIWYG
require("./assets/custom.css");

Vue.config.productionTip = false;

Vue.filter("date", DateFilter);
Vue.filter("datetime", DateTimeFilter);
Vue.filter("hour", HourFilter);
Vue.filter("money", MoneyFilter);
Vue.filter("two_digits", TwoDigitsFilter);
Vue.filter("yesno", YesNoFilter);
Vue.directive("mask", VueMaskDirective);

Vue.use(wysiwyg, {
  hideModules: {
    code: true,
    image: true,
  },
});

Vue.use(VueSession);
Vue.use(VueCryptojs);

const socket = io(URL);

Vue.use(VueSocketIOExt, socket, { store });

new Vue({
  router,
  i18n,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
