<template>
  <div>
    <AppLoader v-if="loading" :size="100" color="primary" />
    <Header :user="user" :key="`${currentPerson.name}${user}`" :current="currentPerson" />
    <NotificationContainer />
    <router-view :key="$route.fullPath" />
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Header from "@/components/userSide/Header.vue";
import Footer from "@/components/userSide/home/Footer.vue";
import NotificationContainer from "@/components/NotificationContainer";
import EventBus from "../../services/events";
import AppLoader from "@/components/base/AppLoader.vue";

export default {
  name: "Home",
  mounted() {
    this.initHome();
    if (this.$session.exists() && this.$session.get("type") === "FRN") {
      this.initLogin();
    }
    EventBus.$on("front-user-fetch", () => {
      this.initLogin();
    }),
    EventBus.$on("people-registered-home", () => {
      this.initLogin();
    });
  },
  components: {
    NotificationContainer,
    Header,
    Footer,
    AppLoader,
  },
  data() {
    return {
      user: false,
      person: {},
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.homeState.loadingStatus,
      configData: (state) => state.homeState.configData,
      currentPerson: (state) => state.peopleModule.singleItem,
      current: (state) => state.frontUserModule.current,
    }),
  },
  watch:{
    current: function(val) {
      this.user = (val !== '');
    }
  },
  methods: {
    async initLogin() {
      this.user = true;
      this.person = this.$session.get("person");
      await this.$store.dispatch("peopleModule/fetchItem", this.person);
    },
    async initHome() {
      window.scrollTo(0, 0);
      await this.$store.dispatch("homeState/fetchCategories");
      await this.$store.dispatch("homeState/fetchTopEvents");
      await this.$store.dispatch("homeState/fetchConfig");
    },
  },
};
</script>
