<template>
  <div>
    <AdminHeader
      color="black"
      icon="monitor-dashboard"
      title="Panel de control - Administrador"
    />
    <v-sheet v-if="needToUpdate" color="amber" class="my-5">
      <v-row class="text-center">
        <v-col cols="12" sm="8">
          <span class="text-h6">
            Es necesario que actualices tu <strong>contraseña.</strong>
          </span>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn @click="showPasswordDialog()">
            Actualizar
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-row class="mt-5">
      <v-col cols="6" sm="6" md="3">
        <DashboardCard title="Eventos programados" :value="'' + dashboardData.events"/>
      </v-col>
      <v-col cols="6" sm="6" md="3">
        <DashboardCard title="Ranchos Registrados" :value="'' + dashboardData.ranchs"/>
      </v-col>
      <v-col cols="6" sm="6" md="3">
        <DashboardCard title="Caballos Registrados" :value="'' + dashboardData.horses"/>
      </v-col>
      <v-col cols="6" sm="6" md="3">
        <DashboardCard title="Ganancias del mes"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card height="550px">
          <v-card-title class="mx-auto justify-center aling-center">
            <div
              class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h5 text-center"
            >
              {{ Date() | date }}
            </div>
          </v-card-title>
          <v-sheet height="450">
            <v-calendar
              ref="calendar"
              v-model="value"
              :weekdays="weekday"
              :type="type"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              @change="getEvents"
            ></v-calendar>
          </v-sheet>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto ma-0" height="100%">
              <v-card-text>
                <p class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h5 text-center">
                  Notificaciones
                </p>
                <p class="text-h5 text--primary text-center">
                  <v-text-field
                    v-model="Message"
                    :counter="50"
                    label="Mensaje"
                    dense
                  ></v-text-field>
                </p>
              </v-card-text>
              <v-card-actions class="d-flex aling-end flex-column">
                <v-btn color="red" @click="submit(Message)" dark >
                  <v-icon class="mr-3">mdi-email-fast</v-icon>
                  Enviar Notificación
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-card class="mx-auto" height="100%">
              <v-card-text>
                <p
                  class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h5 text-center"
                >
                  Compradores registrados ( {{ dashboardData.frontUsers }} ) 
                  <v-icon color="red" class="mouse-pointer ml-3" @click="goToDetail()"> mdi-open-in-new </v-icon>
                </p>
                <v-sheet color="transparent" :key="heartbeats.length" v-if="heartbeatsComplete">
                  <v-sparkline
                    :key="String(avg)"
                    :smooth="16"
                    color="red"
                    show-labels
                    :line-width="1"
                    :value="heartbeats"
                    auto-draw
                    stroke-linecap="round"
                  ></v-sparkline>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="PasswordDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Actualización de contraseña
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-text-field
              label="Contraseña actual"
              v-model="dataUpdate.actual"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              counter
              @click:append="show1 = !show1"
              required
            ></v-text-field>
            <v-text-field
              label="Nueva contraseña"
              v-model="dataUpdate.nueva"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              :rules="passRules"
              counter
              @click:append="show1 = !show1"
              required
            ></v-text-field>
            <v-text-field
              label="Confirmar contraseña"
              v-model="dataUpdate.confirmation"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              counter
              @click:append="show1 = !show1"
              required
            ></v-text-field>
            <v-alert color="orange" type="info" v-if="showWarning">
              {{ alertText }}</v-alert
            >
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="showPasswordDialog()">
            Cancelar
          </v-btn>
          <v-btn dark @click="updatePass">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "@/services/events";
import AdminHeader from "@/components/adminSide/AdminHeader";
import DashboardCard from "@/components/adminSide/DashboardCard";
export default {
  components: {
    AdminHeader,
    DashboardCard
  },
  data: () => ({
    type: "month",
    mode: "stack",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    Message: "",
    heartbeats: [],
    heartbeatsComplete: false,
    PasswordDialog: false,
    dataUpdate: {
      actual: "",
      nueva: "",
      confirmation: "",
    },
    passRules: [
      (v) => !!v || "Contraseña es requerida",
      (v) =>
        /^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])\S{8,}$/.test(v) ||
        "Password debe ser valido (Min 8 caracteres, una minuscula, una mayuscula y un número)",
    ],
    show1: false,
    alertText: "",
    showWarning: false,
    valid: true,
  }),
  beforeDestroy() {
    clearInterval(this.interval);
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.$store.dispatch("dashboardAdminState/getData");
    await this.$store.dispatch("dashboardAdminState/getGrowth");
    await this.$store.dispatch("homeState/fetchTopEvents").then(() => {
      this.getEvents();
    });
    EventBus.$on("events-set", () => {
      this.getEvents();
    });
    EventBus.$on("updateSuccess", () => {
      this.showPasswordDialog();
    });
    this.takePulse();
    if(this.heartbeats.length > 0) this.heartbeatsComplete = true;
  },
  methods: {
    getEvents() {
      const events = [];
      for (let i = 0; i < this.topEvents.length; i++) {
        const allDay = this.rnd(0, 3) === 0;
        events.push({
          name: this.topEvents[i].horse.name,
          start: new Date(this.topEvents[i].initialDate),
          end: new Date(this.topEvents[i].finishDate),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }
      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    submit(msg) {
      this.$socket.client.emit("chat-message", msg);
      this.Message = ''
    },
    heartbeat() {
      return Math.ceil(132);
    },
    takePulse() {
      this.heartbeats = Array.from({ length: this.growt.length }, this.heartbeat);
      Array.from(this.growt).forEach((value, index) => {
        this.heartbeats[index] = value.count;
      }) 
    },
    showHelp() {
      EventBus.$emit("call-show-help", this.content);
    },
    showPasswordDialog() {
      this.PasswordDialog = !this.PasswordDialog;
    },
    updatePass() {
      this.validate();
      if (this.dataUpdate.actual === "") {
        this.alertText = "Contraseña actual es requerida";
        this.showWarning = true;
      } else {
        if (this.dataUpdate.nueva !== this.dataUpdate.confirmation) {
          this.alertText = "Confirmación de contraseña incorrecta";
          this.showWarning = true;
        } else {
          this.$store.dispatch('adminModule/updatePass',this.dataUpdate);
        }
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    goToDetail(){
      this.$router.push({ name: 'all-frontusers'});
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.homeState.loadingStatus,
      topEvents: (state) => state.homeState.topEvents,
      needToUpdate: (state) => state.adminModule.needToUpdate,
      dashboardData: (state) => state.dashboardAdminState.data,
      growt: (state) => state.dashboardAdminState.growt,
    }),
    avg() {
      const sum = this.heartbeats.reduce((acc, cur) => acc + cur, 0);
      const length = this.heartbeats.length;
      if (!sum && !length) return 0;
      return Math.ceil(sum / length);
    },
  },
};
</script>
