<template>
  <div>
    <AdminHeader color="black" icon="account-reactivate" title="Catálogo de administradores"/>
    <Loader v-if="loading" :size="64" />
    <Paginador
      :dataLength="dataLength"
      :pages="pages"
      :CurrentPage="page"
      @next="next"
    />
    <v-card elevation="9" shaped class="mb-10 pb-10">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="create()" dark small>
          <v-icon left>mdi-account-plus</v-icon>
          Invitar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <template v-if="dataLength > 0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Correo electrónico</th>
                  <th class="text-center">Nombre de usuario</th>
                  <th class="text-right">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in adminUsers" :key="item._id">
                  <td>
                    <strong>
                      {{ item.email }}
                    </strong>
                  </td>
                  <td class="text-center">
                      {{ item.user }}
                  </td>
                  <td class="text-right">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-on:click="setItemToStore(item._id)">
                          <v-list-item-title>
                            <v-icon>mdi-lead-pencil</v-icon>Editar
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-on:click="setItemToDelete(item._id)">
                          <v-list-item-title>
                            <v-icon color="red">mdi-trash-can</v-icon>Eliminar
                            </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <v-row dense class="ma-5 justify-center aling-center" v-else>
          <EmptyState :white="true" />
        </v-row>
      </v-card-text>
    </v-card>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Formulario Administrador</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="guardar()" :disabled="!valid">
                {{ label }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list three-line subheader>
            <v-subheader>Invitar nuevo administrador</v-subheader>
            <v-list-item>
              <v-list-item-content class="px-16">
                <v-form v-model="valid">
                  <v-text-field
                    v-model="newOne.email"
                    :counter="100"
                    label="Correo Electrónico"
                    hint="El usuario recibirá aquí la invitación"
                    persistent-hint
                    :rules="emailRules"
                    required
                  ></v-text-field>
                  <v-text-field
                    title="name"
                    v-model="newOne.user"
                    :counter="100"
                    label="Nombre de usuario"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="newOne.pass"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Contraseña"
                    hint="Contraseña temporal de invitación"
                    persistent-hint
                    counter
                    @click:append="show1 = !show1"
                    @keydown.enter="guardar()"
                  ></v-text-field>
                </v-form>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="600">
        <v-card class="pa-3">
          <v-card-title class="headline">
            ¿Deseas eliminar a {{ newOne.user }}?
          </v-card-title>
          <v-card-text>
            <p>
              Esta acción es definitiva
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDelete()"> Cancelar </v-btn>
            <v-btn color="red" text @click="guardar()"> Eliminar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import Loader from "@/components/adminSide/Loader";
import AdminHeader from "@/components/adminSide/AdminHeader";
import Paginador from "@/components/adminSide/Paginador";

export default {
  data() {
    return {
      valid: false,
      dialog: false,
      confirm: false,
      newOne: this.createFreshAdmin(),
      show1: false,
      rules: {
        required: (value) => !!value || "Obligatorio.",
        min: (v) => v.length >= 8 || "longitud mínima 8",
      },
      page: 1,
      eventCrud: 0, // 0 for new, 1 for edit, 2 for delete
      itemsPerPage: 10,
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe ser valido",
      ],
    };
  },
  created() {
    this.init(this.page);
  },
  components: {
    EmptyState,
    Loader,
    AdminHeader,
    Paginador,
  },
  methods: {
    create() {
      this.newOne = this.createFreshAdmin();
      this.dialog = true;
      this.eventCrud = 0;
    },
    createFreshAdmin() {
      // const user = this.$store.state.user;
      return {
        user: "",
        pass: "",
        email: "",
      };
    },
    init(initPage) {
      this.$store.dispatch("adminModule/fetchData", {
        perPage: this.itemsPerPage,
        page: initPage,
      });
      this.newOne = this.createFreshAdmin();
      this.eventCrud = 0;
    },
    guardar() {
      if (this.eventCrud === 0) {
        // CREATE
        this.dialog = false;
        this.$store
          .dispatch("adminModule/createAdmin", this.newOne)
          .then(() => {
            this.init(this.pages)
          });
      } else if (this.eventCrud === 1) {
        //EDIT
        this.$store
          .dispatch("adminModule/updateAdmin", this.newOne)
          .then(() => {
            this.init(this.pages)
            this.dialog = false;     
          });
      } else {
        this.$store
          .dispatch("adminModule/deleteAdmin", this.newOne._id)
          .then(() => {
            this.init(this.pages)
            this.confirm = false;            
          });
      }
    },
    next(param) {
      this.page = param.page;
      this.$store.dispatch("adminModule/fetchData", {
        perPage: param.itemsPerPage,
        page: param.page,
      });
    },
    setItemToStore(id) {
      this.eventCrud = 1;
      this.$store.dispatch("adminModule/fetchAdmin", id).then(() => {
        this.newOne = this.admin;
        this.newOne.pass = "";
        this.dialog = true;
      });
    },
    setItemToDelete(id) {
      this.eventCrud = 2;
      this.confirm = true;
      this.$store.dispatch("adminModule/fetchAdmin", id).then(() => {
        this.newOne = this.admin;
      });
    },
    cancelDelete() {
      this.eventCrud = 0;
      this.confirm = false;
      this.newOne = this.createFreshAdmin();
    },
  },
  computed: {
    label() {
      return this.eventCrud === 0
        ? "Guardar"
        : this.eventCrud === 1
        ? "Editar"
        : "Eliminar";
    },
    ...mapGetters("adminModule", ["dataLength"]),
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.adminModule.loadingStatus,
      pages: (state) => state.adminModule.pages,
      adminUsers: (state) => state.adminModule.data,
      admin: (state) => state.adminModule.singleItem,
    }),
  },
};
</script>
