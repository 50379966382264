import axios from 'axios';

const URL = process.env.VUE_APP_BACKEND_URL;

const apiClient = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers:{
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmYmNiMWEyZTA0ZmMxODI3M2ZkNDdhMSIsInVzZXIiOiJzYWhlcnJlcmEiLCJpYXQiOjE2MDc1NjQzOTF9.VLnUYcHk7yXIaTpmwUKJaCvjJJ0vGxgpq7GZj4b4H-U"
    }
});

const apiClientFormData = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers:{
        'Content-Type': 'multipart/form-data',
        Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmYmNiMWEyZTA0ZmMxODI3M2ZkNDdhMSIsInVzZXIiOiJzYWhlcnJlcmEiLCJpYXQiOjE2MDc1NjQzOTF9.VLnUYcHk7yXIaTpmwUKJaCvjJJ0vGxgpq7GZj4b4H-U"
    }
});

export default {
    auth(route,user){
        return apiClient.post(route,{user: user.name, pass: user.pass});
    },
    authByEmail(route,user){
        return apiClient.post(route,{user: user.email, pass: user.pass});
    },
     getDocs(route){ // Ruta para busqueda general
        return  apiClient.get(route);
    },
    getDocsQuery(route,query){ // Ruta para busqueda general
        return apiClient.get(route+query);
    },
    getDocsByMaster(route,master){//busqueda por ref
        return apiClient.get(route + master);
    },
    getDocsPage(route,perPage,page){// Ruta para busqueda en catálogo
        return apiClient.get(route + page + '&' + perPage);
    },
    getItem(route,id){// Busqueda de un item
        if(id === 0){
            return apiClient.get(route); 
        }
        return apiClient.get(route + id);
    },
    postItem(route,newItem){ // crear item
        var keys = Object.keys(newItem)
        if(newItem.image){
            let formData = new FormData();
            for(let key of keys){
                formData.append(key,newItem[key]);
            }
            return apiClientFormData.post(URL + route,formData);
        }else if(newItem.file){
            let formData = new FormData();
            for(let key of keys){
                formData.append(key,newItem[key]);
            }
            return apiClientFormData.post(URL + route,formData);
        }else if(newItem.photos){
            let formData = new FormData();
            for(let key of keys){
                if(key === 'photos'){
                    for(var i = 0; i < newItem.photos.length; i++){
                        var file = newItem.photos[i];
                        // formData.append("photos["+i+"]",file);
                        formData.append("photos",file);
                    }
                }else{
                    formData.append(key,newItem[key]);
                }
                
            }
            return apiClientFormData.post(URL + route,formData);
        }
        return apiClient.post(route,newItem);
    },
    deleteItem(route,id){//Borrar item
        return apiClient.delete(route+ id);
    },
    updateItem(route,editItem){//Eliminar item
        if(editItem.image){
            let formData = new FormData();
            var keys = Object.keys(editItem)
            for(let key of keys){
                formData.append(key,editItem[key]);
            }
            return apiClientFormData.put(URL + route+editItem._id,formData);
        }
        if(editItem._id){
            return apiClient.put(route + editItem._id,editItem);
        }
        return apiClient.put(route,editItem);
    },
    updateBadge(route,item){
        return apiClient.patch(route + item);
    },
    updateSingleField(route,editItem,value){
        return apiClient.put(route+editItem,{saleman: value})
    },
    updateRemoveImage(route,editItem){
        return apiClient.put(URL + route+editItem._id+'&'+editItem.index);
    },
    updateSingleImage(route,editItem){
            if(editItem.image){
                let formData = new FormData();
                var keys = Object.keys(editItem)
                for(let key of keys){
                    formData.append(key,editItem[key]);
                }
                return apiClientFormData.put(URL + route+editItem._id+'&'+editItem.type,formData);
            }
            return apiClient.put(route+ editItem._id,editItem);
        },
    updateMomento(route,editItem,value){
        return apiClient.put(route+editItem,{momento: value})
    },
    updateCredits(route,editItem,value){
        return apiClient.put(route+editItem,value);
    },
    getImage(imgURI){// Retornar ruta de imagen
        return URL + '/util/' + imgURI;
    },
    executeTask(route,item){
        return apiClient.put(route+item);
    },
    updateAdminPass(editAdmin){
        return apiClient.put('/adminSales/updatePass/'+ editAdmin.user,editAdmin );
    }
}