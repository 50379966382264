<template>
  <v-sheet class="that-bar justify-right mx-auto" dark width="100%">
    <v-row class="mx-auto justify-right aling-right mr-5">
      <v-col cols="12" class="mr-3">
        <div class="d-flex justify-end mb-1">
          <v-chip color="white" small class="d-none d-sm-flex ma-1 text-center">
            <a
              href="#PEDIGREE"
              style="color: black;"
              class="that-bar-link text-center"
            >
              <span class="text-caption text-center font-weight-bold">Genealogía</span>
            </a>
          </v-chip>
          <v-chip color="white" small class="d-none d-sm-flex  ma-1">
            <a
              href="#VIDEOS"
              style="color: black; "
              color="#df2127"
              class="that-bar-link font-weight-bold text-caption"
              >Videos</a
            >
          </v-chip>
          <v-chip color="white" small class=" d-none d-sm-flex ma-1">
            <a
              href="#SALUD"
              style="color: black;"
              color="#df2127"
              class="that-bar-link font-weight-bold"
              >Salud
            </a>
          </v-chip>
          <v-chip
            v-if="showOffer"
            color="#df2127"
            dark
            small
            class="ma-1 linkCursor font-weight-bold"
            @click="$emit('offerNow')"
          >
            Ofertar Ahora
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props:{
    showOffer: Boolean
  }
};
</script>

<style>
.that-bar {
  position: sticky;
  top: 0px;
  z-index: 1000;
}

.that-bottom-bar {
  position: sticky;
  bottom: 0px;
  z-index: 1000;
}

.that-bar-link {
  text-decoration: none !important;
}

.ul-list {
  list-style-type: none;
}
</style>
