<template>
  <v-sheet :dark="isDark" width="100%" class="ma-0 pa-0 text-center">
    <h3 class="py-4">
      <strong> {{ title }} </strong>
    </h3>
  </v-sheet>
</template>

<script>
export default {
  name: "BlackTitle",
  props: {
    title: String,
    isDark: {
      type: Boolean,
      default: true,
    },
  },
};
</script>