<template>
  <div v-if="item">
    <h1>Nombre: {{ item.name }}</h1>
    <ul class="my-5">
      <li v-if="item.shortDescription">{{ item.shortDescription }}</li>
      <li v-if="item.resume">{{ item.resume }}</li>
      <li v-if="item.description">{{ item.description }}</li>
    </ul>
    <v-divider class="my-3"></v-divider>
    <div>
      <h3>Galería:</h3>
      <v-row>
        <v-col v-for="photo in item.photos" :key="photo" md="3" lg="4">
          <v-sheet height="150">
            <v-img
              class="cursor-mouse"
              contain
              :lazy-src="photo"
              max-height="100%"
              max-width="300px"
              :src="photo"
              @click="emitShowImage(photo)"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>
      <v-divider class="my-1"></v-divider>
    </div>
    <div>
      <v-row>
        <v-col cols="12" sm="6">
          <h3>Información:</h3>
          <span v-if="item.type === 1">
            <v-icon small class="mx-3">mdi-calendar</v-icon>
            <strong>Fecha de nacimiento: </strong>
            <span class="ml-3"></span>
            {{ item.dateOfBirth | date }} </span>
          <br />
          <span v-if="item.repStatus && item.type === 1">
            <v-icon small class="mx-3">mdi-chess-knight</v-icon>
            <strong>Sexo: </strong>
            <span class="ml-3">{{ estatusReproductivo }} </span> </span
          ><br />
          <span v-if="item.size">
            <v-icon small class="mx-3">mdi-tape-measure</v-icon>
            <strong>Alzada: </strong>
            <span class="ml-3">{{ item.size }} cm</span> </span
          ><br />
          <span v-if="item.city">
            <v-icon small class="mx-3">mdi-google-maps</v-icon>
            <strong>Ciudad: </strong>
            <span class="ml-3"
              >{{ item.city.name }} - {{ item.city.state.name }} -
              {{ item.city.state.country.name }}</span
            > </span
          ><br />
          <span v-if="item.subCategory">
            <v-icon small class="mx-3"
              >mdi-format-list-bulleted-triangle</v-icon
            >
            <strong>SubCategoría: </strong>
            <span class="ml-3"
              >{{ item.subCategory.name }} -
              {{ item.subCategory.category.name }} -
              {{ item.subCategory.category.group.name }}
            </span> </span
          ><br />
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Status: {{ getStatus }}</h3>
          <span>
            <v-icon small class="mx-3">mdi-credit-card-settings-outline</v-icon>
            <strong>Ofrece Compra directa:: </strong>
            <span class="ml-3">{{ item.offerBuyNow | yesno }}</span> </span
          ><br />
          <span>
            <v-icon small class="mx-3">mdi-registered-trademark</v-icon>
            <strong>Registro: </strong>
            <span class="ml-3">{{ item.papers | yesno }}</span>
            <span class="ml-3" v-if="item.regist">
              [ {{ item.regist }} ]</span
            > </span
          ><br />
          <span>
            <v-icon small class="mx-3">mdi-seal</v-icon>
            <strong>Nominado: </strong>
            <span class="ml-3">{{ item.nominated | yesno }}</span>
          </span>
          <v-sheet class="ma-5" color="amber">
            <h4 class="text-center">
              <v-icon>mdi-currency-usd</v-icon>
              Compra directa: {{ item.buyNow | money }} MXN
            </h4>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorseDetailGeneral",
  props: {
    item: {},
  },
  data() {
    return {
      repStatus: [
        { id: 1, description: "Garañon" },
        { id: 2, description: "Hembra" },
        { id: 3, description: "Castrado" },
      ],
    };
  },
  methods: {
    emitShowImage(photo) {
      this.$emit("showImage", photo);
    },
  },
  computed: {
    getStatus() {
      let estatusLabel = "";
      switch (this.item.momento) {
        case 1:
          estatusLabel = "Captura";
          break;
        case 2:
          estatusLabel = "Listo para auditoria";
          break;
        case 3:
          estatusLabel = "En auditoria";
          break;
        case 4:
          estatusLabel = "Validado con Observaciones";
          break;
        case 5:
          estatusLabel = "Validado para evento";
          break;
        case 6:
          estatusLabel = "Asignado a evento";
          break;
        case 8:
          estatusLabel = "Observaciones Revisadas";
          break;
        default:
          estatusLabel = "N/A";
          break;
      }
      return estatusLabel;
    },
    estatusReproductivo() {
      return this.item.repStatus
        ? this.repStatus.filter((rep) => rep.id === this.item.repStatus)[0]
            .description
        : "";
    },
  },
};
</script>