<template>
    <div>
        <AdminHeader color="black" icon="draw" title="Términos y condiciones"/>
        <Loader v-if="loading" :size="64" />
        <Paginador :dataLength="dataLength" :pages="pages" :CurrentPage="page" @next="next"/>
        <v-card
            elevation="5"
            height="100%"
            class="mb-10"
        >
            <v-card-title>
                <h6>Redacción que los usuarios deberán aceptar para operar a través de eHorsesale</h6>
                <v-spacer></v-spacer>
                
                    <v-btn
                        @click="create()"
                        dark
                        small
                    >
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                        Capturar
                    </v-btn>
                
            </v-card-title>   

            <v-card-subtitle>Versión pública - 
                <v-chip
                    class="ma-1"
                    dark
                >
                {{lastItem.version}}
                </v-chip>                
            </v-card-subtitle>

            <template v-if="dataLength > 0">
                <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Versión
                            </th>
                            <th class="text-left">
                                Fecha de publicación
                            </th>     
                            <th class="text-left">
                                Estatus
                            </th>     
                            <th class="text-right">
                                Opciones
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in docs"
                            :key="item._id"
                        >
                            <td>{{ item.version }}</td>
                            <td>{{ item.publicationDate | date}}</td>
                            <td>

                                <v-chip v-if="item.status"
                                    color="dark"
                                >
                                    Pública
                                </v-chip>

                            </td>
                            <td class="text-right">
                                <v-menu
                                        bottom
                                        left
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                        <v-list-item v-on:click="setItemToStore(item._id)">
                                            <v-list-item-title ><v-icon>mdi-lead-pencil</v-icon> Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-on:click="setItemToDelete(item._id)">
                                            <v-list-item-title><v-icon color="red">mdi-trash-can</v-icon> Eliminar</v-list-item-title>
                                        </v-list-item>
                                        </v-list>
                                    </v-menu>
                            </td>
                        </tr>
                    </tbody>
                </template>
                </v-simple-table>
            </template>
            <v-row dense class="mx-auto justify-center aling-center" v-else>
                <EmptyState :white="true"/>
            </v-row>     
                    
        </v-card>
        
        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card>
                <v-toolbar
                    dark
                >
                    <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Formulario Términos y condiciones</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <v-btn
                        dark
                        text
                        @click="guardar();"
                        :disabled="!valid"
                        color="amber"
                    >
                        {{ label }}
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-list
                    three-line
                    subheader
                >
                    <v-list-item>
                    <v-list-item-content class="ma-5">
                        <v-form v-model="valid">
                            <v-text-field
                                v-model="newOne.version"
                                :counter="100"
                                label="Versión"
                                required
                                :rules="nameRules"
                            ></v-text-field>                           
                            <v-text>Contenido</v-text>
                            <wysiwyg v-model="newOne.body" />
                            <v-checkbox
                                v-model="newOne.status"
                                label= "Publicable"
                            ></v-checkbox>                                                  
                        </v-form>    
                    </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
                v-model="confirm"
                persistent
                max-width="400"
            >
                <v-card>
                <v-card-title class="headline">
                    ¿Deseas eliminar {{newOne.version}}?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    @click="cancelDelete()"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="red"
                        text
                        @click="guardar()"
                    >
                    Eliminar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        
        
    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import EmptyState from '@/components/EmptyState';
    import Loader from '@/components/adminSide/Loader';
    import AdminHeader from '@/components/adminSide/AdminHeader';
    import Paginador from '@/components/adminSide/Paginador';
    
    export default {
        data(){
            return{
                valid:false,
                nameRules: [
                    v => !!v || 'Versión requerida'
                ],
                dialog: false,
                confirm: false,
                newOne: this.createFreshObj(),
                show1: false,
                page: 1,
                eventCrud: 0 // 0 for new, 1 for edit, 2 for delete                
            }
        },
        components:{
            EmptyState,
            AdminHeader,
            Loader,
            Paginador
        },
        created(){
            this.init();
            this.$store.dispatch('termsModule/getPublicableTerms');
        },
        methods:{
            create(){
                this.createFreshObj();
                this.dialog = true;
                this.eventCrud = 0;
            },
            createFreshObj(){
                // const user = this.$store.state.user;
                return {
                    version: '',
                    body: '',
                    publicationDate: Date.now(),
                    status: true
                }
            },
            init(){
                this.$store.dispatch('termsModule/fetchData',{perPage: 5,page: this.page});
                this.newOne.version = "";
            },
            guardar(){             
                this.newOne.publicationDate = Date.now();   
                if(this.eventCrud === 0){// CREATE
                    this.dialog = false;
                    this.$store.dispatch('termsModule/createItem',this.newOne).then(() => {
                        this.newOne = this.createFreshObj(); 
                        this.$store.dispatch('termsModule/fetchData',{perPage: 5,page: this.pages});                    
                    });
                }else if(this.eventCrud ===1){ //EDIT
                    this.$store.dispatch('termsModule/updateItem',this.newOne).then(() =>{
                        this.$store.dispatch('termsModule/fetchData',{perPage: 5,page: this.page});
                        this.dialog = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }else{
                    this.$store.dispatch('termsModule/deleteItem',this.newOne._id).then(() =>{
                        this.$store.dispatch('termsModule/fetchData',{perPage: 5,page: this.page});
                        this.confirm = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }
                this.$store.dispatch('termsModule/getPublicableTerms');
                
            },
            next(param){
                this.page = param.page;
                this.$store.dispatch('termsModule/fetchData',{perPage: param.itemsPerPage,page: param.page});
            },  
            setItemToStore(id){           
                this.eventCrud = 1;
                this.$store.dispatch('termsModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                    this.dialog = true;
                });
            },
            setItemToDelete(id){
                this.eventCrud = 2;
                this.confirm = true;
                this.$store.dispatch('termsModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                });
            },
            cancelDelete(){
                this.eventCrud = 0;
                this.confirm = false;
                this.newOne = this.createFreshObj(); 
            }
        },
        computed: {
            label(){
                return this.eventCrud === 0 ? 'Guardar': (this.eventCrud === 1 ? 'Editar' : 'Eliminar' );
            },
            ...mapGetters('termsModule',['dataLength']),            
            ...mapState({
                user: state => state.user,
                loading: state => state.termsModule.loadingStatus,
                pages: state => state.termsModule.pages,
                docs: state => state.termsModule.data,
                item: state => state.termsModule.singleItem,
                lastItem: state => state.termsModule.lastItem
            })
        }
    }
</script>