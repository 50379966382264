import axios from "axios";

const URL = process.env.VUE_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  setAuth(token) {
    apiClient.defaults.headers.Authorization = `Bearer ${token}`;
  },
  updateDoc(route, doc) {
    return apiClient.put(`${route}/${doc._id}`, doc);
  },
  deleteDoc(route, id){
    return apiClient.delete(`${route}/${id}`);
  },
};
