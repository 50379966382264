<template>
  <v-row no-gutters class="my-5">
    <v-col :cols="showHelp ? '8' : '12'">
      <h5 class="cursor-mouse" v-if="backButton">
        <span @click="goBack()">
          <v-icon class="mr-1" :color="color">mdi-arrow-left</v-icon>
          Regresar
        </span>
      </h5>
      <v-row justify="center" align="center">
        <v-col cols="1" v-if="img !== 'empty'">
          <v-img            
            style="position:float"
            :src="'/images/33157.png'"
            max-height="60px"
            width="60px"
            contain
          />
        </v-col>
        <v-col :cols="img === 'empty' ? '12' : '11'">
          <span class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4">
            <v-icon large class="mr-5" :color="color">{{ "mdi-" + icon }}</v-icon>
            <strong>{{ title }}</strong>
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="text-right" :cols="showHelp ? '4' : '0'" v-if="showHelp">
      <div class="text-right">
        <v-btn color="white" @click="callHelp()" small>
          <v-icon class="mr-1" :color="color">mdi-help-circle-outline</v-icon>
          Ayuda
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EventBus from "@/services/events";

export default {
  name: "AdminHeader",
  props: {
    icon: String,
    img: {
      type: String,
      default: 'empty'
    },
    title: String,
    color: String,
    backButton: {
      type: Boolean,
      default: false,
    },
    showHelp: {
      type: Boolean,
      default: false,
    },
    content: Object,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    callHelp() {
      EventBus.$emit("call-show-help", this.content);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>