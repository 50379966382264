import CRUDServices from '@/services/CRUDServices';
import EventBus from '../../services/events';

export const namespaced = true;

export const state = {
    loadingStatus: false,
    data: [],
    singleItem: {},
    pages: 0,
    total: 0,
    api: '/salesmen/',
    justSaved: '',
    master: {},
    exportList: []
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_DATA(state, data){
      state.data = data;
    },
    SET_NEW_OBJ(state, item){
      state.data.push(item)
    },
    SET_PAGES(state, pages){
      state.pages = pages
    },
    SET_TOTAL(state, total){
      state.total = total
    },
    SET_SINGLE(state, item){
      state.singleItem = item
    },
    SET_SAVED(state, id){
      state.justSaved = id
    },
    SET_MASTER(state, item){
      state.master = item
    },
    SET_EXPORT_LIST(state, exportList){
      state.exportList = exportList
    },
}

export const  actions = {
    setMaster({commit},master){
      commit('SET_MASTER',master);
      // dispatch('ranchsModule/fetchItem',master,{root:true});
    },
    search({dispatch},query){
      CRUDServices.getDocsQuery(state.api+'search/',query)
      .then(response => {       
        EventBus.$emit('search-salesmen',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching salesmen: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchAll({commit,dispatch}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocs(state.api)
      .then(response => {       
        let docs = [];
        
        response.data.forEach(
          element => {
            if(element.image){
              element.image = CRUDServices.getImage(element.image);
            }
            if(element.ine){
              element.ine = CRUDServices.getImage(element.ine);
            }
            if(element.ineBack){
              element.ineBack = CRUDServices.getImage(element.ineBack);
            }
            if(element.comp){
              element.comp = CRUDServices.getImage(element.comp);
            }
            docs.push(element);
          }
        );
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching salesmen: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchData({state,commit,dispatch},{perPage,page}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocsPage(state.api+'r/'+state.master+'&',perPage,page)
      .then(response => {        
        let docs = [];
        response.data.docs.forEach(
          element => {
            if(element.image){
              element.image = CRUDServices.getImage(element.image);
            }
            if(element.ine){
              element.ine = CRUDServices.getImage(element.ine);
            }
            if(element.ineBack){
              element.ineBack = CRUDServices.getImage(element.ineBack);
            }
            if(element.comp){
              element.comp = CRUDServices.getImage(element.comp);
            }
            docs.push(element);
          }
        );
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA', docs);
        commit('SET_PAGES',response.data.totalPages);
        commit('SET_TOTAL',response.data.totalDocs);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching salesmen: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    createItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.postItem(state.api,item).then(response => {
        commit('SET_SAVED',response.data.obj);
        commit('SET_NEW_OBJ',item);
        EventBus.$emit('saved-salesman');
        const notification = {
            type: 'success',
            message: 'Vendedor capturada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
        commit('SET_LOADING_STATUS',false);
      }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem creating Salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchItem({state,commit,dispatch,getters},id){
      var item = getters.getDataByID(id);
      if (item){
        commit('SET_SINGLE',item);
        EventBus.$emit('set-single-salesman');
      }else{
        CRUDServices.getItem(state.api,id)
        .then(response => {
          item = response.data;
          if(item.image)
            item.image = CRUDServices.getImage(item.image);
          if(item.ine)
            item.ine = CRUDServices.getImage(item.ine);
          if(item.ineBack)
            item.ineBack = CRUDServices.getImage(item.ineBack);
          if(item.comp)
            item.comp = CRUDServices.getImage(item.comp);
          commit('SET_SINGLE',item);
          EventBus.$emit('set-single-salesman');
        }).catch(error =>{
          const notification = {
            type: 'error',
            message: 'There was a problem ferching Salesman: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
        });
      }
    },
    deleteItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.deleteItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Vendedor eliminada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem deleting Salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Vendedor actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateSingleImage({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateSingleImage(state.api+'file/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Vendedor actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateItemDatosB({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api+'datosB/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Vendedor actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateBadgeInfo({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api+'update/info/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Logro de Vendedor actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateDocuments({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api+'update/docs/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Logro de Vendedor actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateBank({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api+'update/baks/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Logro de Vendedor actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateCredits({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateBadge(state.api+'update/credits/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Logro de Vendedor actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateFirstHorse({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateBadge(state.api+'update2/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Logro de Vendedor actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateFfirstEvent({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateBadge(state.api+'update3/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Logro de Vendedor actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    async exportList({commit,dispatch}){
      commit('SET_LOADING_STATUS',true);
      await CRUDServices.getDocs(state.api+'export')
      .then(response => {       
        commit('SET_LOADING_STATUS',false);
        commit('SET_EXPORT_LIST',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching export email: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
}

export const getters = {
    getData(state){
      return state.data;
    },
    getDataByID: state => id => {
      return state.data.find(item => item._id === id);
    },
    dataLength: state =>{
      return state.total
    },
    incompleteDocs: state =>{
      return state.singleItem.documents
    },
  }