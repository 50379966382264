<template>
    <div>
        <AdminHeader color="black" icon="format-list-bulleted-triangle" :title="'Categorias de ' + master.name " :backButton="true"/>
        <Loader v-if="loading" :size="64" />
        <Paginador :dataLength="dataLength" :pages="pages" :CurrentPage="page" @next="next"/>
        <v-card
            elevation="5"
            height="100%"
            class="mb-10"
        >
            <v-card-title>
                <h6>Identificadores de caballos para venta</h6>
                <v-spacer></v-spacer>
                <v-btn
                    @click="create()"
                    dark
                    small
                >
                <v-icon left>
                    mdi-plus
                </v-icon>
                    Capturar
                </v-btn>
            </v-card-title>   
            <v-card-text v-if="dataLength > 0">
                <v-simple-table>
                    <template>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Nombre
                                    </th>
                                <th class="text-left">
                                    Estatus
                                </th>
                                <th class="text-right">
                                    Opciones
                                </th>
                                <th class="text-left">                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in docs"
                                :key="item._id"
                            >
                                <td>{{ item.name }}</td>
                                <td>
                                    <v-chip v-if="item.status"
                                        color="green"
                                        text-color="white"
                                    >
                                        Activa
                                    </v-chip>
                                    <v-chip v-else
                                        color="red"
                                        text-color="white"
                                    >
                                        Inactiva
                                    </v-chip>                                    
                                </td>
                                <td class="text-right">
                                    <v-menu
                                            bottom
                                            left
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn                                                icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                            <v-list-item v-on:click="setItemToStore(item._id)">
                                                <v-list-item-title ><v-icon>mdi-lead-pencil</v-icon> Editar</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-on:click="setItemToDelete(item._id)">
                                                <v-list-item-title><v-icon color="red">mdi-trash-can</v-icon> Eliminar</v-list-item-title>
                                            </v-list-item>
                                            </v-list>
                                        </v-menu>
                                </td>
                                <td class="text-right" >
                                    <a @click="detalle(item._id)"> <v-icon> mdi-file-tree</v-icon> SubCategorías </a>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>                
            </v-card-text>
            <v-row dense class="mx-auto justify-center aling-center" v-else>
                <EmptyState :white="true"/>
            </v-row>     
        </v-card>
        
        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar
                        color="black"
                        dark
                    >
                        <v-btn
                            icon
                            @click="dialog = false"
                            dark
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Formulario categoría</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="guardar();"
                            :disabled="!valid"
                            color="amber"
                        >
                            {{ label }}
                        </v-btn>
                    </v-toolbar-items>
                    </v-toolbar>
                    <v-list
                        three-line
                        subheader
                    >
                        <v-list-item>
                            <v-list-item-content class="ma-5">
                                <v-form v-model="valid">
                                    <v-text-field
                                        v-model="newOne.name"
                                        :counter="100"
                                        label="Nombre"
                                        required
                                        :rules="nameRules"
                                    ></v-text-field>       
                                    <v-checkbox
                                        v-model="newOne.status"
                                        label= "Activa"
                                    ></v-checkbox>                      
                                </v-form>    
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="confirm"
                persistent
                max-width="400"
            >
                <v-card>
                <v-card-title class="headline">
                    ¿Deseas eliminar {{newOne.name}}?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    @click="cancelDelete()"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="red"
                        text
                        @click="guardar()"
                    >
                    Eliminar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import EmptyState from '@/components/EmptyState';
    import Loader from '@/components/adminSide/Loader';
    import AdminHeader from '@/components/adminSide/AdminHeader';
    import Paginador from '@/components/adminSide/Paginador';
    
    export default {
        data(){
            return{
                itemsPerPage: 10,
                valid:false,
                nameRules: [
                    v => !!v || 'Nombre Categoría requerido',
                    v => v.length < 100 || 'Nombre debe ser menor a 100 caracteres',
                ],
                dialog: false,
                confirm: false,
                newOne: this.createFreshObj(),
                show1: false,
                page: 1,
                eventCrud: 0 // 0 for new, 1 for edit, 2 for delete                
            }
        },
        components:{
            EmptyState,
            AdminHeader,
            Loader,
            Paginador
        },
        created(){
            this.$store.dispatch('categoriesModule/setMaster',this.$route.params.group);
            this.init();
        },
        beforeMount(){
            this.$store.dispatch('categoriesModule/setMaster',this.$route.params.group);
            this.init();
        },
        mounted(){
            this.$store.dispatch('categoriesModule/setMaster',this.$route.params.group);
            this.init();
        },
        methods:{
            create(){
                this.createFreshObj();
                this.dialog = true;
                this.eventCrud = 0;
            },
            createFreshObj(){
                // const user = this.$store.state.user;
                return {
                    name: '',
                    group: this.master === null ? this.$route.params.group : this.master,
                    status: true
                }
            },
            init(){
                this.$store.dispatch('categoriesModule/fetchData',{perPage: this.itemsPerPage,page: this.page});
                this.newOne.name = "";
            },
            guardar(){         
                if(this.newOne.group === null){
                    alert('no gruop')
                    this.newOne.group = this.$route.params.group;
                }       
                this.validateMaster();    
                if(this.eventCrud === 0){// CREATE
                    this.dialog = false;
                    this.newOne.group = this.master._id;
                    this.$store.dispatch('categoriesModule/createItem',this.newOne).then(() => {
                        this.newOne = this.createFreshObj(); 
                        this.$store.dispatch('categoriesModule/fetchData',{perPage: this.itemsPerPage,page: this.pages});                    
                    });
                }else if(this.eventCrud ===1){ //EDIT
                    this.$store.dispatch('categoriesModule/updateItem',this.newOne).then(() =>{
                        this.$store.dispatch('categoriesModule/fetchData',{perPage: this.itemsPerPage,page: this.page});
                        this.dialog = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }else{

                    this.$store.dispatch('categoriesModule/deleteItem',this.newOne._id).then(() =>{
                        this.$store.dispatch('categoriesModule/fetchData',{perPage: this.itemsPerPage,page: this.page});
                        this.confirm = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }
                
            },
            next(param){
                this.page = param.page;
                this.$store.dispatch('categoriesModule/fetchData',{perPage: param.itemsPerPage,page: param.page});
            },
            setItemToStore(id){                
                this.eventCrud = 1;
                this.$store.dispatch('categoriesModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                    this.dialog = true;
                });
            },
            setItemToDelete(id){
                this.eventCrud = 2;
                this.confirm = true;
                this.$store.dispatch('categoriesModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                });
            },
            cancelDelete(){
                this.eventCrud = 0;
                this.confirm = false;
                this.newOne = this.createFreshObj(); 
            },
            detalle(id){
                this.$store.dispatch('categoriesModule/fetchItem',id).then(() =>{
                    this.$router.push({ name: 'subcategories', params: {category: id}});
                });
            },
            validateMaster(){
                this.$store.dispatch('categoriesModule/setMaster',this.$route.params.group);
            }
        },
        computed: {
            label(){
                return this.eventCrud === 0 ? 'Guardar': (this.eventCrud === 1 ? 'Editar' : 'Eliminar' );
            },
            ...mapGetters('categoriesModule',['dataLength']),            
            ...mapState({
                user: state => state.user,
                loading: state => state.categoriesModule.loadingStatus,
                pages: state => state.categoriesModule.pages,
                docs: state => state.categoriesModule.data,
                item: state => state.categoriesModule.singleItem,
                master: state => state.groupsModule.singleItem
            })
        }
    }
</script>