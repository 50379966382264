<template>
  <v-main class="grey lighten-3 342">
    <Loader v-if="loading" :size="64" />
    <v-container>
      <v-card
        class="mx-auto justify-center aling-center ma-10"
        width="100%"
        height="100%"
        elevation="9"
      >
        <v-sheet>
          <div class="d-flex justify-center aling-center">
            <Logo min-width="250" @click="goback()" />
          </div>
        </v-sheet>
        <v-img height="250" src="/images/ehorsesale/slider/WW7G4K.jpeg"></v-img>

        <v-card-title>Crea tu cuenta</v-card-title>

        <v-card-subtitle>
          {{$t('globalAccess.salesSubtitle3')}} <b> {{ $t("global.platformName") }} </b><br />
          {{$t('globalAccess.haveAccount')}}, (<router-link
            to="/login/salesman"
            tag="b"
            class="cta-red cursor-mouse"
          >
            {{$t('buttons.login') + ' ' +$t('global.hereCTA')}}</router-link
          >
          )
        </v-card-subtitle>
        <v-card-text class="pa-5">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <!-- <v-alert color="green" type="success" v-if="validCode">
              Código Correcto</v-alert
            >
            <v-text-field
              v-if="!validCode"
              v-model="code"
              :counter="100"
              label="Código de invitación"
              required
              @keydown.enter="validateCode(code)"
            ></v-text-field> -->
            <div>
              <v-text-field
                v-model="user.email"
                :counter="100"
                :rules="emailRules"
                label="Email trabajo"
                required
              ></v-text-field>
              <v-text-field
                v-model="user.user"
                :counter="10"
                label="Nombre Usuario"
                required
                :rules="nameRules"
              ></v-text-field>
              <v-text-field
                v-model="user.pass"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                :rules="passRules"
                label="Contraseña"
                counter
                @click:append="show1 = !show1"
                required
              ></v-text-field>
              <v-text-field
                v-model="passValidation"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Confirmación de Contraseña"
                counter
                @click:append="show1 = !show1"
                required
              ></v-text-field>
            </div>
            <v-alert color="orange" type="info" v-if="showWarning">
              {{ alertText }}</v-alert
            >
            <v-alert type="info" dense>El inicio de sesión será con tu nombre de usuario.</v-alert>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-auto justify-center aling-center py-10">
          <!-- <v-btn
            v-if="!validCode"
            class="mr-4 black"
            @click="validateCode(code)"
            dark
          >
            Validar Código
          </v-btn> -->
          <v-btn class="mr-4 black" @click="submit(user)" dark>
            {{ labelButton }}
          </v-btn>
          <v-btn class="mr-4" @click="goback()">
            {{$t('buttons.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <NotificationContainer />
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark>
            <v-btn icon dark @click="rechazoTerminos()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Términos y condiciones</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-toolbar-items>
                            <v-btn
                                color="amber"
                                text
                                @click="aceptoTerminos();"
                            >
                                Acepto los términos y condiciones
                            </v-btn>
                        </v-toolbar-items> -->
          </v-toolbar>
          <v-row class="pa-10">
            <v-list three-line subheader>
              <v-list-item-content>
                <h2>Términos y condiciones</h2>
                <div v-html="lastOne.body"></div>
              </v-list-item-content>
            </v-list>
          </v-row>
          <v-row>
            <v-sheet
              class="dialog-footer 
                                mx-auto 
                                justify-center 
                                aling-center 
                                py-2 gray float-end"
              elevation="14"
            >
              <h4 class="mb-0">
                {{$t('globalAccess.termsCheck')}}
              </h4>
              <v-btn
                class="mx-4 mt-0 amber darken-2"
                @click="aceptoTerminos()"
                dark
              >
                <span>
                  {{$t('buttons.accept')}}
                </span>
              </v-btn>
            </v-sheet>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
  </v-main>
</template>

<script>
import { mapState } from "vuex";

import EventBus from "../../services/events";
import NotificationContainer from "@/components/NotificationContainer";
import Loader from "@/components/adminSide/Loader";
import Logo from "@/components/base/Logo";

export default {
  name: "salesManRegist",
  components: {
    NotificationContainer,
    Loader,
    Logo,
  },
  data() {
    return {
      valid: true,
      dialog: false,
      user: {
        email: "",
        user: "",
        pass: "",
      },
      passValidation: "",
      show1: false,
      acepto: false,
      emailRules: [
        (v) => !!v || this.$t('globalAccess.alertEmailRequired'),
        (v) => /.+@.+\..+/.test(v) || this.$t('globalAccess.alertEmailInvalid'),
      ],
      nameRules: [
        (v) => !!v || "Nombre es requerido",
        (v) =>
          /^[a-zA-Z0-9_.-]*$/.test(v) ||
          this.$t('globalAccess.alertNameRules'),
      ],
      passRules: [
        (v) => !!v || "Contraseña es requerida",
        (v) =>
          /^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])\S{8,}$/.test(v) ||
          this.$t('globalAccess.alertEmailRules'),
      ],
      alertText: "",
      showWarning: false,
      code: "",
      validCode: false,
    };
  },
  computed: {
    labelButton() {
      return this.acepto ? "Registrarme" : "Continuar";
    },
    ...mapState({
      loading: (state) => state.adminSalesModule.loadingStatus,
      salesman: (state) => state.adminSalesModule.user,
      auth: (state) => state.adminSalesModule.auth,
      lastOne: (state) => state.termsModule.lastItem,
    }),
  },
  methods: {
    async validateCode(code) {
      this.code = code;
      await this.$store.dispatch("invitationCodesModule/fetchItemByName", code);
    },
    async submit(user) {
      this.validate();
      if (user.email != "" && user.pass != "" && user.user != "") {
        if (user.pass === this.passValidation) {
          if (this.acepto) {
            user.check = this.lastOne;
            user.useCode = this.code;
            await this.$store.dispatch("adminSalesModule/createAdmin", user);
          } else {
            this.dialog = true;
          }
        } else {
          this.alertText = "Confirmación de contraseña incorrecta.";
          this.showWarning = true;
        }
      } else {
        this.alertText = "Completa los campos requeridos.";
        this.showWarning = true;
      }
    },
    goToDashboard() {
      this.$router.push({ name: "dashboardSales" });
    },
    goToLogin() {
      this.$router.push({ name: "salesManAccess" });
    },
    aceptoTerminos() {
      this.dialog = false;
      this.acepto = true;
      this.showWarning = false;
    },
    rechazoTerminos() {
      this.acepto = false;
      this.dialog = false;
      this.alertText =
        this.$t('globalAccesss.termsRequired');
      this.showWarning = true;
    },
    goback() {
      this.$router.push({ name: "homepaga-us" });
    },
    validate() {
      this.$refs.form.validate();
    },
  },
  async mounted() {
    if (this.$session.exists() && this.$session.get("type") === "SLS") {
      this.goToDashboard();
    }
    EventBus.$on("registerCorrect", () => {
      this.$session.destroy();
      this.goToLogin();
    }),
      EventBus.$on("registerError", () => {
        this.alertText = "Revisa tus datos y contraseña.";
        this.showWarning = true;
      });
    EventBus.$on("code-succes", () => {
      this.validCode = true;
      this.showWarning = false;
    });
    EventBus.$on("code-incorrect", () => {
      this.alertText =
        "Valida el código de invitación, contacta a un administrador";
      this.showWarning = true;
    });
    await this.$store.dispatch("termsModule/getPublicableTerms");
  },
};
</script>

<style>
.dialog-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
</style>
