import AdminServices from "@/services/AdminServices";
import axiosWrapper from '@/services/AxiosWrapper';
import EventBus from "@/services/events";

export const namespaced = true;

export const state = {
  loadingStatus: false,
  data: [],
  singleItem: {},
  pages: 0,
  total: 0,
  needToUpdate: false,
  auth: '',
};

export const mutations = {
  SET_LOADING_STATUS(state, status) {
    state.loadingStatus = status;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_NEW_ADMIN(state, item) {
    // generic?
    state.data.push(item);
  },
  SET_PAGES(state, pages) {
    state.pages = pages;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_ADMIN(state, admin) {
    // generic?
    state.singleItem = admin;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_AUTH(state, token) {
    state.auth = token;
  },
  SET_NEED(state, needToUpdate) {
    state.needToUpdate = needToUpdate;
  },
};

export const actions = {
  logOut(){
    axiosWrapper.setAuth('');
  },
  authRec({commit},auth){
    commit("SET_AUTH", auth);
    axiosWrapper.setAuth(auth);
  },
  logIn({ commit, dispatch }, user) {
    commit("SET_LOADING_STATUS", true);
    AdminServices.auth(user)
      .then((response) => {
        commit("SET_LOADING_STATUS", false);
        if (response.data.token != null) {
          commit("SET_AUTH", response.data.token);
          axiosWrapper.setAuth(response.data.token);
          commit("SET_USER", response.data.user);        
          commit("SET_NEED", response.data.needToUpdate);
          const notification = {
            type: "success",
            message: "Bienvenido " + user.name,
          };
          dispatch("notificationModule/add", notification, { root: true });
          EventBus.$emit("logInSuccess");
        } else {
          const notification = {
            type: "info",
            message: "Usuario y/o contraseña incorrectos. ",
          };
          dispatch("notificationModule/add", notification, { root: true });
          EventBus.$emit("logInNULL");
        }
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "Problemas al iniciar sesión. " + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  fetchData({ commit, dispatch }, { perPage, page }) {
    commit("SET_LOADING_STATUS", true);
    AdminServices.getAdminsPage(perPage, page) // generic?
      .then((response) => {
        commit("SET_LOADING_STATUS", false);
        commit("SET_DATA", response.data.docs);
        commit("SET_PAGES", response.data.totalPages);
        commit("SET_TOTAL", response.data.totalDocs);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "Problema obteniendo los administradores " + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  createAdmin({ commit, dispatch }, admin) {
    commit("SET_LOADING_STATUS", true);
    return AdminServices.postAdmin(admin)
      .then((response) => {
        if (response.data.exito) {
          let emailData = {
            newAdmin: admin,
            location: window.location.origin,
          };
          dispatch("emailModule/adminInvitation", emailData, { root: true });
          commit("SET_NEW_ADMIN", admin);
          const notification = {
            type: "success",
            message: "Administrador creado correctamente",
          };
          dispatch("notificationModule/add", notification, { root: true });
          commit("SET_LOADING_STATUS", false);
        }
      })
      .catch(() => {
        const notification = {
          type: "error",
          message: "Existe un problema al crear al administrador",
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  fetchAdmin({ commit, dispatch, getters }, id) {
    var admin = getters.getDataByID(id);

    if (admin) {
      commit("SET_ADMIN", admin);
    } else {
      AdminServices.getAdmin(id)
        .then((response) => {
          commit("SET_ADMIN", response.data);
        })
        .catch((error) => {
          const notification = {
            type: "error",
            message: "Tenemos un problema al obtener los datos de este administrador " + error.message,
          };
          dispatch("notificationModule/add", notification, { root: true });
        });
    }
  },
  deleteAdmin({ commit, dispatch }, admin) {
    commit("SET_LOADING_STATUS", true);
    return AdminServices.deleteAdmin(admin)
      .then(() => {
        commit("SET_LOADING_STATUS", false);
        const notification = {
          type: "success",
          message: "Admin eliminado correctamente",
        };
        dispatch("notificationModule/add", notification, { root: true });
      })
      .catch(() => {
        const notification = {
          type: "error",
          message: "Existe un problema al borrar a este administrador",
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  updateAdmin({ commit, dispatch }, admin) {
    commit("SET_LOADING_STATUS", true);
    return AdminServices.updateAdmin(admin)
      .then(() => {
        commit("SET_LOADING_STATUS", false);
        const notification = {
          type: "success",
          message: "Admin actualizado correctamente",
        };
        dispatch("notificationModule/add", notification, { root: true });
      })
      .catch(() => {
        const notification = {
          type: "error",
          message: "Existe un problema al actualizar al administrador",
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  updatePass({ commit, dispatch, state }, admin) {
    commit("SET_LOADING_STATUS", true);
    admin.user = state.user;
    return AdminServices.updateAdminPass(admin)
      .then((response) => {
        if(response.data.exito){
          commit("SET_LOADING_STATUS", false);
          commit("SET_NEED", false);
          const notification = {
            type: "success",
            message: "Contraseña actualizado correctamente",
          };
          dispatch("notificationModule/add", notification, { root: true });
          EventBus.$emit("updateSuccess");
        }else{
          const notification = {
            type: "error",
            message: response.data.msg,
          };
          dispatch("notificationModule/add", notification, { root: true });
        }
      })
      .catch(() => {
        const notification = {
          type: "error",
          message: "Valida tu información",
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
};

export const getters = {
  getData(state) {
    return state.data;
  },
  getDataByID: (state) => (id) => {
    return state.data.find((admin) => admin._id === id);
  },
  dataLength: (state) => {
    return state.total;
  },
};
