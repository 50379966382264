import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeViews from './home.routes';
import AdminRoutes from './admin.routes';
import SalesRoutes from './sales.routes';


Vue.use(VueRouter)

const routes = [
  ...AdminRoutes,
  ...HomeViews,
  ...SalesRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

export default router
