<template>
  <v-app class="robotoFont">
    <router-view/>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    data(){
      return{
        users: 0
      }
    },
    sockets: {
      connect() {
        console.debug('Usuario conectado');
      },
      disconnect(){
        console.debug("Usuario desconectado");
      }
    },
    created(){
      this.$socket.$subscribe('user_connected', () => {
        this.users ++;
      });
    }
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

  .robotoFont {
    font-family: 'Roboto';
  }

  .robotoFontBold {
    font-family: 'Roboto', 'Bold';
  }

  .bebasFont {
    font-family: 'Bebas Neue' !important;
  }

  .cursor-mouse {
    cursor: pointer;
  }

  .cta-red {
    color: #df2127
  }

  .drawer-menu-left{
    position:fixed; 
    top:0; 
    right:0; 
    overflow-y:scroll; 
    z-index: 10001;
  }
   
</style>
