<template>
  <div class="notification-container">
      <NotificationBar
        v-for= "notification in notifications"
        :key= "notification.id"
        :notification = "notification"
       /> 
  </div>
</template>

<script>

import NotificationBar from '@/components/NotificationBar'
import { mapState } from 'vuex'

export default {
    components:{
        NotificationBar
    },
    computed:{
        ...mapState('notificationModule',['notifications'])
    }
}
</script>

<style>
    .notification-container{
        position: fixed;
        right: 4px;
        bottom: 0px;
        z-index: 1000;
    }
</style>