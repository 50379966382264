<template>
  <div class="full-height pt-16" >
    <p v-if="processing" class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 text-center font-weight-black">
      Validando dirección de correo      
      <Loader v-if="loading" :size="64" />
    </p>
    <div v-else>
      <div v-if="correct">
        <p class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 text-center font-weight-black">
          Validado correctamente
          <br>
        (<router-link to="/login/salesman" tag="b" class="cta-red cursor-mouse"> iniciar sesión  aquí</router-link>  )
        </p>
        <v-row>
          <v-col>            
              <v-img 
                  :aspect-ratio="16/9"
                  class="mx-5 justify-center aling-center" 
                  :src="'/images/emailValidation.jpeg'" 
                  max-height="300px"
                  alt="emptystate" 
                  contain
              />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <p class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 text-center font-weight-black">
          Existe un problema con tu correo
        </p>      
      </div>
    </div>
    <BlackTitle title="Video Tutoriales" />
    <div class="mx-5 mt-5 mb-16">
      <v-row justify="center">
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="text-center" v-for="(video,i) in videos" :key="i" >
          <youtubeVisor :video="video.video" :small="true" />
          <h3>{{video.title}}</h3>
          <p> {{video.description}} </p>
        </v-col>
        <v-btn color="primary" block @click="goToRoute('help')">
          Ver más...
        </v-btn>
      </v-row>
    </div>

  </div>
</template>

<script>
import Loader from '@/components/adminSide/Loader';
import {mapState} from 'vuex'
import EventBus from '@/services/events';
import BlackTitle from "@/components/userSide/blackTitle";

import youtubeVisor from '@/components/adminSide/youtubeVisor';

export default {
  data(){
    return{
      processing: true,
      correct: false,
      id: '',
      videos:[
        { video: "K-RuvLpUawE", title: "Registro como vendedor.", description: "Registro para vendedores." },
        { video: "pwVIgJ8LVFc", title: "Primer inicio de sesión", description: "Te acompañamos durante tu primer inicio de sesión en la plataforma" },
        { video: "oNKLIw0r2n4", title: "Registro Express de caballos", description: "Registro sencillo de caballo con la información básica." },
        { video: "7p2eKoIgvpg", title: "Registro avanzado caballo", description: "Registro completo de caballo." },
        { video: "lzVyhnjirS4", title: "Perfil de vendedor y compra de Inscripciones", description: "Te acompañamos durante la actualización de datos personales, y compra de tus primeras inscripciones" },
        { video: "UakLmJc8z8Q", title: "Subir video a youtube", description: "Acompañamiento para subir tu primer video a youtube" },
      ]
    }
  },
  created(){
    if(this.processing){
      this.$store.dispatch('adminSalesModule/validateEmail',this.$route.params.salesman).then(() =>{
        this.processing = false;
      });
    }
  },
  mounted(){
    EventBus.$on('validate-email-response',(payload) => {
      this.correct = payload;
    });
  },
  components:{
    Loader,
    BlackTitle,
    youtubeVisor
  },
  computed:{
    ...mapState({
				loading: state => state.adminSalesModule.loadingStatus,
			}),
  }
}
</script>

<style>

</style>