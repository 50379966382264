<template>
    <div>
        <Paginador :dataLength="dataLength" :pages="pages" :CurrentPage="page" @next="next"/>
        <div class="ma-3 pl-3">
            <h3>Artículos capturados</h3>
            <h1>Artículos</h1>
        </div>
        <v-overlay v-if='loading'>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-card
            elevation="5"
            class="mt-0 ma-1"
        >
            <v-card-title>
                <v-spacer></v-spacer>
                
                    <v-btn
                        @click="create()"
                        dark
                    >
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                        Capturar
                    </v-btn>
                
            </v-card-title>   
            <template v-if="dataLength > 0">
                <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>                           
                        <th class="text-left">
                            
                        </th>
                        <th class="text-left">
                            Nombre
                        </th>
                        <th class="text-left">
                            Descripción
                        </th>
                        <th class="text-left">
                            Precio
                        </th>
                        <th class="text-left">
                            Costo
                        </th>
                        <th class="text-left">
                            Stock
                        </th>
                        <th class="text-left">
                            Tag
                        </th>
                        <th class="text-left">
                            
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in docs"
                            :key="item._id"
                        >
                            <td>
                                 <v-img
                                    contain
                                    max-height="80"
                                    max-width="80"
                                    :src="item.image"
                                ></v-img>   
                            </td>
                            <td>
                                <span>
                                    {{ item.name }}
                                </span>
                            </td>    
                            <td>    
                                <span>
                                    {{ item.description }}
                                </span>
                            </td>    
                            <td>     
                                <span>
                                    {{ item.price }}
                                </span>
                            </td>    
                            <td>     
                                <span>
                                    {{ item.cost }}
                                </span>
                            </td>    
                            <td>     
                                <span>
                                    {{ item.stock }}
                                </span>
                            </td>    
                            <td>     
                                <span>
                                    {{ item.tag }}
                                </span>
                                    
                            </td>
                            <td class="text-right">
                                <v-menu
                                        bottom
                                        left
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                        <v-list-item v-on:click="setItemToStore(item._id)">
                                            <v-list-item-title ><v-icon>mdi-lead-pencil</v-icon> Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-on:click="setItemToDelete(item._id)">
                                            <v-list-item-title><v-icon color="red">mdi-trash-can</v-icon> Eliminar</v-list-item-title>
                                        </v-list-item>
                                        </v-list>
                                    </v-menu>
                            </td>      
                        </tr>
                    </tbody>
                </template>
                </v-simple-table>
            </template>
             <v-row dense class="mx-auto justify-center aling-center" v-else>
                <EmptyState :white="true"/>
            </v-row>    
            <v-sheet 
                class="dialog-footer 
                    mx-auto 
                    justify-center 
                    aling-center 
                    py-2 gray float-end"
                elevation="14"   
                v-if="pages > 0" 
            >
                <p>Total de Artículos es de 
                    <strong>
                        {{ dataLength }}
                    </strong>
                </p>
                <v-spacer></v-spacer>
                <v-pagination v-if="pages > 0"
                    v-model="page"
                    :length="pages"
                    :total-visible="7"
                    @input="next"
                    dark
                    color="black"
                ></v-pagination>   
            </v-sheet> 
        </v-card>
        
        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card>
                <v-toolbar                    
                    dark
                >
                    <v-btn
                    icon
                    @click="dialog = false"
                    dark
                    >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Formulario Artículo</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <v-btn
                        text
                        color="amber"
                        dark
                        @click="guardar();"
                        :disabled="!valid"
                    >
                        {{ label }}
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-list
                    three-line
                    subheader
                >
                    <v-list-item>
                    <v-list-item-content class="ma-5">
                        <v-form enctype="multipart/form-data" v-model="valid">
                                <div id="preview">
                                    <img v-if="url" style="max-height: 200px; widht:100%;" :src="url" />
                                </div>
                                <v-file-input     
                                    v-model="file"                      
                                    counter
                                    accept="image/png, image/jpeg, image/bmp"
                                    show-size
                                    truncate-length="50"
                                    @change="changeFile()"
                                ></v-file-input>
                            <v-text-field
                                v-model="newOne.name"
                                :counter="100"
                                label="Nombre"
                                required
                                :rules="nameRules"
                            ></v-text-field>   
                            <v-text-field
                                v-model="newOne.description"
                                :counter="100"
                                label="Description"
                                required
                            ></v-text-field>   
                            <v-text-field
                                v-model="newOne.price"
                                label="Precio de Venta"
                                required
                            ></v-text-field>   
                            <v-text-field
                                v-model="newOne.cost"
                                label="Costo"
                                required
                            ></v-text-field>   
                            <v-text-field
                                v-model="newOne.stock"
                                label="Stock del producto"
                                required
                            ></v-text-field>   
                            <v-col cols="12">
                                <v-select
                                    v-model="tag"                                    
                                    :items="tagsData"
                                    item-text="name"
                                    item-value="_id"
                                    label="Select"
                                    persistent-hint
                                    return-object
                                    single-line
                                    @input="changeTag(tag._id)"
                                ></v-select> 
                            </v-col>                          
                        </v-form>    
                    </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
                v-model="confirm"
                persistent
                max-width="400"
            >
                <v-card>
                <v-card-title class="headline">
                    ¿Deseas eliminar {{newOne.name}}?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    @click="cancelDelete()"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="red"
                        text
                        @click="guardar()"
                    >
                    Eliminar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        
        
    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import EmptyState from '@/components/EmptyState';
    import Paginador from '@/components/adminSide/Paginador';
    
    
    export default {
        data(){
            return{
                valid:false,
                nameRules: [
                    v => !!v || 'Versión requerida'
                ],
                dialog: false,
                confirm: false,
                newOne: this.createFreshObj(),
                show1: false,
                page: 1,
                eventCrud: 0, // 0 for new, 1 for edit, 2 for delete,
                //select data
                tag:{},
                file:null,
                url: null         
            }
        },
        components:{
            EmptyState,
            Paginador
            
        },
        created(){
            this.init();
        },
        methods:{
            create(){
                this.createFreshObj();
                this.dialog = true;
                this.eventCrud = 0;
            },
            createFreshObj(){
                // const user = this.$store.state.user;
                return {
                    name: '',
                    description: '',
                    cost: '',
                    price: '',
                    stock: '',
                    tag: {},
                    status: true,
                    image: null
                }
            },
            init(){
                this.$store.dispatch('articlesModule/fetchData',{perPage: 5,page: this.page});
                this.$store.dispatch('tagsModule/fetchData',{perPage: 5,page: this.page});
                this.newOne.name = "";
            },
            guardar(){         
                this.newOne.city = this.tag._id;       
                if(this.eventCrud === 0){// CREATE
                        this.$store.dispatch('articlesModule/createItem',this.newOne).then(() => {
                        this.newOne = this.createFreshObj(); 
                        this.$store.dispatch('articlesModule/fetchData',{perPage: 5,page: this.pages});                    
                        this.dialog = false;
                    });
                }else if(this.eventCrud ===1){ //EDIT
                    this.$store.dispatch('articlesModule/updateItem',this.newOne).then(() =>{
                        this.$store.dispatch('articlesModule/fetchData',{perPage: 5,page: this.page});
                        this.dialog = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }else{
                    this.$store.dispatch('articlesModule/deleteItem',this.newOne._id).then(() =>{
                        this.$store.dispatch('articlesModule/fetchData',{perPage: 5,page: this.page});
                        this.confirm = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }
                
            },
            next(param){
                this.page = param;
                this.$store.dispatch('groupsModule/fetchData',{perPage: this.itemsPerPage,page: this.page});
            },
            setItemToStore(id){                
                this.eventCrud = 1;
                this.$store.dispatch('articlesModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                    this.dialog = true;
                });
            },
            setItemToDelete(id){
                this.eventCrud = 2;
                this.confirm = true;
                this.$store.dispatch('articlesModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                });
            },
            cancelDelete(){
                this.eventCrud = 0;
                this.confirm = false;
                this.newOne = this.createFreshObj(); 
            },
            changeTag(master){
                this.newOne.tag = master;
            },
            changeFile(){
                
                this.newOne.image = this.file;
                this.url = URL.createObjectURL(this.file)
            },
        },
        computed: {
            label(){
                return this.eventCrud === 0 ? 'Guardar': (this.eventCrud === 1 ? 'Editar' : 'Eliminar' );
            },
            ...mapGetters('articlesModule',['getData']),                     
            ...mapGetters('tagsModule',['dataLength']),            
            ...mapState({
                user: state => state.user,
                loading: state => state.articlesModule.loadingStatus,
                pages: state => state.articlesModule.pages,
                docs: state => state.articlesModule.data,
                item: state => state.articlesModule.singleItem,
                tagsData: state => state.tagsModule.data
            })
        }
    }
</script>