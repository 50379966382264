<template>
    <v-main class="grey lighten-3">
        <v-overlay v-if='loading'>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-container>
            <v-card
                class="mx-auto justify-center aling-center ma-10"
                width="100%"
                height="100%"
                elevation="5"
            >
                     <v-sheet>
                        <div class="d-flex justify-center aling-center">
                            <v-img
                                alt="eHorseSale logo"
                                class="shrink mt-1 cursor-mouse "
                                contain
                                min-width="250"
                                src="https://www.ehorsesale.com/images/ehorsesale/blackbox.png"
                                width="100"
                                @click="goback()"
                            />                            
                        </div>
                    </v-sheet>
                    <v-img
                        height="250"
                        src="/images/ehorsesale/slider/WW7G4K.jpeg" 
                    ></v-img>
                    
                    <v-card-title> Datos de Vendedor </v-card-title>

                    <v-card-subtitle>
                        Queremos conocerte mejor, ayudanos a llenar la siguiente información                                     
                    </v-card-subtitle>

                    <v-card-text class="pa-10">
                        <v-spacer></v-spacer>
                        <form>
                            
                            <v-text-field
                                v-model="salesmenCapture.name"
                                :counter="100"
                                label="Nombre (s)"
                                :rules="nameRules"
                            ></v-text-field>
                            <v-text-field
                                v-model="salesmenCapture.lastnamep"
                                :counter="100"
                                label="Apellido paterno"
                                :rules="apRules"
                            ></v-text-field>
                            <v-text-field
                                v-model="salesmenCapture.lastnamem"
                                :counter="100"
                                label="Apellido materno"
                                :rules="amRules"
                            ></v-text-field>
                            <div id="preview">
                                <img v-if="url" style="max-height: 200px; widht:100%;" :src="url" />
                            </div>
                            <v-file-input     
                                label="Foto de perfil "
                                v-model="file"                      
                                counter
                                accept="image/png, image/jpeg, image/bmp"
                                show-size
                                truncate-length="50"
                                @change="changeFile()"
                            ></v-file-input>
                        </form>

                        <v-alert
                            color="orange"
                            type="info"
                            v-if="showWarning"
                        > {{alertText}}</v-alert>

                        <v-card-actions>
                         <v-btn
                            class="mr-4 black"
                            @click="guardar()"
                            dark
                        >
                            Guardar información
                        </v-btn>
                    </v-card-actions>
                    </v-card-text>
                    

            </v-card>
        </v-container>
        <NotificationContainer />

  </v-main>
</template>

<script>
import { mapState } from 'vuex';
import EventBus from '../../services/events';
import NotificationContainer from '@/components/NotificationContainer';

export default {
    name: 'salesManData',
    components:{
        NotificationContainer
    },
    data(){
        return{
            salesmenCapture:{
                name: ''
            },
            file:null ,
            url:null,
            alertText: "",
            showWarning: false,
            nameRules: [
                v => !!v || 'Nombre es requerido',
            ],   
            apRules: [
                v => !!v || 'Apellido Paterno es requerido',
            ],   
            amRules: [
                v => !!v || 'Apellido Materno es requerido',
            ],   
        }
    },
    computed:{
      ...mapState({
            loading: state => state.salesmenModule.loadingStatus,
            salesman: state => state.adminSalesModule.user,
            auth: state => state.adminSalesModule.auth,
            justSaved: state=> state.salesmenModule.justSaved,
            singleItem: (state) => state.ranchsModule.singleItem,
        })
    },
    methods: {
      goToDashboard(){
          this.$router.push({ name: 'dashboardSales'});
      },
      guardar(){
        this.salesmenCapture.ranch = this.$session.get('ranchProcess') ? this.$session.get('ranchProcess') : this.singleItem._id;
        let currentID = this.$session.get('current');
        let toSave = this.salesmenCapture;


        if(
            this.salesmenCapture.name !== '' &&
            this.salesmenCapture.lastnamep !== '' &&
            this.salesmenCapture.lastnamem !== '' 
        ){
            if(this.salesmenCapture.image){

                this.$store.dispatch('salesmenModule/createItem',this.salesmenCapture).then(()=>{
                    let saved = this.justSaved;
                let toUpdate= {
                    admin: currentID,
                    item: toSave,
                    field: saved
                }
                this.$store.dispatch('adminSalesModule/updateSalesman',toUpdate);

                });
            } else {
                this.alertText = "Imagen de perfil es requerida";
                this.showWarning = true;
            }   
        } else {
            this.alertText = "Completa los campos requeridos.";
            this.showWarning = true;
        }
        
      },
      createFreshObj(){
            return {
                name: '',
                image: null,
                lastnamep: '',
                lastnamem: ''
            }
        },
        changeFile(){
            this.salesmenCapture.image = this.file;
            this.url = URL.createObjectURL(this.file)
        }
    },
    created(){
        this.salesmenCapture = this.createFreshObj();
        this.$store.dispatch('countriesModule/fetchAll');
    },
    mounted(){
        EventBus.$on("saved-salesman", () => {
            this.$session.set('salesman', this.justSaved);
            this.goToDashboard();
        });
    }
}
</script>

<style>

</style>