import CRUDServices from '@/services/CRUDServices';
import EventBus from '@/services/events';

export const namespaced = true;

export const state = {
    loadingStatus: false,
    user: null,
    auth: {},
    current: '',
    api: '/adminSales/',
    exportEmail: []
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_USER(state, user){
      state.user = user;
    },
    SET_AUTH(state, token){
        state.auth = token;
    },
    SET_CURRENT(state, current){
        state.current = current;
    },
    SET_EXPORT_EMAIL(state, exportEmail){
      state.exportEmail = exportEmail
    },
}

export const  actions = {
    logIn({commit,dispatch},user){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.auth(state.api+'login/',user)
        .then(response => {
            commit('SET_LOADING_STATUS',false);
            if(response.data.token != null){
              if(response.data.validated){
                commit('SET_AUTH',response.data.token);
                commit('SET_CURRENT',response.data.current);
                if(response.data.salesman != undefined || response.data.salesman != null){
                  commit('SET_USER',response.data.salesman);
                  commit('SET_LOADING_STATUS',false);
                  EventBus.$emit('logInSuccess');
                }else{
                  commit('SET_LOADING_STATUS',false);
                  EventBus.$emit('logInforNews');
                }
                const notification = {
                  type: 'success',
                  message: 'Bienvenido ' + user.name
                }
                dispatch('notificationModule/add', notification, {root: true}); 
              } else{
                EventBus.$emit('logInNotValidated');
              }
            } else {
                const notification = {
                    type: 'info',
                    message: 'Revisa tu usuario y contraseña.'
                }
                dispatch('notificationModule/add', notification, {root: true});
                EventBus.$emit('logInNULL');
            }
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'Problemas al iniciar sesión. ' + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
        
    },
    createAdmin({commit,dispatch},admin){
        commit('SET_LOADING_STATUS',true);
        return CRUDServices.postItem(state.api+'newSales/',admin).then(response => {
          if(response.data.exito){
            const notification = {
                type: 'success',
                message: 'Vendedor registrado correctamente.'
            }
            let adminCheck = {
              admin: response.data.obj,
              term: admin.check._id,
              checkDate: Date.now()
            }
            let emailData = {
              salesman: admin,
              id: response.data.obj,
              location: window.location.origin
            }
            dispatch('termsModule/salesCheck',adminCheck,{root: true});
            dispatch('notificationModule/add', notification, {root: true});
            dispatch('emailModule/welcomeSalesman',emailData, {root: true});
            commit('SET_LOADING_STATUS',false);
            EventBus.$emit('registerCorrect');
          } else {
            commit('SET_LOADING_STATUS',false);            
            const notification = {
              type: 'error',  
              message: (response.data.msg.includes('email')) ? 'email ya registrado' : 'Nombre de usuario ya registrado'
            }
            dispatch('notificationModule/add', notification, {root: true});
          }
        }).catch(()=>{
          const notification = {
              type: 'error',
              message: 'Problemas al registro.'
          }
          dispatch('notificationModule/add', notification, {root: true});
          EventBus.$emit('registerError');
        });
      },
      updateSalesman({commit,dispatch},toUpdate){
        commit('SET_LOADING_STATUS',true);
        commit('SET_USER',toUpdate.item);
        return CRUDServices.updateSingleField(state.api+'u/',toUpdate.admin,toUpdate.field).then(() => {
          commit('SET_LOADING_STATUS',false);
        }).catch(()=>{
          const notification = {
              type: 'error',
              message: 'Problemas al registrar los datos del vendedor.'
          }
          dispatch('notificationModule/add', notification, {root: true});
        });
      },
      validateEmail({commit,dispatch},toUpdate){
        commit('SET_LOADING_STATUS',true);
        return CRUDServices.updateSingleField(state.api+'validate/',toUpdate,true).then(() => {
          commit('SET_LOADING_STATUS',false);
          EventBus.$emit('validate-email-response',true);
        }).catch(()=>{
          const notification = {
            type: 'error',
            message: 'Problemas al registrar los datos del vendedor.'
          }
          dispatch('notificationModule/add', notification, {root: true});
          EventBus.$emit('validate-email-response',false);
        });
      },
      newPass({ commit, dispatch }, admin) {
        commit("SET_LOADING_STATUS", true);
        return CRUDServices.updateAdminPass(admin)
          .then((response) => {
            if(response.data.exito){
              commit("SET_LOADING_STATUS", false);
              const notification = {
                type: "success",
                message: "Contraseña actualizada correctamente",
              };
              dispatch("notificationModule/add", notification, { root: true });
              EventBus.$emit("updateSuccess");
            }else{
              const notification = {
                type: "error",
                message: response.data.msg,
              };
              dispatch("notificationModule/add", notification, { root: true });
            }
          })
          .catch(() => {
            const notification = {
              type: "error",
              message: "Valida tu información",
            };
            dispatch("notificationModule/add", notification, { root: true });
          });
      },
      async exportEmails({commit,dispatch}) {
        console.log(1)
        commit('SET_LOADING_STATUS',true);
        await CRUDServices.getDocs(state.api+'export')
        .then(response => {       
          commit('SET_LOADING_STATUS',false);
          console.log(3)
          commit('SET_EXPORT_EMAIL',response.data);
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'There was a problem ferching export email: ' + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
      },
}