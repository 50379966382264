<template>
  <div class="pa-2 pa-lg-16">
    <AppLoader v-if="loadingFront" :size="100" color="primary" />
    <NotificationContainer />
    <h2>Hola</h2>
    <h3>Bienvenido, ayudanos a conocerte un poco mejor.</h3>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1">
        Datos Personales
        <small>Queremos conocerte un poco mejor</small>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-form v-model="valid">
          <v-row>
             <v-col cols="12" md="6">
                <v-text-field
                  v-model="newUser.name"
                  :counter="100"
                  label="* Nombre"
                  required
                  :rules="nameRules"
                ></v-text-field>
                <v-text-field
                  v-model="newUser.lastnamep"
                  :counter="100"
                  label="* apellido paterno"
                  required
                  :rules="lastnamepRules"
                ></v-text-field>
                <v-text-field
                  v-model="newUser.lastnamem"
                  :counter="100"
                  label="* apellido materno"
                  required
                  :rules="lastnamemRules"
                ></v-text-field>
                <div id="preview text-center mx-auto">
                  <img v-if="url" style="max-height: 200px; widht:100%;" :src="url" />
                </div>
                <v-file-input
                  v-model="file"
                  counter
                  label="* Foto de perfil"
                  accept="image/png, image/jpeg, image/bmp"
                  show-size
                  truncate-length="50"
                  @change="changeFile()"
                ></v-file-input>
             </v-col>
						<v-col cols="12" md="6">
							<v-text-field
								v-model="newUser.cellPhone"
								:counter="100"
								label="* Teléfono celular"
								:rules="cellPhoneRules"
							></v-text-field>
							<v-text-field
								v-model="newUser.homePhone"
								:counter="100"
								label="Teléfono de casa"
								required
							></v-text-field>
							<v-text-field
								v-model="newUser.address"
								:counter="100"
								label="Dirección"
								required
							></v-text-field>
							<v-text-field
								v-model="newUser.zip"
								:counter="5"
								label="Código postal"
								required
                type="number"
							></v-text-field>
						</v-col>
					</v-row>
          <div class="clear"></div>
          <v-alert color="orange" type="info" v-if="showWarning">
            {{ alertText }}</v-alert
          >
        </v-form>
				<v-row>
					<v-col>
						<v-btn block :disabled="!valid" @click="canContinuar()" color="primary">
							Continuar
						</v-btn>

					</v-col>
					<v-col>
						<v-btn block @click="logout()">
							Cancelar
						</v-btn>
					</v-col>
				</v-row>
      </v-stepper-content>
      <!-- <v-stepper-step :complete="e6 > 2" step="2">
        Registro de Tarjeta
      </v-stepper-step>
      <v-stepper-content step="2">
        <h1>
          Se realizará un cargo de $10.00 MXN para validar y guardar tu tarjeta.
        </h1>
        <iframe
          src="https://ppp-test.safecharge.com/ppp/purchase.do?currency=EUR&item_name_1=Test+Product&item_number_1=1&item_quantity_1=1&item_amount_1=50.00&numberofitems=1&encoding=utf-8&merchant_id=640817950595693192&merchant_site_id=148133&time_stamp=2018-05-15.02:35:21&version=4.0.0&user_token_id=ran100418_scobd%40mailinator.com&user_token=auto&total_amount=50.00&notify_url=https://sandbox.safecharge.com/lib/demo_process_request/response.php&theme_id=178113&checksum=66ce9f4ce1e5f47298e7e5e457d0b21ca8d6a668d549240929924054db6d1a21"
          frameborder="0"
          height="500px"
          width="100%"
        ></iframe>
        <v-btn color="primary" @click="guardar()">
          Continuar
        </v-btn>
        <v-btn @click="logout()">
          Cancelar
        </v-btn>
      </v-stepper-content> -->

      <v-stepper-step :complete="e6 > 3" step="2">
        Documentación
        <small>Ayudanos a validar tu identidad</small>
      </v-stepper-step>
      <v-stepper-content step="3">
        <form>
          <v-row>
            <v-col sm="4">
              <h3>INE (Frente)</h3>
              <v-row>
                <v-col cols="12" md="9">
                  <v-file-input
                    v-model="ine"
                    counter
                    accept="image/png, image/jpeg, image/bmp"
                    show-size
                    truncate-length="50"
                    @change="changeFileIne()"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3">
                  <v-btn :disabled="!ineUp" @click="upload(2)">
                    <v-icon left>
                      mdi-upload
                    </v-icon>
                    subir
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="4">
              <h3>INE (Trasera)</h3>
              <v-row>
                <v-col cols="12" md="9">
                  <v-file-input
                    v-model="ineBack"
                    counter
                    accept="image/png, image/jpeg, image/bmp"
                    show-size
                    truncate-length="50"
                    @change="changeFileIneBack()"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3">
                  <v-btn :disabled="!ineBackUp" @click="upload(4)">
                    <v-icon left>
                      mdi-upload
                    </v-icon>
                    subir
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="4">
              <h3>Comprobante de domicilio</h3>
              <v-row>
                <v-col cols="12" md="9">
                  <v-file-input
                    v-model="comp"
                    counter
                    accept="image/png, image/jpeg, image/bmp"
                    show-size
                    truncate-length="50"
                    @change="changeFileComp()"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3">
                  <v-btn :disabled="!compUp" @click="upload(3)">
                    <v-icon left>
                      mdi-upload
                    </v-icon>
                    Subir
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </form>
        <v-btn
          color="primary"
          :disabled="!(ineUp && compUp && ineBackUp)"
          @click="goToDashboard()"
        >
          Finalizar
        </v-btn>
        <v-btn text @click="logout()">
          Cancelar
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NotificationContainer from "@/components/NotificationContainer";
import EventBus from "../../services/events";
import AppLoader from "@/components/base/AppLoader.vue";
// import cardForm from '@/components/CardForm.vue'

export default {
  data() {
    return {
      newUser: {
        name: "",
        lastnamep: "",
        lastnamem: "",
        cellPhone: "",
      },
      file: null,
      show1: false,
      e6: 1,
      ine: null,
      ineBack: null,
      comp: null,
      caratula: null,
      url: null,
      ineUp: false,
      ineBackUp: false,
      compUp: false,
      alertText: "",
      showWarning: false,
      valid: false,
      nameRules: [
        (v) => !!v || "Nombre requerido",
        (v) => v.length < 100 || "Nombre debe ser menor a 100 caracteres",
      ],
      lastnamepRules: [
        (v) => !!v || "Apellido Paterno requerido",
        (v) => v.length < 100 || "Nombre debe ser menor a 100 caracteres",
      ],
      lastnamemRules: [
        (v) => !!v || "Apellido Materno requerido",
        (v) => v.length < 100 || "Nombre debe ser menor a 100 caracteres",
      ],
      cellPhoneRules: [
        (v) => !!v || "Celular requerido",
        (v) => v.length < 100 || "Nombre debe ser menor a 100 caracteres",
      ],
    };
  },
  components: {
    NotificationContainer,
    AppLoader,
    // cardForm
  },
  computed: {
    ...mapState({
      loadingFront: (state) => state.peopleModule.loadingStatus,
      person: (state) => state.frontUserModule.person,
      auth: (state) => state.frontUserModule.auth,
      justSaved: (state) => state.peopleModule.justSaved,
    }),
  },
  methods: {
    goToDashboard() {
      this.$router.push({ name: "homepaga-us" });
    },
    logout() {
      this.$session.destroy();
      this.$router.push({ name: "homepaga-us" });
    },
    canContinuar() {
      if (
        this.newUser.name !== "" &&
        this.newUser.lastnamep !== "" &&
        this.newUser.lastnamem !== "" &&
        this.newUser.cellPhone !== ""
      ) {
        if (this.newUser.image) {
          this.guardar();
        } else {
          this.showWarning = true;
          this.alertText = "Imagen Requerida";
        }
      } else {
        this.showWarning = true;
        this.alertText = "Completa todos los campos para continuar";
      }
    },
    goToStep(index) {
      this.e6 = index;
    },
    async guardar() {
      let currentID = this.$session.get("current");
      let toSave = this.newUser;

      await this.$store.dispatch("peopleModule/createItem", this.newUser).then(async () => {
        let saved = this.justSaved;
        let toUpdate = {
          front: currentID,
          item: toSave,
          field: saved,
        };
        await this.$store.dispatch("frontUserModule/updateFrontUser", toUpdate);
        this.goToStep(3);
      });
    },
    createFreshObj() {
      return {
        name: "",
        phone: "",
        address: "",
        mail: "",
        city: {},
        status: true,
        image: null,
      };
    },
    changeFile() {
      this.newUser.image = this.file;
      this.url = URL.createObjectURL(this.file);
    },
    changeFileIne() {
      this.ineUp = true;
      this.newUser.ine = this.ine;
    },
    changeFileIneBack() {
      this.ineBackUp = true;
      this.newUser.ineBack = this.ineBack;
    },
    changeFileComp() {
      this.compUp = true;
      this.newUser.comp = this.com;
    },
    changeFileCaratula() {
      this.newUser.caratula = this.caratula;
    },
    async upload(index) {
      let fileUpdate;
      this.newUser._id = this.$session.get("person");

      switch (index) {
        case 1:
          fileUpdate = {
            _id: this.newUser._id,
            image: this.newUser.image,
            type: index,
          };
          break;
        case 2:
          fileUpdate = { _id: this.newUser._id, image: this.ine, type: index };
          break;
        case 3:
          fileUpdate = { _id: this.newUser._id, image: this.comp, type: index };
          break;
        case 4:
          fileUpdate = {
            _id: this.newUser._id,
            image: this.ineBack,
            type: index,
          };
          break;
        default:
          alert("Favor de revisar la imagen que subió");
      }
      await this.$store.dispatch("peopleModule/updateSingleImage", fileUpdate);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.$session.exists() && this.$session.get("type") === "FRN") {
      if (this.$session.get("person")) this.goToDashboard();
    } else {
      this.logout();
    }
    EventBus.$on("people-registered", (person) => {
      this.$session.set("person", person);
      EventBus.$emit("people-registered-home");
    });
  },
};
</script>

<style></style>
