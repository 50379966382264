export default {
  globalAccess: {
    //Admin Access
    adminTitle: "Acceso a Administradores.",
    subtitle1: "",
    subtitle3: "A continuación puedes iniciar sesión con tu cuenta asignada.",
    //Sales Access
    salesTitle: "Acceso a vendedores",
    salesSubtitle1: "¿Qué tal Amigo vendedor, te interesa vender a traves de ",
    salesSubtitle3: "Estas a un paso de iniciar a vender a través de ",
    //User Access
    //Global
    subtitle2: "Bienvenido a",
    haveAccount: "¿Ya tienes cuenta?",
    salesSubtitle2: "A continuación puedes iniciar sesión con tu cuenta.",
    salesSubtitleCTA: "Si aún no te registras puedes hacerlo",
    alertUserPass: "Revisa tu usuario y contraseña.",
    alertUser: "Revisa tu usuario.",
    alertUserRequired: "Ingresa tu usuario.",
    alertEmail: "Revisa tu correo electrónico.",
    alertEmailRequired: "Correo electrónico requerido.",
    alertEmailInvalid: "Correo electrónico invalido.",
    alertNameRules: "Nombre debe ser valido ( Debe contener solamente letras, números y los caracteres especiales (. - _) )",
    alertEmailRules: "Password debe ser valido (Min 8 caracteres, una minuscula, una mayuscula y un número)",
    alertEmailConfirmation: "Es requerido validar tu correo electrónico",
    termsCheck: "Expreso mi voluntad libremente de sujetarme a los términos y condiciones:",
    termsRequired: "Para continuar es necesario aceptar los términos y condiciones."
  },
  global: {
    //titles
    platformName: "eHorseSale",
    //CTA
    hereCTA: "aquí",
    passwordForgotCTA: "¿Olvidaste tu contraseña?"
  },
  buttons: {
    login: "iniciar sesión",
    cancel: "Cancelar",
    accept: "Aceptar",
    recover: "Recuperar",
  }
}