import CRUDServices from '@/services/CRUDServices';

export const namespaced = true;

export const state = {
    loadingStatus: false,
    data: [],
    singleItem: {},
    pages: 0,
    total: 0,
    api: '/states/',
    master: {}
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_DATA(state, data){
      state.data = data;
    },
    SET_NEW_OBJ(state, item){
      state.data.push(item)
    },
    SET_PAGES(state, pages){
      state.pages = pages
    },
    SET_TOTAL(state, total){
      state.total = total
    },
    SET_SINGLE(state, item){
      state.singleItem = item
    },
    SET_MASTER(state, item){
      state.master = item
    }
}

export const  actions = {
    setMaster({commit,dispatch},master){
      commit('SET_MASTER',master);
      dispatch('countriesModule/fetchItem',master,{root:true});
    },
    fetchData({state,commit,dispatch},{perPage,page}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocsPage(state.api+'p/'+state.master+'&',perPage,page)
      .then(response => {        
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data.docs);
        commit('SET_PAGES',response.data.totalPages);
        commit('SET_TOTAL',response.data.totalDocs);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching states: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchByMaster({state,commit,dispatch},master){
      CRUDServices.getDocsByMaster(state.api+'c/', master)
      .then(response => {        
        commit('SET_DATA',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching states: ' + error.message
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    }
    ,createItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.postItem(state.api,item).then(() => {
        commit('SET_NEW_OBJ',item);
        const notification = {
            type: 'success',
            message: 'Estado capturada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
        commit('SET_LOADING_STATUS',false);
      }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem creating state' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchItem({state,commit,dispatch,getters},id){
      var item = getters.getDataByID(id);

      if (item){
        commit('SET_SINGLE',item)
      }else{
        CRUDServices.getItem(state.api,id)
        .then(response => {
          commit('SET_SINGLE',response.data);
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'There was a problem ferching state: ' + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
      }
    },
    deleteItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.deleteItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Estado eliminada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem deleting state' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Estado actualizada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating state' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    }
}

export const getters = {
    getData(state){
      return state.data;
    },
    getDataByID: state => id => {
      return state.data.find(item => item._id === id);
    },
    getSub: state => {
      return state.data.filter(item => item.country === state.master);
    },
    dataLength: state =>{
      return state.data.filter(item => item.country === state.master).length
    }
  }