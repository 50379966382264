import CRUDServices from '@/services/CRUDServices';
import EventBus from '../../services/events';

export const namespaced = true;

let catalogName = 'Configuración';

export const state = {
    loadingStatus: false,
    data: [],
    singleItem: {},
    pages: 0,
    total: 0,
    api: '/config/',
    lastItem: {}
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_DATA(state, data){
      state.data = data;
    },
    SET_NEW_OBJ(state, item){
      state.data.push(item)
    },
    SET_PAGES(state, pages){
      state.pages = pages
    },
    SET_TOTAL(state, total){
      state.total = total
    },
    SET_SINGLE(state, item){
      state.singleItem = item
    },
    SET_LAST(state, item){
      state.lastItem = item
    }
}

export const  actions = {
    fetchAll({commit,dispatch}){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.getDocs(state.api)
        .then(response => {       
          let item = response.data[0];
          let photos = [];
          item.photos.forEach(
            photo => {
              photo = CRUDServices.getImage(photo);
              photos.push(photo)
            }
          );
          item.photos = photos;
          commit('SET_LOADING_STATUS',false);
          commit('SET_DATA',response.data);
          commit('SET_SINGLE',item);
          EventBus.$emit('set-single');
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'There was a problem ferching config: ' + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
    },
    fetchData({state,commit,dispatch},{perPage,page}){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.getDocsPage(state.api+'p/',perPage,page)
      .then(response => {        
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data.docs);
        commit('SET_PAGES',response.data.totalPages);
        commit('SET_TOTAL',response.data.totalDocs);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching config: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    createItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.postItem(state.api,item).then(() => {
        commit('SET_NEW_OBJ',item);
        const notification = {
            type: 'success',
            message: 'config capturada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
        commit('SET_LOADING_STATUS',false);
      }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem creating config' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchItem({state,commit,dispatch,getters},id){
      var item = getters.getDataByID(id);
      if (item){
        commit('SET_SINGLE',item)
      }else{
        CRUDServices.getItem(state.api,id)
        .then(response => {
          commit('SET_SINGLE',response.data);
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'There was a problem ferching config: ' + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
      }
    },
    deleteItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.deleteItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'config eliminada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem deleting config' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'config actualizada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating config' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },getPublicableconfig({state,commit,dispatch}){
        CRUDServices.getItem(state.api+'last/',0).then(response => {
          commit('SET_LAST',response.data);
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'There was a problem ferching the last: ' + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
      },
      updateSingleImage({state,commit,dispatch},item){
        commit('SET_LOADING_STATUS',true);
        return CRUDServices.updateSingleImage(state.api+'file/',item).then(() => {
          commit('SET_LOADING_STATUS',false);
          const notification = {
              type: 'success',
              message: catalogName + ' actualizada correctamente'
          }
          dispatch('notificationModule/add', notification, {root: true});
        }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem updating salesman' + error.message
          }
          dispatch('notificationModule/add', notification, {root: true});
        });
      },
      updateRemoveImage({state,commit,dispatch},item){
        commit('SET_LOADING_STATUS',true);
        return CRUDServices.updateRemoveImage(state.api+'removeFile/',item).then(() => {
          commit('SET_LOADING_STATUS',false);
          const notification = {
              type: 'success',
              message: catalogName + ' actualizada correctamente'
          }
          dispatch('notificationModule/add', notification, {root: true});
        }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem updating salesman' + error.message
          }
          dispatch('notificationModule/add', notification, {root: true});
        });
      },
}

export const getters = {
    getData(state){
      return state.data;
    },
    getDataByID: state => id => {
      return state.data.find(item => item._id === id);
    },
    dataLength: state =>{
      return state.total
    },
  }