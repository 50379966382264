<template>
  <div>
    <AdminHeader color="black" icon="cog" title="Panel de configuración" />
    <Loader v-if="loading" :size="64" />
    <ImagePreview
      v-if="imageDialog"
      :imgDialog="imgDialog"
      @closePreview="closePreview"
    />
    <v-card elevation="5" shaped class="mt-0 ma-1 pa-5">
      <v-card-title>
        <h6>Información relevante para comportamiento de la plataforma</h6>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-overlay v-if="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-list three-line subheader>
        <v-list-item>
          <v-list-item-content>
            <form>
              <p> <strong>Contenido </strong></p>
              <v-text-field
                v-model="newOne.copyWelcome"
                :counter="200"
                label="Frase de bienvenida"
                hint="Texto que se muestra en slider de pagina principal"
                required
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newOne.contactphone"
                    :counter="100"
                    label="Número de contacto"
                    required
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="newOne.email"
                    :counter="150"
                    label="Email de contacto"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <p> <strong>Multas </strong></p>
              <v-row>
                <v-col md="4">
                  <v-text-field
                    v-model="newOne.daysMulta"
                    :counter="150"
                    label="Días para multa"
                    required
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="newOne.multaMonto"
                    prefix="$"
                    label="Monto de multa"
                    suffix="MXN"
                    required
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="newOne.minBuy"
                    prefix="$"
                    label="Monto de confirmación de compra"
                    suffix="MXN"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            <p>
                <strong>Preconfiguración de ofertas en eventos </strong>
            </p>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="newOne.minBid"
                    suffix="%"
                    :counter="2"
                    label="puja baja"
                    required
                    hint="% de valor de última oferta"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    suffix="%"
                    v-model="newOne.medBid"
                    :counter="2"
                    label="puja media"
                    required
                    hint="% de valor de última oferta"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    suffix="%"
                    v-model="newOne.BigBid"
                    :counter="2"
                    label="puja alta"
                    required
                    hint="% de valor de última oferta"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    suffix="%"
                    v-model="newOne.initBid"
                    :counter="2"
                    label="Puja Inicial"
                    required
                    hint="% de valor de caballo"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <span> <strong>Configuración para reportes</strong></span>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newOne.percentageProfit"
                    :counter="10"
                    label="Porcentaje de utilidad"
                    required
                    suffix="%"
                    hint="Valor a ponderar de las ventas generadas por subasta"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="newOne.amountPayment"
                    :counter="10"
                    label="Cuota Procesamiento"
                    required
                    prefix="$"
                    suffix="MXN"
                    hint="Valor fijo por transacción"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="newOne.percentagePayment"
                    :counter="10"
                    label="Porcentaje Procesamiento"
                    required
                    suffix="%"
                    hint="Valor variable por transacción"
                  ></v-text-field>
                </v-col>
              </v-row>
            </form>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-btn color="amber" @click="guardar()" justify="end">
          actualizar
        </v-btn>
        <v-btn @click="showSliderModal()" justify="end">
          Imagenes Slider
        </v-btn>
        <v-btn @click="showPaymentModal()" justify="end">
          Configuración de pagos
        </v-btn>
        <v-btn color="black" dark justify="end">
          <router-link to="/about/" tag="span">ChangeLog</router-link>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-row justify="center">
      <v-dialog v-model="paymentModal" persistent max-width="80%">
        <v-card>
          <v-card-title class="headline">
            Configuración de Pagos.
          </v-card-title>
          <v-card-text>
            <form action="">
                <v-checkbox v-model="newOne.enablePayments" label="Funcionalidad de pagos"></v-checkbox>
            </form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="paymentModal = false">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="sliderModal" persistent max-width="80%">
        <v-card>
          <v-card-title class="headline">
            Configuración de slider
          </v-card-title>
          <v-card-text>
            <div
              id="preview"
              v-if="urls"
              class="mx-auto justify-center aling-center ma-1"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  xl="4"
                  v-for="(photo, index) in urls"
                  :key="photo"
                  class="mx-auto justify-center aling-center"
                >
                  <v-btn
                    fab
                    x-small
                    absolute
                    @click="removeFromArray(photo, index)"
                    small
                    class="red"
                    dark
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                  <v-img
                    aspect-ratio="1"
                    max-height="200px"
                    class="ma-2"
                    :src="photo"
                    @click="showImage(photo)"
                  />
                  <br />
                </v-col>
              </v-row>
            </div>
            <v-btn @click="initImageUpload()"> Cargar imagen </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="sliderModal = false">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showImageUploadDialog" fullscreen>
        <v-card class="mx-auto justify-center aling-center">
          <v-card-title class="headline">
            Cortar imagen
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="showImageUploadDialog = false">
              Cancelar
            </v-btn>
            <v-btn color="black" text @click="crop"> Confirmar </v-btn>
          </v-card-title>
          <v-card-text
            class="mx-auto justify-center text-center upload-example"
          >
            <h5>Selecciona una imagen</h5>
            <v-file-input
              v-model="image"
              counter
              accept="image/png, image/jpeg, image/bmp"
              show-size
              truncate-length="50"
              @change="changeFileImagen()"
            ></v-file-input>

            <div class="upload-example__cropper-wrapper">
              <cropper
                ref="cropper"
                class="upload-example__cropper"
                check-orientation
                :src="url"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "../../services/events";
import Loader from "@/components/adminSide/Loader";
import AdminHeader from "@/components/adminSide/AdminHeader";
import ImagePreview from "@/components/adminSide/imagePreview";

export default {
  data() {
    return {
      newOne: this.newObject(),
      sliderModal: false,
      paymentModal: false,
      showImageUploadDialog: false,
      image: "",
      url: "",
      photos: [],
      urls: [],
      showPhotosWarning: false,
      imageDialog: false,
      imgDialog: "",
    };
  },
  methods: {
    guardar() {
      this.$store.dispatch("configModule/updateItem", this.newOne);
    },
    newObject() {
      return {
        copyWelcome: "",
        enablePayments: true,
      };
    },
    showSliderModal() {
      this.sliderModal = true;
    },
    showPaymentModal() {
      this.paymentModal = true;
    },
    initImageUpload() {
      this.showImageUploadDialog = !this.showImageUploadDialog;
      this.image = "";
      this.url = "";
    },
    changeFileImagen() {
      this.url = URL.createObjectURL(this.image);
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;

      // this.url = canvas.toDataURL();
      this.urls.push(this.url);
      // this.photos.push(this.image);
      canvas.toBlob((blob) => {
        let fileUpdate;
        // this.newOne.photos.push(blob);
        if (this.newOne.photos.length < 1) {
          this.showPhotosWarning = true;
        } else {
          this.showPhotosWarning = false;
        }

        fileUpdate = { _id: this.newOne._id, image: blob, type: 1 };
        this.$store.dispatch("configModule/updateSingleImage", fileUpdate);
      });
      this.initImageUpload();
    },
    removeFromArray(photo, index) {
      var result = /[^/]*$/.exec(photo)[0];
      let toDelete = {
        _id: this.newOne._id,
        index: result,
      };
      this.$store
        .dispatch("configModule/updateRemoveImage", toDelete)
        .then(() => {
          this.urls.splice(index, 1);
        });
    },
    showImage(photo) {
      this.imgDialog = photo;
      this.imageDialog = true;
    },
    closePreview() {
      this.imgDialog = "";
      this.imageDialog = false;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.configModule.loadingStatus,
      data: (state) => state.configModule.data,
      item: (state) => state.configModule.singleItem,
    }),
  },
  async beforeCreate() {
    await this.$store.dispatch("configModule/fetchAll").then(() => {
      this.photos = this.newOne.photos;
      this.urls = this.newOne.photos;
    });
  },
  mounted() {
    window.scrollTo(0, 0);
    EventBus.$on("set-single", () => {
      this.newOne = this.item;
    });
  },
  components: {
    AdminHeader,
    Loader,
    ImagePreview,
  },
};
</script>

<style>
</style>