<template>
  <div>
    <AdminHeader
      color="black"
      icon="earth-arrow-right"
      :title="'Compradores Registrados'"
      :backButton="true"
    />
    <Loader v-if="loading" :size="64" />
    <Paginador
      :dataLength="dataLength"
      :pages="pages"
      :CurrentPage="page"
      @next="next"
    />
    <v-card elevation="3" height="100%" class="mb-10">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="onExportEmails()" dark small class="mx-2">
          <v-icon left>mdi-file-export</v-icon>
          Exportar correos
        </v-btn>
        <v-btn @click="onExportList()" dark small>
          <v-icon left>mdi-file-export</v-icon>
          Exportar números
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        Listado de registrados para compra
      </v-card-subtitle>
      <template>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nombre</th>
                <th class="text-left">email</th>
                <th class="text-left">Validado</th>
                <th class="text-left">entorno</th>
                <th>Fecha registro</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in docs" :key="item._id">
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.validated | yesno }}</td>
                <td>{{ item.from }}</td>
                <td>
                  <span v-if="item.createdAt">{{ item.createdAt | date }}</span>
                </td>
                <td>
                  <v-btn
                    v-if="item.person"
                    @click="setItemToDetails(item.person)"
                    class="mouse-pointer"
                  >
                    Ver detalles
                  </v-btn>
                </td>

                <td>
                  <v-row aling="right">
                    <v-icon v-on:click="setItemToStore(item._id)"
                      >mdi-lead-pencil</v-icon
                    >
                    <div class="mx-2"></div>
                    <v-icon v-on:click="setItemToDelete(item._id)"
                      >mdi-trash-can</v-icon
                    >
                    <div class="mx-2"></div>
                    <v-icon v-on:click="resend(item)">mdi-email</v-icon>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="800">
        <v-card>
          <v-card-title class="headline"> Información detallada </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" sm="6">
                <div class="text-center ma-auto">
                  <v-img
                    :src="details.image"
                    lazy-src="/images/pattern.png"
                    width="200px"
                    height="100%"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <p>
                  <strong>Nombre:</strong> {{ details.name }} <br />
                  <strong>Apellido Paterno:</strong> {{ details.lastnamep
                  }}<br />
                  <strong>Apellido Materno:</strong> {{ details.lastnamem
                  }}<br />
                </p>
                <p>
                  <strong>Dirección:</strong> {{ details.address }}<br />
                  <strong>zip:</strong> {{ details.zip }}
                </p>
                <p><strong>Teléfono:</strong> {{ details.cellPhone }}</p>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="text-center ma-auto" v-if="details.comp">
                  <v-img
                    :src="details.comp"
                    lazy-src="/images/pattern.png"
                    width="200px"
                    height="100%"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <div class="text-center ma-auto" v-if="details.ine">
                  <v-img
                    :src="details.ine"
                    lazy-src="/images/pattern.png"
                    width="200px"
                    height="100%"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="confirm = false"> Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Loader from "@/components/adminSide/Loader";
import AdminHeader from "@/components/adminSide/AdminHeader";
import Paginador from "@/components/adminSide/Paginador";

export default {
  data() {
    return {
      dialog: false,
      confirm: false,
      newOne: this.createFreshObj(),
      show1: false,
      page: 1,
      eventCrud: 0, // 0 for new, 1 for edit, 2 for delete
      details: {},
    };
  },
  components: {
    AdminHeader,
    Loader,
    Paginador,
  },
  mounted() {
    this.init();
  },
  methods: {
    async onExportEmails(){
      await this.$store.dispatch("frontUserModule/exportEmails", {
        perPage: 10,
        page: this.page,
      })
      this.downloadCSV('emails.csv', this.exportEmail)
    },
    async onExportList(){
      await this.$store.dispatch("peopleModule/exportList", {
        perPage: 10,
        page: this.page,
      })
      this.downloadCSV('contactos.csv', this.exportList)
    },
    downloadCSV(name, list) {
      const csvContent = this.convertArrayOfObjectsToCSV(list);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    convertArrayOfObjectsToCSV(data) {
      const separator = ',';
      const keys = Object.keys(data[0]);
      const csvContent =
        keys.join(separator) + '\n' +
        data.map(item => {
          return keys.map(key => {
            return item[key];
          }).join(separator);
        }).join('\n');
      return csvContent;
    },
    create() {
      this.createFreshObj();
      this.dialog = true;
      this.eventCrud = 0;
    },
    createFreshObj() {
      return {
        name: "",
        status: true,
      };
    },
    init() {
      this.$store.dispatch("frontUserModule/fetchData", {
        perPage: 10,
        page: this.page,
      });
      this.newOne.name = "";
    },
    guardar() {
      if (this.eventCrud === 0) {
        // CREATE
        this.dialog = false;
        this.$store
          .dispatch("frontUserModule/createItem", this.newOne)
          .then(() => {
            this.newOne = this.createFreshObj();
            this.$store.dispatch("frontUserModule/fetchData", {
              perPage: 5,
              page: this.pages,
            });
          });
      } else if (this.eventCrud === 1) {
        //EDIT
        this.$store
          .dispatch("frontUserModule/updateItem", this.newOne)
          .then(() => {
            this.$store.dispatch("frontUserModule/fetchData", {
              perPage: 5,
              page: this.page,
            });
            this.dialog = false;
            this.eventCrud = 0;
            this.newOne = this.createFreshObj();
          });
      } else {
        this.$store
          .dispatch("frontUserModule/deleteItem", this.newOne._id)
          .then(() => {
            this.$store.dispatch("frontUserModule/fetchData", {
              perPage: 5,
              page: this.page,
            });
            this.confirm = false;
            this.eventCrud = 0;
            this.newOne = this.createFreshObj();
          });
      }
    },
    next(param) {
      this.page = param.page;
      this.$store.dispatch("frontUserModule/fetchData", {
        perPage: param.itemsPerPage,
        page: param.page,
      });
    },
    setItemToStore(id) {
      this.eventCrud = 1;
      this.$store.dispatch("frontUserModule/fetchItem", id).then(() => {
        this.newOne = this.item;
        this.dialog = true;
      });
    },
    setItemToDelete(id) {
      this.eventCrud = 2;
      this.confirm = true;
      this.$store.dispatch("frontUserModule/fetchItem", id).then(() => {
        this.newOne = this.item;
      });
    },
    async setItemToDetails(id) {
      this.details = {}
      await this.$store.dispatch("peopleModule/fetchItem", id)
      if (this.detailItem) {
        this.details = this.detailItem
        this.confirm = true;
      }
      
    },
    cancelDelete() {
      this.eventCrud = 0;
      this.confirm = false;
      this.newOne = this.createFreshObj();
    },
    regresar() {
      this.$router.go(-1);
    },
    resend(item) {
      let emailData = {
        salesman: item,
        id: item._id,
        location: window.location.origin,
      };
      this.$store.dispatch("emailModule/resendWelcomeSalesman", emailData);
    },
  },
  computed: {
    label() {
      return this.eventCrud === 0
        ? "Guardar"
        : this.eventCrud === 1
        ? "Editar"
        : "Eliminar";
    },
    ...mapGetters("frontUserModule", ["dataLength", "getSub"]),
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.frontUserModule.loadingStatus,
      pages: (state) => state.frontUserModule.pages,
      docs: (state) => state.frontUserModule.data,
      item: (state) => state.frontUserModule.singleItem,
      exportEmail: (state) => state.frontUserModule.exportEmail,
      exportList: (state) => state.peopleModule.exportList,
      detailItem: (state) => state.peopleModule.singleItem
    }),
  },
};
</script>
