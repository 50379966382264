<template>
  <div>
    <iframe :src="URL" frameborder="0" width="100%" height="400px">
    </iframe>
  </div>
</template>

<script>
export default {
  props: {
    URL: String
  }
}
</script>

<style>

</style>