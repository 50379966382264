<template>
  <div>
    <NotificationContainer />
    <section>
      <BlackTitle title="Subastas activas" />
      <v-row>
        <v-col class="d-none d-md-block"> </v-col>
        <v-col md="3" class="d-none d-md-block">
          <v-subheader>Categorías</v-subheader>
          <v-list>
            <v-list-group v-for="item in categoriesData" :key="item._id">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-caption">
                    <div class="text-caption">
                      <b>({{ item.subcategories.length }})</b> - {{ item.name }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in item.subcategories"
                :key="child.name"
              >
                <v-list-item-content>
                  <v-list-item-title
                    ><a @click="goToEventsBySub(child._id)"
                      ><div>{{ child.name }}</div></a
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-btn class="mx-auto" color="#df2127" @click="goToFull()">
            <span style="color:white; font-weight:bold;" class="robotoFont">
              Ver Todas las Subastas
            </span>
          </v-btn>
        </v-col>
        <v-col md="8" sm="12">
          <v-row v-if="topEvents.length === 0">
            <EmptyState />
          </v-row>
          <v-row v-else class=" mt-1 ma-7">
            <v-col
              v-for="event in topEvents"
              :key="event._id"
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="4"
              xl="4"
            >
              <HorseItem :event="event" />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-none d-md-block"> </v-col>
      </v-row>
    </section>
    <!-- #content end -->
  </div>
</template>

<script>
import NotificationContainer from "@/components/NotificationContainer";
// import countdown from '@/components/userSide/CountDownEvent.vue'
import HorseItem from "@/components/userSide/horseItem.vue";
import EmptyState from "@/components/userSide/EmptyState.vue";
import BlackTitle from "@/components/userSide/blackTitle";

import { mapState } from "vuex";

export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  components: {
    NotificationContainer,
    HorseItem,
    EmptyState,
    BlackTitle,
  },
  data() {
    return {
      event: {
        year: 2020,
        month: 11,
        day: 23,
        hour: 23,
        minute: 59,
        second: 59,
        // 	:milliseconds="event.milliseconds"
      },
      selectedItem: 1,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.homeState.loadingStatus,
      configData: (state) => state.homeState.configData,
      categoriesData: (state) => state.homeState.categoriesData,
      topEvents: (state) => state.homeState.topEvents,
    }),
  },
  methods: {
    async goToEventsBySub(id) {
      await this.$store.dispatch("homeState/fetchSubEvents", id);
      this.$router.replace({ name: "events-us", params: { subcat: id } });
    },
    goToDetail(id) {
      this.$router.push({ name: "event-us", params: { event: id } });
    },
    async goToFull() {
      await this.$store.dispatch("homeState/fetchTopEvents");
      this.$router.replace({ name: "events-us" });
    },
  },
};
</script>
