<template>
  <v-row>
    <v-col cols="12" class="mt-5">
      <p
        class="text-h6 text-sm-h5 text-md-h4 text-lg-h3 text-center font-weight-black"
      >
        {{ name }}
      </p>
      <p
        class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-center"
      >
        {{ resume }}
      </p>
      <p
        class="text-body-2 text-sm-body-1 text-md-subtitle-2 text-lg-subtitle-1 text-center"
      >
        {{ shortDescription }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    name: String,
    resume: String,
    shortDescription: String,
  },
};
</script>

<style></style>
