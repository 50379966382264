<template>
  <v-carousel hide-delimiters height="450px" class="bebasFont" cycle>
    <v-carousel-item
      v-for="(item,i) in photos"
      :key="item + '' + i"
      :src="item"
    >
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <v-col sm="12" cols="12" class="mb-0">
          <span class="white--text text-h6 text-sm-h5 text-md-h4 text-lg-h2" :style="'font-weight:bold; text-shadow: 1px 1px 5px black;'" >
            {{ text }}
          </span>
          <!-- <br>
          <v-btn color="#df2127" @click="goTo(item.link)">
              <span style="font-weight:bold;" class="robotoFont"> 
                {{ item.cta }}                
              </span>  
            </v-btn> -->
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    props:{
      photos: Array,
      text: String,
    },
    methods:{
      goTo(index){
        if(index==1){
          this.$router.replace({name:'events-us'});
        }else if(index==3){
          this.$router.push({name:'salesManAccess'});
        }else if(index==4){
          this.$router.push({name:'adminAccess'});
        }else{
          this.$router.replace({name:'homepaga-us'});
        }
		},
    }
  }
</script>

<style  scoped>

  .row {
    text-align: center;
  }
  .btn {
      display: inline-block;
      margin-right: 6px;
      background-color: #EEE;
  }
</style>