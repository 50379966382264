//UserSide
import Home from '../views/userSide/Home.vue'
import HomePage from '../views/userSide/HomePage.vue'
import Events from '../views/userSide/Events.vue'
import Event from '../views/userSide/Event.vue'
import UserRegist from '../views/userSide/UserRegist.vue'
import UserLogin from '../views/userSide/UserLogin.vue'
import UserOnBoarding from '../views/userSide/UserOnBoarding.vue'
import UserProfile from '../views/userSide/userProfile.vue'
import AboutUser from '../views/userSide/About.vue'
import TermsUser from '../views/userSide/Terms.vue'
import Privacy from '../views/userSide/Privacy.vue'
import TripeCorona2024 from '../views/userSide/TripeCorona2024.vue'
import ContactUser from '../views/userSide/Contact.vue'
import Shop from '../views/userSide/Shop.vue'
import ValidateEmail from '../views/userSide/ValidateEmail.vue'
import ValidateEmailFront from '../views/userSide/ValidateEmailFront.vue'
import Help from '../views/userSide/Help.vue'

const routes = [
  {
    path: '/',
    component: Home,
    children:[
      {
        path:'/',
        name: 'homepaga-us',
        component: HomePage
      },
      {
        path:'/events/:subcat?',
        name: 'events-us',
        component: Events,
        props: true
      },
      {
        path:'/event/:event',
        name: 'event-us',
        component: Event
      },
      {
        path:'/register',
        name: 'register-us',
        component: UserRegist
      },
      {
        path:'/login',
        name: 'login-us',
        component: UserLogin
      },
      {
        path:'/onboarding',
        name: 'person-us',
        component: UserOnBoarding
      },
      {
        path:'/profile',
        name: 'person-profile-us',
        component: UserProfile
      },
      {
        path:'/about-us',
        name: 'about-us',
        component: AboutUser
      },
      {
        path:'/terms-us',
        name: 'terms-us',
        component: TermsUser
      },
      {
        path:'/privacy',
        name: 'privacy',
        component: Privacy
      },
      {
        path:'/contact-us',
        name: 'contact-us',
        component: ContactUser
      },
      {
        path:'/shop-us',
        name: 'shop-us',
        component: Shop
      },
      {
        path:'/ayuda',
        name: 'help',
        component: Help
      },
      {
        path:'/validate/:salesman',
        name: 'validate-email',
        component: ValidateEmail,
        props: true
      },
      {
        path:'/validateFront/:salesman',
        name: 'validate-email-front',
        component: ValidateEmailFront,
        props: true
      },
      {
        path:'/tripe-corona-2024',
        name: 'tripe-corona',
        component: TripeCorona2024,
        props: true
      },
    ]
  },
];

export default routes;