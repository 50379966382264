//logIns
import SalesManAccess from '../views/logIn/salesManAccess.vue'
import SalesmanRegist from '../views/logIn/SalesmanRegist.vue'
import adminAccess from '../views/logIn/adminAccess.vue'
import RanchRegist from '../views/logIn/RanchData.vue'
import SalesManData from '../views/logIn/SalesManData.vue'
import SalesmanRecover from '../views/logIn/SalesmanRecover.vue'

//SalesSide
import AdminSaleSide from '../views/AdminSaleSide.vue'
import DashboardSales from '../views/DashboardSales.vue'
import SalesProfile from '../views/salesSide/SalesProfile.vue'
import HorseSales from '../views/salesSide/Horses.vue'
import TrailerSales from '../views/salesSide/Trailers.vue'
import NewHorse from '../views/salesSide/NewHorse.vue'
import UpdateHorse from '../views/salesSide/UpdateHorse.vue'
import NewHorseSimp from '../views/salesSide/NewHorseSimp.vue'
import NewTrailer from '../views/salesSide/NewTrailer.vue'
import HorseDetail from '../views/salesSide/HorseDetails.vue'
import TrailerDetail from '../views/salesSide/TrailerDetails.vue'
import EventsSales from '../views/salesSide/Events.vue'
import CreditsSales from '../views/salesSide/Credits.vue'

const routes = [
  {
    path: '/login/salesman',
    name: 'salesManAccess',
    component: SalesManAccess
  },
  {
    path: '/register/salesman',
    name: 'SalesmanRegist',
    component: SalesmanRegist
  },
  {
    path: '/register/ranch',
    name: 'ranchRegist',
    component: RanchRegist
  },
  {
    path: '/register/salesmandata',
    name: 'salesManData',
    component: SalesManData
  },
  {
    path: '/login/admin',
    name: 'adminAccess',
    component: adminAccess
  },
  {
    path: '/recover/:salesman',
    name: 'SalesmanRecover',
    component: SalesmanRecover
  },
  {
    path: '/sales',
    name: 'sales',
    component: AdminSaleSide,
    children: [
      {
        path: '/sales/',
        name: 'dashboardSales',
        component: DashboardSales
      },
      {
        path: '/me/',
        name: 'salesprofile-ss',
        component: SalesProfile
      },
      {
        path: '/sales/events',
        name: 'EventsSales',
        component: EventsSales
      },
      {
        path: '/sales/horses',
        name: 'HorseSales',
        component: HorseSales
      },
      {
        path: '/sales/trailers',
        name: 'TrailerSales',
        component: TrailerSales
      },
      {
        path: '/sales/newHorse',
        name: 'NewHorse',
        component: NewHorse
      },
      {
        path: '/sales/newHorseSimp',
        name: 'NewHorseSimp',
        component: NewHorseSimp
      },
      {
        path: '/sales/newTrailer',
        name: 'NewTrailer',
        component: NewTrailer
      },
      {
        path: '/sales/HorseDetail/:horse',
        name: 'HorseDetail',
        component: HorseDetail
      },
      {
        path: '/sales/TrailerDetail/:horse',
        name: 'TrailerDetail',
        component: TrailerDetail
      },
      {
        path: '/sales/updateHorse/:horse',
        name: 'UpdateHorse',
        component: UpdateHorse
      },
      {
        path: '/sales/credits/',
        name: 'credits-ss',
        component: CreditsSales
      },
    ]
  },
];

export default routes;