import CRUDServices from "@/services/CRUDServices";
import axiosWrapper from '@/services/AxiosWrapper';

export const namespaced = true;

let catalogName = "notificacion";

export const state = {
  loadingStatus: false,
  data: [],
  singleItem: {},
  api: "/notifications/",
  justSaved: "",
};

export const mutations = {
  SET_LOADING_STATUS(state, status) {
    state.loadingStatus = status;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_NEW_OBJ(state, item) {
    state.data.push(item);
  },
  SET_PAGES(state, pages) {
    state.pages = pages;
  },
  SET_SINGLE(state, item) {
    state.singleItem = item;
  },
  SET_SAVED(state, id) {
    state.justSaved = id;
  },
};

export const actions = {
  fetchAll({ commit, dispatch }) {
    commit("SET_LOADING_STATUS", true);
    CRUDServices.getDocs(state.api)
      .then((response) => {
        commit("SET_LOADING_STATUS", false);
        commit("SET_DATA", response.data);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "There was a problem ferching" + catalogName + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  fetchNew({ commit, dispatch }) {
    commit("SET_LOADING_STATUS", true);
    CRUDServices.getDocs(state.api + 'new')
      .then((response) => {
        commit("SET_LOADING_STATUS", false);
        commit("SET_DATA", response.data);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "There was a problem ferching" + catalogName + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  createItem({ state, commit, dispatch }, item) {
    commit("SET_LOADING_STATUS", true);
    return CRUDServices.postItem(state.api, item)
      .then((response) => {
        commit("SET_NEW_OBJ", item);
        commit("SET_SAVED", response.data.obj);
        const notification = {
          type: "success",
          message: catalogName + " capturado correctamente",
        };
        dispatch("notificationModule/add", notification, { root: true });
        commit("SET_LOADING_STATUS", false);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message:
            "There was a problem creating " + catalogName + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  fetchItem({ state, commit, dispatch, getters }, id) {
    var item = getters.getDataByID(id);

    if (item) {
      commit("SET_SINGLE", item);
    } else {
      CRUDServices.getItem(state.api, id)
        .then((response) => {
          commit("SET_SINGLE", response.data);
        })
        .catch((error) => {
          const notification = {
            type: "error",
            message:
              "There was a problem ferching " + catalogName + error.message,
          };
          dispatch("notificationModule/add", notification, { root: true });
        });
    }
  },
  deleteItem({ state, commit, dispatch }, item) {
    commit("SET_LOADING_STATUS", true);
    return CRUDServices.deleteItem(state.api, item)
      .then(() => {
        commit("SET_LOADING_STATUS", false);
        const notification = {
          type: "success",
          message: catalogName + " eliminada correctamente",
        };
        dispatch("notificationModule/add", notification, { root: true });
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message:
            "There was a problem deleting " + catalogName + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  deleteByHorse({ state, commit, dispatch }, item) {
    commit("SET_LOADING_STATUS", true);
    return CRUDServices.deleteItem(state.api + "byHorse/", item)
      .then(() => {
        commit("SET_LOADING_STATUS", false);
        const notification = {
          type: "success",
          message: catalogName + " eliminada correctamente",
        };
        dispatch("notificationModule/add", notification, { root: true });
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message:
            "There was a problem deleting " + catalogName + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  updateItem({ state, commit, dispatch }, item) {
    commit("SET_LOADING_STATUS", true);
    return axiosWrapper.updateDoc(state.api, item)
      .then(() => {
        commit("SET_LOADING_STATUS", false);
        const notification = {
          type: "success",
          message: catalogName + " actualizada correctamente",
        };
        dispatch("notificationModule/add", notification, { root: true });
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message:
            "There was a problem updating " + catalogName + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  updateItemByHorse({ state, commit, dispatch }, item) {
    commit("SET_LOADING_STATUS", true);
    return axiosWrapper.updateDoc(state.api + 'byhorse', item)
      .then(() => {
        commit("SET_LOADING_STATUS", false);
        const notification = {
          type: "success",
          message: catalogName + " actualizada correctamente",
        };
        dispatch("notificationModule/add", notification, { root: true });
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message:
            "There was a problem updating " + catalogName + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
};

export const getters = {
  getData(state) {
    return state.data;
  },
  getDataByID: (state) => (id) => {
    return state.data.find((item) => item._id === id);
  },
  dataLength: (state) => {
    return state.data.length;
  },
  dataNews: (state) => {
    return state.data.filter( item => item.status === 0).length;
  },
};
