<template>
  <v-card class="mx-auto ma-3" shaped height="90%">
    <v-sheet :class="colorStatus(item.momento)">
      <div class="d-flex flex-row-reverse">
        <strong class="mr-2">{{ getStatus }} </strong>
        <v-icon large class="mr-5" color="black">{{ "mdi-" + getIcon }}</v-icon>
      </div>
    </v-sheet>
    <v-card-text>
      <v-row dense>
        <v-col cols="6" sm="6">
          <v-avatar size="100%" tile>
            <v-img
              aspect-ratio="1"
              max-height="200px"
              :src="item.photos[0]"
            ></v-img>
          </v-avatar>
        </v-col>
        <v-col sm="6">
          <v-card-subtitle>{{ item.name }}</v-card-subtitle>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <v-icon color="amber">
              {{
                item.papers
                  ? "mdi-check-circle"
                  : "mdi-checkbox-blank-circle-outline"
              }}
            </v-icon>
            Registro <br />
            <v-icon color="amber">
              {{
                item.nominated
                  ? "mdi-check-circle"
                  : "mdi-checkbox-blank-circle-outline"
              }}
            </v-icon>
            Nominado <br />
            <!-- <v-icon color="amber"> {{ item.training ? "mdi-check-circle" : "mdi-checkbox-blank-circle-outline" }} </v-icon> Entrenamiento <br>                                    
                          <v-icon color="amber"> {{ item.awards ? "mdi-check-circle" : "mdi-checkbox-blank-circle-outline" }} </v-icon> Premios <br>                                     -->
          </v-card-text>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="text-center justify-center mb-10">
      <v-row justify="center">
        <v-btn
          class="ma-1"
          outlined
          color="black"
          small
          @click="goToDetails(item._id)"
        >
          Detalle
        </v-btn>
        <v-btn
          v-if="item.momento == 3"
          class="ma-1"
          color="red"
          outlined
          small
          v-on:click="updateMomento(4)"
        >
          Enviar observaciones
        </v-btn>
        <v-btn
          v-if="item.momento == 3"
          class="ma-1"
          color="green"
          outlined
          small
          v-on:click="updateMomento(6)"
        >
          Autorizar
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import EventBus from "@/services/events";
export default {
  name: "HorseCard",
  props: {
    item: {},
  },
  methods: {
    colorStatus(momento) {
      let estatusLabel = "";
      switch (momento) {
        case 1:
          estatusLabel = "white";
          break;
        case 2:
        case 5:
          estatusLabel = "green";
          break;
        case 3:
          estatusLabel = "amber";
          break;
        case 4:
          estatusLabel = "red";
          break;
        case 6:
          estatusLabel = "blue";
          break;
        case 7:
          estatusLabel = "grey";
          break;
        case 8:
          estatusLabel = "orange";
          break;
        default:
          estatusLabel = "N/A";
          break;
      }
      return estatusLabel;
    },
    async goToDetails() {
      await this.$store.dispatch("horsesModule/fetchItem", this.item._id);
      await this.updateMomento(3);
      this.$router.push({
        name: "HorseDetailAdmin",
        params: { horse: this.item._id },
      });
    },
    updateMomento(momento) {
      this.$store.dispatch("horsesModule/fetchItem", this.item._id).then(() => {
        this.$store.dispatch("horsesModule/updateMomento", momento).then(() => {
          let updateData = {
            _id: this.item._id,
            updateDate: new Date(),
            status: momento,
          };
          if (momento === 3 || momento === 4)
            this.$store.dispatch(
              "adminNotificationsModule/updateItemByHorse",
              updateData
            );
          if (momento === 5) {
            let emailData = {
              horse: this.item._id,
              salesman: this.item.salesman,
              location: window.location.origin,
              photo: this.item.photos[0],
            };
            this.$store.dispatch(
              "emailModule/notificateAutorization",
              emailData
            );
          }
          EventBus.$emit("horse-audit-fetch");
        });
      });
    },
  },
  computed: {
    getIcon() {
      let icon = ""
      switch(this.item.type){
        case 1: icon = 'horse'; break;
        case 2: icon = 'truck-trailer'; break;
        case 3: icon = 'horse'; break;
        default: icon = 'horse'; break;
      }
      return icon
    },
    getStatus() {
      let estatusLabel = "";
      switch (this.item.momento) {
        case 1:
          estatusLabel = "Captura";
          break;
        case 2:
          estatusLabel = "Listo para auditoria";
          break;
        case 3:
          estatusLabel = "En auditoria";
          break;
        case 4:
          estatusLabel = "Validado con Observaciones";
          break;
        case 5:
          estatusLabel = "Autorizado";
          break;
        case 6:
          estatusLabel = "Listo para evento";
          break;
        case 7:
          estatusLabel = "Asignado a evento";
          break;
        case 8:
          estatusLabel = "Observaciones Revisadas";
          break;
        default:
          estatusLabel = "N/A";
          break;
      }
      return estatusLabel;
    },
  },
};
</script>

<style>
</style>