<template>
  <div class="full-height grey lighten-3">
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      style="position:fixed; top:0; right:0; overflow-y:scroll; z-index: 10001;"
    >
      <v-card class="mx-auto hidden-sm-and-down" max-width="434" tile>
        <v-img height="15%" src="/images/ehorsesale/slider/BR6XMC2.jpeg"></v-img>
      </v-card>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item
            v-for="item in drawerList"
            :key="item.name"
            @click="goTo(item.direction)"
          >
            <v-list-item-icon v-if="item.img">
              <v-img 
                :aspect-ratio="16/9"
                :src="item.img" 
                max-height="100px"
                contain
            />
            </v-list-item-icon>
            <v-list-item-icon v-else>
              <v-icon color="black">mdi-{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-list-group :value="false" prepend-icon="mdi-file-chart">
          <template v-slot:activator>
            <v-list-item-title>Reportes</v-list-item-title>
          </template>

          <v-list-item
            v-for="item in reportsList"
            :key="item.name"
            @click="goTo(item.direction)"
          >
            <v-list-item-icon class="text-center">
              <v-icon color="primary">mdi-{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="black" dark>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        </v-app-bar-nav-icon>
        <v-img
          @click="goTo('dashboard')"
          alt="eHorseSale"
          class="shrink my-2 hidden-sm-and-down cursor-mouse"
          contain
          min-width="150"
          src="/images/ehorsesale/white-horse.png"
          width="100"
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn outlined @click="goTo('NotificationCenter')" :class="dataNews > 0 ? 'red' : ''">
        <v-icon>mdi-bell</v-icon>
        <span class="mr-2 hidden-sm-and-down">{{ dataNews > 0 ? `(${dataNews}) Pendientes` : 'Centro de notificaciones'}}</span>
      </v-btn>
      <v-btn text @click="logout()">
        <span class="mr-2 hidden-sm-and-down">Cerrar Sesión</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="grey lighten-3 full-height">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <NotificationContainer />
  </div>
</template>

<script>
import NotificationContainer from "@/components/NotificationContainer";
import adminOptions from "@/config/adminOptions.json";
import { mapGetters } from 'vuex';

export default {
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push("/");
    } else {
      this.$store.dispatch("adminModule/authRec",this.$session.get('jwt'));
      if (this.$session.get("type") != "ADM") {
        alert("401");
        this.$router.go(-1);
      }
    }
  },
  data: function() {
    return {
      drawerList: [],      
      reportsList: [
        {
          icon: "card",
          name: "Ventas de Inscripciones",
          direction: "CreditBuy",
        },
        { icon: "card", name: "Anticipos", direction: "FirstPayment" },
        { icon: "card", name: "Liquidaciones", direction: "FinalPayment" },
      ],
      drawer: false,
      group: null,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  components: {
    NotificationContainer,
  },
  created(){
    this.$store.dispatch("adminNotificationsModule/fetchNew");
  },
  mounted(){
    this.drawerList = adminOptions;
  },
  methods: {
    logout() {
      this.$session.destroy();
      this.$store.dispatch('adminModule/logOut');
      this.goTo("homepaga-us");
    },
    goTo(direction) {
      if (this.$router.history.current.name !== direction)
        this.$router.replace({ name: direction });
    },
  },
  computed:{
    ...mapGetters('adminNotificationsModule',['dataNews']),
  }
};
</script>

<style>
  .admin-side{
    height: 100%;
  }
</style>
