<template>
  <v-card class="mx-auto my-12" max-width="374" shaped>
    <div class="float-start" v-if="eventFinished">
      <v-chip color="primary" style="position: absolute; z-index:1000;" class="ma-2">
        <v-icon color="white" size="20" class="mr-2">mdi-lock-clock</v-icon>
        <strong>Subasta cerrada</strong>
      </v-chip>
    </div>
    <v-img
      height="300"
      class="linkCursor"
      v-if="event.horse && event.horse.photos.length > 0"
      :src="event.horse.photos[0]"
      @click="goToDetail(event._id)"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <div class="float-start mt-n14" v-if="(event.currentBid && event.open)">
      <v-chip color="primary" style="position: absolute; z-index:1000;" class="ma-2" v-if="!eventFinished">
        <v-icon color="white" size="20" class="mr-2">mdi-invoice-text-clock</v-icon>
        <strong>{{ event.currentBid | money }} MXN</strong>
      </v-chip>
    </div>
    <v-card-title class="linkCursor" @click="goToDetail(event._id)">
      <strong class="secondary--text text-center"> <span class="primary--text">| </span>{{ event.horse.name }}</strong>
    </v-card-title>
    <v-card-text v-if="event.open && !eventFinished" class="secondary">
      <v-row no-gutters>
        <v-col cols="12">
          <h3 class="text-center white--text mt-3">
            {{eventStarted ? 'Termina en:' : 'Inicia en:'}}
            <countdown
              dark
              :year="eventDate.year"
              :month="eventDate.month"
              :day="eventDate.day"
              :hour="eventDate.hour"
              :minute="eventDate.minutes"
            />
          </h3>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import countdown from "@/components/userSide/CountDownEvent.vue";

export default {
  props: {
    event: {},
  },
  components: {
    countdown,
  },
  methods: {
    goToDetail(id) {
      this.$router.push({ name: "event-us", params: { event: id } });
    },
  },
  computed: {
    eventStarted() {
      return new Date(this.event.initialDate) < Date.now();
    },
    eventFinished() {
      return new Date(this.event.finishDate) < Date.now();
    },
    eventDate() {
      return new Date(this.event.initialDate) < Date.now() ? this.event.due : this.event.init
    }
  },
};
</script>
