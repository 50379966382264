<template>
  <v-main class="grey lighten-3">
    <v-container>
      <Loader v-if="loadingRanch" :size="64" />
      <v-card
        class="mx-auto justify-center aling-center ma-10"
        width="100%"
        height="100%"
        elevation="9"
      >
        <v-sheet>
          <div class="d-flex justify-center aling-center">
            <Logo min-width="250" @click="goback()" />
          </div>
        </v-sheet>
        <v-img height="250" src="/images/ehorsesale/slider/WW7G4K.jpeg"></v-img>

        <v-card-title>Tipo de vendedor</v-card-title>

        <v-card-subtitle>
          <div class="mx-auto my-2 justify-center aling-center text-center">
            <h2> ¿Cuál es tu situación como vendedor? </h2> 
            <v-btn :outlined="radioGroup !== 1" color="primary" class="ma-3" @click="radioGroup = 1">
              <v-icon size="20px" class="mx-5"> mdi-account</v-icon>
              Vendedor independiente
            </v-btn>
            <v-btn :outlined="radioGroup !== 2" color="primary" class="ma-3" @click="radioGroup = 2">
              <v-icon size="20px" class="mx-5"> mdi-barn</v-icon>
              Representante de rancho/criadero
            </v-btn>
          </div>
<!-- 
          <v-radio-group v-model="radioGroup">
            <v-radio
              label="Soy vendedor independiente"
              :value="1"
            ></v-radio>
            <v-radio
              label="Represento a un rancho/criadero"
              :value="2"
            ></v-radio>
          </v-radio-group> -->
              </v-card-subtitle>
        <!-- <v-card-text v-if="buscar" class="pa-10">
          <h4>
            Tal vez ya se encuentra capturado el rancho que deseas dar de alta,
            busca dentro de nuestros proveedores
          </h4>
          <h5>
            Tu cuenta de vendedor solo se puede asignar a un rancho, y será
            validada por el equipo de eHorseSale
          </h5>

          <v-autocomplete
            v-model="ranchSelected"
            :items="ranchs"
            item-text="name"
            item-value="_id"
            label="Seleccionar un rancho registrado"
            persistent-hint
            return-object
            single-line
            @input="selectRanch(ranchSelected)"
          ></v-autocomplete>

          <div v-if="ranchSelected">
            <v-img
              contain
              :lazy-src="ranchSelected.image"
              max-height="200"
              max-width="200"
              :src="ranchSelected.image"
            ></v-img>
            <span>Nombre: {{ ranchSelected.name }}</span
            ><br />
            <span>Ciudad: {{ city.name }}</span>
          </div>
          <v-alert color="orange" type="info" v-if="showWarning">
            {{ alertText }}</v-alert
          >
          <v-card-actions class="mx-auto justify-center aling-center py-10">
            <v-btn
              class="mr-4 black"
              dark
              @click="persisteRanch()"
              :disabled="ranchSelected === null"
            >
              Seleccionar
            </v-btn>
            <br />

            <v-btn class="mr-4" @click="initCapturaRanch()"> Capturar </v-btn>
          </v-card-actions>
        </v-card-text> -->

        <v-card-text v-if="capturar && radioGroup === 1" class="pa-8">
          <v-card-actions class="mx-auto justify-end aling-center">
            <v-btn class="mr-4 black" dark @click="guardarDefault()">
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-card-text v-if="capturar && radioGroup === 2" class="pa-8">
          <v-form ref="form" v-model="valid" lazy-validation>

            <v-text-field
              v-model="ranchCapture.name"
              :counter="100"
              label="Nombre del Rancho"
              :rules="nameRules"
              @keydown="validateUniqName(ranchCapture.name)"
              @input="validateUniqName(ranchCapture.name)"
              @focus="validateUniqName(ranchCapture.name)"
              @change="validateUniqName(ranchCapture.name)"
              @formchange="validateUniqName(ranchCapture.name)"
            ></v-text-field>
            <span v-if="!showWarningUniq">El nombre de tu rancho debe ser único, validaremos que no existan ranchos registrados con el mismo nombre</span>
            <v-alert :color="validName ? 'green':'orange'" type="info" v-if="showWarningUniq">
              {{ alertTextUniq }}</v-alert
            >
            <div v-if="validName">
                <v-text-field
                v-model="ranchCapture.mail"
                :counter="50"
                :rules="emailRules"
                label="Email de contacto"
                ></v-text-field>
                <v-autocomplete
                v-model="countrySelect"
                :items="countriesData"
                item-text="name"
                item-value="_id"
                label="País"
                persistent-hint
                return-object
                single-line
                @input="changeCountry(countrySelect._id)"
                ></v-autocomplete>
                <v-autocomplete
                v-if="showState"
                v-model="stateSelect"
                :items="statesData"
                item-text="name"
                item-value="_id"
                label="Estado"
                persistent-hint
                return-object
                single-line
                @input="changeState(stateSelect._id)"
                ></v-autocomplete>
                <v-autocomplete
                v-if="showCity"
                v-model="citySelect"
                :items="citiesData"
                item-text="name"
                item-value="_id"
                label="Ciudad"
                persistent-hint
                return-object
                single-line
                @input="changeCity(citySelect._id)"
                ></v-autocomplete>
                <span
                >Nos reservamos el derecho de registro de empresas de los estados
                y ciudades disponibles</span
                >
                <div id="preview">
                <img
                    v-if="url"
                    style="max-height: 200px; widht: 100%"
                    :src="url"
                />
                </div>
                <v-file-input
                v-model="file"
                counter
                label="Logo"
                accept="image/png, image/jpeg, image/bmp"
                show-size
                truncate-length="50"
                @change="changeFile()"
                ></v-file-input>
            </div>
          </v-form>

          <v-alert color="orange" type="info" v-if="showWarning">
            {{ alertText }}</v-alert
          >

          <v-card-actions class="mx-auto justify-end aling-center">
            <v-btn class="mr-4 black" dark @click="guardar()"
                :disabled="!validName"
            >
              Continuar
            </v-btn>
            <!-- <v-btn
                                class="mr-4"
                                @click="initCapturaRanch()"
                            >
                                Regresar a Buscar 
                            </v-btn> -->
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-container>
    <NotificationContainer />
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "../../services/events";
import NotificationContainer from "@/components/NotificationContainer";
import Loader from "@/components/adminSide/Loader";
import Logo from "@/components/base/Logo";

export default {
  name: "salesManRanch",
  components: {
    NotificationContainer,
    Loader,
    Logo,
  },
  data() {
    return {
      radioGroup: 1,
      valid: true,
      ranchCapture: {
        name: "",
      },
      capturar: true,
      buscar: false,
      ranchSelected: null,
      countrySelect: {},
      stateSelect: {},
      citySelect: {},
      showState: false,
      showCity: false,
      file: null,
      url: null,
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe ser valido",
      ],
      nameRules: [(v) => !!v || "Nombre es requerido"],
      showWarning: false,
      showWarningUniq: false,
      alertText: "",
      alertTextUniq: "",
      validName: false
    };
  },
  computed: {
    ...mapState({
      loadingRanch: (state) => state.ranchsModule.loadingStatus,
      salesman: (state) => state.adminSalesModule.user,
      auth: (state) => state.adminSalesModule.auth,
      ranchs: (state) => state.ranchsModule.data,
      singleItem: (state) => state.ranchsModule.singleItem,
      justSaved: (state) => state.ranchsModule.justSaved,
      countriesData: (state) => state.countriesModule.data,
      statesData: (state) => state.statesModule.data,
      citiesData: (state) => state.citiesModule.data,
      city: (state) => state.citiesModule.singleItem,
    }),
  },
  mounted() {
    EventBus.$on("anch-created", (id) => {
      this.$session.set("ranchProcess", id);
      this.goToStep2();
    });
  },
  methods: {
    validateUniqName(value){
        if(value.length > 2){
            this.ranchSelected = this.ranchs.filter(obj => {
                return obj.name.toLowerCase() == value.toLowerCase()
            })
            if(this.ranchSelected.length > 0){
                this.showWarningUniq = true;
                this.alertTextUniq = "Nombre de rancho no disponible";
                this.validName = false;
            } else {
                this.showWarningUniq = true;
                this.alertTextUniq = "Nombre disponible";
                this.validName = true;
            }
        }else{
            this.showWarningUniq = false;
            this.validName = false;
        }
    },
    validate() {
      this.$refs.form.validate();
    },
    submit(user) {
      if (user.pass === this.passValidation) {
        this.$store.dispatch("adminSalesModule/createAdmin", user);
      } else {
        alert("Valida tus passwords no estan iguales");
      }
    },
    goToDashboard() {
      this.$router.push({ name: "dashboardSales" });
    },
    goToStep2() {
      this.$router.push({ name: "salesManData" });
    },
    selectRanch(ranch) {
      this.$store.dispatch("ranchsModule/fetchItem", ranch._id);
      this.$store.dispatch("citiesModule/fetchItem", ranch.city);
      this.ranchSelected = this.singleItem;
    },
    persisteRanch() {
      if (this.ranchSelected) {
        this.$session.set("ranchProcess", this.ranchSelected._id);
        this.$router.push({ name: "salesManData" });
      } else {
        this.alertText = "Selecciona o captura un Rancho para continuar";
        this.showWarning = true;
      }
    },
    guardar() {
      this.validate();
      this.ranchCapture.city = this.citySelect._id;
      if (
        this.ranchCapture.name !== "" &&
        this.ranchCapture.mail !== "" &&
        typeof this.ranchCapture.city !== "undefined"
      ) {
        if (this.ranchCapture.image) {
          this.$store
            .dispatch("ranchsModule/createItem", this.ranchCapture)
            .then(() => {
              this.$session.set("ranchProcess", this.justSaved);
              this.goToStep2();
            });
        } else {
          this.alertText = "El logo es requerido.";
          this.showWarning = true;
        }
      } else {
        this.alertText = "Completa los campos.";
        this.showWarning = true;
      }
    },
    async guardarDefault() {
      await this.$store.dispatch("ranchsModule/fetchDefault").then((ranch)=>{
        this.$session.set("ranchProcess", ranch);
        this.$session.set("ranchProcess", ranch);
        this.$session.set("ranchProcess", ranch);
        this.goToStep2();
      });
    },
    initCapturaRanch() {
      this.showWarning = false;
      this.capturar = !this.capturar;
      this.buscar = !this.buscar;
      this.ranchSelected = null;
      this.ranchCapture = this.createFreshObj();
    },
    createFreshObj() {
      return {
        name: "",
        phone: "",
        address: "",
        mail: "",
        city: {},
        status: true,
        image: null,
      };
    },
    changeCountry(master) {
      this.showState = true;
      this.$store.dispatch("statesModule/fetchByMaster", master);
    },
    changeState(master) {
      this.showCity = true;
      this.$store.dispatch("citiesModule/fetchByMaster", master);
    },
    changeCity(master) {
      this.ranchCapture.city = master;
    },
    changeFile() {
      this.ranchCapture.image = this.file;
      this.url = URL.createObjectURL(this.file);
    },
    goback() {
      this.$router.push({ name: "homepaga-us" });
    },
  },
  created() {
    this.createFreshObj();
    this.$store.dispatch("ranchsModule/fetchAll");
    this.$store.dispatch("countriesModule/fetchAll");
  },
};
</script>

<style>
</style>