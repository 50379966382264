<template>
  <div>
    <AdminHeader color="black" img="'/images/33157.png'" title="Auditoria" />
    <Loader v-if="loading" :size="64" />
    <Paginador
      :dataLength="total"
      :pages="pages"
      :CurrentPage="page"
      @next="next"
    />
    <v-row v-if="docs.length > 0" class="mb-10">
      <v-col
        cols="12"
        md="6"
        sm="12"
        lg="4"
        v-for="item in docs"
        :key="item._id"
      >
        <HorseCard :item="item" />
      </v-col>
    </v-row>
    <v-row v-else>
      <EmptyState :white="false" />
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyState from "@/components/EmptyState";
import AdminHeader from "@/components/adminSide/AdminHeader";
import Loader from "@/components/adminSide/Loader";
import HorseCard from "@/components/adminSide/HorseCard";
import EventBus from "@/services/events";
import Paginador from "@/components/adminSide/Paginador";

export default {
  data() {
    return {
      page: 1,
      confirm: false,
      newOne: {},
    };
  },
  components: {
    EmptyState,
    AdminHeader,
    Loader,
    HorseCard,
    Paginador,
  },
  created() {
    this.$store.dispatch("horsesModule/fetchPending", {
      perPage: 10,
      page: this.page,
    });
  },
  methods: {
    next(param) {
      this.page = param.page;
      this.$store.dispatch("horsesModule/fetchData", {
        perPage: param.itemsPerPage,
        page: param.page,
      });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.horsesModule.loadingStatus,
      item: (state) => state.horsesModule.singleItem,
      pages: (state) => state.horsesModule.pages,
      total: (state) => state.horsesModule.total,
      docs: (state) => state.horsesModule.data,
    }),
  },
  mounted() {
    EventBus.$on("horse-audit-fetch", () => {
      this.$store.dispatch("horsesModule/fetchPending", {
        perPage: 10,
        page: this.page,
      });
    });
  },
  destroyed() {
    EventBus.$off("horse-audit-fetch");
  },
};
</script>

<style>
</style>