<template>
  <div class="pa-3">
    <v-alert
      v-if="!isSocketNotification"
      border="bottom"
      :color="color"
      dismissible
      elevation="11"
      :type="notification.type"
    >
      {{ notification.message }}
    </v-alert>
    <v-card v-if="isSocketNotification" elevation="11">
      <v-card-title :class="`${color}--text`">
        <v-icon :class="`${color}--text`">mdi-bell-badge</v-icon> Notificación
      </v-card-title>
      <v-card-text>
        {{ notification.message }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    notificationTypeClass() {
      return `-text-${this.notification.type}`;
    },
    color() {
      return this.notification.type === "error"
        ? "red"
        : this.notification.type === "info"
        ? "orange"
        : "green";
    },
    isSocketNotification() {
      return this.notification.type === "socket-message";
    },
  },
  methods: {
    ...mapActions("notificationModule", ["remove"]),
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 5000);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style scope>
.notification-bar {
  margin: 1em 0 1em;
}
</style>