<template>
  <v-row no-gutters>
    <ImagePreview
      v-if="imageDialog"
      :imgDialog="imgDialog"
      @closePreview="closePreview"
    />
    <v-col cols="12">
      <table
        border="0"
        cellpadding="0"
        cellspacing="0"
        width="100%"
        class="table-tree"
        c
      >
        <tr class="text-center table-tree">
          <th class="person_name" width="33%" rowspan="4">
            <v-chip :x-small="isSmall" outlined dense color="black">
              <span class="text-caption">{{ pedigreeTree.dad }}</span>
            </v-chip>
          </th>
          <th class="person_name leftborder" width="33%" rowspan="2">
            <v-chip :x-small="isSmall" grandgrandmom4 outlined dense color="black">
              <span class="text-caption">{{ pedigreeTree.granddad1 }}</span>
            </v-chip>
          </th>
          <th class="person_name leftborder" width="33%">
            <v-chip :x-small="isSmall" outlined dense color="black">
              <span class="text-caption">{{
                pedigreeTree.grandgranddad1
              }}</span>
            </v-chip>
          </th>
        </tr>
        <tr>
          <th class="person_name leftborder" width="33%">
            <v-chip :x-small="isSmall" outlined dense color="black">
              <span class="text-caption">{{
                pedigreeTree.grandgrandmom1
              }}</span>
            </v-chip>
          </th>
        </tr>
        <tr>
          <th class="person_name leftborder" width="33%" rowspan="2">
            <v-chip :x-small="isSmall" outlined dense color="black">
              <span class="text-caption">{{ pedigreeTree.grandmom2 }}</span>
            </v-chip>
          </th>
          <th class="person_name leftborder" width="33%">
            <v-chip :x-small="isSmall" outlined dense color="black">
              <span class="text-caption">{{
                pedigreeTree.grandgranddad2
              }}</span>
            </v-chip>
          </th>
        </tr>
        <tr>
          <th class="person_name leftborder" width="33%">
            <v-chip :x-small="isSmall" outlined dense color="black">
              <span class="text-caption">{{
                pedigreeTree.grandgrandmom2
              }}</span>
            </v-chip>
          </th>
        </tr>
        <tr>
          <th class="person_name1" width="33%" rowspan="4">
            <v-chip :x-small="isSmall" outlined dense color="black">
              <span class="text-caption">{{ pedigreeTree.mom }}</span>
            </v-chip>
          </th>
          <th class="person_name leftborder" width="33%" rowspan="2">
            <v-chip :x-small="isSmall" outlined dense color="black">
              <span class="text-caption">{{ pedigreeTree.granddad3 }}</span>
            </v-chip>
          </th>
          <th class="person_name leftborder" width="33%">
            <v-chip :x-small="isSmall" outlined dense color="black">
              <span class="text-caption">{{
                pedigreeTree.grandgranddad3
              }}</span>
            </v-chip>
          </th>
        </tr>
        <tr>
          <th class="person_name leftborder" width="33%">
            <v-chip :x-small="isSmall" outlined dense color="black"
              ><span class="text-caption">{{
                pedigreeTree.grandgrandmom3
              }}</span></v-chip
            >
          </th>
        </tr>
        <tr>
          <th class="person_name1 leftborder" width="33%" rowspan="2">
            <v-chip :x-small="isSmall" outlined dense color="black"
              ><span class="text-caption">{{
                pedigreeTree.grandmom4
              }}</span></v-chip
            >
          </th>
          <th class="person_name leftborder" width="33%">
            <v-chip :x-small="isSmall" outlined dense color="black"
              ><span class="text-caption">{{
                pedigreeTree.grandgranddad4
              }}</span></v-chip
            >
          </th>
        </tr>
        <tr>
          <th class="person_name1 leftborder" width="33%">
            <v-chip :x-small="isSmall" outlined dense color="black"
              ><span class="text-caption">{{
                pedigreeTree.grandgrandmom4
              }}</span></v-chip
            >
          </th>
        </tr>
      </table>
    </v-col>
    <v-col cols="12" class="mx-auto mt-10 justify-center aling-center text-center">
      <h3>Documento de respaldo</h3>
      <v-img
        class="mx-auto justify-center aling-center text-center"
        contain
        :lazy-src="pedigreeTree.file"
        max-height="100%"
        max-width="50%"
        :src="pedigreeTree.file"
        @click="showImage(pedigreeTree.file)"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
import ImagePreview from "@/components/adminSide/imagePreview";
export default {
  data() {
    return {
      imgDialog: "",
      imageDialog: false,
    };
  },
  props: {
    pedigreeTree: {},
  },
  methods: {
    showImage(photo) {
      this.imgDialog = photo;
      this.imageDialog = true;
    },
    closePreview() {
      this.imgDialog = "";
      this.imageDialog = false;
    },
  },
  components: {
    ImagePreview,
  },
  computed:{
    isSmall() {
      return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm";
    },
  }
};
</script>

<style lang="scss">
.table-tree {
  border: 1px;
  margin-top: 30px;
}

.person_name1 {
  vertical-align: center;
  border-bottom: solid 0px black;
  margin: 0;
  padding: 5px 5px 5px 5px;
}

.person_name {
  vertical-align: center;
  border-bottom: dashed 1px grey;
  margin: 0;
  padding: 5px 5px 5px 5px;
}

.leftborder {
  border-left: dashed 1px grey;
}
</style>
