<template>
  <div class="ma-5">
    <iframe
      :width="sizeW"
      :height="sizeH"
      :src="`https://www.youtube.com/embed/${videoHash}`"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>

export default {
  props:{
    video: String,
    small: Boolean
  },
  computed:{
    sizeW(){
      if (this.isShort) {
        return '315px'
      } else {
        return this.small ? '300px' : '400px'
      }
    },
    sizeH(){
      if (this.isShort) {
        return '560px'
      } else {
        return this.small ? '250px' : '350px'
      }
    },
    videoHash(){
      if (this.video.includes('&')){
        return this.video.substring(this.video.indexOf("=") + 1, this.video.indexOf("&"))
      } else if (this.isShort) {
        let newVideoUrl = this.video.replace('https://www.youtube.com/shorts/','');
        return newVideoUrl.substring(this.video.indexOf("=") + 1, this.video.length)
      } else {
        return this.video.substring(this.video.indexOf("=") + 1, this.video.length)
      }
    },
    isShort(){
      return this.video.includes('https://www.youtube.com/shorts/')
    }
  }
}
</script>

<style>

</style>