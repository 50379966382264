<template>
    <v-main class="grey lighten-3">
         <v-overlay v-if='loading'>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-container>
            <v-card
                class="mx-auto justify-center aling-center ma-10"
                width="100%"
                height="100%"
                elevation="5"
            >
                <v-sheet>
                    <div class="d-flex justify-center aling-center">
                        <v-img
                            alt="eHorseSale logo"
                            class="shrink mt-1 cursor-mouse "
                            contain
                            min-width="250"
                            src="https://www.ehorsesale.com/images/ehorsesale/blackbox.png"
                            width="100"
                            @click="goback()"
                        />                            
                    </div>
                </v-sheet>
                <v-img
                    height="250"
                    src="/images/ehorsesale/slider/WW7G4K.jpeg" 
                ></v-img>
                    
                <v-card-title>Recuperación de contraseña</v-card-title>
                <v-card-subtitle>
                    Recuerda que para cambiar tu contraseña deberá ser diferente a la anterior.
                </v-card-subtitle>
                <v-card-text
                    class="pa-5"
                >                    
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        onSubmit="return false;"
                    >                        
                        <v-text-field
                            v-model="user.pass"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-1"
                            :rules="passRules"
                            label="Nueva contraseña"
                            counter
                            @click:append="show1 = !show1"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="passValidation"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-1"
                            label="Confirmación de Nueva contraseña"
                            counter
                            @click:append="show1 = !show1"
                            required
                        ></v-text-field>
                        <v-alert
                            color="orange"
                            type="info"
                            v-if="showWarning"
                        > {{alertText}}</v-alert>
                    </v-form>
                </v-card-text>
                    <v-card-actions class="mx-auto justify-center aling-center py-10">
                         <v-btn 
                            class="mr-4 black"
                            @click="submit(user)"
                            dark
                        >
                            Continuar
                        </v-btn>
                        <v-btn
                            class="mr-4"
                            @click="goback()"
                        >
                            Cancelar
                        </v-btn>
                    </v-card-actions>

            </v-card>
        </v-container>
        <NotificationContainer />
    </v-main>
</template>

<script>
import { mapState } from 'vuex';

import EventBus from '../../services/events';
import NotificationContainer from '@/components/NotificationContainer';

export default {
    name: 'salesManRegist',
    components:{
        NotificationContainer
    },
    data(){
        return{
            valid: true,
            user:{
                user: this.$route.params.salesman,
                pass: ''
            },
            passValidation:'',
            show1: false,
            passRules: [
                v => !!v || 'Contraseña es requerida',
                v => /^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])\S{8,}$/.test(v) || 'Password debe ser valido (Min 8 caracteres, una minuscula, una mayuscula y un número)'
            ],
            alertText: "",
            showWarning: false,
        }
    },
    computed:{
        ...mapState({
                loading: state => state.adminSalesModule.loadingStatus,
            })
    },
    methods: {
        submit (user) {
            this.validate();
            if(user.pass != ''){
                if (user.pass === this.passValidation){
                    this.$store.dispatch('adminSalesModule/newPass',user);
                } else {
                    this.alertText = 'Confirmación de contraseña incorrecta.';
                    this.showWarning = true;
                }
            } else {
                this.alertText = "Completa los campos requeridos.";
                this.showWarning = true;
            }    
        },
        goToLogin(){
            this.$router.push({ name: 'salesManAccess'});
        },
        goback(){
            this.$router.push({ name: 'homepaga-us'});
        },
        validate () {
            this.$refs.form.validate()
        },
    },
    mounted () {
        if(this.$session.exists() && this.$session.get('type') === 'SLS'){
            this.goToDashboard();
        }
        EventBus.$on("updateSuccess", () => {
            this.$session.destroy();
            this.goToLogin();
        }),
        EventBus.$on("registerError", () => {
            this.alertText = "Revisa tus datos y contraseña.";
            this.showWarning = true;
        });
    }
}
</script>

<style>
    .dialog-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%; 
        color: white;
        text-align: center;
    }
</style>