import CRUDServices from '@/services/CRUDServices';
export const namespaced = true;


export const state = {
  loadingStatus: false,
  data: [],
  singleItem: {},
  pages: 0,
  total: 0,
  api: '/messages/'
}

export const mutations = {
  SET_LOADING_STATUS(state, status){
    state.loadingStatus = status;
  },
  SET_DATA(state, data){
    state.data = data;
  },
  SET_NEW_OBJ(state, item){
    state.data.push(item)
  },
  SET_PAGES(state, pages){
    state.pages = pages
  },
  SET_TOTAL(state, total){
    state.total = total
  },
  SET_SINGLE(state, item){
    state.singleItem = item
  },
}

export const  actions = {
  createItem({state,commit,dispatch}, item){
    commit('SET_LOADING_STATUS',true);
    return CRUDServices.postItem(state.api, item ).then(() => {
      commit('SET_NEW_OBJ',item);
      const notification = {
          type: 'success',
          message: 'Mensaje capturado correctamente'
      }
      dispatch('notificationModule/add', notification, {root: true});
      commit('SET_LOADING_STATUS',false);
    }).catch( error =>{
      const notification = {
          type: 'error',
          message: 'Existe un problema registrando el mensaje'
      }
      console.error(error.message)
      dispatch('notificationModule/add', notification, {root: true});
    });
  },
  fetchData({state,commit,dispatch}){
    commit('SET_LOADING_STATUS',true);
    CRUDServices.getDocs(state.api)
    .then(response => {        
      commit('SET_LOADING_STATUS',false);
      console.log(response.data)
      console.log(response.data.length)
      commit('SET_DATA',response.data);
      commit('SET_TOTAL',response.data.length);
    }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem ferching groups: ' + error.message 
        }
        dispatch('notificationModule/add', notification, {root: true});
    });
  },
  updateItem({state,commit,dispatch},item){
    commit('SET_LOADING_STATUS',true);
    return CRUDServices.updateItem(state.api,item).then(() => {
      commit('SET_LOADING_STATUS',false);
      const notification = {
          type: 'success',
          message: 'mensaje actualizada correctamente'
      }
      dispatch('notificationModule/add', notification, {root: true});
    }).catch(error =>{
      const notification = {
          type: 'error',
          message: 'There was a problem updating message' + error.message
      }
      dispatch('notificationModule/add', notification, {root: true});
    });
  }
}

export const getters = {
  dataLength: state =>{
    return state.do
  },
}