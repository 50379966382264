<template>
  <div class="full-height grey lighten-3">
    <v-app-bar app color="black" dark dense>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        </v-app-bar-nav-icon>
        <v-img
          alt="eHorseSale"
          class="shrink my-2 hidden-sm-and-down"
          contain
          min-width="150"
          src="/images/ehorsesale/white-horse.png"
          width="100"
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn text @click="logout()">
        <span class="mr-2 hidden-sm-and-down">Cerrar Sesión</span>
        <v-icon>mdi-account-arrow-right-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      style="position:fixed; top:0; right:0; overflow-y:scroll; z-index: 10001;"
    >
      <v-card
        class="d-flex justify-center aling-center"
        max-width="434"
        tile
        dark
      >
        <v-row align="end" class="fill-height mx-auto">
          <v-col class="d-flex justify-center aling-center" cols="12">
            <v-avatar class="profile" color="grey" size="120">
              <v-img :src="item.image"></v-img>
            </v-avatar>
          </v-col>
          <v-col class="d-flex justify-center aling-center">
            <v-list-item color="rgba(0, 0, 0, .4)" dark>
              <v-list-item-content>
                <v-list-item-title class="title text-center">
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-center">
                  {{ item.mail }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <!-- </v-img> -->
      </v-card>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
            v-for="item in drawerList"
            :key="item.name"
            @click="goTo(item.direction)"
          >
            <v-list-item-icon v-if="item.img">
              <v-img 
                :aspect-ratio="16/9"
                :src="item.img" 
                max-height="100px"
                contain
            />
            </v-list-item-icon>
            <v-list-item-icon v-else>
              <v-icon color="black">mdi-{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showHelp"
      absolute
      temporary
      right
      width="70%"
      style="overflow-y:scroll;"
    >
      <v-card elevation="0">
        <v-card-title class="text-center">
          <h1 dark>{{ content.title }}</h1>
        </v-card-title>
        <v-card-subtitle>
          <h4>{{ content.subtitle }}</h4>
        </v-card-subtitle>
        <v-card-text>
          <v-expansion-panels class="mb-6" flat mandatory>
            <v-expansion-panel
              v-for="section in content.sections"
              :key="section.title"
            >
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                <strong>{{ section.title }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li v-for="info in section.info" :key="info.title">
                    <strong>
                      {{ info.title }}
                    </strong>
                    <p>
                      {{ info.description }}
                    </p>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-main class="blue-grey lighten-5 full-height">
      <v-alert dense color="orange" v-if="showDocsWarning" dark>
        <v-row>
          <v-col cols="8" class="text-left ma-auto">
            <strong>
               Es requerido que actualices tus documentos de identificación, no podrás continuar con la captura.
            </strong>
          </v-col>
          <v-col cols="4" class="text-right ma-auto">
            <span @click="goTo(drawerList[1].direction)" class="cursor-mouse">
              ir a {{ drawerList[1].name }}</span
            >
          </v-col>
        </v-row>
      </v-alert>
      <v-container fluid class="px-5">
        <router-view />
      </v-container>
    </v-main>
    <NotificationContainer />
  </div>
</template>

<script>
import EventBus from "@/services/events";
import NotificationContainer from "@/components/NotificationContainer";
import salesOptions from "../config/salesOptions.json";
import { mapState } from "vuex";

export default {
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push("/");
    } else {
      if (this.$session.get("type") != "SLS") {
        alert("401");
        this.$router.go(-1);
      }
    }
  },
  async created() {
    this.salesman = this.$session.get("salesman");
    // await this.$store.dispatch("salesmenModule/fetchItem", this.salesman);
    await this.$store.dispatch("salesmenModule/setMaster", this.salesman);

  },
  async mounted() {
    this.fullDrawerList = salesOptions;
    await this.$store.dispatch(
      "salesmenModule/fetchItem",
      (this.salesman = this.$session.get("salesman"))
    );
    EventBus.$on("call-show-help", (payload) => {
      this.showHelp = true;
      this.content = payload;
    });
    // if(!this.showDocsWarning){
    //   this.drawerList.push({ icon: "calendar-clock", name: "Eventos", direction: "EventsSales" })
    //   this.drawerList.push({ icon: "horse", name: "Registro de Caballo", direction: "HorseSales",img:'/images/33157.png'})
    // }
  },
  data: function() {
    return {
      drawer: true,
      showHelp: false,
      group: null,
      salesman: {},
      fullDrawerList: [],
      content: {},
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  components: {
    NotificationContainer,
  },
  methods: {
    logout() {
      this.$session.destroy();
      this.$router.replace({ name: "homepaga-us" });
    },
    goTo(direction) {
      if (this.$router.history.current.name !== direction)
        this.$router.replace({ name: direction });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.salesmenModule.loadingStatus,
      item: (state) => state.salesmenModule.singleItem,
    }),
    showDocsWarning() {
      return !this.item.ine || !this.item.comp === null;
    },
    drawerList(){
      return this.fullDrawerList.filter(element => element.visible === true)
    },
  }
}
</script>

<style lang="scss">
  .full-height {
    height: 100%;
  }
</style>
