<template>
  <div>
    <AdminHeader
      color="black"
      icon="account-edit"
      title="Perfil de vendedor"
      :showHelp="true"
      :content="content"
    />
    <Loader v-if="loading" :size="64" />
    <v-row>
      <v-col cols="12" md="3">
        <v-sheet rounded="lg" color="white">
          <v-list color="transparent">
            <v-list-item> Perfil de Vendedor </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  justify="center"
                  class="justify-center align-center mx-auto text-center"
                >
                  <v-avatar class="profile" color="grey" size="150" tile>
                    <v-img
                      :src="newOne.image"
                      :key="newOne.image"
                      v-if="profilePicture"
                    ></v-img>
                    <v-img :src="url" :key="url" v-else></v-img>
                  </v-avatar>
                  <v-btn
                    color="amber"
                    fab
                    small
                    absolute
                    top
                    right
                    @click="editImage = true"
                  >
                    <v-icon dark>mdi-lead-pencil</v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <v-list-item v-if="editImage" color="grey lighten-4">
              <v-list-item-content>
                <v-list-item-title>
                  <v-row>
                    <v-col>
                      <v-file-input
                        v-model="image"
                        counter
                        accept="image/png, image/jpeg, image/bmp"
                        show-size
                        truncate-length="50"
                        @change="changeFileImagen()"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-btn
                        small
                        color="amber"
                        :disabled="readyToUploadImg"
                        @click="upload(1)"
                      >
                        <!--  -->
                        <v-icon left> mdi-upload </v-icon>
                        Subir
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        small
                        :disabled="readyToUploadImg"
                        @click="croppingPhoto = true"
                      >
                        <v-icon dark>mdi-crop</v-icon>
                        Cortar
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn small @click="cancelUploadImg()">
                        <v-icon left> mdi-cancel </v-icon>
                        Cancelar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="mx-auto justify-center aling-center text-center">
            <h4 class="text-center">Logros desbloqueados</h4>
            <v-progress-linear
              v-model="power"
              :color="power < 40 ? 'red' : power < 75 ? 'orange' : 'green'"
              height="15"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <img v-if="newOne.info" src="images/icons/logroGreen.png" alt="logro completo" height="40px" />
                  <img v-else src="images/icons/logroRed.png" alt="logro incompleto" height="40px" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="newOne.info ? 'black--text' : 'primary--text'">Información completa</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <img v-if="newOne.documents" src="images/icons/logroGreen.png" alt="logro completo" height="40px" />
                  <img v-else src="images/icons/logroRed.png" alt="logro incompleto" height="40px" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="newOne.documents ? 'black--text' : 'primary--text'">Documentación completa</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <img v-if="newOne.bankInfo" src="images/icons/logroGreen.png" alt="logro completo" height="40px" />
                  <img v-else src="images/icons/logroRed.png" alt="logro incompleto" height="40px" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="newOne.bankInfo ? 'black--text' : 'primary--text'">Información bancaria completa</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <img v-if="newOne.firstHorse" src="images/icons/logroGreen.png" alt="logro completo" height="40px" />
                  <img v-else src="images/icons/logroRed.png" alt="logro incompleto" height="40px" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="newOne.firstHorse ? 'black--text' : 'primary--text'">Primer caballo registrado</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <img v-if="newOne.firstCredit" src="images/icons/logroGreen.png" alt="logro completo" height="40px" />
                  <img v-else src="images/icons/logroRed.png" alt="logro incompleto" height="40px" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="newOne.firstCredit ? 'black--text' : 'primary--text'">Primera inscripción</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <img v-if="newOne.firstEvent" src="images/icons/logroGreen.png" alt="logro completo" height="40px" />
                  <img v-else src="images/icons/logroRed.png" alt="logro incompleto" height="40px" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="newOne.firstEvent ? 'black--text' : 'primary--text'">Primera subasta</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-sheet>
      </v-col>

      <v-col>
        <v-card>
          <v-tabs color="secondary" fixed-tabs hide-slider>
            <v-tab
              class="
                text-caption
                text-sm-body-1
                text-md-subtitle-2
                text-lg-subtitle-1
              "
            >
              <v-icon v-if="newOne.info" class="mx-1" color="green"
                >mdi-check-circle
              </v-icon>
              <v-icon v-else class="mx-1" color="primary">mdi-alert </v-icon>
              Datos <br  />
              personales
            </v-tab>
            <v-tab
              v-if="!newRanch.default && !isSmall"
              class="
                text-caption
                text-sm-body-1
                text-md-subtitle-2
                text-lg-subtitle-1
              "
              
            >
              <v-icon v-if="newOne.documents" class="mx-1" color="green"
                >mdi-check-circle
              </v-icon>
              <v-icon v-else class="mx-1" color="primary">mdi-alert </v-icon>
              Rancho</v-tab
            >
            <v-tab
              class="
                text-caption
                text-sm-body-1
                text-md-subtitle-2
                text-lg-subtitle-1
              "
            >
              <v-icon class="mx-1" color="green">mdi-check-circle </v-icon>
              Documentos</v-tab
            >
            <v-tab
              class="
                text-caption
                text-sm-body-1
                text-md-subtitle-2
                text-lg-subtitle-1
              "
            >
              <v-icon v-if="newOne.bankInfo" class="mx-1" color="green"
                >mdi-check-circle
              </v-icon>
              <v-icon v-else class="mx-1" color="primary">mdi-alert </v-icon>
              Datos <br v-if="isSmall" />
              Bancarios</v-tab
            >

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <form>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="newOne.name"
                            :counter="200"
                            label="Nombre"
                            required
                            :disabled="!editPersonal"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4">
                          <v-text-field
                            v-model="newOne.lastnamep"
                            :counter="100"
                            label="Apellido Paterno"
                            required
                            :disabled="!editPersonal"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4">
                          <v-text-field
                            v-model="newOne.lastnamem"
                            :counter="150"
                            label="Apellido materno"
                            required
                            :disabled="!editPersonal"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="newOne.phone"
                            :counter="100"
                            label="Número de contacto"
                            required
                            :disabled="!editPersonal"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="newOne.mail"
                            :counter="150"
                            label="Email de contacto"
                            required
                            :disabled="!editPersonal"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-text-field
                        v-model="newOne.address"
                        :counter="150"
                        label="Dirección"
                        :disabled="!editPersonal"
                      ></v-text-field>
                    </form>
                    <v-btn @click="guardar()" color="amber">
                      <v-icon left>
                        {{ editPersonal ? "" : "mdi-lead-pencil" }}
                      </v-icon>
                      {{ editPersonal ? "Guardar" : "Editar" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item v-if="!newRanch.default && !isSmall">
              <v-row justify="center" class="mx-auto">
                <v-col cols="12" md="3" centered>
                  <v-img
                    contain
                    :lazy-src="newRanch.image"
                    max-height="200"
                    max-width="200"
                    :src="newRanch.image"
                  ></v-img>
                </v-col>
                <v-col cols="12" md="7" class="justify-center">
                  <h5>Nombre: {{ newRanch.name }}</h5>
                  <h5 :key="city._id" v-if="city">
                    Ciudad: {{ city.name }} - {{ city.state.name }}
                  </h5>
                  <br />
                  <h5>Correo: {{ newRanch.mail }}</h5>
                  <h5>Telefono de contacto: {{ newRanch.phone }}</h5>
                </v-col>
              </v-row>
              <v-row class="ma-3">
                <v-col cols="12" sm="8"> </v-col>
                <v-col
                  class="text-right pt-1 aling-right justify-right"
                  cols="12"
                  sm="4"
                >
                  <v-btn color="amber" @click="dialogCaptura = !dialogCaptura">
                    Solicitar actualización de datos
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="dialogCaptura" justify="center">
                <v-col cols="10">
                  <v-text-field
                    v-model="ranchCapture.name"
                    :counter="100"
                    label="Nombre del Rancho"
                    :rules="nameRules"
                    @keydown="validateUniqName(ranchCapture.name)"
                    @input="validateUniqName(ranchCapture.name)"
                    @focus="validateUniqName(ranchCapture.name)"
                    @change="validateUniqName(ranchCapture.name)"
                    @formchange="validateUniqName(ranchCapture.name)"
                  ></v-text-field>
                  <span v-if="!showWarningUniq"
                    >El nombre de tu rancho debe ser único, validaremos que no
                    existan ranchos registrados con el mismo nombre</span
                  >
                  <v-alert
                    :color="validName ? 'green' : 'orange'"
                    type="info"
                    v-if="showWarningUniq"
                  >
                    {{ alertTextUniq }}</v-alert
                  >
                  <div v-if="validName">
                    <v-text-field
                      v-model="ranchCapture.mail"
                      :counter="50"
                      :rules="emailRules"
                      label="Email de contacto"
                    ></v-text-field>
                    <v-autocomplete
                      v-model="countrySelect"
                      :items="countriesData"
                      item-text="name"
                      item-value="_id"
                      label="País"
                      persistent-hint
                      return-object
                      single-line
                      @input="changeCountry(countrySelect._id)"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-if="showState"
                      v-model="stateSelect"
                      :items="statesData"
                      item-text="name"
                      item-value="_id"
                      label="Estado"
                      persistent-hint
                      return-object
                      single-line
                      @input="changeState(stateSelect._id)"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-if="showCity"
                      v-model="citySelect"
                      :items="citiesData"
                      item-text="name"
                      item-value="_id"
                      label="Ciudad"
                      persistent-hint
                      return-object
                      single-line
                      @input="changeCity(citySelect._id)"
                    ></v-autocomplete>
                    <span
                      >Nos reservamos el derecho de registro de empresas de los
                      estados y ciudades disponibles</span
                    >
                    <div id="preview">
                      <img
                        v-if="url"
                        style="max-height: 200px; widht: 100%"
                        :src="url"
                      />
                    </div>
                    <v-file-input
                      v-model="file"
                      counter
                      label="Logo"
                      accept="image/png, image/jpeg, image/bmp"
                      show-size
                      truncate-length="50"
                      @change="changeFile()"
                    ></v-file-input>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-container fluid>
                <v-row v-if="!editDocuments">
                  <v-col cols="4">
                    <h6>INE</h6>
                    <v-img
                      contain
                      max-height="200"
                      max-width="200"
                      :src="newOne.ine"
                    ></v-img>
                  </v-col>
                  <v-col cols="4">
                    <h6>INE Trasera</h6>
                    <v-img
                      contain
                      max-height="200"
                      max-width="200"
                      :src="newOne.ineBack"
                    ></v-img>
                  </v-col>
                  <v-col cols="4">
                    <h6>Comprobante de domicilio</h6>
                    <v-img
                      contain
                      max-height="200"
                      max-width="200"
                      :src="newOne.comp"
                    ></v-img>
                  </v-col>
                </v-row>
                <div class="mx-auto justify-center aling-center text-center">
                  <v-btn v-if="!editDocuments" class="amber" @click="editDocuments = !editDocuments">
                    <v-icon left> mdi-upload </v-icon> Editar documentos
                  </v-btn>
                  <v-btn v-else class="primary" @click="editDocuments = !editDocuments">
                    <v-icon left> mdi-cancel </v-icon> Cancelar
                  </v-btn>
                </div>

                <v-row v-if="editDocuments">
                  <v-col>
                    <form>
                      <v-divider class="my-2"></v-divider>
                      <h3>INE o IFE (parte frontal)</h3>
                      <v-row>
                        <v-col cols="12" md="9">
                          <div id="preview">
                            <img
                              v-if="urlIne"
                              style="max-height: 200px; widht: 100%"
                              :src="urlIne"
                            />
                          </div>
                          <v-file-input
                            v-model="ine"
                            counter
                            accept="image/png, image/jpeg, image/bmp"
                            show-size
                            truncate-length="50"
                            @change="changeFileIne()"
                          ></v-file-input>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-btn
                            @click="upload(2)"
                            color="amber"
                            :disabled="!ine"
                          >
                            <v-icon left> mdi-upload </v-icon>
                            subir
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-divider class="my-2"></v-divider>
                      <h3>Parte trasera identificación</h3>
                      <v-row>
                        <v-col cols="12" md="9">
                          <div id="preview">
                            <img
                              v-if="urlineBack"
                              style="max-height: 200px; widht: 100%"
                              :src="urlineBack"
                            />
                          </div>
                          <v-file-input
                            v-model="ineBack"
                            counter
                            accept="image/png, image/jpeg, image/bmp"
                            show-size
                            truncate-length="50"
                            @change="changeFileineBack()"
                          ></v-file-input>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-btn
                            @click="upload(4)"
                            color="amber"
                            :disabled="!ineBack"
                          >
                            <v-icon left> mdi-upload </v-icon>
                            Subir
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-divider class="my-2"></v-divider>
                      <h3>Comprobante de domicilio</h3>
                      <v-row>
                        <v-col cols="12" md="9">
                          <div id="preview">
                            <img
                              v-if="urlCom"
                              style="max-height: 200px; widht: 100%"
                              :src="urlCom"
                            />
                          </div>
                          <v-file-input
                            v-model="comp"
                            counter
                            accept="image/png, image/jpeg, image/bmp"
                            show-size
                            truncate-length="50"
                            @change="changeFileComp()"
                          ></v-file-input>
                        </v-col>
                        <v-col md="3" cols="12">
                          <v-btn
                            class="mx-auto"
                            @click="upload(3)"
                            color="amber"
                            :disabled="!comp"
                          >
                            <v-icon left> mdi-upload </v-icon>
                            Subir
                          </v-btn>
                        </v-col>
                      </v-row>
                    </form>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container fluid>
                <h5>
                  La siguiente información es solicitada para poder realizar la
                  transferencia de los recursos después de una venta
                </h5>
                <v-row>
                  <v-col>
                    <form>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="newOne.banco"
                            :counter="100"
                            label="Banco"
                            required
                            :disabled="!editBancarios"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="12">
                          <v-text-field
                            v-model="newOne.clabe"
                            :counter="18"
                            label="Clabe"
                            required
                            :disabled="!editBancarios"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="12">
                          <v-text-field
                            v-model="newOne.titular"
                            :counter="18"
                            label="Titular de la cuenta"
                            required
                            :disabled="!editBancarios"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </form>
                    <v-btn @click="updateDatosB()" color="amber">
                      <v-icon left>
                        {{ editBancarios ? "" : "mdi-lead-pencil" }}
                      </v-icon>
                      {{ editBancarios ? "Guardar" : "Editar" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="croppingPhoto" persistent>
      <v-card max-height="600px">
        <v-card-title class="headline">
          Cortar imagen
          <v-spacer></v-spacer>
          <v-btn text @click="croppingPhoto = false"> Cancelar </v-btn>
          <v-btn color="red" text @click="crop"> Confirmar </v-btn>
        </v-card-title>
        <v-card-text class="upload-example">
          <div class="upload-example__cropper-wrapper">
            <cropper
              ref="cropper"
              class="upload-example__cropper"
              check-orientation
              :src="url"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "../../services/events";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import AdminHeader from "@/components/adminSide/AdminHeader";
import Loader from "@/components/adminSide/Loader";

export default {
  data() {
    return {
      editDocuments: false,
      ranchCapture: {
        name: "",
      },
      newOne: this.newObject(),
      newRanch: this.newObject(),
      editImage: false,
      image: null,
      ine: null,
      comp: null,
      ineBack: null,
      readyToUploadImg: true,
      url: null,
      urlIne: null,
      urlineBack: null,
      urlCom: null,
      profilePicture: true, // true image from user, false image edit
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      croppingPhoto: false,
      editPersonal: false,
      editBancarios: false,
      dialogCaptura: false,
      content: {
        title: "Mi Perfil",
        subtitle:
          "En esta sección podrás configurar la información relacionada con tus datos de vendedor.",
        sections: [
          {
            title: "General",
            info: [
              {
                title: "Fotografía",
                description:
                  "Puedes subir una imagen de perfil para que los compradores puedan ubicarte.",
              },
            ],
          },
          {
            title: "Datos Personales",
            info: [
              {
                title: "Nombre (Obligatorio)",
                description: "Campo para captura del nombre del vendedor",
              },
              {
                title: "Apellido paterno (Obligatorio)",
                description:
                  "Campo para captura del apellido paterno del vendedor",
              },
              {
                title: "Apellido materno (Obligatorio)",
                description:
                  "Campo para captura del apellido materno del vendedor",
              },
              {
                title: "Número de contacto",
                description: "Teléfono de contacto del vendedor",
              },
              {
                title: "Email de contacto",
                description:
                  "Correo electrónico de contacto del vendedor, a este correo llegarán las notificaciones de compra/venta",
              },
              {
                title: "Dirección",
                description:
                  "Información de contacto para ubicación física del vendedor, en caso de reclamaciones por incumplimiento",
              },
            ],
          },
          {
            title: "Rancho",
            info: [
              {
                title: "Descripción general del Rancho afiliado",
                description:
                  "Esta información no puede ser editada por el vendedor, es requerido solicitar a soporte@ehorsesale.com la actualización",
              },
              {
                title: "Solicitud de actualización de datos",
                description:
                  "La información relacionada al rancho solamente puede ser actualizada con solicitud al equipo de eHorsale",
              },
            ],
          },
          {
            title: "Documentos",
            info: [
              {
                title: "Identificación oficial INE/IFE (Obligatorio)",
                description:
                  "Mecanismo de identificación de vendedores para garantizar transacciones seguras",
              },
              {
                title: "Comprobante de domicilio (Obligatorio)",
                description:
                  "Mecanismo de identificación de vendedores para garantizar transacciones seguras",
              },
            ],
          },
          {
            title: "Datos Bancarios",
            info: [
              {
                title: "Banco",
                description:
                  "Institución bancaría donde el vendedor tiene una cuenta",
              },
              {
                title: "CLABE",
                description:
                  "Clabe de identificación para transferencias interbancarias, en esta cuenta será realizada la liquidación de tus ventas",
              },
              {
                title: "Titular de la cuenta",
                description:
                  "Persona física o moral representante de la cuenta donde serán transferidos los recursos",
              },
            ],
          },
        ],
      },
    };
  },
  components: {
    Cropper,
    AdminHeader,
    Loader,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.configModule.loadingStatus,
      loadingsalesman: (state) => state.salesmenModule.loadingStatus,
      item: (state) => state.salesmenModule.singleItem,
      ranch: (state) => state.ranchsModule.singleItem,
      city: (state) => state.citiesModule.singleItem,
    }),
    showDocsWarning() {
      return !this.item.ine || !this.item.comp;
    },
    isSmall() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
    power() {
      let power = 0;
      power = power + (this.newOne.info ? 16.6 : 0);
      power = power + (this.newOne.documents ? 16.6 : 0);
      power = power + (this.newOne.bankInfo ? 16.6 : 0);
      power = power + (this.newOne.firstHorse ? 16.6 : 0);
      power = power + (this.newOne.firstCredit ? 16.6 : 0);
      power = power + (this.newOne.firstEvent ? 16.6 : 0);
      return power;
    },
  },
  methods: {
    guardar() {
      if (this.editPersonal) {
        const updated = this.newOne
        delete updated.image
        this.$store.dispatch("salesmenModule/updateItem", updated);
        this.$store.dispatch("salesmenModule/updateBadgeInfo", this.newOne);
        this.$store.dispatch(
          "salesmenModule/fetchItem",
          (this.salesman = this.$session.get("salesman"))
        );
      }
      this.editPersonal = !this.editPersonal;
    },
    newObject() {
      return {
        name: "",
        banco: "",
        clabe: "",
        titular: "",
      };
    },
    updateDatosB() {
      if (this.editBancarios) {
        var datosB = {
          _id: this.newOne._id,
          banco: this.newOne.banco,
          clabe: this.newOne.clabe,
          titular: this.newOne.titular,
        };
        this.$store.dispatch("salesmenModule/updateItemDatosB", datosB);
        this.$store.dispatch("salesmenModule/updateBank", this.newOne);
        this.$store.dispatch(
          "salesmenModule/fetchItem",
          (this.salesman = this.$session.get("salesman"))
        );
      }
      this.editBancarios = !this.editBancarios;
    },
    changeFileImagen() {
      this.profilePicture = false;
      this.newOne.image = this.image;
      this.readyToUploadImg = false;
      this.url = URL.createObjectURL(this.image);
    },
    changeFileIne() {
      this.urlIne = URL.createObjectURL(this.ine);
      this.newOne.ine = this.ine;
    },
    changeFileComp() {
      this.urlCom = URL.createObjectURL(this.comp);
      this.newOne.comp = this.comp;
    },
    changeFileineBack() {
      this.urlineBack = URL.createObjectURL(this.ineBack);
      this.newOne.caratula = this.ineBack;
    },
    upload(index) {
      let fileUpdate;

      switch (index) {
        case 1:
          fileUpdate = {
            _id: this.newOne._id,
            image: this.newOne.image,
            type: index,
          };
          break;
        case 2:
          fileUpdate = { _id: this.newOne._id, image: this.ine, type: index };
          break;
        case 3:
          fileUpdate = { _id: this.newOne._id, image: this.comp, type: index };
          break;
        case 4:
          fileUpdate = {
            _id: this.newOne._id,
            image: this.ineBack,
            type: index,
          };
          break;
        default:
          alert("Favor de revisar la imagen que subió");
      }
      this.$store
        .dispatch("salesmenModule/updateSingleImage", fileUpdate)
        .then(() => {
          this.$store.dispatch(
            "salesmenModule/fetchItem",
            (this.salesman = this.$session.get("salesman"))
          );
          this.editDocuments = false;
          this.ineBack = "";
          this.comp = "";
          this.ine = "";
          if (this.newOne.ine && this.newOne.ineBack && this.newOne.comp)
            this.$store.dispatch("salesmenModule/updateDocuments", this.newOne);
        });
      this.readyToUploadImg = false;
    },
    cancelUploadImg() {
      this.$store.dispatch(
        "salesmenModule/fetchItem",
        (this.salesman = this.$session.get("salesman"))
      );
      this.editImage = false;
      this.readyToUploadImg = true;
      this.profilePicture = true;
      this.image = null;
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.newOne.image = canvas.toDataURL();
      this.url = canvas.toDataURL();
      canvas.toBlob((blob) => {
        let fileUpdate;

        fileUpdate = { _id: this.newOne._id, image: blob, type: 1 };
        this.$store.dispatch("salesmenModule/updateSingleImage", fileUpdate);
        this.readyToUploadImg = false;
        this.editImage = false;
      });
      this.$store.dispatch(
        "salesmenModule/fetchItem",
        (this.salesman = this.$session.get("salesman"))
      );
      this.croppingPhoto = false;
    },
  },
  beforeCreate() {
    this.$store.dispatch(
      "salesmenModule/fetchItem",
      (this.salesman = this.$session.get("salesman"))
    );
    this.profilePicture = true;
  },
  mounted() {
    EventBus.$on("set-single-salesman", () => {
      this.newOne = this.item;
      if(this.newOne.ranch){
        this.$store.dispatch("ranchsModule/fetchItem", this.newOne.ranch);
      }
    });
    EventBus.$on("set-single-ranch", () => {
      this.newRanch = this.ranch;
      if (!this.ranch.default) {
        this.$store.dispatch("citiesModule/fetchItem", this.newRanch.city);
      }
    });
  },
  destroyed() {
    EventBus.$off("set-single-salesman");
    EventBus.$off("set-single-ranch");
  },
};
</script>

<style lang="scss">
.upload-example {
  margin-top: 20px;
  margin-bottom: 20px;
  user-select: none;
  &__cropper {
    border: solid 1px #eee;
    min-height: 300px;
    max-height: 500px;
    width: 100%;
  }
  &__cropper-wrapper {
    position: relative;
  }
  &__reset-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    background: rgba(#3fb37f, 0.7);
    transition: background 0.5s;
    &:hover {
      background: #3fb37f;
    }
  }
  &__buttons-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }
  &__button {
    border: none;
    outline: solid transparent;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    background: #3fb37f;
    cursor: pointer;
    transition: background 0.5s;
    margin: 0 16px;
    &:hover,
    &:focus {
      background: #38d890;
    }
    input {
      display: none;
    }
  }
  &__file-type {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #0d0d0d;
    border-radius: 5px;
    padding: 0px 10px;
    padding-bottom: 2px;
    font-size: 12px;
    color: white;
  }
}
</style>