<template>
  <div width="100%">
    <AppLoader v-if="false" :size="100" color="primary" />
    <v-row class="d-flex aling-center justify center ma-0" no-gutters>
      <v-col cols="12" md="6" :class="isSmall ? 'mb-n16' : 'ma-auto'">
        <v-img
          :aspect-ratio="isSmall ? 1.6 : ''"
          :height="imageHeight"
          src="/images/ehorsesale/login/YeguasyPotrillos-6.jpg"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-10"
        :class="isSmall ? 'mt-n16' : 'ma-auto'"
      >
        <v-card elevation="3">
          <v-card-title class="text-center">Contacto</v-card-title>
          <v-card-subtitle>
            <p>
              ¿Necesitas ayuda?
              <strong> equipo eHorseSale </strong>
            </p>
          </v-card-subtitle>
          <v-card-text>
            <v-text-field
              v-model="data.email"
              label="correo electrónico"
              :rules="emailRules"
            ></v-text-field>
            <v-text-field v-model="data.topic" label="Asunto"></v-text-field>
            <v-textarea v-model="data.message" label="Mensaje"></v-textarea>
            <v-alert color="orange" type="info" v-if="showWarning">{{
              warningText
            }}</v-alert>
          </v-card-text>
          <v-card-actions> </v-card-actions>
          <v-card-actions class="justify-center">
            <v-btn color="primary" @click="sendMessage()">
              Envíar Mensaje
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <NotificationContainer />
  </div>
</template>

<script>
import AppLoader from "@/components/base/AppLoader.vue";
import NotificationContainer from "@/components/NotificationContainer";

export default {
  components: {
    AppLoader,
    NotificationContainer
  },
  data() {
    return {
      data: {
        email: "",
        topic: "",
        message: "",
      },
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe ser valido",
      ],
      showWarning: false,
    };
  },
  computed: {
    isSmall() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
    imageHeight() {
      let value;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          value = "200px";
          break;
        case "sm":
          value = "300px";
          break;
        case "md":
          value = "700px";
          break;
        case "lg":
          value = "800px";
          break;
        case "xl":
          value = "800px";
          break;
      }
      return value;
    },
  },
  methods: {
    async sendMessage(){
        await this.$store.dispatch("messagesModule/createItem", this.data);
    }
  }
};
</script>