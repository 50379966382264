<template>
    <div>
        <AdminHeader color="black" icon="horse" title="Información general del caballo"/>
        <ImagePreview v-if="imageDialog" :imgDialog="imgDialog" @closePreview="closePreview"/>
        <Loader v-if="idLoading" :size="64" />
        <v-row v-else>
            <v-col md="2" cols="12">
                <v-sheet rounded="lg">
                    <v-list color="transparent">
                        <v-list-item
                            v-for="section in sections.filter(sec => sec.ver < 4)"
                            :key="section.ver"
                            link
                            @click="activateSection(section.ver)"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-{{section.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                            {{section.name}}
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider class="my-2"></v-divider>
                        <v-list-item
                            link
                            color="grey lighten-4"
                            @click="goBack()"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                            >                            
                                Regresar
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            link
                            color="black"
                            v-if="[1,2,4].includes(item.momento)"
                            @click="goToedit()"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-lead-pencil</v-icon> 
                            </v-list-item-icon>
                            <v-list-item-title>                                
                                Editar
                            </v-list-item-title>                        
                        </v-list-item>
                    </v-list>
                </v-sheet>
            </v-col>
            <v-col md="10" sm="12">
                <v-card
                    v-if="activeSection === 0" 
                    elevation="5"
                    height="100%"
                >
                    <v-card-title>
                        <h6>
                            <v-icon class="mr-1">mdi-{{sections[activeSection].icon}}</v-icon>
                            {{sections[activeSection].name}}
                        </h6>
                        <v-spacer></v-spacer>
                    </v-card-title>  
                    <v-card-text>
                        <HorseDetailGeneral :item="item" @showImage="showImage"/>
                    </v-card-text>
                </v-card>
                <!-- <v-card
                    v-if="activeSection === 2"
                    elevation="5"
                    height="100%"
                >
                    <v-card-title>
                        <h6>
                            <v-icon class="mr-1">mdi-{{sections[activeSection].icon}}</v-icon>
                            {{sections[activeSection].name}}
                        </h6>
                        <v-spacer></v-spacer>
                        <h6 v-if="hdtData[0]">Tiempo de entrenamiento {{hdtData[0].time}} años</h6>
                    </v-card-title>  
                    <v-card-text>
                        <div>
                            <h1>Nombre: {{item.name}}</h1>
                        </div>
                        <v-divider class="my-3"></v-divider>
                        <div v-if="hdtData[0]">
                            <h3>Valoración de entretamiento:  ( 1 Bien - 5 Mal)</h3>   
                            <v-row>
                                <v-col cols="12" sm="4">
                                    Nivel de experiencia requerido por el manejador
                                    <v-rating
                                        v-model="hdtData[0].levelOfExp"
                                        background-color="black"
                                        color="black"
                                        readonly
                                        size="25"
                                    ></v-rating>

                                </v-col>
                                <v-col cols="12" sm="4">
                                    Calificación para montar o manejar la disposición del caballo
                                    <v-rating
                                        v-model="hdtData[0].levelOfRiding"
                                        background-color="black"
                                        color="black"
                                        readonly
                                        size="25"
                                    ></v-rating>
                                </v-col>
                            </v-row>     
                            <h3>Valoración de comportamiento:  ( 1 Mal - 5 Bien)</h3> 
                            <v-row>
                                <v-col cols="12" md="4">
                                        Disposición General
                                    <v-rating
                                        v-model="hdtData[0].general"
                                        background-color="black"
                                        color="black"
                                        readonly
                                    ></v-rating>
                                </v-col>
                                <v-col cols="12" md="4">
                                    Modales en el puesto
                                    <v-rating
                                        v-model="hdtData[0].manners"
                                        background-color="black"
                                        color="black"
                                        readonly
                                    ></v-rating>
                                </v-col>
                                <v-col cols="12" md="4">
                                    Comporamiento con otros caballos
                                    <v-rating
                                        v-model="hdtData[0].alongWithHorse"
                                        background-color="black"
                                        color="black"
                                        readonly
                                    ></v-rating>
                                </v-col>
                            </v-row>                                     
                            <h3>Dificultad de manejo:  ( 1 Bien - 5 Mal)</h3> 
                            <v-row>
                                <v-col cols="12" md="4">
                                        Dificultad de herrar
                                    <v-rating
                                        v-model="hdtData[0].farrier"
                                        background-color="black"
                                        color="red"
                                        size="25"
                                    ></v-rating>
                                </v-col>
                                <v-col cols="12" md="4">
                                    Dificultad de carga y remolque
                                    <v-rating
                                        v-model="hdtData[0].loading"
                                        background-color="black"
                                        color="red"
                                        size="25"
                                    ></v-rating>
                                </v-col>
                                <v-col cols="12" md="4">
                                    Dificiltad para recorte
                                    <v-rating
                                        v-model="hdtData[0].clipping"
                                        background-color="black"
                                        color="black"
                                        size="25"
                                    ></v-rating>
                                </v-col>
                            </v-row>
                        </div>
                        <EmptyState v-else :white="true" />
                    </v-card-text>
                </v-card> -->
                <v-card
                    v-if="activeSection === 1"
                    elevation="5"
                    height="100%"
                >
                    <v-card-title>
                        <h6>
                            <v-icon class="mr-1">mdi-{{sections[activeSection].icon}}</v-icon>
                            {{sections[activeSection].name}}
                        </h6>
                        <v-spacer></v-spacer>          
                        <h6>SALUD</h6>
                    </v-card-title>
                    <v-card-text v-if="hdhData.length > 0">
                        <div>
                            <h1>Nombre: {{item.name}}</h1>
                        </div>
                        <v-divider class="my-3"></v-divider>
                        <div class="text-center" v-if="hdhData[0].file">
                            <pdfViewer :URL="hdhData[0].file" /><br>
                        </div>
                        <div class="text-center" v-if="hdhData[0].file">
                            <a :href="hdhData[0].file" target="blank"> Abrir </a>                            
                        </div>
                        <div v-if="hdhData[0]">
                                <h3 v-if="hdhData[0].observations" >Observaciones: {{hdhData[0].observations}} </h3>
                                <v-row v-if="hdhData[0]" class="ma-5">
                                    <v-col lg="2" md="3" sm="6" cols="6"><v-checkbox
                                            :input-value="hdhData[0].cribber"
                                            label="Aereofagia"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6"><v-checkbox
                                            :input-value="hdhData[0].weaver"
                                            label="Criptorquideo"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6"> <v-checkbox
                                            :input-value="hdhData[0].nerved"
                                            label="Transtorno Neurológico"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6"><v-checkbox
                                            :input-value="hdhData[0].foundered"
                                            label="Ring Boon"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6"><v-checkbox
                                            :input-value="hdhData[0].majorIS"
                                            label="Cirugia mayor"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6"><v-checkbox
                                            :input-value="hdhData[0].parrotMouth"
                                            label="¿Boca de perico?"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6"><v-checkbox
                                            :input-value="hdhData[0].colic"
                                            label="Laminitis"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6"><v-checkbox
                                            :input-value="hdhData[0].pastLameness"
                                            label="Cojera"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6"><v-checkbox
                                            :input-value="hdhData[0].defects"
                                            label="Transtorno navicular"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6"><v-checkbox
                                            :input-value="hdhData[0].osteartitis"
                                            label="Osteartitis"
                                            value
                                            disabled
                                        > </v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6" ><v-checkbox
                                            :input-value="hdhData[0].ceguera"
                                            label="Ceguera"
                                            value
                                            disabled
                                        ></v-checkbox></v-col>
                                </v-row>
                                <h3 if="showFemaleHealth">Información de Hembras</h3>
                                <v-row class="ma-5">        
                                    <v-col lg="2" md="3" sm="6" cols="6" ><v-checkbox
                                            :input-value="hdhData[0].aborto"
                                            label="¿Aborto?"
                                            value
                                            disabled
                                        ></v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6"  cols="6"><v-checkbox
                                            :input-value="hdhData[0].transtorno"
                                            label="¿transtorno en el ciclo?"
                                            value
                                            disabled
                                        ></v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6" ><v-checkbox
                                            :input-value="hdhData[0].extirpasion"
                                            label="¿Extirpación de ovarios?"
                                            value
                                            disabled
                                        ></v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6" ><v-checkbox
                                            :input-value="hdhData[0].cesarea"
                                            label="¿Cesárea?"
                                            value
                                            disabled
                                        ></v-checkbox></v-col>
                                    <v-col lg="2" md="3" sm="6" cols="6" ><v-checkbox
                                            :input-value="hdhData[0].cuello"
                                            label="¿Operaciones al cuello uterino?"
                                            value
                                            disabled
                                        ></v-checkbox></v-col>
                                </v-row>
                        </div>
                        <EmptyState v-else :white="true" />
                    </v-card-text>
                </v-card>
                <v-card
                    v-if="activeSection === 2" :key="hdpData.length"
                    elevation="5"
                    height="100%"
                >
                    <v-card-title>
                        <h6>
                            <v-icon class="mr-1">mdi-{{sections[activeSection].icon}}</v-icon>
                            {{sections[activeSection].name}}
                        </h6>
                        <v-spacer></v-spacer>                    
                    </v-card-title>  
                    <v-card-text>
                        <div>
                            <h1>Nombre: {{item.name}}</h1>
                        </div>
                        <v-divider class="my-3"></v-divider>
                        <PedigreeTree v-if="hdpData[0]" :pedigreeTree="hdpData[0]" />                                    
                        <EmptyState v-else :white="true" />
                    </v-card-text>
                </v-card>
                <v-card
                    v-if="activeSection === 3"
                    elevation="5"
                    height="100%"
                >
                    <v-card-title>
                        <h6>
                            <v-icon class="mr-1">mdi-{{sections[activeSection].icon}}</v-icon>
                            {{sections[activeSection].name}}
                        </h6>
                        <v-spacer></v-spacer>                        
                    </v-card-title>  
                    <v-card-text>
                        <div>
                            <h1>Nombre: {{item.name}}</h1>
                        </div>
                        <v-divider class="my-3"></v-divider>
                        <v-row v-if="item.youtubeVideo.length > 0 && item.youtubeVideo[0] !== '' ">
                            <v-col sm="4" v-for="video in item.youtubeVideo" :key="video">
                                <youtubeVisor :video="video" :small="false" />
                            </v-col>
                        </v-row>                                   
                        <EmptyState v-else :white="true" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
            <v-card>
                <v-card-title>
                    Observaciones de auditoría
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="3"
                        v-for="step in horseAuditData"
                                :key="step._id">
                            <v-alert
                                outlined
                                :type="step.status ? 'info':'warning'"
                                prominent
                                border="left"
                            >
                                <h3>Sección: {{sections[step.step].name}}</h3>
                                <h6>{{step.dateObs | date}}</h6>    
                                <span>{{step.comment}}</span>
                                <br/>
                                <v-btn @click="resolveOb(step)" v-if="!step.status" color="primary" x-small>resolver</v-btn>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import EventBus from '../../services/events';
import Loader from '@/components/adminSide/Loader';
import AdminHeader from '@/components/adminSide/AdminHeader';
import ImagePreview from '@/components/adminSide/imagePreview';
import PedigreeTree from '@/components/adminSide/PedigreeTree';
import EmptyState from '@/components/EmptyState';
import youtubeVisor from '@/components/adminSide/youtubeVisor';
import pdfViewer from '@/components/adminSide/pdfViewer';
import HorseDetailGeneral from "@/components/adminSide/HorseDetailGeneral";

export default {
    data(){
        return {
            sections:[
                {name:'General',ver: 0,icon:"file-document-outline"},
                // {name:'Entrenamiento',ver: 2,icon: "dumbbell"},
                {name:'Salud',ver: 1, icon:"bottle-tonic-plus"},
                {name:'Arbol Genealogico',ver: 2,icon:"family-tree"},
                {name:'Videos',ver: 3, icon:"video-vintage"},
                {name:'Vendedor',ver: 4, icon:"information-outline"}
            ],
            activeSection: 0,
            imgDialog: '',
            imageDialog: false,
        }    
    },
    mounted(){
        this.init();
        EventBus.$on("horse-fetch-item", () => {
            this.fetchDetails(); 
        });
    },
    methods:{
        async resolveOb(step){
            step.status = true
            await this.$store.dispatch('horseAuditModule/updateItem',step).then(() => {                                        
                this.$store.dispatch('horseAuditModule/fetchByMaster',this.$route.params.horse);    
            });
        },
        init(){
            this.$store.dispatch('horsesModule/fetchItem',this.$route.params.horse).then(()=>{
                this.fetchDetails(); 
            });
        },
        fetchDetails(){
            this.$store.dispatch('hdhModule/fetchByMaster',this.$route.params.horse);
            this.$store.dispatch('hdpModule/fetchByMaster',this.$route.params.horse);
            this.$store.dispatch('horseAuditModule/fetchByMaster',this.$route.params.horse);
        },
        activateSection(id){
            this.activeSection = id;
        },
        goBack(){
            this.$router.go(-1);
        },
        showImage(photo){
            this.imgDialog = photo;
            this.imageDialog = true;
        },
        closePreview(){
            this.imgDialog = '';
            this.imageDialog = false;
        },
        goToedit(){            
            this.$router.replace({name: "UpdateHorse", params: { horse: this.$route.params.horse }});	
        }
    },
    computed:{
        ...mapState({
                user: state => state.user,
                loadingHorse: state => state.horsesModule.loadingStatus,
                // loadinghdt: state => state.hdtModule.loadingStatus,
                loadinghdh: state => state.hdhModule.loadingStatus,
                loadinghdp: state => state.hdpModule.loadingStatus,
                item: state => state.horsesModule.singleItem,
                // hdtData: state => state.hdtModule.data,
                hdhData: state => state.hdhModule.data,
                hdpData: state => state.hdpModule.data,
                horseAuditData: state => state.horseAuditModule.data,
        }),
        idLoading(){
            return this.loadingHorse && this.loadinghdh && this.loadinghdp;
        },
    },
    components:{
        AdminHeader,
        Loader,
        ImagePreview,
        EmptyState,
        PedigreeTree,
        youtubeVisor,
        pdfViewer,
        HorseDetailGeneral
    }
}
</script>

<style>

</style>