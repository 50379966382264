import CRUDServices from '@/services/CRUDServices';
import EventBus from '../../services/events';

export const namespaced = true;

export const state = {
    loadingStatus: false,
    data: [],
    singleItem: {
      actualCredits: 0
    },
    pages: 0,
    total: 0,
    api: '/transaction/',
    justSaved: '',
    master: {}
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_DATA(state, data){
      state.data = data;
    },
    SET_NEW_OBJ(state, item){
      state.data.push(item)
    },
    SET_PAGES(state, pages){
      state.pages = pages
    },
    SET_TOTAL(state, total){
      state.total = total
    },
    SET_SINGLE(state, item){
      state.singleItem = item
    },
    SET_SAVED(state, id){
      state.justSaved = id
    },
    SET_MASTER(state, item){
      state.master = item
    }
}

export const actions = {
    setMaster({commit},master){
        commit('SET_MASTER',master);
    },
    fetchData({state,commit,dispatch}){      
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocs(state.api+'credits/credit_buy')
      .then(response => {        
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data);
        commit('SET_TOTAL',response.data.length);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching groups: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchDataAvance({state,commit,dispatch}){      
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocs(state.api+'all/first_payment')
      .then(response => {        
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data);
        commit('SET_TOTAL',response.data.length);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching groups: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchDataFinal({state,commit,dispatch}){      
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocs(state.api+'all/final_pay')
      .then(response => {        
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data);
        commit('SET_TOTAL',response.data.length);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching groups: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    validatePayment({state,commit,dispatch},id){
        commit('SET_LOADING_STATUS',true);
        CRUDServices.getItem(state.api+'last/',id)
            .then(response => {
                if(response.data != null){
                    commit('SET_SINGLE',response.data);
                    EventBus.$emit('set-single-credits');
                }
                commit('SET_LOADING_STATUS',false);
            }).catch(error =>{
                const notification = {
                    type: 'error',
                    message: 'There was a problem ferching payments: ' + error.message 
            }
                dispatch('notificationModule/add', notification, {root: true});
            });
      },
      
}

export const getters = {
  getData(state){
    return state.data;
  },
  getDataByID: state => id => {
    return state.data.find(item => item._id === id);
  },
  dataLength: state =>{
    return state.total
  },
}