<template>
  <v-main class="amber lighten-2">
    <Loader v-if="loading" :size="100" />
    <v-container>
      <v-card
        class="mx-auto justify-center aling-center ma-10"
        width="100%"
        height="100%"
        elevation="9"
        shaped
      >
        <v-sheet>
          <div class="d-flex justify-center aling-center">
            <Logo min-width="250" @click="goback()"/>
          </div>
        </v-sheet>
        <v-img height="250" src="/images/ehorsesale/slider/WW7G4K.jpeg"></v-img>
        <v-card-title>{{$t('globalAccess.adminTitle')}}</v-card-title>
        <v-card-subtitle>
          {{$t('globalAccess.subtitle1')}} {{$t('globalAccess.subtitle2')}}  <b> {{$t('global.platformName')}}</b>.<br />
          {{$t('globalAccess.subtitle3')}}
        </v-card-subtitle>
        <v-card-text class="pa-10">
          <form>
            <v-text-field
              v-model="user.name"
              label="Nombre de usuario"
              required
            ></v-text-field>
            <v-text-field
              v-model="user.pass"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Contraseña"
              @click:append="show1 = !show1"
              @keydown.enter="submit(user)"
            ></v-text-field>
            <v-alert color="orange" type="info" v-if="showWarning">{{$t('globalAccess.alertUserPass')}}</v-alert>
          </form>
        </v-card-text>
        <v-card-actions class="mx-auto justify-center aling-center py-10">
          <v-btn class="mr-4 black" dark @click="submit(user)">
            {{$t('buttons.login')}}
          </v-btn>
          <v-btn class="mr-4" @click="goback()">
            {{$t('buttons.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <NotificationContainer />
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "../../services/events";
import NotificationContainer from "@/components/NotificationContainer";
import Loader from "@/components/adminSide/Loader";
import Logo from "@/components/base/Logo";

export default {
  name: "adminAccess",
  components: {
    NotificationContainer,
    Loader,
    Logo
  },
  data() {
    return {
      user: {
        name: "",
        pass: "",
      },
      show1: false,
      showWarning: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.adminModule.loadingStatus,
      auth: (state) => state.adminModule.auth,
    }),
  },
  methods: {
    async submit(user) {
      if (this.validateField()) await this.$store.dispatch("adminModule/logIn", user);
      else this.showWarning = true;
    },
    goToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    goback() {
      this.$router.push({ name: "homepaga-us" });
    },
    validateField() {
      return this.user.name.length !== 0 && this.user.pass.length !== 0;
    },
  },
  mounted() {
    if (this.$session.exists() && this.$session.get("type") === "ADM") {
      this.goToDashboard();
    }
    EventBus.$on("logInSuccess", () => {
      this.$session.destroy();
      this.$session.start();
      this.$session.set("jwt", this.auth);
      this.$session.set("type", "ADM");
      this.goToDashboard();
    }),
      EventBus.$on("logInNULL", () => {
        this.showWarning = true;
      });
  },
};
</script>

<style></style>
