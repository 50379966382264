<template>
    <v-row>
        <v-col>            
            <v-img 
                :aspect-ratio="16/9"
                class="mx-5 justify-center aling-center" 
                :src=" !white ? '/images/empty.png' : '/images/emptystate.jpg'" 
                max-height="500px"
                alt="emptystate" 
                contain
            />
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "EmptyState",
        props: {
            white: {type: Boolean}
        }
    }
</script>

<style>

</style>