<template>
    <div> 
        <v-row>
            <v-col class="ma-16">
                <v-list-item-content>
                <div v-html="lastOne.body"></div>
              </v-list-item-content>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import { mapState } from "vuex";

    export default {
        computed: {
        ...mapState({
            loading: (state) => state.termsModule.loadingStatus,            
            lastOne: (state) => state.termsModule.lastItem,
            }),
        },
        async created(){
            await this.$store.dispatch("termsModule/getPublicableTerms");
        }
    }
</script>
