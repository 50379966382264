<template>
  <div class="grey lighten-3 py-16 pa-16">
    <v-sheet width="100%" class="ma-0 pa-0 text-center" color="grey lighten-3">
      <h3 class="pt-2 pb-2">
        <strong> Perfil de Usuario </strong>
      </h3>
    </v-sheet>
    <v-row>
      <v-col cols="12" md="3">
        <v-sheet rounded="lg" color="gray">
          <v-list color="transparent">
            <v-list-item> Perfil de Usuario </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  justify="center"
                  class="justify-center align-center mx-auto text-center"
                >
                  <v-avatar class="profile" color="grey" size="150">
                    <v-img
                      :src="newOne.image"
                      :key="newOne.image"
                      v-if="profilePicture"
                    ></v-img>
                    <v-img :src="url" :key="url" v-else></v-img>
                  </v-avatar>
                  <v-btn
                    color="amber"
                    fab
                    small
                    absolute
                    top
                    right
                    @click="editImage = true"
                  >
                    <v-icon dark>mdi-lead-pencil</v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-5"></v-divider>

            <v-list-item v-if="editImage" color="grey lighten-4">
              <v-list-item-content>
                <v-list-item-title>
                  <v-row>
                    <v-col>
                      <v-file-input
                        v-model="image"
                        counter
                        accept="image/png, image/jpeg, image/bmp"
                        show-size
                        truncate-length="50"
                        @change="changeFileImagen()"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-btn
                        small
                        color="amber"
                        :disabled="readyToUploadImg"
                        @click="upload(1)"
                      >
                        <!--  -->
                        <v-icon left> mdi-upload </v-icon>
                        Subir
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        small
                        :disabled="readyToUploadImg"
                        @click="croppingPhoto = true"
                      >
                        <v-icon dark>mdi-crop</v-icon>
                        Cortar
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn small @click="cancelUploadImg()">
                        <v-icon left> mdi-cancel </v-icon>
                        Cancelar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="9">
        <v-card class="pa-5">
          <v-tabs color="deep-purple accent-4" right>
            <v-tab>Datos personales</v-tab>
            <v-tab>Documentos</v-tab>
            <v-tab>Subastas Ganadas</v-tab>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <form>
                      <v-row>
                        <v-col lg="4" sm="12">
                          <v-text-field
                            v-model="newOne.name"
                            :counter="200"
                            label="Nombre"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col lg="4" sm="12">
                          <v-text-field
                            v-model="newOne.lastnamep"
                            :counter="100"
                            label="Apellido Paterno"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col lg="4" sm="12">
                          <v-text-field
                            v-model="newOne.lastnamem"
                            :counter="150"
                            label="Apellido materno"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            v-model="newOne.phone"
                            :counter="100"
                            label="Número de contacto"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            v-model="newOne.mail"
                            :counter="150"
                            label="Email de contacto"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-text-field
                        v-model="newOne.address"
                        :counter="150"
                        label="Dirección"
                      ></v-text-field>
                    </form>
                    <v-btn @click="guardar()">
                      <v-icon left> mdi-lead-pencil </v-icon>
                      Editar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <h6>INE (Frente)</h6>
                    <v-img
                      contain
                      max-height="200"
                      max-width="200"
                      :src="newOne.ine"
                    ></v-img>
                  </v-col>
                  <v-col>
                    <h6>INE (Trasera)</h6>
                    <v-img
                      contain
                      max-height="200"
                      max-width="200"
                      :src="newOne.ineBack"
                    ></v-img>
                  </v-col>
                  <v-col>
                    <h6>Comprobante de domicilio</h6>
                    <v-img
                      contain
                      max-height="200"
                      max-width="200"
                      :src="newOne.comp"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <form>
                      <v-divider class="my-2"></v-divider>
                      <h3>INE o IFE</h3>
                      <v-row>
                        <v-col cols="12" md="9">
                          <v-file-input
                            v-model="ine"
                            counter
                            accept="image/png, image/jpeg, image/bmp"
                            show-size
                            truncate-length="50"
                            @change="changeFileIne()"
                          ></v-file-input>
                        </v-col>
                        <v-col md="3">
                          <v-btn @click="upload(2)">
                            <v-icon left> mdi-upload </v-icon>
                            subir
                          </v-btn>
                        </v-col>
                      </v-row>
                      <h3>INE o IFE ( trasera ) </h3>
                      <v-row>
                        <v-col cols="12" md="9">
                          <v-file-input
                            v-model="ineBack"
                            counter
                            accept="image/png, image/jpeg, image/bmp"
                            show-size
                            truncate-length="50"
                            @change="changeFileIneBack()"
                          ></v-file-input>
                        </v-col>
                        <v-col md="3">
                          <v-btn @click="upload(4)">
                            <v-icon left> mdi-upload </v-icon>
                            subir
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-divider class="my-2"></v-divider>
                      <h3>Comprobante de domicilio</h3>
                      <v-row>
                        <v-col cols="12" md="9">
                          <v-file-input
                            v-model="comp"
                            counter
                            accept="image/png, image/jpeg, image/bmp"
                            show-size
                            truncate-length="50"
                            @change="changeFileComp()"
                          ></v-file-input>
                        </v-col>
                        <v-col md="3">
                          <v-btn @click="upload(3)">
                            <v-icon left> mdi-upload </v-icon>
                            Subir
                          </v-btn>
                        </v-col>
                      </v-row>
                    </form>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-row v-if="myEvents.length === 0">
                <EmptyState />
              </v-row>
              <v-row v-else>
                <v-col
                  v-for="eventView in myEvents"
                  :key="eventView._id"
                  cols="12"
                  md="6"
                >
                  <v-row>
                    <v-col cols="12">
                      <HorseItem :event="eventView" />
                    </v-col>
                    <v-col cols="6" class="text-center">
                      <strong>Primer pago:</strong> <v-spacer />
                      {{ 10000 | money }}
                    </v-col>
                    <v-col cols="6" class="text-center">
                      <strong>Pago pendiente:</strong>
                      <v-spacer />
                      {{ (eventView.currentBid - 10000) | money }}
                      <br />
                      <v-btn
                        @click="
                          showPay = true;
                          event = eventView;
                        "
                        class="primary"
                        >Liquidar</v-btn
                      >
                    </v-col>
                  </v-row>
                  <div></div>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-container fluid>
            <h1>Eventos en las que participé</h1>
            <h6 class="green text-right ma-2 pa-2">Eres la mejor oferta</h6>
            
            <v-simple-table class="ma-3">
              <thead>
                <tr>
                  <th class="text-left">Caballo</th>
                  <th class="text-left">Mi Oferta</th>
                  <th class="text-left">Fecha de mi oferta</th>
                  <th class="text-left">Oferta Actual</th>
                  <th class="text-left">Fecha termina el evento</th>
                </tr>
              </thead>
              <tbody v-if="myBids.length > 0">
                <tr 
                  v-for="bid in myBids" :key="bid.id"
                >
                  <td
                    @click="goToDetail(bid.event._id)"                    
                    class="cursor-mouse"
                    v-if="bid.event"
                  >
                    {{ bid.event.resume }}
                  </td>
                   <td
                    v-if="bid.event"
                   >{{ bid.currentBid | money }}</td>
                  <td
                    v-if="bid.event"
                  >{{ bid.biddate | date }}</td>
                  <td
                    v-if="bid.event"
                    :class="
                      bid.currentBid === bid.event.currentBid
                        ? 'green'
                        : ''
                    "
                  >
                    {{ bid.event.currentBid | money }}
                  </td>
                  <td v-if="bid.event && bid.event.open">
                    {{ bid.event.finishDate | date }}
                  </td>
                  <td v-else>
                    <span v-if="bid.event">
                      Evento finalizado falta liquidar
                    </span>
                    <span v-if="bid.event && bid.currentBid === bid.event.currentBid">
                      {{ (bid.event.horse.buyNow - 10000) | money }}
                      MXN
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>        
        </v-container>
      </v-col>
      <v-col cols="12" md="6">    
        <h1>Logros Desbloqueados</h1>
        <v-row justify="center">
          <v-col cols="6" sm="6" md="4" lg="3">
            <v-card>
              <v-card-text class="mx-auto text-center">
                <h5>Información completa</h5>
                <v-icon
                  class="ma-5"
                  :color="newOne.info ? 'green' : 'grey'"
                  size="35"
                  >mdi-account-box-outline</v-icon
                >
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6" md="4" lg="3">
            <v-card>
              <v-card-text class="mx-auto text-center">
                <h5>Documentación completa</h5>
                <v-icon
                  class="ma-5"
                  :color="newOne.documents ? 'green' : 'grey'"
                  size="35"
                  >mdi-file-document-multiple-outline</v-icon
                >
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6" md="4" lg="3">
            <v-card>
              <v-card-text class="mx-auto text-center">
                <h5>Primer pago</h5>
                <v-icon
                  class="ma-5"
                  :color="newOne.bankInfo ? 'green' : 'grey'"
                  size="35"
                  >mdi-bank-outline</v-icon
                >
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6" md="4" lg="3">
            <v-card>
              <v-card-text class="mx-auto text-center">
                <h5>Primera oferta realizada</h5>
                <v-icon
                  class="ma-5"
                  :color="myBids.length > 0 ? 'green' : 'grey'"
                  size="35"
                  >mdi-book-open-page-variant-outline</v-icon
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="croppingPhoto" persistent>
      <v-card max-height="600px">
        <v-card-title class="headline">
          Cortar imagen
          <v-spacer></v-spacer>
          <v-btn text @click="croppingPhoto = false"> Cancelar </v-btn>
          <v-btn color="red" text @click="crop"> Confirmar </v-btn>
        </v-card-title>
        <v-card-text class="upload-example">
          <div class="upload-example__cropper-wrapper">
            <cropper
              ref="cropper"
              class="upload-example__cropper"
              check-orientation
              :src="url"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPay">
      <v-card max-height="800px">
        <v-card-title class="headline"> Liquidar compra </v-card-title>
        <v-card-text class="upload-example">
          <payment-process
            currency="MXN"
            itemName="liquidación"
            :itemNumber="1"
            :itemQuantity="1"
            :itemAmount="'' + event.currentBid - 10000"
            :numberOfItems="1"
            :userTokenId="this.$session.get('person')"
            :totalAmount="'' + event.currentBid - 10000"
            :first_name="persontopay.name"
            :last_name="persontopay.last_name"
            :address1="persontopay.address1"
            :city="persontopay.city"
            :zip="persontopay.zip"
            :country="persontopay.country"
            :phone1="persontopay.phone1"
            :email="persontopay.email"
            customField1="final_pay"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "../../services/events";
import { Cropper } from "vue-advanced-cropper";
import HorseItem from "@/components/userSide/horseItem.vue";
import EmptyState from "@/components/userSide/EmptyState.vue";
import PaymentProcess from "@/components/adminSide/PaymentProcess";
import "vue-advanced-cropper/dist/style.css";
export default {
  data() {
    return {
      newOne: this.newObject(),
      persontopay: {
        name: "Samuel",
        last_name: "Herrera",
        address1: "calle 1",
        city: "Chihuahua",
        zip: "31135",
        country: "MX",
        phone1: "6141763011",
        email: "soysam@zelin.studio",
      },
      editImage: false,
      image: null,
      ine: null,
      ineBack: null,
      comp: null,
      caratula: null,
      profilePicture: true, // true image from user, false image edit
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      croppingPhoto: false,
      readyToUploadImg: true,
      url: null,
      showPay: false,
      event: {},
    };
  },
  components: {
    Cropper,
    HorseItem,
    EmptyState,
    PaymentProcess,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.peopleModule.loadingStatus,
      item: (state) => state.peopleModule.singleItem,
      myBids: (state) => state.homeState.myBids,
      myEvents: (state) => state.homeState.myEvents,
    }),
  },
  methods: {
    goToDetail(id) {
      this.$router.push({ name: "event-us", params: { event: id } });
    },
    logout() {
      this.$session.destroy();
      this.$router.push({ name: "homepaga-us" });
    },
    newObject() {
      return {
        name: "",
        banco: "",
        clabe: "",
        city: "Chihuahua",
        zip: "31100",
        country: "MX",
        phone1: "6141763011",
        email: "emailtest",
      };
    },
    guardar() {
      const updated = this.newOne
      delete updated.image
      this.$store.dispatch("peopleModule/updateItem", this.newOne);
      this.$store.dispatch("peopleModule/updateBadgeInfo", this.newOne);
    },
    changeFileIne() {
      this.newOne.ine = this.ine;
    },
    changeFileIneBack() {
      this.newOne.ineBack = this.ineBack;
    },
    changeFileComp() {
      this.newOne.comp = this.com;
    },
    changeFileCaratula() {
      this.newOne.caratula = this.caratula;
    },
    changeFileImagen() {
      this.profilePicture = false;
      this.newOne.image = this.image;
      this.readyToUploadImg = false;
      this.url = URL.createObjectURL(this.image);
    },
    upload(index) {
      let fileUpdate;

      switch (index) {
        case 1:
          fileUpdate = {
            _id: this.newOne._id,
            image: this.newOne.image,
            type: index,
          };
          break;
        case 2:
          fileUpdate = { _id: this.newOne._id, image: this.ine, type: index };
          break;
        case 3:
          fileUpdate = { _id: this.newOne._id, image: this.comp, type: index };
          break;
        case 4:
          fileUpdate = { _id: this.newOne._id, image: this.ineBack, type: index };
          break;
        default:
          alert("Favor de revisar la imagen que subió");
      }
      this.$store.dispatch("peopleModule/updateSingleImage", fileUpdate);
      this.$store.dispatch("peopleModule/updateDocuments", this.newOne);
      this.$store.dispatch(
        "peopleModule/fetchItem",
        (this.person = this.$session.get("person"))
      );
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.newOne.image = canvas.toDataURL();
      this.url = canvas.toDataURL();
      canvas.toBlob((blob) => {
        let fileUpdate;

        fileUpdate = { _id: this.newOne._id, image: blob, type: 1 };
        this.$store.dispatch("peopleModule/updateSingleImage", fileUpdate);
        this.readyToUploadImg = false;
        this.editImage = false;
      });
      this.$store.dispatch(
        "peopleModule/fetchItem",
        (this.salesman = this.$session.get("salesman"))
      );
      this.croppingPhoto = false;
    },
  },
  async beforeCreate() {
    await this.$store.dispatch(
      "peopleModule/fetchItem",
      (this.person = this.$session.get("person"))
    );
    await this.$store.dispatch(
      "homeState/fetchBidByUser",
      this.$session.get("person")
    );
    await this.$store.dispatch(
      "homeState/fetchEventsByUser",
      this.$session.get("person")
    );
  },
  mounted() {
    window.scrollTo(0, 0);
    EventBus.$on("set-single-person", () => {
      this.newOne = this.item;
    });
    if (!(this.$session.exists() && this.$session.get("type") === "FRN")) {
      this.logout();
    }
  },
};
</script>
