<template>
  <div>
    <AdminHeader color="black" icon="warehouse" title="Catálogo de Ranchos" />
    <div class="d-flex justify-end">
    <v-spacer></v-spacer>
        <v-btn @click="create()" dark small>
          <v-icon left> mdi-plus </v-icon>
          Capturar
        </v-btn>
        <v-btn class="ml-3" @click="allSalesMan()" dark small>
          Vendedores
        </v-btn>

    </div>
    <Loader v-if="loading" :size="64" />
    <Paginador
      :dataLength="dataLength"
      :pages="pages"
      :CurrentPage="page"
      @next="next"
    />
    <v-row v-if="dataLength > 0">
      <v-col cols="12" sm="6" md="3" v-for="item in docs" :key="item._id">
        <v-card>
          <v-card-title
            class="ma-auto"
          >
                <v-img
                class="mx-auto"
                    contain
                    max-height="120"
                    width="120"
                    :src="item.image"
                    lazy-src="/images/preloader.gif"
                  >                    
                  </v-img>  
            </v-card-title>
          <v-card-text>
                  <span>
                      <strong>
                            {{ item.name }}
                      </strong>
                  </span>
                  <br/>


                  {{ item.mail }}

          </v-card-text>
          <v-card-actions class="d-flex justify-end"> 
              <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-on:click="setItemToStore(item._id)">
                        <v-list-item-title
                          ><v-icon>mdi-lead-pencil</v-icon>
                          Editar</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item v-on:click="setItemToDelete(item._id)">
                        <v-list-item-title
                          ><v-icon color="red">mdi-trash-can</v-icon>
                          Eliminar</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                <div class="text-right">
                  <v-icon> mdi-file-tree</v-icon>
                  <a @click="detalle(item._id)"> Vendedores </a>
                </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="mx-auto justify-center aling-center" v-else>
        <EmptyState :white="true" />
      </v-row>

    <!-- <v-card elevation="5" height="100%" class="mb-10">
      <v-card-title>
        <h6>Ranchos capturados por los vendedores</h6>

        <v-spacer></v-spacer>

        <v-btn @click="create()" dark small>
          <v-icon left> mdi-plus </v-icon>
          Capturar
        </v-btn>
        <v-btn class="ml-3" @click="allSalesMan()" dark small>
          Vendedores
        </v-btn>
      </v-card-title>
      <template v-if="dataLength > 0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">Nombre</th>
                <th class="text-left">Email</th>
                <th class="text-right">Opciones</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in docs" :key="item._id">
                <td class="justify-center text-center">
                  <v-img
                    contain
                    max-height="120"
                    max-width="120"
                    :src="item.image"
                  ></v-img>
                </td>
                <td>
                  <span>
                    {{ item.name }}
                  </span>
                </td>
                <td>{{ item.mail }}</td>
                <td class="text-right">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-on:click="setItemToStore(item._id)">
                        <v-list-item-title
                          ><v-icon>mdi-lead-pencil</v-icon>
                          Editar</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item v-on:click="setItemToDelete(item._id)">
                        <v-list-item-title
                          ><v-icon color="red">mdi-trash-can</v-icon>
                          Eliminar</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td class="text-right">
                  <v-icon> mdi-file-tree</v-icon>
                  <a @click="detalle(item._id)"> Vendedores </a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <v-row dense class="mx-auto justify-center aling-center" v-else>
        <EmptyState :white="true" />
      </v-row>
    </v-card> -->

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="black" dark>
            <v-btn icon @click="dialog = false" dark>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Formulario Rancho</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text @click="guardar()" :disabled="!valid" color="amber">
                {{ label }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list three-line subheader>
            <v-list-item>
              <v-list-item-content class="ma-5">
                <v-form enctype="multipart/form-data" v-model="valid">
                  <v-text-field
                    v-model="newOne.name"
                    :counter="100"
                    label="Nombre"
                    required
                    :rules="nameRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="newOne.mail"
                    :counter="100"
                    label="Email"
                    required
                  ></v-text-field>
                  <v-col cols="12">
                    <v-select
                      v-model="countrySelect"
                      :items="getData"
                      item-text="name"
                      item-value="_id"
                      label="Select"
                      persistent-hint
                      return-object
                      single-line
                      @input="changeCountry(countrySelect._id)"
                    ></v-select>
                    <v-select
                      v-if="showState"
                      v-model="stateSelect"
                      :items="statesData"
                      item-text="name"
                      item-value="_id"
                      label="Select"
                      persistent-hint
                      return-object
                      single-line
                      @input="changeState(stateSelect._id)"
                    ></v-select>
                    <v-select
                      v-if="showCity"
                      v-model="citySelect"
                      :items="citiesData"
                      item-text="name"
                      item-value="_id"
                      label="Select"
                      persistent-hint
                      return-object
                      single-line
                      @input="changeCity(citySelect._id)"
                    ></v-select>
                    <div id="preview">
                      <img
                        v-if="url"
                        style="max-height: 200px; widht: 100%"
                        :src="url"
                      />
                    </div>
                    <v-file-input
                      v-model="file"
                      counter
                      accept="image/png, image/jpeg, image/bmp"
                      show-size
                      truncate-length="50"
                      @change="changeFile()"
                    ></v-file-input>
                  </v-col>
                </v-form>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">
            ¿Deseas eliminar {{ newOne.name }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDelete()"> Cancelar </v-btn>
            <v-btn color="red" text @click="guardar()"> Eliminar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import Loader from "@/components/adminSide/Loader";
import AdminHeader from "@/components/adminSide/AdminHeader";
import Paginador from "@/components/adminSide/Paginador";

export default {
  data() {
    return {
      itemsPerPage: 8,
      valid: false,
      nameRules: [(v) => !!v || "Versión requerida"],
      dialog: false,
      confirm: false,
      newOne: this.createFreshObj(),
      show1: false,
      page: 1,
      eventCrud: 0, // 0 for new, 1 for edit, 2 for delete,
      //select data
      countrySelect: {},
      stateSelect: {},
      citySelect: {},
      showState: false,
      showCity: false,
      file: null,
      url: null,
    };
  },
  components: {
    EmptyState,
    AdminHeader,
    Loader,
    Paginador,
  },
  created() {
    this.init();
  },
  methods: {
    create() {
      this.createFreshObj();
      this.dialog = true;
      this.eventCrud = 0;
    },
    createFreshObj() {
      // const user = this.$store.state.user;
      return {
        name: "",
        phone: "",
        address: "",
        mail: "",
        city: {},
        status: true,
        image: null,
      };
    },
    init() {
      this.$store.dispatch("ranchsModule/fetchData", {
        perPage: this.itemsPerPage,
        page: this.page,
      });
      this.newOne.name = "";
    },
    allSalesMan() {
      this.$router.push({ name: "all-salesmen" });
    },
    guardar() {
      this.newOne.city = this.citySelect._id;
      if (this.eventCrud === 0) {
        // CREATE
        this.$store
          .dispatch("ranchsModule/createItem", this.newOne)
          .then(() => {
            this.newOne = this.createFreshObj();
            this.$store.dispatch("ranchsModule/fetchData", {
              perPage: this.itemsPerPage,
              page: this.pages,
            });
            this.dialog = false;
          });
      } else if (this.eventCrud === 1) {
        //EDIT
        this.$store
          .dispatch("ranchsModule/updateItem", this.newOne)
          .then(() => {
            this.$store.dispatch("ranchsModule/fetchData", {
              perPage: this.itemsPerPage,
              page: this.page,
            });
            this.dialog = false;
            this.eventCrud = 0;
            this.newOne = this.createFreshObj();
          });
      } else {
        this.$store
          .dispatch("ranchsModule/deleteItem", this.newOne._id)
          .then(() => {
            this.$store.dispatch("ranchsModule/fetchData", {
              perPage: this.itemsPerPage,
              page: this.page,
            });
            this.confirm = false;
            this.eventCrud = 0;
            this.newOne = this.createFreshObj();
          });
      }
    },
    next(param) {
      this.page = param.page;
      this.$store.dispatch("ranchsModule/fetchData", {
        perPage: param.itemsPerPage,
        page: param.page,
      });
    },
    setItemToStore(id) {
      this.eventCrud = 1;
      this.$store.dispatch("ranchsModule/fetchItem", id).then(() => {
        this.newOne = this.item;
        this.dialog = true;
      });
    },
    setItemToDelete(id) {
      this.eventCrud = 2;
      this.confirm = true;
      this.$store.dispatch("ranchsModule/fetchItem", id).then(() => {
        this.newOne = this.item;
      });
    },
    cancelDelete() {
      this.eventCrud = 0;
      this.confirm = false;
      this.newOne = this.createFreshObj();
    },
    changeCountry(master) {
      this.showState = true;
      this.$store.dispatch("statesModule/fetchByMaster", master);
    },
    changeState(master) {
      this.showCity = true;
      this.$store.dispatch("citiesModule/fetchByMaster", master);
    },
    changeCity(master) {
      this.newOne.city = master;
    },
    changeFile() {
      this.newOne.image = this.file;
      this.url = URL.createObjectURL(this.file);
    },
    detalle(id) {
      this.$store.dispatch("ranchsModule/fetchItem", id).then(() => {
        this.$router.push({ name: "salesmen-as", params: { ranch: id } });
      });
    },
  },
  computed: {
    label() {
      return this.eventCrud === 0
        ? "Guardar"
        : this.eventCrud === 1
        ? "Editar"
        : "Eliminar";
    },
    ...mapGetters("countriesModule", ["getData"]),
    ...mapGetters("ranchsModule", ["dataLength"]),
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.ranchsModule.loadingStatus,
      pages: (state) => state.ranchsModule.pages,
      docs: (state) => state.ranchsModule.data,
      item: (state) => state.ranchsModule.singleItem,
      statesData: (state) => state.statesModule.data,
      citiesData: (state) => state.citiesModule.data,
    }),
  },
};
</script>