<template>
  <v-main class="grey lighten-3">
    <Loader v-if="loading" :size="64" />
    <v-container>
      <v-card
        class="mx-auto justify-center aling-center ma-10"
        width="100%"
        height="100%"
        elevation="9"
      >
        <v-sheet>
          <div class="d-flex justify-center aling-center">
            <Logo min-width="250" @click="goback()" />
          </div>
        </v-sheet>
        <v-img height="250" src="/images/ehorsesale/slider/BR6XMC.jpeg"></v-img>
        <v-card-title>{{ $t("globalAccess.salesTitle") }}</v-card-title>
        <v-card-subtitle>
          {{ $t("globalAccess.salesSubtitle1") }}
          <b> {{ $t("global.platformName") }} </b>?<br />
          {{ $t("globalAccess.salesSubtitle2")
          }}{{ $t("globalAccess.salesSubtitleCTA") }}
          <router-link
            to="/register/salesman"
            tag="b"
            class="cta-red cursor-mouse"
            >{{ $t("global.hereCTA") }}</router-link
          >
        </v-card-subtitle>
        <v-card-text class="pa-10">
          <form>
            <v-text-field
              v-model="user.name"
              label="Nombre de usuario"
              required
            ></v-text-field>
            <v-text-field
              v-model="user.pass"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Contraseña"
              @click:append="show1 = !show1"
              required
              @keydown.enter="submit(user)"
            ></v-text-field>
            <v-alert color="orange" type="info" v-if="showWarning">
              {{ warningText }}</v-alert
            >
          </form>
          <span class="cta-red cursor-mouse" @click="passwordRecover">
            <b>{{ $t("global.passwordForgotCTA") }}</b>
          </span>
        </v-card-text>
        <v-card-actions class="mx-auto justify-center aling-center py-10">
          <v-btn class="mr-4 black" @click="submit(user)" dark>
            {{ $t("buttons.login") }}
          </v-btn>
          <v-btn class="mr-4" @click="goback()">
            {{ $t("buttons.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <NotificationContainer />
    <v-dialog v-model="showRecover" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Recuperar contraseña</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="emailRecover"
                  :rules="emailRules"
                  label="Email utilizado para registro"
                  required
                ></v-text-field>
                <v-alert color="orange" type="info" v-if="showWarningRecover">
                  {{ warningText }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showRecover = false">
            {{ $t("buttons.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="sendRecover()">
            {{ $t("buttons.recover") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "../../services/events";
import NotificationContainer from "@/components/NotificationContainer";
import Loader from "@/components/adminSide/Loader";
import Logo from "@/components/base/Logo";

export default {
  name: "salesManAccess",
  components: {
    NotificationContainer,
    Loader,
    Logo,
  },
  data() {
    return {
      user: {
        name: "",
        pass: "",
      },
      show1: false,
      showWarning: false,
      showWarningRecover: false,
      warningText: "",
      emailRecover: "",
      showRecover: "",
      emailRules: [
        (v) => !!v || this.$t('globalAccess.alertEmailRequired'),
        (v) => /.+@.+\..+/.test(v) || this.$t('globalAccess.alertEmailInvalid'),
      ],
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.adminSalesModule.loadingStatus,
      salesman: (state) => state.adminSalesModule.user,
      auth: (state) => state.adminSalesModule.auth,
      current: (state) => state.adminSalesModule.current,
    }),
  },
  methods: {
    async submit(user) {
      if (user.name != "" && user.pass != "") {
        await this.$store.dispatch("adminSalesModule/logIn", user);
      } else {
        this.showWarning = true;
        this.warningText = this.$t("globalAccess.alertUserPass");
      }
    },
    goToDashboard() {
      this.$router.push({ name: "dashboardSales" });
    },
    goToSteps() {
      this.$router.push({ name: "ranchRegist" });
    },
    goback() {
      this.$router.push({ name: "homepaga-us" });
    },
    passwordRecover() {
      this.showRecover = true;
      this.emailRecover = "";
    },
    async sendRecover() {
      if (this.emailRecover === "" || !/.+@.+\..+/.test(this.emailRecover)) {
        this.showWarningRecover = true;
        this.warningText = this.$t("globalAccess.alertEmailRequired");
      } else {
        this.showWarningRecover = false;
        this.warningText = "";
        let emailData = {
          salesman: this.emailRecover,
          location: window.location.origin,
        };
        await this.$store.dispatch("emailModule/recoverSalesman", emailData);
        this.showWarningRecover = true;
        this.warningText = this.$t("globalAccess.alertEmail");
      }
    },
  },
  mounted() {
    if (this.$session.exists() && this.$session.get("type") === "SLS") {
      this.goToDashboard();
    }
    EventBus.$on("logInSuccess", () => {
      this.$session.destroy();
      this.$session.start();
      this.$session.set("jwt", this.auth);
      this.$session.set("type", "SLS");
      this.$session.set("current", this.current);
      this.$session.set("salesman", this.salesman);
      this.goToDashboard();
    }),
      EventBus.$on("logInNULL", () => {
        this.$session.destroy();
        this.showWarning = true;
        this.warningText = this.$t("globalAccess.alertUserPass");
      });
    EventBus.$on("logInNotValidated", () => {
      this.$session.destroy();
      this.showWarning = true;
      this.warningText = this.$t('globalAccess.alertEmailConfirmation');
    });
    EventBus.$on("logInforNews", () => {
      this.$session.destroy();
      this.$session.start();
      this.$session.set("jwt", this.auth);
      this.$session.set("type", "SLS");
      this.$session.set("current", this.current);
      this.goToSteps();
    });
  },
};
</script>

<style></style>
