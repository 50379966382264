import CRUDServices from '@/services/CRUDServices';
// import EventBus from '@/services/events';

export const namespaced = true;

export const state = {
  data: {},
  loading: false,
  api: '/dashboardSales/'
};
export const mutations = {
  SET_DATA(state,data){
    state.data = data;
  },
  SET_LOADING(state,loading){
    state.loading = loading
  }
};
export const actions = {
  getData({commit,state}){
    commit("SET_LOADING",true);
    return CRUDServices.getDocs(state.api).then((response) =>{
      console.debug(response)
      commit("SET_LOADING",false);
    }).catch((error) => {
      console.debug(error)
      commit("SET_LOADING",false);
    });
  }
};
export const getters = {};