import AdminSide from '../views/dashboards/admin/AdminSide.vue'

import Dashboard from '../views/dashboards/admin/Dashboard.vue'
import About from '../views/About.vue'

//catalogos
import AdminUsers from '../views/catalogs/AdminUsers.vue'
import Groups from '../views/catalogs/Groups.vue'
import Tags from '../views/catalogs/Tags.vue'
import Categories from '../views/catalogs/Categories.vue'
import SubCategories from '../views/catalogs/SubCategories.vue'
import Countries from '../views/catalogs/Countries.vue'
import States from '../views/catalogs/States.vue'
import Cities from '../views/catalogs/Cities.vue'
import Ranchs from '../views/catalogs/Ranchs.vue'
import Articles from '../views/catalogs/Articles.vue'
import Salesmen from '../views/catalogs/SalesMen.vue'
import AllSalesMen from '../views/catalogs/AllSalesMen.vue'
import AllFrontMan from '../views/catalogs/AllFrontMan.vue'
import Terms from '../views/catalogs/Terms.vue'
import Memberships from '../views/catalogs/Memberships.vue'
import Config from '../views/catalogs/Config.vue'
import CreditBuy from '../views/catalogs/CreditBuy.vue'
import FirstPayment from '../views/catalogs/FirstPayment.vue'
import FinalPayment from '../views/catalogs/FinalPay.vue'
import InvitationCodes from '../views/catalogs/InvitationCodes.vue'
import EventsAdmin from '../views/catalogs/Events.vue'
import NotificationCenter from '../views/catalogs/NotificationCenter'
import Messages from '../views/catalogs/Messages'

//process
import HorseAudits from '../views/process/HorsesAudit.vue'
import HorseDetailAdmin from '../views/process/HorseDetailsAdmin.vue'

const routes = [
  {
    path: '/admin',
    name: 'admin',
    component: AdminSide,
    children:[
      {
        path:'/admin/',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path:'/about/',
        name: 'about',
        component: About
      },
      {
        path:'/admin/admins',
        name: 'adminUsers',
        component: AdminUsers
      },
      {
        path:'/admin/invitationCodes',
        name: 'InvitationCodes',
        component: InvitationCodes
      },
      {
        path:'/admin/terms',
        name: 'terms',
        component: Terms
      },
      {
        path:'/admin/config',
        name: 'Config',
        component: Config
      },
      {
        path:'/admin/memberships',
        name: 'memberships',
        component: Memberships
      },
      {
        path:'/admin/groups',
        name: 'groups',
        component: Groups
      },
      {
        path:'/admin/tags',
        name: 'tags',
        component: Tags
      },
      {
        path:'/admin/categories/:group',
        name: 'categories',
        component: Categories
      },
      {
        path:'/admin/categories/:category',
        name: 'subcategories',
        component: SubCategories
      },
      {
        path:'/admin/countries',
        name: 'countries',
        component: Countries
      },
      {
        path:'/admin/countries/:country',
        name: 'states',
        component: States
      },
      {
        path:'/admin/countries/states/:state',
        name: 'cities',
        component: Cities
      },
      {
        path:'/admin/ranchs',
        name: 'ranchs',
        component: Ranchs
      },
      {
        path:'/admin/articles',
        name: 'articles',
        component: Articles
      },
      {
        path:'/admin/ranchs/:ranch',
        name: 'salesmen-as',
        component: Salesmen
      },
      {
        path:'/admin/salesman',
        name: 'all-salesmen',
        component: AllSalesMen
      },
      {
        path:'/admin/frontusers',
        name: 'all-frontusers',
        component: AllFrontMan
      },
      {
        path:'/admin/audits',
        name: 'HorseAudits',
        component: HorseAudits
      },
      {
        path:'/admin/HorseDetail/:horse',
        name: 'HorseDetailAdmin',
        component: HorseDetailAdmin
      },
      {
        path:'/admin/report/credits',
        name: 'CreditBuy',
        component: CreditBuy
      },
      {
        path:'/admin/report/avance',
        name: 'FirstPayment',
        component: FirstPayment
      },
      {
        path:'/admin/report/final',
        name: 'FinalPayment',
        component: FinalPayment
      },
      {
        path:'/admin/events',
        name: 'EventsAdmin',
        component: EventsAdmin
      },
      {
        path:'/notification-center',
        name: 'NotificationCenter',
        component: NotificationCenter
      },
      {
        path:'/messages',
        name: 'Messages',
        component: Messages
      },
    ]
  },
];

export default routes;