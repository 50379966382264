<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Condición
            </th>
            <th class="text-left">
              Estatus
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Aereofagía</td>
            <td> {{healthDetail.cribber | yesno}} </td>
          </tr>
          <tr>
            <td> Criptorquideo </td>
            <td> {{healthDetail.weaver | yesno}}  </td>
          </tr>
          <tr>
            <td> Transtorno Neurológico </td>
            <td> {{healthDetail.nerved | yesno}}  </td>
          </tr>
          <tr>
            <td> SobreHueso </td>
            <td> {{healthDetail.foundered | yesno}} </td>
          </tr>
          <tr>
            <td> Boca de perico </td>
            <td> {{healthDetail.parrotMouth | yesno}} </td>
          </tr>
          <tr>
            <td> Cirugía Mayor </td>
            <td> {{healthDetail.majorIS | yesno}} </td>
          </tr>
          <tr>
            <td> Laminitis </td>
            <td> {{healthDetail.colic | yesno}} </td>
          </tr>
          <tr>
            <td> Cojera </td>
            <td> {{healthDetail.pastLameness | yesno}} </td>
          </tr>
          <tr>
            <td> Transtorno navicular </td>
            <td> {{healthDetail.defects | yesno}} </td>
          </tr>
          <tr>												
            <td> Osteartitis</td>
            <td> {{healthDetail.osteartitis | yesno}} </td>
          </tr>
          <tr>
            <td> ¿Ceguera </td>
            <td> {{healthDetail.ceguera | yesno}} </td>
          </tr>
        </tbody>
      </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" md="6">
        <div class="text-center" v-if="healthDetail.file">
            <pdfViewer :URL="healthDetail.file" v-if="healthDetail.file"/><br>
        </div>
        <div class="text-center" v-if="healthDetail.file">
            <a :href="healthDetail.file" target="blank" v-if="healthDetail.file"> Abrir </a>                            
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import pdfViewer from '@/components/adminSide/pdfViewer';
  export default {
    props:{
      healthDetail: {}
    },
    components:{
      pdfViewer
    }
  }
</script>

<style>

</style>