import CRUDServices from '@/services/CRUDServices';
// import EventBus from '@/services/events';

export const namespaced = true;

export const state = {
  data: {},
  loading: false,
  api: '/dashboardAdmin/',
  growt: {}
};
export const mutations = {
  SET_DATA(state,data){
    state.data = data;
  },
  SET_LOADING(state,loading){
    state.loading = loading
  },
  SET_GROWTH(state, data){
    state.growt = data
  }
};
export const actions = {
  getData({commit,state}){
    commit("SET_LOADING",true);
    return CRUDServices.getDocs(state.api).then((response) =>{
      commit("SET_DATA",response.data);
      commit("SET_LOADING",false);
    }).catch((error) => {
      console.debug(error)
      commit("SET_LOADING",false);
    });
  },
  getGrowth({commit, state}){
    commit("SET_LOADING",true);
    return CRUDServices.getDocs(state.api+'growth').then((response) =>{
      commit("SET_GROWTH",response.data);
      commit("SET_LOADING",false);
    }).catch((error) => {
      console.debug(error)
      commit("SET_LOADING",false);
    });
  }
};
export const getters = {};