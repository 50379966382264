<template>
  <v-row>
    <v-col cols="12">
      <p
        class="text-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-center font-weight-bold"
      >
        Información
      </p>
      <v-row class="text-center" justify="center">
        <v-col
          cols="6"
          md="3"
          lg="2"
          v-if="horse.dateOfBirth"
          class="text-center"
        >
          <cardDetail
            icon="calendar"
            title="Fecha de nacimiento"
            :info="horse.dateOfBirth | date"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
          lg="2"
          v-if="horse.subCategory"
          class="text-center"
        >
          <cardDetail
            icon="format-list-bulleted-triangle"
            title="Clasificación"
            :info="clasificacionText"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
          lg="2"
          v-if="horse.city"
          class="text-center"
        >
          <cardDetail
            icon="google-maps"
            title="Ubicación"
            :info="ubicacionText"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
          lg="2"
          v-if="horse.repStatus"
          class="text-center"
        >
          <cardDetail
            icon="chess-knight"
            title="Sexo"
            :info="estatusReproductivo"
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
          lg="2"
          v-if="horse.size"
          class="text-center"
        >
          <cardDetail
            icon="tape-measure"
            title="Alzada"
            :info="horse.size + 'cm'"
          />
        </v-col>
        <v-col cols="6" sm="3" lg="2" class="text-center">
          <cardDetail
            icon="registered-trademark"
            title="Registro"
            :info="registroText || ''"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import cardDetail from "@/components/userSide/cardDetail";

export default {
  components:{cardDetail},
  props:{
    horse: {}
  },
  data: () => ({
    repStatus: [
      { id: 1, description: "Garañon" },
      { id: 2, description: "Hembra" },
      { id: 3, description: "Castrado" },
    ],
  }),
  computed:{
    estatusReproductivo() {
      return this.horse.repStatus
        ? this.repStatus.filter(
            (rep) => rep.id === this.horse.repStatus
          )[0].description
        : "";
    },
    registroOrg() {
      return this.horse.registOrg || ''
    },
    registroText() {
      return this.horse.papers
        ? `${this.horse.regist} - ${this.registroOrg}`
        : "N/A";
    },
    clasificacionText() {
      if (this.horse.subCategory)
        return `${this.horse.subCategory.name} · ${this.horse.subCategory.category.name} · ${this.horse.subCategory.category.group.name}`;
      else return "";
    },
    ubicacionText() {
      if (this.horse.city)
        return `${this.horse.city.name} · ${this.horse.city.state.name} · ${this.horse.city.state.country.name}`;
      else return "";
    },
  }
};
</script>

<style></style>
