<template>
    <v-card
        class="mx-auto"
        elevation="1"
    >
        <div class="horse-card">
            <v-img
                class="linkCursor"
                :src="article.image"
                height="300px"
                contain
            ></v-img>
        </div>

        <v-card-title>
            <h3 class="robotoFontBold linkCursor" >
                <strong>
                    {{article.name}}
                </strong>
            </h3>
        </v-card-title>
        <v-card-subtitle>
            <h3>
                {{ article.description}} 
            </h3>
        </v-card-subtitle>
        <v-card-text>
            <h3>
                <strong>
                    <em>
                        {{ article.price | money}} 
                    </em> 
                </strong>
            </h3> 
        </v-card-text>
        <v-card-text>
            <h3>
                <strong>
                    <em>
                        {{ article.tag}} 
                    </em> 
                </strong>
            </h3> 
        </v-card-text>
        <v-btn 
            class="ma-2" 
            color="#df2127" 
            dark 
        >
            Comprar
        </v-btn>
    </v-card>
</template>

<script>

    export default {
        props: {
            article: {}
        },
        methods:{
        }
    }
</script>

<style scoped>
    .horse-card {
        max-height: 300px; 
    }
</style>