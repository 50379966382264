<template>
  <v-overlay>
    <v-progress-circular
      indeterminate
      :size="size"
      :color="color"
      width="10"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "app-loader",
  props: {
    size:{
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>