<template>
  <v-row 
			class="fill-height ma-0 pa-0 text-center"
			align="center"
			justify="center"
		>
			<v-col class="ma-0 pa-0 text-center bebasFont" style="background-color:#df2127;">
				<v-btn height="50px" class="pa-1" block color="#df2127" dark @click="goToRegist()">
					<h3 class="ma-1 pa-0 text-center" >
						Quiero comprar
					</h3>	
				</v-btn>
			</v-col>
			<v-col class="ma-0 pa-0 text-center bebasFont" style="background-color:#302e2e;">
				<v-btn height="50px" class="pa-1" block color="#302e2e" dark @click="goToRegistSales()">
					<h3 class="ma-0 pa-0 text-center">
						Quiero vender
					</h3>	
				</v-btn>
			</v-col>
		</v-row>
</template>

<script>
export default {
  methods:{
    goToRegist(){
			this.$router.push({name:'register-us'});
		},
		goToRegistSales(){
			this.$router.push({name:'SalesmanRegist'});
		},
  }
}
</script>

<style>

</style>