<template>
  <div>
    <Slider 
      v-if="configData"
      :photos="configData.photos" 
      :text="configData.copyWelcome"
      />
    <NotificationContainer />
    <CtaRegist />
    <div>
      <BlackTitle title="SUBASTAS ACTIVAS" />
      <v-sheet width="100%" class="text-right">
        <v-btn
          color="#df2127"
          dark
          @click="showCategoriesDrawer()"
          small
          outlined
          right
          class="ma-4"
        >
          <span>Filtros</span>
          <v-icon>mdi-filter-outline</v-icon>
        </v-btn>
      </v-sheet>
      <v-row v-if="topEvents.length === 0">
        <EmptyState />
      </v-row>
      <v-row 
        justify="center"
        class="mt-1 ma-7"
        v-else 
      >
        <v-col
          v-for="event in topEvents"
          :key="event._id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <HorseItem :event="event" />
        </v-col>
      </v-row>
    </div>
    <div class="mt-5 mb-16">
      <BlackTitle title="Video Tutoriales" />
      <v-row justify="center">
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="text-center" v-for="(video,i) in videos" :key="i" >
          <VideoTutorial :video="video" />
        </v-col>
      </v-row>
      <v-row justify="center" class="px-10">
        <v-btn color="primary" block @click="goToRoute('help')">Ver más...</v-btn>
      </v-row>
    </div>
    <v-navigation-drawer
      width="300px"
      v-model="categoriesDrawer"
      absolute
      temporary
      class="justify-center drawer-left"
    >
      <v-list dense>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>Selecciona una categoría</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group v-for="item in categoriesData" :key="item._id" >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text-caption">
                <div class="text-caption">
                  <b>({{ item.subcategories.length }})</b> - {{ item.name }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="child in item.subcategories" :key="child.name" dense>
            <v-list-item-content>
              <v-list-item-title >
                <a @click="goToEventsBySub(child._id)">
                  <div>{{ child.name }}</div>
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Slider from "@/components/userSide/home/Slider.vue";
import NotificationContainer from "@/components/NotificationContainer";
import HorseItem from "@/components/userSide/horseItem.vue";
import EmptyState from "@/components/userSide/EmptyState.vue";
import BlackTitle from "@/components/userSide/blackTitle";
import CtaRegist from "@/components/userSide/home/CtaRegist";
import VideoTutorial from '@/components/userSide/home/VideoTutorial.vue';

import videoTutoriales from "@/config/videoTutoriales.json";

export default {
  components: {
    Slider,
    NotificationContainer,
    HorseItem,
    EmptyState,
    BlackTitle,
    CtaRegist,
    VideoTutorial
  },
  data() {
    return {
      categoriesDrawer: false,
      event: {
        year: 2020,
        month: 11,
        day: 23,
        hour: 23,
        minute: 59,
        second: 59,
      },
      videos:[]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.videos = videoTutoriales;
  },
  computed: {
    ...mapState({
      loading: (state) => state.homeState.loadingStatus,
      configData: (state) => state.homeState.configData,
      categoriesData: (state) => state.homeState.categoriesData,
      topEvents: (state) => state.homeState.topEvents,
    }),
  },
  methods: {
    async goToEventsBySub(id) {
      await this.$store.dispatch("homeState/fetchSubEvents", id);
      this.$router.replace({ name: "events-us", params: { subcat: id } });
    },
    goToRoute(route){
      this.$router.replace({ name: route });
    },
    showCategoriesDrawer(){
      this.categoriesDrawer = true;
    }
  },
};
</script>

<style>
	.that-bar{
		position: sticky;
		top: 0px;
		z-index: 1000;
	}

	.that-bottom-bar{
		position: sticky;
		bottom: 0px;
		z-index: 1000;
	}

	.that-bar-link {
		text-decoration: none !important;
	}

	.ul-list{
		list-style-type:none;
	}
</style>