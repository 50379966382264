<template>
  <div class="countdown" loaded>
    <div class="countdown__num" v-if="displayDays > 0">
      <span class="countdown__num--time text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">{{ displayDays }}</span>
      <span class="countdown__num--unit text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">Días</span>
    </div>
    <span class="countdown__num--colon" v-if="displayDays > 0">:</span>
    <div class="countdown__num">
      <span class="countdown__num--time text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">{{ displayHours }}</span>
      <span class="countdown__num--unit text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">Horas</span>
    </div>
    <span class="countdown__num--colon">:</span>
    <div class="countdown__num">
      <span class="countdown__num--time text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">{{ displayMinutes }}</span>
      <span class="countdown__num--unit text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">Min</span>
    </div>
    <span class="countdown__num--colon text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">:</span>
    <div class="countdown__num">
      <span class="countdown__num--time text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">{{ displaySeconds }}</span>
      <span class="countdown__num--unit text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">Seg</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    year: { type: Number, required: true, default: 2020 },
    month: { type: Number, required: true, default: 0 },
    day: { type: Number, required: true, default: 0 },
    hour: { type: Number, required: false, default: 0 },
    minute: { type: Number, required: false, default: 0 },
    second: { type: Number, required: false, default: 0 },
    milliseconds: { type: Number, required: false, default: 0 }
  },
  data() {
    return {
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      loaded: false,
      expired: false
    }
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60
    },
    _hours() {
      return this._minutes * 60
    },
    _days() {
      return this._hours * 24
    },
    END() {
      return new Date(
        this.year,
        this.month,
        this.day,
        this.hour,
        this.second,
        this.minute,
        this.milliseconds
      )
    }
  },
  mounted() {
    this.showRemaining()
  },
  methods: {
    formatNum: num => (num < 10 ? '0' + num : num),
    showRemaining() {
      const TIMER = setInterval(() => {
        const NOW = new Date()
        const DISTANCE = this.END.getTime() - NOW.getTime()
        if (DISTANCE < 0) {
          clearInterval(TIMER)
          this.expired = true
          this.loaded = true
          return
        }
        const DAYS = Math.floor(DISTANCE / this._days)
        const HOURS = Math.floor((DISTANCE % this._days) / this._hours)
        const MINUTES = Math.floor((DISTANCE % this._hours) / this._minutes)
        const SECONDS = Math.floor((DISTANCE % this._minutes) / this._seconds)
        this.displayMinutes = this.formatNum(MINUTES)
        this.displaySeconds = this.formatNum(SECONDS)
        this.displayHours = this.formatNum(HOURS)
        this.displayDays = this.formatNum(DAYS)
        this.loaded = true
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .countdown {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    margin-top: 5px;
    padding: 0px;
    &__num {
      display: flex;
      flex-direction: column;
      padding: 5px;
      &--time {
        color: var(--color-primary);
        margin: auto;
        font-size: 1em;
      }
      &--unit {
        color: var(--color-gray);
        margin: auto;
        font-size: 0.7em;
      }
      &--colon {
        color: var(--color-primary);
        font-size: 1em;
        margin: auto;
        line-height: 1.4em;
      }
    }
  }
</style>