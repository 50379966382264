import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translations from "./translations"

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: "es",
  fallbackLocale: "es",
  ...translations
});

export default i18n;

