<template>
  <div>
    <BlackTitle title="Vendedor" />
    <div class="mx-5 mt-5 mb-16">
      <v-row >
        <v-col cols="12" sm="12" md="6" lg="4" xl="3" class="text-center" v-for="(video,i) in videos" :key="i" >
          <youtubeVisor :video="video.video" :small="false" />
          <h3>{{video.title}}</h3>
          <p> {{video.description}} </p>
        </v-col>
      </v-row>
    </div>
    <BlackTitle title="Compradores" />
    <BlackTitle title="Transacciones" />    
  </div>
</template>

<script>
import youtubeVisor from '@/components/adminSide/youtubeVisor';
import BlackTitle from "@/components/userSide/blackTitle";

export default {
  components: {
    BlackTitle,
    youtubeVisor
  },
  data() {
    return {
      videos:[
        { video: "K-RuvLpUawE", title: "Registro como vendedor.", description: "Registro para vendedores." },
        { video: "pwVIgJ8LVFc", title: "Primer inicio de sesión", description: "Te acompañamos durante tu primer inicio de sesión en la plataforma" },
        { video: "oNKLIw0r2n4", title: "Registro Express de caballos", description: "Registro sencillo de caballo con la información básica." },
        { video: "7p2eKoIgvpg", title: "Registro avanzado caballo", description: "Registro completo de caballo." },
        { video: "lzVyhnjirS4", title: "Perfil de vendedor y compra de Inscripciones", description: "Te acompañamos durante la actualización de datos personales, y compra de tus primeras inscripciones" },
        { video: "UakLmJc8z8Q", title: "Subir video a youtube", description: "Acompañamiento para subir tu primer video a youtube" },
      ]
    };
  },
}
</script>

<style>

</style>