<template>
  <div>
    <AdminHeader
      color="black"
      icon="earth-arrow-right"
      :title="'Vendedores del ' + master.name"
      :backButton="true"
    />
    <Loader v-if="loading" :size="64" />
    <Paginador
      :dataLength="dataLength"
      :pages="pages"
      :CurrentPage="page"
      @next="next"
    />
    <ImagePreview v-if="imageDialog" :imgDialog="imgDialog" @closePreview="closePreview"/>

    <v-row v-if="dataLength > 0">
      <v-col cols="12" sm="6" md="3" v-for="item in docs" :key="item._id">
        <v-card>
          <v-card-title class="ma-auto">
            <v-img
              class="mx-auto"
              contain
              max-height="120"
              width="120"
              :src="item.image"
              lazy-src="/images/preloader.gif"
            >
            </v-img>
          </v-card-title>
          <v-card-text>
            <span>
              <strong>
                {{ `${item.name} ${item.lastnamep} ${item.lastnamep}` }}
              </strong>
            </span>
            <br />
            {{ item.mail }}
            <div class="d-flex justify-center column ma-5">
                <!-- <v-icon v-on:click="setItemToStore(item._id)">mdi-lead-pencil</v-icon> -->
                <div class="mx-2"></div>
                <v-icon v-on:click="setItemToDelete(item._id)">mdi-trash-can</v-icon>
                <div class="mx-2"></div>
                <v-icon v-on:click="resend(item)">mdi-email</v-icon> 
                <div class="mx-2"></div>
                <v-icon v-on:click="resend(item)">mdi-restart-alert</v-icon>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-chip :color="!item.ine ? 'red':'green'" text-color="white" class="mx-1"
                @click="setImage(item.ine)"
            >
              <v-icon left v-if="!item.ine"> mdi-alert-octagon </v-icon>
              <v-icon left v-else> mdi-check </v-icon>
              INE(F)
            </v-chip>
            <v-chip :color="!item.ineBack ? 'red':'green'" text-color="white" class="mx-1"
                @click="setImage(item.ineBack)"
            >
              <v-icon left v-if="!item.ineBack"> mdi-alert-octagon </v-icon>
              <v-icon left v-else> mdi-check </v-icon>
              INE(T)
            </v-chip>
            <v-chip :color="!item.comp ? 'red':'green'" text-color="white" class="mx-1"
                @click="setImage(item.comp)"
            >
              <v-icon left v-if="!item.comp"> mdi-alert-octagon </v-icon>
              <v-icon left v-else> mdi-check </v-icon>
              Comprobante
            </v-chip>
            
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="mx-auto justify-center aling-center" v-else>
      <EmptyState :white="true" />
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Nueva Ciudad</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="guardar()">
                {{ label }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list three-line subheader>
            <v-subheader>Crear nueva Ciudad</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Formulario para registrar un nueva Ciudad</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <form>
                  <v-text-field
                    v-model="newOne.name"
                    :counter="100"
                    label="Nombre"
                    required
                  ></v-text-field>
                </form>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">
            ¿Deseas eliminar {{ newOne.name }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDelete()"> Cancelar </v-btn>
            <v-btn color="red" text @click="guardar()"> Eliminar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Loader from "@/components/adminSide/Loader";
import AdminHeader from "@/components/adminSide/AdminHeader";
import Paginador from "@/components/adminSide/Paginador";
import ImagePreview from '@/components/adminSide/imagePreview';

export default {
  data() {
    return {
      dialog: false,
      confirm: false,
      newOne: this.createFreshObj(),
      show1: false,
      page: 1,
      eventCrud: 0, // 0 for new, 1 for edit, 2 for delete,
      imgDialog: '',
    imageDialog: false,
    };
  },
  components: {
    AdminHeader,
    Loader,
    Paginador,
    ImagePreview
  },
  created() {
    this.$store.dispatch("salesmenModule/setMaster", this.$route.params.ranch);
  },
  beforeMount() {
    this.$store.dispatch("salesmenModule/setMaster", this.$route.params.ranch);
  },
  mounted() {
    this.init();
    this.$store.dispatch("salesmenModule/setMaster", this.$route.params.ranch);
  },
  methods: {
      setImage(img){
          if(img){
            this.imgDialog = img;
            this.imageDialog = true;
          } else{
              alert('No existe una imagen asociada');
          }
      },
      closePreview(){
            this.imgDialog = '';
            this.imageDialog = false;
        },
    create() {
      this.createFreshObj();
      this.dialog = true;
      this.eventCrud = 0;
    },
    createFreshObj() {
      this.validateMaster();
      return {
        name: "",
        ranch: this.master,
        status: true,
      };
    },
    init() {
      this.$store.dispatch("salesmenModule/fetchData", {
        perPage: 5,
        page: this.page,
      });
      this.newOne.name = "";
    },
    guardar() {
      if (this.newOne.ranch === null) {
        this.newOne.ranch = this.$route.params.ranch;
      }
      this.validateMaster();

      if (this.eventCrud === 0) {
        // CREATE
        this.dialog = false;
        this.newOne.ranch = this.master._id;
        this.$store
          .dispatch("salesmenModule/createItem", this.newOne)
          .then(() => {
            this.newOne = this.createFreshObj();
            this.$store.dispatch("salesmenModule/fetchData", {
              perPage: 5,
              page: this.pages,
            });
          });
      } else if (this.eventCrud === 1) {
        //EDIT
        this.$store
          .dispatch("salesmenModule/updateItem", this.newOne)
          .then(() => {
            this.$store.dispatch("salesmenModule/fetchData", {
              perPage: 5,
              page: this.page,
            });
            this.dialog = false;
            this.eventCrud = 0;
            this.newOne = this.createFreshObj();
          });
      } else {
        this.$store
          .dispatch("salesmenModule/deleteItem", this.newOne._id)
          .then(() => {
            this.$store.dispatch("salesmenModule/fetchData", {
              perPage: 5,
              page: this.page,
            });
            this.confirm = false;
            this.eventCrud = 0;
            this.newOne = this.createFreshObj();
          });
      }
    },
    next(param) {
      this.page = param.page;
      this.$store.dispatch("salesmenModule/fetchData", {
        perPage: param.itemsPerPage,
        page: param.page,
      });
    },
    setItemToStore(id) {
      this.eventCrud = 1;
      this.$store.dispatch("salesmenModule/fetchItem", id).then(() => {
        this.newOne = this.item;
        this.dialog = true;
      });
    },
    setItemToDelete(id) {
      this.eventCrud = 2;
      this.confirm = true;
      this.$store.dispatch("salesmenModule/fetchItem", id).then(() => {
        this.newOne = this.item;
      });
    },
    cancelDelete() {
      this.eventCrud = 0;
      this.confirm = false;
      this.newOne = this.createFreshObj();
    },
    regresar() {
      this.$router.go(-1);
    },
    validateMaster() {
      this.$store.dispatch(
        "salesmenModule/setMaster",
        this.$route.params.ranch
      );
    },
    resend(item) {
      let emailData = {
        salesman: item,
        id: item._id,
        location: window.location.origin,
      };
      this.$store.dispatch("emailModule/resendWelcomeSalesman", emailData);
    },
  },
  computed: {
    label() {
      return this.eventCrud === 0
        ? "Guardar"
        : this.eventCrud === 1
        ? "Editar"
        : "Eliminar";
    },
    ...mapGetters("salesmenModule", ["dataLength", "getSub"]),
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.salesmenModule.loadingStatus,
      pages: (state) => state.salesmenModule.pages,
      docs: (state) => state.salesmenModule.data,
      item: (state) => state.salesmenModule.singleItem,
      master: (state) => state.ranchsModule.singleItem,
    }),
  },
};
</script>