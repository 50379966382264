
import Vue from 'vue'
import Vuex from 'vuex'
//Data models
import * as user from '@/store/modules/user'
import * as adminModule from '@/store/modules/admin'
import * as adminSalesModule from '@/store/modules/adminSales'
import * as categoriesModule from '@/store/modules/categories'
import * as subCategoriesModule from '@/store/modules/subcategories'
import * as countriesModule from '@/store/modules/countries'
import * as statesModule from '@/store/modules/states'
import * as citiesModule from '@/store/modules/cities'
import * as ranchsModule from '@/store/modules/ranchs'
import * as salesmenModule from '@/store/modules/salesmen'
import * as termsModule from '@/store/modules/terms'
import * as membershipsModule from '@/store/modules/memberships'
import * as horsesModule from '@/store/modules/horses'
import * as hdtModule from '@/store/modules/hdt'
import * as hdhModule from '@/store/modules/hdh'
import * as hdpModule from '@/store/modules/hdp'
import * as horseAuditModule from '@/store/modules/horseAudit'
import * as configModule from '@/store/modules/config'
import * as eventsModule from '@/store/modules/events'
import * as creditsModule from '@/store/modules/credits'
import * as frontUserModule from '@/store/modules/frontUser'
import * as peopleModule from '@/store/modules/people'
import * as groupsModule from '@/store/modules/groups'
import * as tagsModule from '@/store/modules/tags'
import * as articlesModule from '@/store/modules/articles'
import * as paymentsModule from '@/store/modules/payments'
import * as invitationCodesModule from '@/store/modules/invitationCodes'
//Utils
import * as notificationModule from '@/store/modules/notification'
import * as emailModule from '@/store/util/emails'
import * as adminNotificationsModule from '@/store/util/adminNotification'
import * as messagesModule from '@/store/modules/messages'
//view
import * as homeState from '@/store/views/home'
import * as dashboardSalesState from '@/store/views/dashboardSales'
import * as dashboardAdminState from '@/store/views/dashboardAdmin'


Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    user,
    adminModule,
    adminSalesModule,
    notificationModule,
    categoriesModule,
    countriesModule,
    subCategoriesModule,
    statesModule,
    citiesModule,
    ranchsModule,
    salesmenModule,
    termsModule,
    membershipsModule,
    horsesModule,
    hdtModule,
    hdhModule,
    hdpModule,
    horseAuditModule,
    configModule,
    eventsModule,
    creditsModule,
    homeState,
    frontUserModule,
    peopleModule,
    groupsModule,
    tagsModule,
    articlesModule,
    paymentsModule,
    invitationCodesModule,
    emailModule,
    dashboardSalesState,
    dashboardAdminState,
    adminNotificationsModule,
    messagesModule
  }
})
