<template>
  <v-carousel cycle hide-delimiters class="mt-0" height="400px">
      <v-carousel-item
        contain
        v-for="(photo, i) in photos"
        :key="i"
        :src="photo"
      ></v-carousel-item>
    </v-carousel>
</template>

<script>
export default {
  props:{
    photos: Array
  }
}
</script>

<style>

</style>