import CRUDServices from '@/services/CRUDServices';
import EventBus from '../../services/events';

export const namespaced = true;

export const state = {
    loadingStatus: false,
    data: [],
    singleItem: {
      actualCredits: 0
    },
    pages: 0,
    total: 0,
    api: '/salescredits/',
    justSaved: '',
    master: {}
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_DATA(state, data){
      state.data = data;
    },
    SET_NEW_OBJ(state, item){
      state.data.push(item)
    },
    SET_PAGES(state, pages){
      state.pages = pages
    },
    SET_TOTAL(state, total){
      state.total = total
    },
    SET_SINGLE(state, item){
      state.singleItem = item
    },
    SET_SAVED(state, id){
      state.justSaved = id
    },
    SET_MASTER(state, item){
      state.master = item
    }
}

export const actions = {
    setMaster({commit},master){
        commit('SET_MASTER',master);
    },
    fetchItem({state,commit,dispatch},id){
        commit('SET_LOADING_STATUS',true);
        CRUDServices.getItem(state.api+'active/',id)
            .then(response => {
                if(response.data != null){
                    commit('SET_SINGLE',response.data);
                    EventBus.$emit('set-single-credits');
                }
                commit('SET_LOADING_STATUS',false);
            }).catch(error =>{
                const notification = {
                    type: 'error',
                    message: 'There was a problem ferching credits: ' + error.message 
            }
                dispatch('notificationModule/add', notification, {root: true});
            });
      },
      fetchAll({state,commit,dispatch},id){
        commit('SET_LOADING_STATUS',true);
        CRUDServices.getItem(state.api+'inactive/',id)
            .then(response => {
                if(response.data != null){
                    commit('SET_DATA',response.data);
                }
                commit('SET_LOADING_STATUS',false);
            }).catch(error =>{
                const notification = {
                    type: 'error',
                    message: 'There was a problem ferching credits: ' + error.message 
            }
                dispatch('notificationModule/add', notification, {root: true});
            });
      },
      createItem({state,commit,dispatch},item){
        commit('SET_LOADING_STATUS',true);
        return CRUDServices.postItem(state.api,item).then(() => {
          const notification = {
              type: 'success',
              message: 'Inscripción registrada correctamente'
          }
          dispatch('notificationModule/add', notification, {root: true});
          
          commit('SET_LOADING_STATUS',false);
        }).catch( error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem creating credits' + error.message
          }
          dispatch('notificationModule/add', notification, {root: true});
        });
      },
      spoilerItem({state,dispatch},item){
        return CRUDServices.executeTask(state.api+'killspoiler/',item).then(result =>{
          if(result.objs){
            const notification = {
                type: 'warning',
                message: 'Caducaron tus inscripciones'
            }
            dispatch('notificationModule/add', notification, {root: true});
          }
        }).catch( error => {
            const notification = {
                type: 'error',
                message: 'There was a problem spoiling credits' + error.message
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
      },
      addMomento({state,dispatch},item){
        return CRUDServices.updateItem(state.api+'addEvent/'+state.master,{item: item,itemDate: new Date}).then(result =>{
          if(result.objs){
            const notification = {
                type: 'warning',
                message: 'Caducaron tus inscripciones'
            }
            dispatch('notificationModule/add', notification, {root: true});
          }
        }).catch( error => {
            const notification = {
                type: 'error',
                message: 'There was a problem spoiling credits' + error.message
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
      },
      consumeCredit({state,dispatch},item){
        return CRUDServices.updateCredits(state.api,item.salesman,item).then(result =>{
          if(result){
            const notification = {
                type: 'success',
                message: 'inscrito correctamente'
            }
            dispatch('notificationModule/add', notification, {root: true});
          }
        }).catch( error => {
            const notification = {
                type: 'error',
                message: 'Un problema consumiendo la inscripción' + error
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
      },
}