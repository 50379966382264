<template>
  <div>
    <v-row v-for="(item,index) in items" :key="index" justify="center">
      <v-col cols="12">
        <v-icon size="30px" class=" mr-5">mdi-{{item.icon}}</v-icon>
        <span class="text-h6 text-sm-h5 text-md-h6 text-lg-h5 font-weight-black text-left">{{item.title}}</span>
        <p class="ml-12 text-body-2 text-sm-body-1 text-md-subtitle-2 text-lg-subtitle-1 text-left">{{item.subtitle}}</p>
      </v-col>
    </v-row>   
  </div>
</template>

<script>
export default {
  props:{
    items: Array
  }
}
</script>

<style>

</style>