<template>
  <div>
    <AdminHeader
      color="black"
      icon="truck-trailer"
      title="Mis remolques."
      :showHelp="true"
      :content="content"
    />
    <Loader v-if="loading" :size="64" />
    <v-row>
      <v-col cols="12" sm="8"> </v-col>
      <v-col class="text-right pt-1 aling-right justify-right" cols="12" sm="4">
        <v-btn @click="createExpress()" dark>
          <v-icon left> mdi-plus </v-icon>
          Registrar
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="docs.length > 0" class="mb-16 pb-5">
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="4"
        v-for="item in docs"
        :key="item._id"
      >
        <v-card :loading="loading" class="mx-auto ma-5 px-2 pb-5" shaped>
          <v-sheet :class="colorStatus(item.momento)">
            <div class="d-flex flex-row-reverse">
              <strong class="mr-2">{{ getStatus(item.momento) }} </strong>
            </div>
          </v-sheet>
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-text>
            <v-row no-gutters dense>
              <v-col cols="6" sm="6">
                <v-avatar size="100%" tile>
                  <v-img
                    contain
                    max-height="200px"
                    :src="item.photos[0]"
                  ></v-img>
                </v-avatar>
              </v-col>
              <v-col sm="6">
                <v-card-subtitle>{{ item.name }}</v-card-subtitle>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <v-icon color="amber">
                    {{
                      item.papers
                        ? "mdi-check-circle"
                        : "mdi-checkbox-blank-circle-outline"
                    }}
                  </v-icon>
                  Registro
                  <br />
                  <br />
                  <v-icon color="amber">
                    {{
                      item.enroll
                        ? "mdi-check-circle"
                        : "mdi-checkbox-blank-circle-outline"
                    }}
                  </v-icon>
                  Inscrito
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="text-center justify-center">
            <v-row justify="center">
              <v-btn
                class="ma-1"
                v-if="canDelete(item.momento)"
                color="red"
                outlined
                small
                v-on:click="setItemToDelete(item._id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
              <v-btn
                outlined
                color="black"
                small
                @click="goToDetails(item._id)"
                class="ma-1"
              >
                <v-icon class="mr-1">mdi-view-compact</v-icon> Ver Detalle
              </v-btn>
              <v-btn
                class="ma-1"
                v-if="(item.momento == 1 || item.momento == 8) || !item.enroll"
                color="green"
                outlined
                small
                v-on:click="showConfirmation(item)"
              >
              <span v-if="item.enroll">
                <v-icon >mdi-email-send</v-icon> Validar
              </span>
              <span v-else>
                <v-icon>mdi-email-send</v-icon> Inscribir
              </span>
              </v-btn>
              <v-btn
                class="ma-1"
                v-if="item.momento == 5"
                color="green"
                dark
                small
                v-on:click="updateMomento(item, 6)"
              >
                <v-icon>mdi-check-all</v-icon> Terminar
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <EmptyState :white="false" />
    </v-row>
    <Paginador
      :dataLength="total"
      :pages="pages"
      :CurrentPage="page"
      @next="next"
    />
    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">
            ¿Deseas eliminar {{ newOne.name }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelEliminar()"> Cancelar </v-btn>
            <v-btn color="red" text @click="eliminar()"> Eliminar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="dialogDocumentación" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5"> Documentación pendiente </v-card-title>
        <v-card-text> Para iniciar el proceso de validación es necesario completar tu documentación</v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialogDocumentación = false">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogoDePago" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">
            Pago de inscripción
          </v-card-title>
          <v-card-text class="px-5">
            <p>
              Para continuar es necesario realizar el pago correspondiente a la inscripción:
            </p>
            <p>RFC: SIM920409R75</p>
            <p>RAZON SOCIAL: SERVICIOS INTERNACIONALES MEXICANOS SA DE CV</p>
            <p>BANCO: BANREGIO</p>
            <p>CUENTA:062973590010</p>
            <p>CLABE INTERBANCARIA: 058150629735900101</p>
            <p>MONTO: $1,000.00 MXN</p>
            <p><strong>CONCEPTO: {{ item.name }}</strong> </p>

            <v-file-input
                v-model="file"
                counter
                label="Documento de respaldo"
                accept="image/png, image/jpeg"
                show-size
                truncate-length="50"
                @change="changeFilePayment()"
              ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogoDePago = false"> Cancelar </v-btn>
            <v-btn :disabled="!fileSelected" text @click="processPaymentFile()"> Continuar </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import Loader from "@/components/adminSide/Loader";
import AdminHeader from "@/components/adminSide/AdminHeader";
import Paginador from "@/components/adminSide/Paginador";

export default {
  data() {
    return {
      fileSelected: false,
      response: "",
      dialogConfirmation: false,
      dialogDocumentación: false,
      confirmationResponse: false,
      idSelected: "",
      initialState: false,
      page: 1,
      dialog: false,
      canContinue: false,
      confirm: false,
      newOne: {
        image: ""
      },
      dialogCaptura: false,
      dialogoDePago: false,
      content: {
        title: "Listado de caballo capturados",
        subtitle:
          "Sección de configuración del catalogo de caballos del vendedor",
        sections: [
          {
            title: "General",
            info: [
              {
                title: "Listado",
                description: "Listado de caballos capturados",
              },
              {
                title: "Registrar ",
                description: "Capturar un nuevo caballo",
              },
              {
                title: "Ver detalle ",
                description: "Validar información capturada del caballo",
              },
              {
                title: "Presentar ",
                description:
                  "Enviar al equipo de ehorsesale para su validación ",
              },
              {
                title: "Terminar",
                description: "Observaciones han sido resueltas ",
              },
            ],
          },
          {
            title: "Captura",
            info: [
              {
                title: "Express",
                description: "Captura rápida de caballo",
              },
              {
                title: "Avanzada ",
                description:
                  "Captura con información adicional de salud y genealogía",
              },
            ],
          },
          {
            title: "Estatus de caballo",
            info: [
              {
                title: "Captura",
                description: "Caballo se encuentra en proceso de edición",
              },
              {
                title: "Terminado",
                description: "Caballo enviado a equipo de ehorsesale",
              },
              {
                title: "En auditoria",
                description:
                  "Caballo se encuentra en validación por parte de eHorsesale",
              },
              {
                title: "Validado con Observaciones",
                description:
                  "Equipo de ehorsesale detecto anomalías en la captura y dejo observaciones",
              },
              {
                title: "Validado para evento",
                description:
                  "Caballo se encuentra listo para agendar su subasta",
              },
              {
                title: "Asignado a evento",
                description: "Subasta del caballo se encuentra en proceso",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    changeFilePayment() {
      this.newOne.image = this.file;
      this.fileSelected = true;
    },
    comprar() {
      this.dialog = true;
      this.initialState = true;
      this.canContinue = false;
    },
    cancelConfirmation() {
      this.confirmationResponse = false;
      this.dialogConfirmation = false;
    },
    showConfirmation(item) {
      if(item.enroll){
        this.idSelected = item;
        if(this.incompleteDocs){
          this.confirmConfirmation()
        } else {
          this.dialogDocumentación = true
        }
      } else {
        this.dialogoDePago = true
        this.setItemToPay(item._id)
        return
      }
    },
    confirmConfirmation() {
      this.confirmationResponse = true;
      this.dialogConfirmation = false;
      this.updateMomento(this.idSelected, 2);
    },
    async process() {
      this.newOne = {
        salesman: this.$session.get("salesman"),
        credits: 1,
        buyDate: Date.now(),
        spoilerDate: this.addDays(Date.now(), 365),
        active: true,
      };

      await this.$store.dispatch("creditsModule/createItem", this.newOne);

      await this.$store.dispatch(
        "creditsModule/fetchItem",
        this.$session.get("salesman")
      );

      this.dialog = false;
    },
    async init() {
      await this.$store.dispatch(
        "horsesModule/setMaster",
        this.$session.get("salesman")
      );
      await this.$store.dispatch("horsesModule/fetchDataTrailers", {
        perPage: 10,
        page: this.page,
      });

      await this.$store.dispatch(
      "salesmenModule/fetchItem",
      (this.salesman = this.$session.get("salesman"))
    );
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    createExpress() {
      this.$router.push("NewTrailer");
    },
    next() {
      this.$store.dispatch("horsesModule/fetchDataTrailers", {
        perPage: 10,
        page: this.page,
      });
    },
    eliminar() {
      this.$store
        .dispatch("horsesModule/deleteItem", this.newOne._id)
        .then(() => {
          this.$store.dispatch(
            "adminNotificationsModule/deleteByHorse",
            this.newOne._id
          );
          this.$store.dispatch("horsesModule/fetchDataTrailers", {
            perPage: 10,
            page: this.page,
          });
          this.confirm = false;
        });
    },
    cancelEliminar(){
      this.confirm = false;
    },
    async setItemToDelete(id) {
      this.confirm = true;
      await this.$store.dispatch("horsesModule/fetchItem", id);
      this.newOne = this.item;
    },
    async setItemToPay(id) {
      await this.$store.dispatch("horsesModule/fetchItem", id);
      this.newOne = this.item;
    },
    getStatus(momento) {
      let estatusLabel = "";
      switch (momento) {
        case 1:
          estatusLabel = "Captura";
          break;
        case 2:
          estatusLabel = "Listo para auditoria";
          break;
        case 3:
          estatusLabel = "En auditoria";
          break;
        case 4:
          estatusLabel = "Validado con Observaciones";
          break;
        case 8:
          estatusLabel = "Observaciones Revisadas";
          break;
        case 5:
          estatusLabel = "Autorizado & Listo para evento";
          break;
        case 6:
          estatusLabel = "Autorizado & Listo para evento";
          break;
        case 7:
          estatusLabel = "Asignado a evento";
          break;
        default:
          estatusLabel = "N/A";
          break;
      }
      return estatusLabel;
    },
    goToDetails(id) {
      this.$store.dispatch("horsesModule/fetchItem", id).then(() => {
        this.$router.push({ name: "TrailerDetail", params: { horse: id } });
      });
    },
    async processPaymentFile(){
      let fileUpdate = { _id: this.newOne._id, image: this.newOne.image, type: 0 };

      this.$store
        .dispatch("horsesModule/updatePaymentImage", fileUpdate)
        .then(() => {
          this.init()
        });

      this.dialogoDePago = false;
    },
    async updateMomento(item, momento) {
      const id = item._id;
      await this.$store.dispatch("horsesModule/fetchItem", id);
      if (momento === 2 && !item.enroll) {
        if (this.itemCredits.credits > 0) {
          if (this.confirmationResponse) {
            await this.$store.dispatch("horsesModule/updateMomento", momento);

            const mov = {
              horse: item._id,
              usedOn: Date.now(),
              salesman: this.$session.get("salesman"),
            };
            await this.$store.dispatch("creditsModule/consumeCredit", mov);
            let notification = {
              horse: id,
              salesman: this.$session.get("salesman"),
              creationDate: new Date(),
              updateDate: new Date(),
              status: 0,
            };
            if (momento === 2)
              this.$store.dispatch(
                "adminNotificationsModule/createItem",
                notification
              );
            await this.$store.dispatch("horsesModule/fetchDataTrailers", {
              perPage: 10,
              page: this.page,
            });
          } else {
            this.dialogConfirmation = false;
          }
        } else {
          alert("Esta acción requiere comprar una inscripción");
          this.comprar();
        }
      } else {
        await this.$store.dispatch("horsesModule/updateMomento", momento);
        let notification = {
          horse: id,
          salesman: this.$session.get("salesman"),
          creationDate: new Date(),
          updateDate: new Date(),
          status: 0,
        };
        if (momento === 2)
          this.$store.dispatch(
            "adminNotificationsModule/createItem",
            notification
          );
        await this.$store.dispatch("horsesModule/fetchDataTrailers", {
          perPage: 10,
          page: this.page,
        });
      }
    },
    canDelete(momento) {
      return momento !== 7;
    },
    colorStatus(momento) {
      let estatusLabel = "";
      switch (momento) {
        case 1:
          estatusLabel = "white";
          break;
        case 2:
          estatusLabel = "green";
          break;
        case 3:
          estatusLabel = "amber";
          break;
        case 4:
          estatusLabel = "red";
          break;
        case 5:
          estatusLabel = "green";
          break;
        case 6:
          estatusLabel = "blue";
          break;
        case 7:
          estatusLabel = "grey";
          break;
        case 8:
          estatusLabel = "orange";
          break;
        default:
          estatusLabel = "N/A";
          break;
      }
      return estatusLabel;
    },
  },
  computed: {
    ...mapGetters("salesmenModule", ["incompleteDocs"]),
    ...mapState({
      itemCredits: (state) => state.creditsModule.singleItem,
      user: (state) => state.user,
      loading: (state) => state.horsesModule.loadingStatus,
      item: (state) => state.horsesModule.singleItem,
      pages: (state) => state.horsesModule.pages,
      total: (state) => state.horsesModule.total,
      docs: (state) => state.horsesModule.data,
    }),
  },
  components: {
    EmptyState,
    AdminHeader,
    Loader,
    Paginador,
  },
};
</script>

<style></style>
