<template>
    <div>
        <div>
            <h3>Identificadores de caballos para venta</h3>
            <h1>Tags</h1>
        </div>
        <v-overlay v-if='loading'>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-card
            elevation="5"
        >
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                    @click="create()"
                    dark
                >
                <v-icon left>
                    mdi-plus
                </v-icon>
                    Capturar
                </v-btn>
            </v-card-title>   
            <v-card-text v-if="dataLength > 0">
                <v-simple-table>
                    <template>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Nombre
                                    </th>
                                <th class="text-left">
                                    Estatus
                                </th>
                                <th class="text-right">
                                    Opciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in docs"
                                :key="item._id"
                            >
                                <td>{{ item.name }}</td>
                                <td>
                                    <v-chip v-if="item.status"
                                        class="ma-2"
                                        color="green"
                                        text-color="white"
                                    >
                                        Activa
                                    </v-chip>
                                    <v-chip v-else
                                        class="ma-2"
                                        color="red"
                                        text-color="white"
                                    >
                                        Inactiva
                                    </v-chip>                                    
                                </td>
                                <td class="text-right">
                                    <v-menu
                                            bottom
                                            left
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn                                                icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                            <v-list-item v-on:click="setItemToStore(item._id)">
                                                <v-list-item-title ><v-icon>mdi-lead-pencil</v-icon> Editar</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-on:click="setItemToDelete(item._id)">
                                                <v-list-item-title><v-icon color="red">mdi-trash-can</v-icon> Eliminar</v-list-item-title>
                                            </v-list-item>
                                            </v-list>
                                        </v-menu>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-row dense class="mx-auto justify-center aling-center" v-else>
                <EmptyState :white="true"/>
            </v-row>     
            <v-sheet 
                class="dialog-footer 
                    mx-auto 
                    justify-center 
                    aling-center 
                    py-2 gray float-end"
                elevation="14"   
                v-if="pages > 0" 
            >
                <p>Total de registros es de 
                    <strong>
                        {{ dataLength }}
                    </strong>
                </p>
                <v-spacer></v-spacer>
                <v-pagination v-if="pages > 0"
                    v-model="page"
                    :length="pages"
                    :total-visible="7"
                    @input="next"
                    dark
                    color="black"
                ></v-pagination>   
            </v-sheet>      
        </v-card>
        
        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar
                        color="black"
                        dark
                    >
                        <v-btn
                            icon
                            @click="dialog = false"
                            dark
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Formulario tag</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="guardar();"
                            :disabled="!valid"
                            color="amber"
                        >
                            {{ label }}
                        </v-btn>
                    </v-toolbar-items>
                    </v-toolbar>
                    <v-list
                        three-line
                        subheader
                    >
                        <v-list-item>
                            <v-list-item-content class="ma-5">
                                <v-form v-model="valid">
                                    <v-text-field
                                        v-model="newOne.name"
                                        :counter="100"
                                        label="Nombre"
                                        required
                                        :rules="nameRules"
                                    ></v-text-field>       
                                    <v-checkbox
                                        v-model="newOne.status"
                                        label= "Activa"
                                    ></v-checkbox>                      
                                </v-form>    
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="confirm"
                persistent
                max-width="400"
            >
                <v-card>
                <v-card-title class="headline">
                    ¿Deseas eliminar {{newOne.name}}?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    @click="cancelDelete()"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="red"
                        text
                        @click="guardar()"
                    >
                    Eliminar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import EmptyState from '@/components/EmptyState';
    
    export default {
        data(){
            return{
                valid:false,
                nameRules: [
                    v => !!v || 'Nombre Tag requerido',
                    v => v.length < 100 || 'Nombre debe ser menor a 100 caracteres',
                ],
                dialog: false,
                confirm: false,
                newOne: this.createFreshObj(),
                show1: false,
                page: 1,
                eventCrud: 0 // 0 for new, 1 for edit, 2 for delete                
            }
        },
        components:{
            EmptyState
        },
        created(){
            this.init();
        },
        methods:{
            create(){
                this.createFreshObj();
                this.dialog = true;
                this.eventCrud = 0;
            },
            createFreshObj(){
                // const user = this.$store.state.user;
                return {
                    name: '',
                    status: true
                }
            },
            init(){
                this.$store.dispatch('tagsModule/fetchData',{perPage: 5,page: this.page});
                this.newOne.name = "";
            },
            guardar(){                
                if(this.eventCrud === 0){// CREATE
                    this.dialog = false;
                    this.$store.dispatch('tagsModule/createItem',this.newOne).then(() => {
                        this.newOne = this.createFreshObj(); 
                        this.$store.dispatch('tagsModule/fetchData',{perPage: 5,page: this.pages});                    
                    });
                }else if(this.eventCrud ===1){ //EDIT
                    this.$store.dispatch('tagsModule/updateItem',this.newOne).then(() =>{
                        this.$store.dispatch('tagsModule/fetchData',{perPage: 5,page: this.page});
                        this.dialog = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }else{

                    this.$store.dispatch('tagsModule/deleteItem',this.newOne._id).then(() =>{
                        this.$store.dispatch('tagsModule/fetchData',{perPage: 5,page: this.page});
                        this.confirm = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }
                
            },
            next(){
                this.$store.dispatch('tagsModule/fetchData',{perPage: 5,page: this.page});
            },
            setItemToStore(id){                
                this.eventCrud = 1;
                this.$store.dispatch('tagsModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                    this.dialog = true;
                });
            },
            setItemToDelete(id){
                this.eventCrud = 2;
                this.confirm = true;
                this.$store.dispatch('tagsModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                });
            },
            cancelDelete(){
                this.eventCrud = 0;
                this.confirm = false;
                this.newOne = this.createFreshObj(); 
            }
        },
        computed: {
            label(){
                return this.eventCrud === 0 ? 'Guardar': (this.eventCrud === 1 ? 'Editar' : 'Eliminar' );
            },
            ...mapGetters('tagsModule',['dataLength']),            
            ...mapState({
                user: state => state.user,
                loading: state => state.tagsModule.loadingStatus,
                pages: state => state.tagsModule.pages,
                docs: state => state.tagsModule.data,
                item: state => state.tagsModule.singleItem
            })
        }
    }
</script>