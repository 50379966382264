<template>
  <v-overlay>
    <v-progress-circular
      indeterminate
      :size="size"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "loader",
  props:{
    size: Number
  }
}
</script>

<style>

</style>