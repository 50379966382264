<template>
  <div>
    <AdminHeader
      color="black"
      icon="calendar-clock"
      title="Subastas programadas"
      :showHelp="true"
      :content="content"
    />
    <Loader v-if="loading" :size="64" />
    <v-card elevation="5" min-height="600px">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="create()" dark>
          <v-icon left> mdi-plus</v-icon>
          Programar
        </v-btn>
      </v-card-title>

      <v-card-text v-if="dataLength > 0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Descripción
                </th>
                <th class="text-left">
                  Inicio
                </th>
                <th class="text-left">
                  Término
                </th>
                <th class="text-left">
                  Estatus
                </th>
                <th class="text-right">
                  Opciones
                </th>
                <th class="text-right">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in docs" :key="item._id">
                <td>{{ item.resume }}</td>
                <td>{{ item.initialDate | date }} {{ item.initialHour }}</td>
                <td v-if="item.open">
                  {{ item.finishDate | date }} {{ item.finishHour }}
                </td>
                <td v-else>Subasta terminó</td>
                <td>
                  <h6 v-if="!item.open">
                      <span v-if="item.firstPay"> Trato en proceso </span>
                      <span v-if="item.deal"> Trato cerrado</span>
                  </h6>
                  <v-chip
                    v-if="item.status"
                    class="ma-2"
                    color="green"
                    text-color="white"
                  >
                    Activo
                  </v-chip>
                  <v-chip v-else class="ma-2" color="red" text-color="white">
                    Inactivo
                  </v-chip>
                </td>
                <td class="text-right">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-on:click="setItemToStore(item._id)">
                        <v-list-item-title
                          ><v-icon>mdi-lead-pencil</v-icon>
                          Editar</v-list-item-title
                        >
                      </v-list-item>
                       <v-list-item v-on:click="getBids(item._id)">
                                                <v-list-item-title><v-icon color="red">mdi-history</v-icon> Movimientos</v-list-item-title>
                                            </v-list-item>
                      <v-list-item v-on:click="setItemToDelete(item._id)">
                        <v-list-item-title
                          ><v-icon color="red">mdi-trash-can</v-icon>
                          Eliminar</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td class="text-right">
                  <v-icon v-on:click="addStreaming(item._id)"
                    >mdi-access-point</v-icon
                  >
                  <v-icon v-on:click="addStreaming(item._id)"
                    >list-status</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-row dense class="ma-6 justify-center aling-center" v-else>
        <EmptyState :white="true" />
      </v-row>

    </v-card>
    <v-sheet
      class="dialog-footer 
                  mx-auto 
                  justify-center 
                  aling-center 
                  py-2 gray float-end"
      elevation="14"
      v-if="pages > 0"
    >
      <p>
        Total de registros es de
        <strong>
          {{ dataLength }}
        </strong>
      </p>
      <v-spacer></v-spacer>
      <v-pagination
        v-if="pages > 0"
        v-model="page"
        :length="pages"
        :total-visible="7"
        @input="next"
        dark
        color="black"
      ></v-pagination>
    </v-sheet>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
          <v-card-text>
            <v-toolbar dark>
            <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Nueva Subasta</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="guardar()" color="amber">
                {{ label }}
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>
            <v-list three-line subheader>
            <v-subheader>Programar subasta</v-subheader>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Formulario para programar una nueva subasta </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="ma-5">
                <form>
                    <span>Elemento a subastar</span>
                    <v-select
                    v-model="horseSelected"
                    :items="horseData"
                    item-text="name"
                    item-value="_id"
                    label="Selecciona"
                    persistent-hint
                    return-object
                    single-line
                    @input="changeHorse(horseSelected._id)"
                    ></v-select>
                    <v-alert color="orange" type="info" v-if="showWarning">
                    Es requerido seleccionar un elemento para continuar</v-alert
                    >
                    <!-- <v-text-field
                                        v-model="newOne.number"
                                        :counter="100"
                                        label="Numero"
                                        required                                    
                                ></v-text-field>      -->
                    <!-- <v-text-field
                                        v-model="newOne.resume"
                                        :counter="100"
                                        label="Descripción"
                                        required                                    
                                ></v-text-field>      -->
                    <v-text-field
                    v-model="horseSelected.buyNow"
                    :counter="100"
                    label="Comprar ahora"
                    required
                    prefix="$"
                    suffix="MXN"
                    ></v-text-field>

                    <h5>Configuración para ofertas rápidas</h5>
                    <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                        v-model="newOne.initBid"
                        :counter="100"
                        label="Inicia en: "
                        required
                        prefix="$"
                        suffix="MXN"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6">
                        <v-text-field
                        v-model="newOne.smallBid"
                        :counter="10"
                        label="Oferta baja"
                        required
                        prefix="$"
                        ></v-text-field>
                        <!-- <v-text-field
                                                v-model="newOne.mediumBid"
                                                :counter="10"
                                                label="Oferta media"
                                                required  
                                                prefix="$"                                  
                                        ></v-text-field>   -->
                    </v-col>
                    <!-- <v-col md="4">
                                        <v-text-field
                                                v-model="newOne.bigBid"
                                                :counter="10"
                                                label="Oferta alta"
                                                required    
                                                prefix="$"                                
                                        ></v-text-field>  
                                    </v-col> -->
                    </v-row>
                    <v-checkbox
                    v-model="newOne.status"
                    label="¿Publicar?"
                    ></v-checkbox>
                    <h3>Duración</h3>
                    <v-row>
                    <v-col cols="12" sm="12" md="10">
                        <v-expansion-panels accordion mandatory v-model="panel">
                        <v-expansion-panel>
                            <v-expansion-panel-header
                            >Configuración Rápida</v-expansion-panel-header
                            >
                            <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="newOne.initialDate"
                                label="Fecha inicial"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="newOne.initialDate"
                                :min="new Date().toISOString().substr(0, 10)"
                                @change="save"
                            ></v-date-picker>
                            </v-menu>
                            <h6>
                            Todas las subastas programadas termnarán a las (19:00
                            Horas - Hora del Centro)
                            </h6>
                            <v-expansion-panel-content>
                            <v-radio-group v-model="panelGroup" row mandatory>
                                <v-radio label="3 días" value="3"></v-radio>
                                <v-radio label="5 días" value="5"></v-radio>
                                <v-radio label="7 días" value="7"></v-radio>
                            </v-radio-group>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- <v-expansion-panel>
                                                <v-expansion-panel-header>Configuración Avanzada</v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row>
                                                        <v-col cols="12" md="3">
                                                            <v-menu
                                                        ref="menu"
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="newOne.initialDate"
                                                                label="Fecha inicial"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            ref="picker"
                                                            v-model="newOne.initialDate"
                                                            max="2022-01-01"
                                                            :min="new Date().toISOString().substr(0, 10)"                                                
                                                            @change="save"
                                                        ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-menu
                                                            ref="menu3"
                                                            v-model="menu3"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            :return-value.sync="time"
                                                            transition="scale-transition"
                                                            offset-y
                                                            max-width="290px"
                                                            min-width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="newOne.initialHour"
                                                                label="Hora inicial"
                                                                prepend-icon="mdi-clock-time-four-outline"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            ></v-text-field>
                                                            </template>
                                                            <v-time-picker
                                                            v-if="menu3"
                                                            v-model="newOne.initialHour"
                                                            full-width
                                                            @click:minute="$refs.menu.save(time)"
                                                            ></v-time-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-menu
                                                            ref="menu2"
                                                            v-model="menu2"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="newOne.finishDate"
                                                                    label="Fecha final"
                                                                    prepend-icon="mdi-calendar"
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                ref="picker"
                                                                v-model="newOne.finishDate"
                                                                min="2021-04-20"
                                                                max="2021-04-27"
                                                                @change="save2"
                                                            ></v-date-picker>
                                                        </v-menu> 


                                                        </v-col>
                                                        <v-col cols="12" md="3">
                                                            <v-menu
                                                            ref="menu4"
                                                            v-model="menu4"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            :return-value.sync="time2"
                                                            transition="scale-transition"
                                                            offset-y
                                                            max-width="290px"
                                                            min-width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="newOne.finishHour"
                                                                label="Hora final"
                                                                prepend-icon="mdi-clock-time-four-outline"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            ></v-text-field>
                                                            </template>
                                                            <v-time-picker
                                                            v-if="menu4"
                                                            v-model="newOne.finishHour"
                                                            full-width
                                                            @click:minute="$refs.menu.save(time2)"
                                                            ></v-time-picker>
                                                        </v-menu>
                                                        </v-col>
                                                        
                                                    </v-row>      
                                                </v-expansion-panel-content>
                                            </v-expansion-panel> -->
                        </v-expansion-panels>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        v-if="newOne.initialDate"
                        class="black"
                        tag="div"
                    >
                        <div class="ma-2 white--text">
                        <h2>
                            <v-icon class="mr-1 white--text">mdi-calendar</v-icon
                            >inicia:
                        </h2>
                        <h3>
                            {{ calculateinitialDate | datetime }}
                        </h3>
                        </div>
                        <div class="ma-2 white--text">
                        <h2>
                            <v-icon class="mr-1 white--text">mdi-calendar</v-icon
                            >termina:
                        </h2>
                        <h3>
                            {{ calculateFinalDate | datetime }}
                        </h3>
                        </div>
                    </v-col>
                    </v-row>
                </form>
                </v-list-item-content>
            </v-list-item>
            </v-list>
            <v-divider></v-divider>
          </v-card-text>
      </v-card>
    </v-dialog>

    <v-row justify="center">
            <v-dialog
                v-model="dialogBids"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                
            >
                <v-toolbar
                    dark
                >
                    <v-btn
                    icon
                    dark
                    @click="dialogBids = false"
                    >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                    
                </v-toolbar>
                <v-card >
                    <v-card-title>
                        Movimientos
                    </v-card-title>
                    <v-card-text>
                        <v-timeline>
                            <v-timeline-item v-for="(bid,i) in myBids" :key="i" :color="i==0
                             ? 'green' : 'red lighten-2'" large>
                            <template v-slot:opposite>
                                <span>{{bid.biddate | datetime}}</span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="headline">{{bid.bid | money}}</v-card-title>
                                <v-card-text v-if="bid.user">
                                    <v-avatar>
                                        <v-img
                                            :src="bid.image"
                                        ></v-img>
                                    </v-avatar>
                                    <span>                                        
                                        {{bid.user.name}}
                                    </span>
                                    
                                    <span>
                                        {{bid.user.lastnamep}}
                                    </span>
                                </v-card-text>

                            </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>

    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">
            ¿Deseas eliminar {{ newOne.name }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDelete()">
              Cancelar
            </v-btn>
            <v-btn color="red" text @click="guardar()">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="showStreaming" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">
            ¿Deseas incluir una transmisión a tu subasta?
          </v-card-title>

          <v-text-field v-model="toAdd">
            <template v-slot:label>
              https://youtu.be/
            </template>
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelStreaming()">
              Cancelar
            </v-btn>
            <v-btn color="green" text @click="guardarStreaming()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import AdminHeader from "@/components/adminSide/AdminHeader";
import Loader from "@/components/adminSide/Loader";

export default {
  data() {
    return {
      horseSelected: {},
      dialog: false,
      confirm: false,
      newOne: this.createFreshObj(),
      show1: false,
      page: 1,
      eventCrud: 0, // 0 for new, 1 for edit, 2 for delete
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      time: null,
      time2: null,
      showStreaming: false,
      toEdit: {},
      streamingKey: "",
      toAdd: "",
      calcsb: 0,
      calcmb: 0,
      calcbb: 0,
      panel: 0,
      panelGroup: 0,
      showWarning: false,
      dialogBids:false,
      content: {
        title: "Subastas",
        subtitle: "Sección de Subastas programadas dentro de la plataforma",
        sections: [
          {
            title: "General",
            info: [
              {
                title: "Tabla",
                description: "Listado de subastas programados",
              },
              {
                title: "+ programar ",
                description: "Programación de nuevas subastas",
              },
              {
                title: "Inscripciones disponibles ",
                description:
                  "Inscripciones disponibles para el registro de subastas",
              },
              {
                title: "Opciones ",
                description:
                  "Las subastas programados pueden ser actualizados o cancelados",
              },
            ],
          },
          {
            title: "Captura",
            info: [
              {
                title: "Caballo a subastar",
                description: "Caballos disponibles para programación de subasta",
              },
              {
                title: "Comprar ahora ",
                description:
                  "Cantidad configurada en el caballo, estimación de venta",
              },
              {
                title: "Oferta inicial ",
                description:
                  "Monto calculado para iniciar la subasta / oferta inicial",
              },
              {
                title: "Oferta Baja ",
                description: "Oferta propuesta a compradores incremento del 5%",
              },
              {
                title: "Publicar",
                description:
                  "Guardar en formato publico desde el momento de programación",
              },
              {
                title: "Duración",
                description:
                  "Todas las subastas tienen una duración de 3,5 o 7 días y terminan de manera automatica a las 19:00 ( Hora central)  el día final",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.$store.dispatch(
      "eventsModule/setMaster",
      this.$session.get("salesman")
    );
    this.$store.dispatch(
      "horsesModule/setMaster",
      this.$session.get("salesman")
    );
    this.$store.dispatch("configModule/fetchAll").then(() => {
      this.newOne.smallBid = this.configItem.minBid;
      this.newOne.mediumBid = this.configItem.medBid;
      this.newOne.bigBid = this.configItem.BigBid;
      this.newOne.initBid = this.configItem.initBid;      
    });
    this.init();
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },
    create() {
      this.createFreshObj();
      this.dialog = true;
      this.eventCrud = 0;
      this.eventCrud = 0;
      this.eventCrud = 0;
      this.eventCrud = 0;
      this.eventCrud = 0;
    },
    createFreshObj() {
      return {
        horse: "",
        status: true,
        number: 0,
        resume: "",
        smallBid: 0,
        mediumBid: 0,
        bigBid: 0,
        initialDate: null,
      };
    },
    async init() {
      await this.$store.dispatch("eventsModule/fetchData", {
        perPage: 10,
        page: this.page,
      });
      await this.$store.dispatch(
        "horsesModule/fetchByMaster",
        this.$session.get("salesman")
      );
      this.newOne.name = "";
    },
    guardarStreaming() {
      let item = {
        _id: this.toEdit,
        streamKey: this.toAdd,
      };
      this.$store.dispatch("eventsModule/addStreamingKey", item);
      this.showStreaming = false;
    },
    guardar() {
      this.newOne.salesman = this.$session.get("salesman");
      this.buyNow = this.horseSelected.buyNow;
      this.newOne.resume = this.horseSelected.name;
      this.newOne.currentBid = this.newOne.initBid;
      this.newOne.finishDate = this.calculateFinalDate;
      this.newOne.initialDate = this.calculateinitialDate;

      if (this.eventCrud === 0) {
        // CREATE
        if (this.newOne.horse) {
          this.dialog = false;
          this.$store
            .dispatch("eventsModule/createItem", this.newOne)
            .then(async () => {
              await this.$store.dispatch("salesmenModule/updateFfirstEvent", this.newOne.salesman);
              this.newOne = this.createFreshObj();              
              await this.$store.dispatch("eventsModule/fetchData", {
                perPage: 10,
                page: this.pages,
              });

              // let bid;
              // if(this.currentValue === null)
              //     this.currentValue = parseFloat(value)
              // else
              //     this.currentValue = parseFloat(value)
              // bid = {
              //     currentBid: this.currentValue,
              //     event: this.$route.params.event,
              //     user: this.$session.get('person'),
              //     bid: parseFloat(value),
              //     biddate: Date.now()
              // }
              // this.$store.dispatch('homeState/makeBid',bid).then(()=>{
              //     this.drawer = !this.drawer
              //     this.$socket.client.emit('event_set_bid', bid);
              //     this.calculateNextOffer();

              // });
            });
        } else {
          this.showWarning = true;
        }
      } else if (this.eventCrud === 1) {
        //EDIT
        this.$store
          .dispatch("eventsModule/updateItem", this.newOne)
          .then(() => {
            this.$store.dispatch("eventsModule/fetchData", {
              perPage: 10,
              page: this.page,
            });
            this.dialog = false;
            this.eventCrud = 0;
            this.newOne = this.createFreshObj();
          });
      } else {
        this.$store
          .dispatch("eventsModule/deleteItem", this.newOne)
          .then(async () => {
            this.$store.dispatch("eventsModule/fetchData", {
              perPage: 10,
              page: this.page,
            });
            await this.$store.dispatch("horsesModule/updateMomento", 6);
            this.confirm = false;
            this.eventCrud = 0;
            this.newOne = this.createFreshObj();
          });
      }
      this.$store.dispatch(
        "horsesModule/fetchByMaster",
        this.$session.get("salesman")
      );
    },
    next() {
      this.$store.dispatch("eventsModule/fetchData", {
        perPage: 10,
        page: this.page,
      });
    },
    setItemToStore(id) {
      this.eventCrud = 1;
      this.$store.dispatch("eventsModule/fetchItem", id).then(() => {
        this.newOne = this.item;
        this.dialog = true;
      });
    },
    setItemToDelete(id) {
      this.eventCrud = 2;
      this.confirm = true;
      this.$store.dispatch("eventsModule/fetchItem", id).then(() => {
        this.newOne = this.item;
      });
    },
    cancelDelete() {
      this.eventCrud = 0;
      this.confirm = false;
      this.newOne = this.createFreshObj();
    },
    changeHorse(master) {
      this.newOne.horse = master;

      this.newOne.initBid = parseFloat(
        this.horseSelected.buyNow * (this.configItem.initBid / 100)
      ).toFixed(2);
      let temp = parseFloat(
        this.newOne.initBid * (this.configItem.minBid / 100)
      ).toFixed(2);
      this.newOne.smallBid = parseFloat(temp) + parseFloat(this.newOne.initBid);
      this.newOne.smallBid = Math.round(this.newOne.smallBid / 1000) * 1000;


      if(this.horseSelected.buyNow > 10000 && this.horseSelected.buyNow < 50000 )
        this.newOne.initBid = 10000
      if(this.horseSelected.buyNow > 50001 && this.horseSelected.buyNow < 100000 )
        this.newOne.initBid = 20000
      if(this.horseSelected.buyNow > 100001  )
        this.newOne.initBid = 40000
      if(this.horseSelected.buyNow > 200000  )
        this.newOne.initBid = 100000
      this.newOne.smallBid = this.newOne.initBid * 0.20
    },
    addStreaming(id) {
      this.showStreaming = true;
      this.toEdit = id;
    },
    cancelStreaming() {
      this.showStreaming = false;
    },
    getBids(id){
                this.dialogBids = true;
                this.$store.dispatch('homeState/fetchBidByEvents',id);
            }    
  },
  computed: {
    label() {
      return this.eventCrud === 0
        ? "Guardar"
        : this.eventCrud === 1
        ? "Editar"
        : "Eliminar";
    },
    ...mapGetters("eventsModule", ["dataLength"]),
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.eventsModule.loadingStatus,
      pages: (state) => state.eventsModule.pages,
      docs: (state) => state.eventsModule.data,
      item: (state) => state.eventsModule.singleItem,
      horseData: (state) => state.horsesModule.data,
      configData: (state) => state.configModule.data,
      configItem: (state) => state.configModule.singleItem,
      myBids: state => state.homeState.myBids,
    }),
    calculateFinalDate() {
      let timestamp = Date.parse(this.newOne.initialDate);
      let finalDate = new Date(timestamp);
      finalDate.setHours(19, 0, 0);
      finalDate.setDate(finalDate.getDate() + 1 + parseInt(this.panelGroup));
      return finalDate;
    },
    calculateinitialDate() {
      let timestamp = Date.parse(this.newOne.initialDate);
      let finalDate = new Date(timestamp);
      finalDate.setHours(9, 0, 0);
      finalDate.setDate(finalDate.getDate() + 1);
      return finalDate;
    },
  },

  components: {
    EmptyState,
    AdminHeader,
    Loader,
  },
};
</script>
