import CRUDServices from '@/services/CRUDServices';
import EventBus from '@/services/events';

export const namespaced = true;

export const state = {
    loadingStatus: false,
    person: null,
    auth: {},
    current: '',
    api: '/frontUser/',
    data: [],
    validated: false,
    pages: 0,
    total: 0,
    exportEmail: []
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_USER(state, user){
      state.person = user;
    },
    SET_AUTH(state, token){
        state.auth = token;
    },
    SET_CURRENT(state, current){
        state.current = current;
    },
    SET_DATA(state, data){
      state.data = data;
    },
    SET_VALIDATED(state, validated){
      state.validated = validated;
    },
    SET_PAGES(state, pages){
      state.pages = pages
    },
    SET_TOTAL(state, total){
      state.total = total
    },
    SET_EXPORT_EMAIL(state, exportEmail){
      state.exportEmail = exportEmail
    },
}

export const  actions = {
    fetchAll({commit,dispatch}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocs(state.api)
      .then(response => {       
        let docs = [];
        response.data.forEach(
          element => {
            if(element.person){
              if(element.person.image){
                element.person.image = CRUDServices.getImage(element.person.image);
              }
              if(element.person.ine){
                element.person.ine = CRUDServices.getImage(element.person.ine);
              }
              if(element.person.comp){
                element.person.comp = CRUDServices.getImage(element.person.comp);
              }
            }
            docs.push(element);
          }
        );
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching salesmen: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    async exportEmails({commit,dispatch}){
      commit('SET_LOADING_STATUS',true);
      await CRUDServices.getDocs(state.api+'export')
      .then(response => {       
        commit('SET_LOADING_STATUS',false);
        commit('SET_EXPORT_EMAIL',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching export email: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchData({commit,dispatch},{perPage,page}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocsPage(state.api+'p/',perPage,page)
      .then(response => {       
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data.docs);
        commit('SET_PAGES',response.data.totalPages);
        commit('SET_TOTAL',response.data.totalDocs);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching frontman: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    async logIn({commit,dispatch},user){
      commit('SET_LOADING_STATUS',true);
      await CRUDServices.authByEmail(state.api+'login/',user)
        .then(response => {
            if(response.data.token != null){
              commit('SET_VALIDATED',response.data.validated);
              if(response.data.validated){
                commit('SET_AUTH',response.data.token);
                commit('SET_CURRENT',response.data.current);
                if(response.data.person != undefined){
                  commit('SET_USER',response.data.person);
                }
                const notification = {
                  type: 'success',
                  message: 'Acceso correcto'
                }
                dispatch('notificationModule/add', notification, {root: true}); 
              }
            }else{
              const notification = {
                type: 'error',
                message: 'Problema para iniciar sesión '
              }
              dispatch('notificationModule/add', notification, {root: true});
              EventBus.$emit('front-user-bad-login');
            }
            commit('SET_LOADING_STATUS',false);
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'There was a problem login: ' + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
        
    },
    createFront({commit,dispatch},admin){
        commit('SET_LOADING_STATUS',true);
        return CRUDServices.postItem(state.api+'newUser/',admin).then(response => {
            if(response.data.exito){
                let notification = {
                  type: 'success',
                  message: 'Usuario creado correctamente'
                }
                let emailData = {
                  front: admin,
                  id: response.data.obj,
                  location: window.location.origin
                }
                dispatch('notificationModule/add', notification, {root: true});
                dispatch('emailModule/welcomeFront',emailData, {root: true});
                commit('SET_LOADING_STATUS',false);
                notification = {
                  type: 'success',
                  message: 'Consulta tu bandeja de correo electrónico'
                }
                dispatch('notificationModule/add', notification, {root: true});

                EventBus.$emit('registerCorrect');
            }else{
                const notification = {
                  type: 'error',
                  message: 'email ya registrado'
              }
              dispatch('notificationModule/add', notification, {root: true});
              commit('SET_LOADING_STATUS',false);
            }
            
        }).catch(()=>{
          const notification = {
              type: 'error',
              message: 'There was a problem creating user'
          }
          dispatch('notificationModule/add', notification, {root: true});
          EventBus.$emit('registerError');
          commit('SET_LOADING_STATUS',false);
        });
      },
      updateFrontUser({commit,dispatch},toUpdate){
        commit('SET_LOADING_STATUS',true);
        commit('SET_USER',toUpdate.item);
        return CRUDServices.updateSingleField(state.api+'u/',toUpdate.front,toUpdate.field).then(() => {
          commit('SET_LOADING_STATUS',false);
        }).catch(()=>{
          const notification = {
              type: 'error',
              message: 'There was a problem setting the salesman'
          }
          dispatch('notificationModule/add', notification, {root: true});
        });
      },
      updateSingleImage({state,commit,dispatch},item){
        commit('SET_LOADING_STATUS',true);
        return CRUDServices.updateSingleImage(state.api+'file/',item).then(() => {
          commit('SET_LOADING_STATUS',false);
          const notification = {
              type: 'success',
              message: 'Vendedor actualizada correctamente'
          }
          dispatch('notificationModule/add', notification, {root: true});
        }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem updating salesman' + error.message
          }
          dispatch('notificationModule/add', notification, {root: true});
        });
      },
      validateEmail({commit,dispatch},toUpdate){
        commit('SET_LOADING_STATUS',true);
        return CRUDServices.updateSingleField(state.api+'validate/',toUpdate,true).then(() => {
          commit('SET_LOADING_STATUS',false);
          EventBus.$emit('validate-email-response',true);
        }).catch(()=>{
          const notification = {
            type: 'error',
            message: 'Problemas al registrar los datos del front user.'
          }
          dispatch('notificationModule/add', notification, {root: true});
          EventBus.$emit('validate-email-response',false);
        });
      },

}

export const getters = {
  dataLength: state =>{
    return state.total
  },
}