<template>
    <div>
        <AdminHeader color="black" icon="money" title="Ventas por Inscripciones"/>
        <Loader v-if="loading" :size="64" />

        <v-card
            elevation="5"
            height="100%"
        >
            <v-card-title>
                <h6>Reporte de ventas</h6>
                <v-spacer></v-spacer>
            </v-card-title>   
            <v-card-text v-if="dataLength > 0">
                <v-simple-table>
                    <template>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    TX
                                    </th>
                                <th class="text-left">
                                    User
                                    </th>
                                <th class="text-left">
                                    status
                                </th>
                                <th >
                                    razón
                                </th>
                                <th class="text-left">
                                    Monto
                                </th>
                                <th class="text-left">
                                    Tipo
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in docs"
                                :key="item._id"
                            >
                                <td>{{ item.ppp_TransactionId }}</td>
                                <td>{{ item.userToken }}</td>
                                <td>{{ item.status }}</td>
                                <td>{{ item.reason }}</td>
                                <td>{{ item.totalAmount | money}}</td>
                                <td>{{ item.type }}</td>
                                <td>{{ item.jsonObj }}</td>
                            </tr>
                            
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-row dense class="mx-auto justify-center aling-center" v-else>
                <EmptyState :white="true"/>
            </v-row>     
            <v-sheet 
                class="dialog-footer 
                    mx-auto 
                    justify-center 
                    aling-center 
                    py-2 gray float-end"
                elevation="14"   
                v-if="pages > 0" 
            >
                <p>Total de compras es de 
                    <strong>
                        {{ dataLength }}
                    </strong>
                </p>
                <v-spacer></v-spacer>
                <v-pagination v-if="pages > 0"
                    v-model="page"
                    :length="pages"
                    :total-visible="7"
                    @input="next"
                    dark
                    color="black"
                ></v-pagination>   
            </v-sheet>   
        </v-card>

    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import EmptyState from '@/components/EmptyState';
    import Loader from '@/components/adminSide/Loader';
    import AdminHeader from '@/components/adminSide/AdminHeader';
    
    export default {
        data(){
            return{
                itemsPerPage: 10,
                valid:false,
                nameRules: [
                    v => !!v || 'Nombre Grupo requerido',
                    v => v.length < 100 || 'Nombre debe ser menor a 100 caracteres',
                ],
                dialog: false,
                confirm: false,
                newOne: this.createFreshObj(),
                show1: false,
                page: 1,
                eventCrud: 0 // 0 for new, 1 for edit, 2 for delete                
            }
        },
        components:{
            EmptyState,
            AdminHeader,
            Loader
        },
        created(){
            this.init();
        },
        methods:{
            create(){
                this.createFreshObj();
                this.dialog = true;
                this.eventCrud = 0;
            },
            createFreshObj(){
                // const user = this.$store.state.user;
                return {
                    name: '',
                    status: true
                }
            },
            init(){
                this.$store.dispatch('paymentsModule/fetchDataFinal');
                this.newOne.name = "";
            },
            next(){
                this.$store.dispatch('paymentsModule/fetchDataFinal');
            },
        },
        computed: {
            ...mapGetters('paymentsModule',['dataLength']),            
            ...mapState({
                user: state => state.user,
                loading: state => state.paymentsModule.loadingStatus,
                pages: state => state.paymentsModule.pages,
                docs: state => state.paymentsModule.data,
                item: state => state.paymentsModule.singleItem
            })
        }
    }
</script>