<template>
  <div>
    <AdminHeader
      v-if="item.type === 1"
      color="black"
      img="'/images/33157.png'"
      title="Información general del caballo"
    />
    <AdminHeader
      v-else
      color="black"
      icon="truck-trailer"
      title="Información general del remolque"
    />
    <ImagePreview
      v-if="imageDialog"
      :imgDialog="imgDialog"
      @closePreview="closePreview"
    />
    <Loader v-if="idLoading" :size="64" />
    <v-row v-else :key="item._id">
      <v-col md="2" cols="12" sm="12">
        <v-sheet rounded="lg">
          <v-list color="transparent" v-if="item.type === 1">
            <v-list-item
              v-for="section in sections"
              :key="section.ver"
              link
              @click="activateSection(section.ver)"
            >
              <v-list-item-icon v-if="section.icon">
                <v-icon>mdi-{{ section.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-if="section.section">
                {{ section.section }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item link color="grey lighten-4">
              <v-list-item-icon>
                <v-icon>mdi-arrow-left</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="goBack()">
                Regresar
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list color="transparent" v-else>
            <v-list-item
              v-for="section in sectionsTrailer"
              :key="section.ver"
              link
              @click="activateSection(section.ver)"
            >
              <v-list-item-icon v-if="section.icon">
                <v-icon>mdi-{{ section.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-if="section.section">
                {{ section.section }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item link color="grey lighten-4">
              <v-list-item-icon>
                <v-icon>mdi-arrow-left</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="goBack()">
                Regresar
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col md="10" sm="12">
        <v-card v-if="activeSection === 0" elevation="5" height="100%">
          <v-card-title>
            <h6 v-if="sections.length > 0">
              <v-icon class="mr-1"
                >mdi-{{ sections[activeSection].icon }}</v-icon
              >
              {{ sections[activeSection].section }}
            </h6>
            <v-spacer></v-spacer>
            <v-btn @click="addObs(1)" color="Black" dark absolute top right fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <HorseDetailGeneral :item="item" @showImage="showImage" />
            <div>
            </div>
          </v-card-text>
        </v-card>
        <!-- <v-card
                    v-if="activeSection === 1"
                    elevation="5"
                    height="100%"
                >
                    <v-card-title>
                        <h6 v-if="sections.length > 0">
                            <v-icon class="mr-1">mdi-{{sections[activeSection].icon}}</v-icon>
                            {{sections[activeSection].section}}
                        </h6>
                        <v-spacer></v-spacer>
                        <v-btn
                        @click="addObs(1)"
                        color="Black"
                        dark
                        absolute   
                        top
                        right                 
                        fab
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    </v-card-title>  
                    <v-card-text>
                        <div>
                            <h1 v-if="item">Nombre: {{item.section}}</h1>
                            <h6 v-if="hdtData[0]">Tiempo de entrenamiento {{hdtData[0].time}} años</h6>
                        </div>
                        <v-divider class="my-3"></v-divider>
                        <div v-if="hdtData[0]">
                            <h3>Valoración de entretamiento:  ( 1 Bien - 5 Mal)</h3>   
                            <v-row>
                                <v-col cols="12" sm="4">
                                    Nivel de experiencia requerido por el manejador
                                    <v-rating
                                        v-model="hdtData[0].levelOfExp"
                                        background-color="black"
                                        color="black"
                                        readonly
                                        size="25"
                                    ></v-rating>

                                </v-col>
                                <v-col cols="12" sm="4">
                                    Calificación para montar o manejar la disposición del caballo
                                    <v-rating
                                        v-model="hdtData[0].levelOfRiding"
                                        background-color="black"
                                        color="black"
                                        readonly
                                        size="25"
                                    ></v-rating>
                                </v-col>
                            </v-row>     
                            <h3>Valoración de comportamiento:  ( 1 Mal - 5 Bien)</h3> 
                            <v-row>
                                <v-col cols="12" md="4">
                                        Disposición General
                                    <v-rating
                                        v-model="hdtData[0].general"
                                        background-color="black"
                                        color="black"
                                        readonly
                                    ></v-rating>
                                </v-col>
                                <v-col cols="12" md="4">
                                    Modales en el puesto
                                    <v-rating
                                        v-model="hdtData[0].manners"
                                        background-color="black"
                                        color="black"
                                        readonly
                                    ></v-rating>
                                </v-col>
                                <v-col cols="12" md="4">
                                    Comporamiento con otros caballos
                                    <v-rating
                                        v-model="hdtData[0].alongWithHorse"
                                        background-color="black"
                                        color="black"
                                        readonly
                                    ></v-rating>
                                </v-col>
                            </v-row>                                     
                            <h3>Dificultad de manejo:  ( 1 Bien - 5 Mal)</h3> 
                            <v-row>
                                <v-col cols="12" md="4">
                                        Dificultad de herrar
                                    <v-rating
                                        v-model="hdtData[0].farrier"
                                        background-color="black"
                                        color="red"
                                        size="25"
                                    ></v-rating>
                                </v-col>
                                <v-col cols="12" md="4">
                                    Dificultad de carga y remolque
                                    <v-rating
                                        v-model="hdtData[0].loading"
                                        background-color="black"
                                        color="red"
                                        size="25"
                                    ></v-rating>
                                </v-col>
                                <v-col cols="12" md="4">
                                    Dificiltad para recorte
                                    <v-rating
                                        v-model="hdtData[0].clipping"
                                        background-color="black"
                                        color="black"
                                        size="25"
                                    ></v-rating>
                                </v-col>
                            </v-row>
                        </div>
                        <EmptyState v-else :white="true" />
                    </v-card-text>
                </v-card> -->
        <v-card v-if="activeSection === 1" elevation="5" height="100%">
          <v-card-title>
            <h6 v-if="sections.length > 0">
              <v-icon class="mr-1"
                >mdi-{{ sections[activeSection].icon }}</v-icon
              >
              {{ sections[activeSection].section }}
            </h6>
            <v-spacer></v-spacer>
            <v-btn @click="addObs(1)" color="Black" dark absolute top right fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div>
              <h1 v-if="item">Nombre: {{ item.name }}</h1>
            </div>

            <v-divider class="my-3"></v-divider>
            <div class="text-center" v-if="hdhData[0]">
              <pdfViewer :URL="hdhData[0].file" v-if="hdhData[0].file" /><br />
            </div>
            <div class="text-center" v-if="hdhData[0]">
              <a :href="hdhData[0].file" target="blank" v-if="hdhData[0].file">
                Abrir
              </a>
            </div>
            <div v-if="hdhData[0]">
              <h3 v-if="hdhData[0].observations">
                Observaciones: {{ hdhData[0].observations }}
              </h3>
              <v-row v-if="hdhData[0]" class="ma-5">
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].cribber"
                    label="Aereofagia"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].weaver"
                    label="Criptorquideo"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6">
                  <v-checkbox
                    :input-value="hdhData[0].nerved"
                    label="Transtorno Neurológico"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].foundered"
                    label="Ring Boon"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].majorIS"
                    label="Cirugia mayor"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].parrotMouth"
                    label="¿Boca de perico?"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].colic"
                    label="Laminitis"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].pastLameness"
                    label="Cojera"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].defects"
                    label="Transtorno navicular"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].osteartitis"
                    label="Osteartitis"
                    value
                    disabled
                  >
                  </v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].ceguera"
                    label="Ceguera"
                    value
                    disabled
                  ></v-checkbox
                ></v-col>
              </v-row>
              <h3 if="showFemaleHealth">Información de Hembras</h3>
              <v-row class="ma-5">
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].aborto"
                    label="¿Aborto?"
                    value
                    disabled
                  ></v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].transtorno"
                    label="¿transtorno en el ciclo?"
                    value
                    disabled
                  ></v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].extirpasion"
                    label="¿Extirpación de ovarios?"
                    value
                    disabled
                  ></v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].cesarea"
                    label="¿Cesárea?"
                    value
                    disabled
                  ></v-checkbox
                ></v-col>
                <v-col lg="2" md="3" sm="6" cols="6"
                  ><v-checkbox
                    :input-value="hdhData[0].cuello"
                    label="¿Operaciones al cuello uterino?"
                    value
                    disabled
                  ></v-checkbox
                ></v-col>
              </v-row>
            </div>
            <EmptyState v-else :white="true" />
          </v-card-text>
        </v-card>
        <v-card
          v-if="activeSection === 2"
          :key="hdpData.length"
          elevation="5"
          height="100%"
        >
          <v-card-title>
            <h6 v-if="sections.length > 0">
              <v-icon class="mr-1"
                >mdi-{{ sections[activeSection].icon }}</v-icon
              >
              {{ sections[activeSection].section }}
            </h6>
            <v-spacer></v-spacer>
            <v-btn @click="addObs(4)" color="Black" dark absolute top right fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div>
              <h1>Nombre: {{ item.name }}</h1>
            </div>
            <v-divider class="my-3"></v-divider>
            <PedigreeTree v-if="hdpData[0]" :pedigreeTree="hdpData[0]" />
            <EmptyState v-else :white="true" />
          </v-card-text>
        </v-card>
        <v-card v-if="activeSection === 3" elevation="5" height="100%">
          <v-card-title>
            <h6 v-if="sections.length > 0">
              <v-icon class="mr-1"
                >mdi-{{ sections[activeSection].icon }}</v-icon
              >
              {{ sections[activeSection].section }}
            </h6>
            <v-spacer></v-spacer>
            <v-btn @click="addObs(5)" color="Black" dark absolute top right fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div>
              <h1 v-if="item">Nombre: {{ item.name }}</h1>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-row
              v-if="item.youtubeVideo.length > 0 && item.youtubeVideo[0] !== ''"
            >
              <v-col sm="4" v-for="video in item.youtubeVideo" :key="video">
                <iframe
                  v-if="video !== ''"
                  width="100%"
                  height="315"
                  :src="'https://www.youtube.com/embed/' + video"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </v-col>
            </v-row>
            <EmptyState v-else :white="true" />
          </v-card-text>
        </v-card>
        <v-card v-if="activeSection === 4" elevation="5" height="100%">
          <v-card-title>
            <h6 v-if="sections.length > 0">
              <v-icon class="mr-1"
                >mdi-{{ sections[activeSection].icon }}</v-icon
              >
              {{ sections[activeSection].section }}
            </h6>
            <v-spacer></v-spacer>
            <v-btn @click="addObs(5)" color="Black" dark absolute top right fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="item.salesman">
            <h1>
              {{
                item.salesman.name +
                " " +
                item.salesman.lastnamep +
                " " +
                item.salesman.lastnamem
              }}
            </h1>
            <h3>{{ item.salesman.mail }}</h3>
            <hr class="mt-6 mb-4" />
            <v-row v-if="!item.salesman.ranch.default" class="my-5">
              <h3>Rancho</h3>
              <v-col cols="1" class="text-center" v-if="item.salesman.ranch">
                <v-avatar v-if="item.salesman.ranch.image">
                  <img :src="item.salesman.ranch.image" alt="Rancho" />
                </v-avatar>
              </v-col>
              <v-col cols="11" v-if="item.salesman.ranch">
                <h3>{{ item.salesman.ranch.name }}</h3>
                <h5>{{ item.salesman.ranch.email }}</h5>
                <h5 v-if="item.salesman.ranch.city">
                  {{
                    item.salesman.ranch.city.name +
                    " - " +
                    item.salesman.ranch.city.state.name +
                    " - " +
                    item.salesman.ranch.city.state.country.name
                  }}
                </h5>
              </v-col>
            </v-row>

          <h3 class="mx-auto my-2 justify-center aling-center text-center">Comprobante de pago</h3>
          <v-img  
            class="mx-auto justify-center aling-center text-center"
              contain
              :lazy-src="item.paymentComprobant"
              max-height="100%"
              max-width="300px"
              :src="item.paymentComprobant"
              @click="showImage(item.paymentComprobant)"
          ></v-img>
          <div class="mx-auto my-2 justify-center aling-center text-center">
            <v-btn color="red" class="dark mx-3" outlined @click="changeEnroll(false)"> RECHAZAR </v-btn>
            <v-btn color="green" class="dark mx-3" outlined @click="changeEnroll(true)"> ACEPTAR </v-btn>

          </div>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Observaciones de auditoría </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                v-for="step in horseAuditData"
                :key="step._id"
              >
                <v-alert
                  outlined
                  :type="step.status ? 'info' : 'warning'"
                  prominent
                  border="left"
                >
                  <h3>Sección: {{ sections[step.step].section }}</h3>
                  <h6>{{ step.dateObs | date }}</h6>
                  <span>{{ step.comment }}</span>
                  <br />
                  <v-btn
                    @click="resolveOb(step)"
                    v-if="!step.status"
                    color="primary"
                    x-small
                    >resolver</v-btn
                  >
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Nueva observación</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="comentario"
                    v-model="dialogText"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelObs()">
              Cerrar
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveObs(dialogText)">
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/adminSide/Loader";
import AdminHeader from "@/components/adminSide/AdminHeader";
import ImagePreview from "@/components/adminSide/imagePreview";
import HorseDetailGeneral from "@/components/adminSide/HorseDetailGeneral";
import PedigreeTree from "@/components/adminSide/PedigreeTree";
import EmptyState from "@/components/EmptyState";
import pdfViewer from "@/components/adminSide/pdfViewer";

export default {
  data() {
    return {
      sections: [
        { section: "General", ver: 0, icon: "file-document-outline" },
        // {section:'Entrenamiento',ver: 1,icon: "dumbbell"},
        { section: "Salud", ver: 1, icon: "bottle-tonic-plus" },
        { section: "Arbol Genealogico", ver: 2, icon: "family-tree" },
        { section: "Videos", ver: 3, icon: "video-vintage" },
        { section: "Vendedor", ver: 4, icon: "information-outline" },
      ],
      sectionsTrailer: [
        { section: "General", ver: 0, icon: "file-document-outline" },
        { section: "Vendedor", ver: 4, icon: "information-outline" }
      ],
      activeSection: 0,
      observation: {
        comment: "",
        dateObs: "",
        step: "",
        status: false,
      },
      dialogText: "",
      dialog: false,
      imgDialog: "",
      imageDialog: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    resolveOb(step) {
      step.status = true;
      this.$store.dispatch("horseAuditModule/updateItem", step).then(() => {
        this.observation = {};
        this.$store.dispatch(
          "horseAuditModule/fetchByMaster",
          this.$route.params.horse
        );
        this.dialog = false;
        this.dialogText = "";
      });
    },
    async init() {
      await this.$store
        .dispatch("horsesModule/fetchItem", this.$route.params.horse)
        .then(() => {
          this.fetchDetails();
        });
    },
    fetchDetails() {
      this.$store.dispatch("hdhModule/fetchByMaster", this.$route.params.horse);
      this.$store.dispatch("hdtModule/fetchByMaster", this.$route.params.horse);
      this.$store.dispatch("hdpModule/fetchByMaster", this.$route.params.horse);
      this.$store.dispatch(
        "horseAuditModule/fetchByMaster",
        this.$route.params.horse
      );
    },
    activateSection(id) {
      this.activeSection = id;
    },
    goBack() {
      this.$router.go(-1);
    },
    addObs(id) {
      this.section = id - 1;
      this.dialog = true;
    },
    cancelObs() {
      this.observation = {};
      this.dialog = false;
    },
    createObservation(comment, section, date, horse, status) {
      return {
        comment: comment,
        step: section,
        dateObs: date,
        horse: horse,
        status: status,
      };
    },
    saveObs(comment) {
      this.observation = this.createObservation(
        comment,
        this.activeSection,
        Date.now(),
        this.$route.params.horse,
        false
      );
      this.$store
        .dispatch("horseAuditModule/createItem", this.observation)
        .then(() => {
          this.observation = {};
          this.$store.dispatch(
            "horseAuditModule/fetchByMaster",
            this.$route.params.horse
          );
          this.dialog = false;
          this.dialogText = "";
        });
    },
    showImage(photo) {
      this.imgDialog = photo;
      this.imageDialog = true;
    },
    closePreview() {
      this.imgDialog = "";
      this.imageDialog = false;
    },
    async changeEnroll(status){
      if (status) {
        this.saveObs("Comprobante de pago aceptado")
      } else {
        this.saveObs("Comprobante de pago rechazado")
        await this.$store.dispatch("horsesModule/updateEnroll", false);
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loadingHorse: (state) => state.horsesModule.loadingStatus,
      // loadinghdt: state => state.hdtModule.loadingStatus,
      loadinghdh: (state) => state.hdhModule.loadingStatus,
      loadinghdp: (state) => state.hdpModule.loadingStatus,
      item: (state) => state.horsesModule.singleItem,
      // hdtData: state => state.hdtModule.data,
      hdhData: (state) => state.hdhModule.data,
      hdpData: (state) => state.hdpModule.data,
      horseAuditData: (state) => state.horseAuditModule.data,
    }),
    idLoading() {
      return this.loadingHorse && this.loadinghdh && this.loadinghdp;
    },
  },
  components: {
    AdminHeader,
    Loader,
    ImagePreview,
    EmptyState,
    PedigreeTree,
    pdfViewer,
    HorseDetailGeneral,
  },
};
</script>

<style>
</style>