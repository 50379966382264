import axios from 'axios';

const URL = process.env.VUE_APP_BACKEND_URL;

const apiClient = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers:{
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmYmNiMWEyZTA0ZmMxODI3M2ZkNDdhMSIsInVzZXIiOiJzYWhlcnJlcmEiLCJpYXQiOjE2MDc1NjQzOTF9.VLnUYcHk7yXIaTpmwUKJaCvjJJ0vGxgpq7GZj4b4H-U"
    }
});

export default {
    auth(user){
        return apiClient.post('/usuarioAdmin/login',{user: user.name, pass: user.pass});
    },
    getAdmins(){
        return apiClient.get('/usuarioAdmin');
    },
    getAdminsPage(perPage,page){
        return apiClient.get('/usuarioAdmin/p/'+ page + '&' + perPage);
    },
    getAdmin(id){
        return apiClient.get('/usuarioAdmin/' + id);
    },
    postAdmin(newAdmin){
        return apiClient.post('/usuarioAdmin/newAdmin',newAdmin);
    },
    deleteAdmin(id){
        return apiClient.delete('/usuarioAdmin/'+ id);
    },
    updateAdmin(editAdmin){
        return apiClient.put('/usuarioAdmin/'+ editAdmin._id,editAdmin );
    },
    updateAdminPass(editAdmin){
        return apiClient.put('/usuarioAdmin/updatePass/'+ editAdmin.user,editAdmin );
    }
}