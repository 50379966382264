<template>
    <div class="ma-6">
        <h1>Artículos en Venta</h1>
        <v-row>            
			<v-col v-for="article in articles" :key="article._id"
				cols="12" xs="12" sm="6" md="6" lg="4" xl="4"
			>
				<articleItem :article="article" />
			</v-col>
		</v-row> 
    </div>
</template>

<script>

    import {mapState} from 'vuex';
    import articleItem from '@/components/userSide/articleItem.vue'

    export default {
        beforeCreate(){
            this.$store.dispatch('homeState/fetchArticles');

        },
        computed:{
            ...mapState({
                    loading: state => state.homeState.loadingStatus,                    
                    articles: state => state.homeState.articles,
                }),
            },
        components:{
            articleItem
        }
    }
</script>
