<template>
  <v-img
    alt="eHorseSale logo"
    class="shrink mt-1 cursor-mouse "
    contain
    :min-width="minWidth"
    src="https://www.ehorsesale.com/images/ehorsesale/blackbox.png"
    width="100"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name:"Logo",
  props:{
    minWidth:{
      type: String,
      default: "250"
    }
  }
}
</script>

<style>

</style>