<template>
  <v-card
    elevation="0"
  >
    <v-icon size="40px" color="red"> mdi-{{icon}}</v-icon>
    <p class="text-body-2 text-sm-body-2 text-md-subtitle-2 text-center">{{title}}</p>
    <strong class="text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">
      {{info}}
    </strong>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    info: String
  }
}
</script>

<style>

</style>