import CRUDServices from '@/services/CRUDServices';
import EventBus from '../../services/events';

export const namespaced = true;

let catalogName = 'Caballo';

export const state = {
    loadingStatus: false,
    data: [],
    singleItem: {},
    pages: 0,
    total: 0,
    api: '/horses/',
    master: {},
    justSaved: null,
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_DATA(state, data){
      state.data = data;
    },
    SET_NEW_OBJ(state, item){
      state.data.push(item)
    },
    SET_PAGES(state, pages){
      state.pages = pages
    },
    SET_TOTAL(state, total){
      state.total = total
    },
    SET_SINGLE(state, item){
      state.singleItem = item
    },
    SET_MASTER(state, item){
      state.master = item
    },
    SET_SAVED(state, id){
      state.justSaved = id
    },
    SET_MOMENTO(state,moment){
      state.singleItem.momento = moment;
    }
}

export const  actions = {
    setMaster({commit},master){
      commit('SET_MASTER',master);
    },
    fetchAll({commit,dispatch}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocs(state.api)
      .then(response => {       
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching' + catalogName + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchByMaster({state,commit,dispatch},master){
      CRUDServices.getDocsByMaster(state.api+'s/', master)
      .then(response => {        
        commit('SET_DATA',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching states: ' + error.message
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchPending({state,commit,dispatch},{perPage,page}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocsPage(state.api+'audit/',perPage,page)
      .then(response => {        
        let docs = [];
        response.data.docs.forEach(
          element => {
            if(element.photos){
              let photos = [];
              element.photos.forEach(
                photo => {
                  if(element.photos){
                    photo = CRUDServices.getImage(photo);
                  }
                  photos.push(photo);
                }
              );
              element.photos = photos;
            }
            docs.push(element);
          }
        );
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA', docs);
        commit('SET_PAGES',response.data.totalPages);
        commit('SET_TOTAL',response.data.totalDocs);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching  ' + catalogName + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchData({state,commit,dispatch},{perPage,page}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocsPage(state.api+'p/'+state.master+'&',perPage,page)
      .then(response => {        
        let docs = [];
        
        response.data.docs.forEach(
          element => {
            if(element.photos){
              let photos = [];

              element.photos.forEach(
                photo => {
                  if(element.photos){
                    photo = CRUDServices.getImage(photo);
                  }
                  photos.push(photo);
                }
              );
              element.photos = photos;
            }
            docs.push(element);
          }
        );

        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA', docs);
        commit('SET_PAGES',response.data.totalPages);
        commit('SET_TOTAL',response.data.totalDocs);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching  ' + catalogName + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchDataTrailers({state,commit,dispatch},{perPage,page}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocsPage(state.api+'p/trailer/'+state.master+'&',perPage,page)
      .then(response => {        
        let docs = [];
        
        response.data.docs.forEach(
          element => {
            if(element.photos){
              let photos = [];

              element.photos.forEach(
                photo => {
                  if(element.photos){
                    photo = CRUDServices.getImage(photo);
                  }
                  photos.push(photo);
                }
              );
              element.photos = photos;
            }
            docs.push(element);
          }
        );

        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA', docs);
        commit('SET_PAGES',response.data.totalPages);
        commit('SET_TOTAL',response.data.totalDocs);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching  ' + catalogName + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    createItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.postItem(state.api,item).then(response => {
        if(response.data.exito){
          commit('SET_NEW_OBJ',item);
          commit('SET_SAVED',response.data.obj);
          const notification = {
              type: 'success',
              message: catalogName + ' capturado correctamente'
          }
          dispatch('notificationModule/add', notification, {root: true});
        }else{
          const notification = {
            type: 'error',
            message: 'sin Exito' + catalogName
          }
          dispatch('notificationModule/add', notification, {root: true});
        }
        commit('SET_LOADING_STATUS',false);
      }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem creating ' + catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    createTrailerItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.postItem(state.api+"trailer",item).then(response => {
        if(response.data.exito){
          commit('SET_NEW_OBJ',item);
          commit('SET_SAVED',response.data.obj);
          const notification = {
              type: 'success',
              message: 'capturado correctamente'
          }
          dispatch('notificationModule/add', notification, {root: true});
        }else{
          const notification = {
            type: 'error',
            message: 'sin Exito' + catalogName
          }
          dispatch('notificationModule/add', notification, {root: true});
        }
        commit('SET_LOADING_STATUS',false);
      }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem creating ' + catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    async fetchItem({state,commit,dispatch},id){
      commit('SET_LOADING_STATUS',true);
        await CRUDServices.getItem(state.api,id)
        .then(response => {
          let item = response.data;
          let photos = [];
          item.photos.forEach(
            photo => {
              photo = CRUDServices.getImage(photo);
              photos.push(photo)
            }
          );
          item.photos = photos;
          if(item.youtubeVideo){
            if(item.youtubeVideo.length > 0) {
              item.youtubeVideo = item.youtubeVideo[0].split(',');
            }
          }
          let ranchPhoto = item.salesman.ranch.image;
          item.salesman.ranch.image = CRUDServices.getImage(ranchPhoto);
          let paymentComprobant = item.paymentComprobant;
          item.paymentComprobant = CRUDServices.getImage(paymentComprobant);
          commit('SET_SINGLE',item);
          EventBus.$emit('horse-fetch-item');
          commit('SET_LOADING_STATUS',false);
        }).catch(error =>{
          commit('SET_LOADING_STATUS',false);
            const notification = {
                type: 'error',
                message: 'There was a problem ferching '+ catalogName + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
    },
    deleteItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.deleteItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message:  catalogName + ' eliminada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem deleting '+ catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message:  catalogName + ' actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating '+ catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateMomento({state,commit,dispatch},newMomento){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateMomento(state.api+'m/',state.singleItem._id,newMomento).then(() => {
        commit('SET_LOADING_STATUS',false);
        commit('SET_MOMENTO',newMomento);
        const notification = {
            type: 'success',
            message:  catalogName + ' actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating '+ catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateEnroll({state,commit,dispatch},status){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateMomento(state.api+'enroll/',state.singleItem._id,status).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message:  catalogName + ' actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating '+ catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateSingleImage({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateSingleImage(state.api+'file/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: catalogName + ' actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updatePaymentImage({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateSingleImage(state.api+'payment/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Pago actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateRemoveImage({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateRemoveImage(state.api+'removeFile/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: catalogName + ' actualizado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating salesman' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
}

export const getters = {
    getData(state){
      return state.data;
    },
    getDataByID: state => id => {
      return state.data.find(item => item._id === id);
    },
    dataLength: state =>{
      return state.total
    },
  }