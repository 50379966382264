<template>
  <v-dialog max-width="80%" :value="imageDialog" max-height="70%" @click:outside="closePreview" style="z-index:1000000;">
    <v-card class="mx-auto justify-center aling-center">
      <v-card-title>
        <span class="headline">Preview</span>
      </v-card-title>
      <v-card-text class="mx-auto justify-center text-center">
        <v-img
          contain
          class="ma-auto"
          max-width="80%"
          max-height="600px"
          :src="imgDialog"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="blue darken-1"
          text
          dark
          @click="closePreview"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    imgDialog: String,
  },
  data() {
    return {
      imageDialog: true,
    };
  },
  methods: {
    closePreview() {
      this.$emit("closePreview");
    },
  },
};
</script>

<style>
</style>