<template>
  <div>
    <youtubeVisor :video="video.video" :small="true" />
    <h3>{{video.title}}</h3>
    <p> {{video.description}} </p>
  </div>
</template>

<script>
import youtubeVisor from '@/components/adminSide/youtubeVisor';

export default {
  components:{
    youtubeVisor
  },
  props: {
    video: Object
  }

}
</script>

<style>

</style>