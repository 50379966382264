import CRUDServices from '@/services/CRUDServices';
import EventBus from '../../services/events';

export const namespaced = true;

let catalogName = 'HDH';

export const state = {
    loadingStatus: false,
    data: [],
    singleItem: {},
    pages: 0,
    total: 0,
    api: '/hdh/',
    master: {},
    justSaved: '',
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_DATA(state, data){
      state.data = data;
    },
    SET_NEW_OBJ(state, item){
      state.data.push(item)
    },
    SET_PAGES(state, pages){
      state.pages = pages
    },
    SET_TOTAL(state, total){
      state.total = total
    },
    SET_SINGLE(state, item){
      state.singleItem = item
    },
    SET_MASTER(state, item){
      state.master = item
    },
    SET_SAVED(state, id){
      state.justSaved = id
    }
}

export const  actions = {
    setMaster({commit},master){
      commit('SET_MASTER',master);
    },
    fetchAll({commit,dispatch}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocs(state.api)
      .then(response => {       
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching' + catalogName + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchData({state,commit,dispatch},{perPage,page}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocsPage(state.api+'h/'+state.master+'&',perPage,page)
      .then(response => {        
        let docs = [];
        response.data.docs.forEach(
          element => {
            if(element.photos){
              let photos = [];

              element.photos.forEach(
                photo => {
                  if(element.photos){
                    photo = CRUDServices.getImage(photo);
                  }
                  photos.push(photo);
                }
              );
              element.photos = photos;
            }
            docs.push(element);
          }
        );

        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA', docs);
        commit('SET_PAGES',response.data.totalPages);
        commit('SET_TOTAL',response.data.totalDocs);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching  ' + catalogName + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    async fetchByMaster({state,commit,dispatch},master){
      if(typeof master === 'undefined' ){
        return
      }
      await CRUDServices.getDocsByMaster(state.api+'h/', master)
      .then(response => {
        let data = [];
        data = response.data;
        if(data.length > 0){
          if(data[0].file){
            data[0].file = CRUDServices.getImage(data[0].file);
          }        
          commit('SET_DATA',data);
          EventBus.$emit("event-hdh-set",data[0]);
        }
        else{
          commit('SET_DATA',data);
        }
        EventBus.$emit("event-hdh-set");
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching '+ catalogName + error.message
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    createItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.postItem(state.api,item).then(() => {
        // commit('SET_NEW_OBJ',item);
        // commit('SET_SAVED',response.data.obj);
        const notification = {
            type: 'success',
            message: catalogName + ' capturado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
        commit('SET_LOADING_STATUS',false);
      }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem creating ' + catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchItem({state,commit,dispatch,getters},id){
      var item = getters.getDataByID(id);

      if (item){
        commit('SET_SINGLE',item)
      }else{
        CRUDServices.getItem(state.api,id)
        .then(response => {
          commit('SET_SINGLE',response.data);
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'There was a problem ferching '+ catalogName + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
      }
    },
    deleteItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.deleteItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message:  catalogName + ' eliminada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem deleting '+ catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message:  catalogName + ' actualizada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating '+ catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateFile({state,commit,dispatch},item) {
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api+'file/',item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message:  catalogName + ' actualizada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating '+ catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    createItemExpress({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.postItem(state.api+'express/',item).then(response => {
        commit('SET_NEW_OBJ',item);
        commit('SET_SAVED',response.data.obj);
        const notification = {
            type: 'success',
            message: catalogName + ' capturado correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
        commit('SET_LOADING_STATUS',false);
      }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem creating ' + catalogName + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
}

export const getters = {
    getData(state){
      return state.data;
    },
    getDataByID: state => id => {
      return state.data.find(item => item._id === id);
    },
    dataLength: state =>{
      return state.total
    },
  }