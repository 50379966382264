<template>
  <v-sheet
    class="dialog-footer mx-auto justify-center aling-end gray float-end pa-2"
    elevation="9"
  >
    <v-row class="ma-auto justify-center aling-center">
      <v-col cols="12" md="3" class="ma-auto justify-center aling-center pa-0">
        <h3>
          Total <strong>{{ dataLength || 0 }}</strong>
        </h3>
      </v-col>
      <v-col cols="12" md="9" class="ma-auto justify-center aling-center pa-0">
        <v-pagination
          v-if="pages > 1"
          v-model="page"
          :length="pages"
          :total-visible="5"
          @input="next"
          dark
          color="black"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      page: this.CurrentPage,
      itemsPerPage: 10,
    };
  },
  props: {
    dataLength: Number,
    pages: Number,
    CurrentPage: Number,
  },
  methods: {
    next() {
      this.$emit("next", {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
      });
    },
  },
};
</script>

<style lang="scss">
.paginador {
  z-index: 10000;
}
</style>
