<template>
  <iframe
    :src="urlToRender"
    frameborder="0"
    height="600px"
    width="100%"
    
  ></iframe>
</template>

<script>
import sha256 from "crypto-js/sha256";

export default {
  data() {
    return {
      sandbox: true,
      realPay: false,
      encoding: "utf-8",      
      version: "4.0.0",
      src_demo:
        "https://ppp-test.safecharge.com/ppp/purchase.do?currency=EUR&item_name_1=Test+Product&item_number_1=1&item_quantity_1=1&item_amount_1=50.00&numberofitems=1&encoding=utf-8&merchant_id=640817950595693192&merchant_site_id=148133&time_stamp=2018-05-15.02:35:21&version=4.0.0&user_token_id=ran100418_scobd%40mailinator.com&user_token=auto&total_amount=50.00&notify_url=https://sandbox.safecharge.com/lib/demo_process_request/response.php&theme_id=178113&checksum=66ce9f4ce1e5f47298e7e5e457d0b21ca8d6a668d549240929924054db6d1a21",
      notify_url: "https://ehorsesale.herokuapp.com/transaction/",
      user_token: "auto",      
      success_url: window.location.href + "/success",
      error_url: window.location.href + "/fail",
      payment_method:"cc_card",
      item_open_amount_1:false,
      item_min_amount_1:1,
      item_max_amount_1:100,
      item_discount_1:0,
      total_tax:0,
      discount:0,
      isNative:1
    };
  },
  props: {
    currency: String,
    itemName: String,
    itemNumber: Number,
    itemQuantity: Number,
    itemAmount: String,
    numberOfItems: Number,
    userTokenId: String,
    totalAmount: String,
    customField1: String,
    first_name: String,
    last_name: String,
    address1: String,
    city: String,
    zip: String,
    country:String,
    phone1:String,
    email:String,
  },
  methods: {
    goToPAyment() {
      window.location.href = this.urlToRender;
    },
  },
  computed: {
    secret_key(){
      return this.realPay
         ? process.env.VUE_APP_SECRET_KEY_PROD
         : process.env.VUE_APP_SECRET_KEY
    },
    merchant_site_id(){
      return this.realPay
        ? process.env.VUE_APP_MERCHANT_SITE_ID_PROD
        : process.env.VUE_APP_MERCHANT_SITE_ID
    },
    merchant_id(){
      return this.realPay
        ? process.env.VUE_APP_MERCHANT_ID_PROD
        : process.env.VUE_APP_MERCHANT_ID;
    },
    urlToRender() {
      let query = `?merchant_id=${this.merchant_id}&merchant_site_id=${this.merchant_site_id}&user_token=${this.user_token}&user_token_id=${this.userTokenId}&userid=${this.userTokenId}&item_open_amount_1=${this.item_open_amount_1}&item_min_amount_1=${this.item_min_amount_1}&item_max_amount_1=${this.item_max_amount_1}&item_name_1=${this.itemName}&item_amount_1=${this.itemAmount}&item_quantity_1=${this.itemQuantity}&item_discount_1=${this.item_discount_1}&numberofitems=${this.numberOfItems}&total_tax=${this.total_tax}&discount=${this.discount}&total_amount=${this.totalAmount}&currency=${this.currency}&version=${this.version}&encoding=${this.encoding}&first_name=${this.first_name}&last_name=${this.last_name}&address1=${this.address1}&city=${this.city}&zip=${this.zip}&country=${this.country}&phone1=${this.phone1}&email=${this.email}&time_stamp=${this.time_stamp}&notify_url=${this.notify_url}&customField1=${this.customField1}&payment_method=${this.payment_method}&theme_id=${this.theme_id}&isNative=${this.isNative}&checksum=${this.checksum}`;
      if(this.realPay){
        return this.url + "" + query;
      } else {
        return this.sandbox ? this.url + "" + query : this.url;
      }
    },
    url() {
      if (this.realPay)
        return "https://secure.safecharge.com/ppp/purchase.do"
      else
        return this.sandbox
          ? "https://ppp-test.safecharge.com/ppp/purchase.do"
          : this.src_demo;
    },
    time_stamp() {
      return "2021-11-24.02:35:21";
    },
    checksum() {
      let cadena = `${this.merchant_id}${this.merchant_site_id}${this.user_token}${this.userTokenId}${this.userTokenId}${this.item_open_amount_1}${this.item_min_amount_1}${this.item_max_amount_1}${this.itemName}${this.itemAmount}${this.itemQuantity}${this.item_discount_1}${this.numberOfItems}${this.total_tax}${this.discount}${this.totalAmount}${this.currency}${this.version}${this.encoding}${this.first_name}${this.last_name}${this.address1}${this.city}${this.zip}${this.country}${this.phone1}${this.email}${this.time_stamp}${this.notify_url}${this.customField1}${this.payment_method}${this.theme_id}${this.isNative}`;
      let fullcadena = this.secret_key + "" + cadena;
      return sha256(fullcadena).toString(); //"66ce9f4ce1e5f47298e7e5e457d0b21ca8d6a668d549240929924054db6d1a21"
    },
    theme_id() {
      let value;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
          value = this.realPay ? 203098 : 356468;
          break;
        case "lg":
        case "xl":
          value = this.realPay ? 203088 : 178113;
          break;
      }
      return value;
    },
  },
};
</script>

<style></style>
