<template>
    <div>
        <AdminHeader color="black" icon="truck-trailer" title="Información general del remolque"/>
        <ImagePreview v-if="imageDialog" :imgDialog="imgDialog" @closePreview="closePreview"/>
        <Loader v-if="idLoading" :size="64" />
        <v-row v-else>
            <v-col md="2" cols="12">
                <v-sheet rounded="lg">
                    <v-list color="transparent">
                        <v-list-item
                            v-for="section in sections.filter(sec => sec.ver < 4)"
                            :key="section.ver"
                            link
                            @click="activateSection(section.ver)"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-{{section.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                            {{section.name}}
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider class="my-2"></v-divider>
                        <v-list-item
                            link
                            color="grey lighten-4"
                            @click="goBack()"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                            >                            
                                Regresar
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-sheet>
            </v-col>
            <v-col md="10" sm="12">
                <v-card
                    v-if="activeSection === 0" 
                    elevation="5"
                    height="100%"
                >
                    <v-card-title>
                        <h6>
                            <v-icon class="mr-1">mdi-{{sections[activeSection].icon}}</v-icon>
                            {{sections[activeSection].name}}
                        </h6>
                        <v-spacer></v-spacer>
                    </v-card-title>  
                    <v-card-text>
                        <HorseDetailGeneral :item="item" @showImage="showImage"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
            <v-card>
                <v-card-title>
                    Observaciones de auditoría
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="3"
                        v-for="step in horseAuditData"
                                :key="step._id">
                            <v-alert
                                outlined
                                :type="step.status ? 'info':'warning'"
                                prominent
                                border="left"
                            >
                                <h3>Sección: {{sections[step.step].name}}</h3>
                                <h6>{{step.dateObs | date}}</h6>    
                                <span>{{step.comment}}</span>
                                <br/>
                                <v-btn @click="resolveOb(step)" v-if="!step.status" color="primary" x-small>resolver</v-btn>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import EventBus from '../../services/events';
import Loader from '@/components/adminSide/Loader';
import AdminHeader from '@/components/adminSide/AdminHeader';
import ImagePreview from '@/components/adminSide/imagePreview';
import HorseDetailGeneral from "@/components/adminSide/HorseDetailGeneral";

export default {
    data(){
        return {
            sections:[
                {name:'General',ver: 0,icon:"file-document-outline"},
            ],
            activeSection: 0,
            imgDialog: '',
            imageDialog: false,
        }    
    },
    mounted(){
        this.init();
        EventBus.$on("horse-fetch-item", () => {
            this.fetchDetails(); 
        });
    },
    methods:{
        async resolveOb(step){
            step.status = true
            await this.$store.dispatch('horseAuditModule/updateItem',step).then(() => {                                        
                this.$store.dispatch('horseAuditModule/fetchByMaster',this.$route.params.horse);    
            });
        },
        init(){
            this.$store.dispatch('horsesModule/fetchItem',this.$route.params.horse).then(()=>{
                this.fetchDetails(); 
            });
        },
        fetchDetails(){
            this.$store.dispatch('hdhModule/fetchByMaster',this.$route.params.horse);
            this.$store.dispatch('hdpModule/fetchByMaster',this.$route.params.horse);
            this.$store.dispatch('horseAuditModule/fetchByMaster',this.$route.params.horse);
        },
        activateSection(id){
            this.activeSection = id;
        },
        goBack(){
            this.$router.go(-1);
        },
        showImage(photo){
            this.imgDialog = photo;
            this.imageDialog = true;
        },
        closePreview(){
            this.imgDialog = '';
            this.imageDialog = false;
        },
        goToedit(){            
            this.$router.replace({name: "UpdateHorse", params: { horse: this.$route.params.horse }});	
        }
    },
    computed:{
        ...mapState({
                user: state => state.user,
                loadingHorse: state => state.horsesModule.loadingStatus,
                // loadinghdt: state => state.hdtModule.loadingStatus,
                loadinghdh: state => state.hdhModule.loadingStatus,
                loadinghdp: state => state.hdpModule.loadingStatus,
                item: state => state.horsesModule.singleItem,
                // hdtData: state => state.hdtModule.data,
                hdhData: state => state.hdhModule.data,
                hdpData: state => state.hdpModule.data,
                horseAuditData: state => state.horseAuditModule.data,
        }),
        idLoading(){
            return this.loadingHorse && this.loadinghdh && this.loadinghdp;
        },
    },
    components:{
        AdminHeader,
        Loader,
        ImagePreview,
        HorseDetailGeneral
    }
}
</script>

<style>

</style>