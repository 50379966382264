<template>
  <v-container
    class="py-8 px-6"
    fluid
  >
    <v-row justify="end">
      <v-col cols="1">
        <v-img 
          src="https://www.solidemx.com/site/assets/img/solide/contralogo_xs.png" 
          alt="logo solide" 
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(card, indexCard) in content" 
        :key="indexCard"
        cols="12"
      >
        <v-card>
          <v-subheader>{{ card.cardTitle }}</v-subheader>
          <v-expansion-panels v-if="card.versiones">
            <v-expansion-panel v-for="(version,index) in card.versiones" :key="index">
              <v-expansion-panel-header>
                <strong> {{ version.version }} </strong> {{ version.title }}  
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <h5>Admin</h5>
                    <ul>
                      <li v-for="(descripcion,index) in version.changes.admin" :key="index">
                          <span>{{descripcion}}</span>
                      </li>
                    </ul>
                  </v-col>
                  <v-col>
                    <h5>Admin</h5>
                    <ul>
                      <li v-for="(descripcion,index) in version.changes.sales" :key="index">
                          <span>{{descripcion}}</span>
                      </li>
                    </ul>
                  </v-col>
                  <v-col>
                    <h5>Admin</h5>
                    <ul>
                      <li v-for="(descripcion,index) in version.changes.buyer" :key="index">
                          <span>{{descripcion}}</span>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import versionData from "../config/versiones.json";

  export default {
    mounted(){
      window.scrollTo(0,0);
      this.content = versionData;
    },
    data: () => ({
      content: []
    }),
  }
</script>