<template>
    <div>
        <AdminHeader color="black" icon="format-list-bulleted-triangle" title="Mensajes"/>
        <Loader v-if="loading" :size="64" />
        <Paginador :dataLength="dataLength" :pages="pages" :CurrentPage="page" @next="next"/>
        <v-card
            elevation="5"
            height="100%"
            class="mb-10"
        >
            <v-card-title>
                <h6>Mensajes</h6>
                <v-spacer></v-spacer>
            </v-card-title>   
            <v-card-text v-if="docs.length > 0">
                <v-simple-table>
                    <template>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    email
                                </th>
                                <th class="text-left">
                                    Tema
                                </th>
                                <th class="text-left">
                                    Mensaje
                                </th>
                                <th class="text-left">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in docs"
                                :key="item._id"
                            >
                                <td>{{ item.email }}</td>
                                <td>{{ item.topic }}</td>
                                <td>{{ item.message }}</td>
                                <td> 
                                    <v-btn class="primary" @click="update(item)" v-if="!item.seen">
                                        <v-icon color="white">mdi-check</v-icon>
                                    </v-btn>
                                    <v-btn class="white" disabled v-else>
                                        <v-icon color="gray">mdi-check-all</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-row dense class="mx-auto justify-center aling-center" v-else>
                <EmptyState :white="true"/>
            </v-row>   
        </v-card>
    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import EmptyState from '@/components/EmptyState';
    import Loader from '@/components/adminSide/Loader';
    import AdminHeader from '@/components/adminSide/AdminHeader';
    import Paginador from '@/components/adminSide/Paginador';
    
    export default {
        data(){
            return{
                itemsPerPage: 10,
                valid:false,
                nameRules: [
                    v => !!v || 'Nombre Grupo requerido',
                    v => v.length < 100 || 'Nombre debe ser menor a 100 caracteres',
                ],
                dialog: false,
                confirm: false,
                newOne: this.createFreshObj(),
                show1: false,
                page: 1,
                eventCrud: 0 // 0 for new, 1 for edit, 2 for delete                
            }
        },
        components:{
            EmptyState,
            AdminHeader,
            Loader,
            Paginador
        },
        created(){
            this.init();
        },
        methods:{
            create(){
                this.createFreshObj();
                this.dialog = true;
                this.eventCrud = 0;
            },
            createFreshObj(){
                // const user = this.$store.state.user;
                return {
                    name: '',
                    status: true
                }
            },
            init(){
                this.$store.dispatch('messagesModule/fetchData');
                this.newOne.name = "";
            },
            next(param){
                this.page = param.page;
                this.$store.dispatch('messagesModule/fetchData');
            },
            update(item){
                this.$store.dispatch('messagesModule/updateItem',item).then(() => {
                    this.$store.dispatch('messagesModule/fetchData');
                })
            },
        },
        computed: {
            ...mapGetters('messagesModule',['dataLength']),            
            ...mapState({
                user: state => state.user,
                loading: state => state.messagesModule.loadingStatus,
                pages: state => state.messagesModule.pages,
                docs: state => state.messagesModule.data,
                item: state => state.messagesModule.singleItem
            })
        }
    }
</script>