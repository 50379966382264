<template>
  <v-container>
    <Loader v-if="loading" :size="64" />
    <v-row v-if="item">
      <v-col cols="12" md="3">
        <v-sheet rounded="lg">
          <v-list color="transparent">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="justify-center aling-center text-center"
                >
                  <h1>{{ item.credits }}</h1>
                  <p>
                    {{item.credits === 1 ? 'inscripción disponible' : 'Inscripciones disponibles'}}</p>
                  <br />
                  <v-btn color="primary" @click="comprar()">
                    <v-icon left> mdi-upload </v-icon>
                    Comprar
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col>
        <v-card>
          <v-tabs color="primary" right>
            <v-tab>Movimientos actuales</v-tab>
            <v-tab-item>
              <v-card-subtitle>Últimos movimientos:</v-card-subtitle>
              <v-card-text class="py-0">
                <v-row>
                  <v-col cols="6">
                    <v-timeline align-top dense>
                      <v-timeline-item
                        color="green"
                        small
                        v-for="one in item.details"
                        :key="one.buyDate"
                      >
                        Adquiridos el día: {{ one.buyDate | date }}

                        <v-row class="pt-1">
                          <v-col>
                            <strong>Inscripciones {{ one.credits }}</strong>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                  <v-col cols="6">
                    <v-timeline align-top dense>
                      <v-timeline-item
                        color="red"
                        small
                        v-for="one in item.movs"
                        :key="one.usedOn"
                      >
                        Utilizados el día: {{ one.usedOn | date }}

                        <v-row class="pt-1">
                          <v-col>
                            <strong v-if="one.horse">{{
                              one.horse.name
                            }}</strong>
                            <strong v-else>Sin identificar</strong>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="mx-auto justify-center aling-center ma-16 mt-sm-5">
        <v-card
          width="400px"
          class="mx-auto justify-center aling-center ma-16 mt-sm-5"
        >
          <v-card-text>
            <p class="text-h6 text--primary text-center">
              No cuentas con inscripciones disponibles
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mx-auto justify-center aling-center ma-16 mt-sm-5"
              @click="comprar()"
              color="amber"
            >
              <v-icon left> mdi-package-variant </v-icon>
              Comprar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="1500px" style="z-index:1000000;">
      <v-card v-if="step === 1">
        <v-card-title>
          <span class="headline">Paquetes</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <h3>Selecciona un paquete</h3>
            <v-row justify="center">
              <v-col
                v-for="membership in membershipsData"
                :key="membership._id"
                cols="6"
                md="4"
                lg="3"
              >
                <v-card
                  class="mx-auto justify-center aling-center"
                  max-width="344"
                >
                  <v-card-title>
                    <v-icon left color="red" v-if="membership.recommended">
                      mdi-fire
                    </v-icon>
                    {{ membership.name }}
                  </v-card-title>

                  <v-card-subtitle>
                    {{ membership.creditos }} Inscripciones
                    {{ membership.precio | money }}
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-btn
                      color="blue darken-1"
                      text
                      dark
                      @click="nextStep(membership)"
                    >
                      Elegir
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-chip class="ma-2" color="primary">
            <v-icon left> mdi-fire </v-icon>
            Recomendado
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialog = false"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="step === 2">
        <v-card-title>
          <span class="headline">Procesemos tu pago</span>
        </v-card-title>
        <v-card-text>
          <h3 class="mx-auto d-none d-sm-flex">Selecciona un paquete</h3>
          <v-row>
            <v-col cols="12" md="3" >
              <v-card class="mx-auto" max-width="344" >
                <v-card-title class="text-caption">
                  {{ selectedMembership.creditos }} {{ selectedMembership.creditos > 1 ? 'Inscripiones' : 'Inscripión' }} : {{ selectedMembership.precio | money }}
                </v-card-title>
                <v-card-actions>
                  <v-btn color="Red" text @click="backStep()">
                    Descartar
                  </v-btn>
                </v-card-actions>
              </v-card>
              <div class="mt-10 d-flex justify-end">
                <v-btn text :disabled="canContinue" @click="dialog = false; backStep()">
                  Cancelar
                </v-btn>
                <v-btn color="primary" text @click="process()" :disabled="!canContinue">
                  Finalizar
                </v-btn>
              </div>
            </v-col>

            <v-col md="9" cols="12">
              <div
                v-if="initialState"
                style="max-height: 800px; overflow: scroll"
              >
                <payment-process
                  currency="MXN"
                  itemName="inscripcion"
                  :itemNumber="1"
                  :itemQuantity="1"
                  :itemAmount="'' + selectedMembership.precio"
                  :numberOfItems="1"
                  :userTokenId="this.$session.get('salesman')"
                  :totalAmount="'' + selectedMembership.precio"
                  :first_name="this.currentSalesman.name"
                  :last_name="this.currentSalesman.last_name"
                  :address1="this.currentSalesman.address1"
                  :city="this.currentSalesman.city"
                  :zip="this.currentSalesman.zip"
                  :country="this.currentSalesman.country"
                  :phone1="this.currentSalesman.phone1"
                  :email="this.currentSalesman.email"
                  customField1="credit_buy"
                />
              </div>
              <div v-else>
                <div v-if="canContinue">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <img
                        class="mx-auto justify-center aling-center"
                        height="200px"
                        width="200px"
                        src="/images/pay/successPay.jpeg"
                        alt="payment"
                      />
                    </v-col>
                    <v-col cols="12" class="text-center text-h4">
                      <span class="headline"
                        >Pago procesado correctamente</span
                      >
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <img
                        class="mx-auto justify-center aling-center"
                        height="200px"
                        width="200px"
                        src="/images/pay/failPay.jpeg"
                        alt="payment"
                      />
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <span class="headline">Existe un problema con tu pago {{ response }}</span>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import cardForm from '@/components/CardForm.vue'
import PaymentProcess from "@/components/adminSide/PaymentProcess";
import EventBus from "../../services/events";
import Loader from "@/components/adminSide/Loader";

import { mapState } from "vuex";

export default {
  data: () => ({
    dialog: false,
    selectedMembership: {},
    step: 1,
    newOne: {},
    current: {
      active: false,
    },
    show1: false,
    canContinue: false,
    initialState: true,
    response: "",
    currentSalesman: {
      name: "Samuel",
      last_name: "Herrera",
      address1: "Street1",
      city: "Chihuahua",
      zip: "31150",
      country: "MX",
      phone1: "6141763011",
      email: "hsamuel.alberto@gmail.com",
    },
  }),
  methods: {
    comprar() {
      this.dialog = true;
      this.initialState = true;
      this.canContinue = false;
    },
    nextStep(membership) {
      this.selectedMembership = membership;
      if (membership.precio === 0) this.process();
      else this.step = 2;
      this.currentSalesman.email = this.salesman.mail
      this.currentSalesman.phone1 = this.salesman.phone
      this.currentSalesman.address1 = this.salesman.address
      this.currentSalesman.name = this.salesman.name
      this.currentSalesman.last_name = this.salesman.name
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    async process() {
      // TOKENIZAR TARJETA
      // CREAR TOKEN DE USUARIO
      // TODO CREAR ORDEN
      this.newOne = {
        salesman: this.$session.get("salesman"),
        credits: this.selectedMembership.creditos,
        buyDate: Date.now(),
        spoilerDate: this.addDays(Date.now(), 365),
        active: true,
      };

      await this.$store.dispatch("creditsModule/createItem", this.newOne);
      await this.$store.dispatch("salesmenModule/updateCredits", this.newOne.salesman);
      await this.$store.dispatch(
        "creditsModule/fetchItem",
        this.$session.get("salesman")
      );

      this.dialog = false;
      this.backStep();
    },
    backStep() {
      this.selectedMembership = {};
      this.step = 1;
    },
  },
  components: {
    PaymentProcess,
    Loader,
  },
  async beforeCreate() {
    await this.$store.dispatch(
      "creditsModule/fetchItem",
      this.$session.get("salesman")
    );
    await this.$store.dispatch(
      "membershipsModule/fetchAll",
      this.$session.get("salesman")
    );
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.creditsModule.loadingStatus,
      item: (state) => state.creditsModule.singleItem,
      membershipsData: (state) => state.membershipsModule.data,
      data: (state) => state.creditsModule.data,
      salesman: (state) => state.salesmenModule.singleItem
    }),
  },
  mounted() {
    EventBus.$on("set-single-credits", () => {
      this.current = this.item;
    });
  },
  created() {
    this.$socket.$subscribe("salesman_buy_credit", (payload) => {
      if (this.$session.get("salesman") === payload.userToken) {
        this.initialState = false;
        if (payload.status === "APPROVED" || payload.ppp_status === "OK") {
          this.canContinue = true;
        } else {
          this.response = payload.reason;
        }
      }
    });
  },
};
</script>

<style lang="css" scoped>
.iframe-payments {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
</style>