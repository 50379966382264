<template>
  <div>
    <AdminHeader color="black" icon="bell" title="Centro de notificaciones" />
    <Loader v-if="loading" :size="64" />

    <v-card>
      <v-card-title>
        Bandeja de entrada
        <v-spacer></v-spacer>
        <v-btn dark small @click="init()">
          <v-icon>mdi-refresh</v-icon>
          Actualizar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in docs" :key="i">
            <v-expansion-panel-header class="text-left">
              <span>
                {{ item.horse.name }}
              </span>
              <v-spacer />
              <span>
                {{ getStatusTicket(item.status) }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="4">
                  <h5>información caballo</h5>
                  <div>
                    <h3>Nombre: {{ item.horse.name }}</h3>
                    <h3 class="my-3" v-if="item.shortDescription">
                      - {{ item.shortDescription }}
                    </h3>
                    <h3 class="my-3" v-if="item.description">
                      - {{ item.description }}
                    </h3>
                  </div>
                  <h3>Status: {{ getStatusHorse(item.horse.momento) }}</h3>
                  <span>
                    <v-icon small class="mr-3"
                      >mdi-credit-card-settings-outline</v-icon
                    >
                    <strong>Ofrece Compra directa:: </strong>
                    <span class="ml-3">{{
                      item.horse.offerBuyNow | yesno
                    }}</span> </span
                  ><br />
                  <h3>Información:</h3>
                  <span>
                    <v-icon small class="mr-3">mdi-calendar</v-icon>
                    <strong>Fecha de nacimiento: </strong>
                    <span class="ml-3"></span
                    >{{ item.horse.dateOfBirth | date }} </span
                  ><br />
                  <span v-if="item.size">
                    <v-icon small class="mr-3">mdi-tape-measure</v-icon>
                    <strong>Alzada: </strong>
                    <span class="ml-3">{{ item.horse.size }} cm</span>
                  </span>
                  <span>
                    <v-icon small class="mxr-3"
                      >mdi-registered-trademark</v-icon
                    >
                    <strong>Registro: </strong>
                    <span class="ml-3">{{ item.papers | yesno }}</span>
                    <span class="ml-3" v-if="item.regist">
                      [
                      {{
                        item.horse.regist + " ante " + item.horse.registOrg
                      }}
                      ]</span
                    >
                  </span>
                  <v-sheet class="ma-5" color="amber">
                    <h4 class="text-center">
                      <v-icon>mdi-currency-usd</v-icon>
                      Compra directa: {{ item.horse.buyNow | money }} MXN
                    </h4>
                  </v-sheet>
                </v-col>
                <v-col cols="6" md="4">
                  <h5>información Vendedor</h5>
                  <h1>
                    {{
                      item.salesman.name +
                      " " +
                      item.salesman.lastnamep +
                      " " +
                      item.salesman.lastnamep
                    }}
                  </h1>
                  <h3>{{ item.salesman.mail }}</h3>
                </v-col>
                <v-col cols="6" md="4" v-if="item.assigned">
                  <h5>información caballo</h5>
                  <h1>{{ item.assigned.user }}</h1>
                  <h3>{{ item.salesman.mail }}</h3>
                  <span>enviado: {{ item.updateDate | date }}</span>
                </v-col>
              </v-row>
              <v-row class="ma-auto">
                <v-col class="ma-auto justify-end text-right">
                  <v-btn
                    small
                    class="ma-2 amber"
                    @click="goToDetails(item.horse._id)"
                  >
                    Ir a auditoria
                  </v-btn>
                  <v-btn
                    small
                    class="ma-2 black"
                    dark
                    @click="assigAdmin(item._id)"
                  >
                    Asignarme
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AdminHeader from "@/components/adminSide/AdminHeader";
import Loader from "@/components/adminSide/Loader";

import { mapState } from "vuex";

export default {
  components: {
    AdminHeader,
    Loader,
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch("adminNotificationsModule/fetchAll");
    },
    assigAdmin(id) {
      let updateData = {
        _id: id,
        updateDate: new Date(),
        status: 1,
      };
      this.$store.dispatch("adminNotificationsModule/updateItem", updateData);
      this.$store.dispatch("adminNotificationsModule/fetchAll");
    },
    async goToDetails(horse) {
      this.$store.dispatch("horsesModule/fetchItem", horse).then(async () => {
        await this.$store.dispatch("horsesModule/updateMomento", 3);
        this.$router.push({
          name: "HorseDetailAdmin",
          params: { horse: horse },
        });
      });
    },
    getStatusHorse(momento) {
      let estatusLabel = "";
      switch (momento) {
        case 1:
          estatusLabel = "Captura";
          break;
        case 2:
          estatusLabel = "Listo para auditoria";
          break;
        case 3:
          estatusLabel = "En auditoria";
          break;
        case 4:
          estatusLabel = "Validado con Observaciones";
          break;
        case 5:
          estatusLabel = "Autorizado";
          break;
        case 6:
          estatusLabel = "Listo para evento";
          break;
        case 7:
          estatusLabel = "Asignado a evento";
          break;
        default:
          estatusLabel = "N/A";
          break;
      }
      return estatusLabel;
    },
    getStatusTicket(momento) {
      let estatusLabel = "";
      switch (momento) {
        case 0:
          estatusLabel = "Sin Asignar";
          break;
        case 1:
          estatusLabel = "Asignado";
          break;
        case 3:
          estatusLabel = "En auditoria";
          break;
        case 4:
          estatusLabel = "Validado con Observaciones";
          break;
        case 5:
          estatusLabel = "Validado";
          break;
        case 8:
          estatusLabel = "Observaciones Revisadas";
          break;
        default:
          estatusLabel = "N/A";        
          break;
      }
      return estatusLabel;
    },
  },
  computed: {
    ...mapState({
      docs: (state) => state.adminNotificationsModule.data,
      loading: (state) => state.adminNotificationsModule.loadingStatus,
    }),
  },
};
</script>

<style></style>
