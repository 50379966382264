<template>
  <div>
    <AdminHeader
      color="black"
      title="Captura de caballo para subasta"
      :backButton="true"
    />
    <Loader v-if="loading" :size="64" />
    <ImagePreview
      v-if="imageDialog"
      :imgDialog="imgDialog"
      @closePreview="closePreview"
    />
    <v-card class="mx-lg-15 mx-sm-5 px-lg-15">
      <v-card-title>Información general del caballo</v-card-title>
      <v-card-text>
        <v-form enctype="multipart/form-data" v-model="valid">
          <v-text-field
            class="my-3"
            v-model="newOne.name"
            :rules="nameRules"
            :counter="100"
            label="Nombre Caballo"
            required          
            hint="Requerido"
            persistent-hint
          ></v-text-field>
          <div
            id="preview"
            v-if="urls"
            class="mx-auto justify-center aling-center my-3 ma-3"
          >
            <v-row>
              <v-col
                cols="4"
                md="3"
                xl="2"
                v-for="(photo, index) in urls"
                :key="photo"
                class="mx-auto justify-start aling-center"
              >
                <v-btn
                  fab
                  x-small
                  absolute
                  @click="removeFromArray(index)"
                  small
                  class="red"
                  dark
                >
                  <v-icon dark>mdi-trash-can</v-icon>
                </v-btn>
                <v-img
                contain
                  aspect-ratio="1"
                  height="100%"
                  width="200px"
                  class="ma-2"
                  :src="photo"
                  @click="showImage(photo)"
                />
                <br />
              </v-col>
            </v-row>
          </div>
          <v-btn @click="initImageUpload()" small class="amber my-3" :disabled="urls.length > 9">
            {{ urls.length > 0 ? "Agregar más fotos " : "Cargar imagen" }}
          </v-btn>
          <v-alert color="orange" type="info" v-if="showPhotosWarning">
            Es requerida al menos una imagen</v-alert
          >
          <v-row class="my-3">
            <v-col cols="6" lg="6">
              <v-text-field
                v-model="newHDP.dad"
                :counter="50"
                label="Padre Caballo"
              ></v-text-field>
            </v-col>
            <v-col cols="6" lg="6">
              <v-text-field
                v-model="newHDP.mom"
                :counter="50"
                label="Madre Caballo"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-textarea
            class="my-3"
            v-model="newOne.description"
            :counter="300"
            label="Descripción general"
            required
          ></v-textarea>

          <v-row>
            <v-col cols="12" md="12">
              <v-checkbox
                v-model="newOne.nominated"
                label="Nominar a TRIPLE CORONA MILLONARIA - Hipódromo Ávalos CUU"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="my-5">
            <v-col cols="12" md="4">
              <span>Clasificación</span>
              <v-autocomplete
                v-model="groupSelect"
                :items="groupsData"
                item-text="name"
                item-value="_id"
                label="Grupo"
                persistent-hint
                return-object
                single-line
                @input="changeGroup(groupSelect._id)"
                hint="Requerido"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="showCategory">
              <span>Categoría (* requerido)</span>
              <v-autocomplete
                v-model="categorySelect"
                :items="categoriesData"
                item-text="name"
                item-value="_id"
                label="Categoría"
                hint="Requerido"
                persistent-hint
                return-object
                single-line
                @input="changeCategory(categorySelect._id)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="showSubCategory">
              <span>Subcategoría (* requerido)</span>
              <v-autocomplete
                v-model="subCategorySelect"
                :items="subCategoriesData"
                item-text="name"
                item-value="_id"
                label="SubCategoría"
                hint="Requerido"
                persistent-hint
                return-object
                single-line
                @input="changeSubCategory(subCategorySelect._id)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col md="6">
              <vuetify-money
                v-model="newOne.buyNow"
                v-bind:label="label"
                v-bind:readonly="readonly"
                v-bind:disabled="disabled"
                v-bind:clearable="clearable"
                v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                v-bind:options="options"
                v-bind:properties="properties"
                v-bind:persistent-hint="true"
              />
              <v-checkbox
                v-model="newOne.offerBuyNow"
                label="¿Ofrecer opción de comprar ahora?"
              ></v-checkbox>
            <v-alert color="amber black--text" type="info">
                Cuando seleccionas la opción de comprar ahora les ofreces a los usuarios interesados la posibilidad de evadir la subasta y comprar directamente en tu precio estimado.
                <br>
                <br>
                Si esta opción no es habilitada, el evento se terminará al culminar el tiempo programado, y el ganador será la última oferta realizada.
                </v-alert>
            </v-col>
            <v-col cols="12" md="6">
                <v-img
                    aspect-ratio="1.6"
                    height="100%"
                    width="800px"
                    class="ma-auto"
                    :src="'/images/preview.png'"
                    @click="showImage('/images/preview.png')"
                />
            </v-col>
          </v-row>
        

          <v-text-field
            class="my-5"
            v-model="toAdd"
            label="Video de Youtube"
          >
          </v-text-field>
          <v-btn
            class="mr-4 info"
            small
            @click="showImage('/images/ehorsesale/help/youtube.png')"
          >
            ¿Qué es esto?
          </v-btn>
          <v-btn
            class="mr-4"
            :disabled="toAdd === '' || newOne.youtubeVideo > 4"
            @click="addToVideos()"
            small
          >
            Agregar video
          </v-btn>
          <br />
          <br />
          <v-row>
            <v-col
              cols="3"
              v-for="item in newOne.youtubeVideo"
              :key="item.name"
            >
              <youtubeVisor :video="item" :small="true" />
              <v-chip
                class="ma-2"
                close
                small
                @click:close="removeFromVideos(item)"
              >
                {{ item }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row class="my-1">
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="newOne.papers"
                label="¿Cuenta con Registro?"
              ></v-checkbox>
            </v-col>
            <!-- <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="newOne.training"
                                label="¿Cuenta con entrenamiento?"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="newOne.awards"
                                label="¿Ganador de premios?"
                            ></v-checkbox>
                        </v-col> -->
          </v-row>
          <v-row v-if="newOne.papers" class="my-1">
            <v-col cols="12" sm="6">
              <v-file-input
                v-model="file"
                counter
                label="Documento de respaldo"
                accept="image/png, image/jpeg"
                show-size
                truncate-length="50"
                @change="changeFilePedigree()"
              ></v-file-input>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newOne.regist"
                :counter="30"
                label="Clave de registro"
                placeholder="Número de registro"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col>
              <h3>Certificado Médico</h3>
              <v-file-input
                v-model="fileCert"
                counter
                show-size
                @change="changeFileCert()"
                hint="Solo archivos .pdf"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" cols="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newOne.dateOfBirth"
                    label="Fecha de nacimiento"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="newOne.dateOfBirth"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="4" cols="6">
              <v-text-field
                v-model="newOne.size"
                type="number"
                suffix="cm"
                label="Alzada"
                hint="Tamaño del caballo"
              ></v-text-field>
              <v-btn
                class="info"
                small
                @click="showImage('/images/alzada.png')"
              >
                ¿Qué es esto?
              </v-btn>
            </v-col>
            <v-col md="4" cols="6">
              <v-select
                v-model="repStatusSelect"
                :items="repStatus"
                item-text="description"
                item-value="id"
                label="Sexo"
                persistent-hint
                return-object
                single-line
                @input="changeRepStatus(repStatusSelect.id)"
              ></v-select>
            </v-col>
          </v-row>
          <span>Ubicación del caballo</span>
          <v-row class="mb-5">
            <v-col cols="12" md="4">
              <span>País (* requerido)</span>
              <v-autocomplete
                v-model="countrySelect"
                :items="countriesData"
                item-text="name"
                item-value="_id"
                label="País"
                persistent-hint
                return-object
                single-line
                @input="changeCountry(countrySelect._id)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4" v-if="showState">
              <span>Estado (* requerido)</span>
              <v-autocomplete
                v-model="stateSelect"
                :items="statesData"
                item-text="name"
                item-value="_id"
                label="Estado"
                persistent-hint
                return-object
                single-line
                @input="changeState(stateSelect._id)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="showCity">
              <span>Ciudad (* requerido)</span>
              <v-autocomplete
                v-model="citySelect"
                :items="citiesData"
                item-text="name"
                item-value="_id"
                label="Ciudad"
                persistent-hint
                return-object
                single-line
                @input="changeCity(citySelect._id)"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="goBack()">
          Cancelar
        </v-btn>
        <v-btn color="primary" :disabled="!valid" @click="guardar()">
          Registrar
        </v-btn>

      </v-card-actions>
    </v-card>
    <v-dialog style="z-index:1000000;" v-model="showImageUploadDialog" fullscreen>
      <v-card class="mx-auto justify-center aling-center">
        <v-card-title class="headline">
          Cortar imagen
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="showImageUploadDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="black" text @click="crop">
            Confirmar
          </v-btn>
        </v-card-title>
        <v-card-text class="mx-auto justify-center text-center upload-example">
          <h5>Selecciona una imagen</h5>
          <v-file-input
            v-model="image"
            counter
            accept="image/png, image/jpeg, image/bmp"
            show-size
            truncate-length="50"
            @change="changeFileImagen()"
          ></v-file-input>

          <div class="upload-example__cropper-wrapper">
            <cropper
              ref="cropper"
              class="upload-example__cropper"
              check-orientation
              :src="url"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="erroresPendientes" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">
            Información pendiente:
          </v-card-title>
          <v-card-text class="pa-5">
            <ul>
              <li v-for="(error, index) in errores" :key='index'> {{error}}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="erroresPendientes = false"> Continuar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/adminSide/Loader";
import AdminHeader from "@/components/adminSide/AdminHeader";
import ImagePreview from "@/components/adminSide/imagePreview";
import youtubeVisor from "@/components/adminSide/youtubeVisor";

export default {
  created() {
    this.$store.dispatch("groupsModule/fetchAll");
    this.$store.dispatch("countriesModule/fetchAll");
  },
  data() {
    return {
      valid: false,
      erroresPendientes: false,
      errores: [],
      whatAlzada: false,
      nameRules: [
        (v) => !!v || "Nombre requerido",
        (v) => v.length < 100 || "Nombre debe ser menor a 100 caracteres",
      ],
      buyRules: [(v) => !!v || "Valor requerido"],
      showPhotosWarning: false,
      e1: 1,
      toAdd: "",
      photos: [],
      groupSelect: {},
      showCategory: false,
      categorySelect: {},
      showSubCategory: false,
      subCategorySelect: {},
      countrySelect: {},
      stateSelect: {},
      citySelect: {},
      showState: false,
      showCity: false,
      newOne: {
        name: "",
        number: "",
        resume: "",
        shortDescription: "",
        description: "",
        youtubeVideo: [],
        buyNow: "",
        papers: false,
        regist: "",
        dateOfBirth: "",
        subCategory: "",
        city: "",
        ranch: {}, //Automatic REF
        training: false,
        salesman: {}, //Automatic REF
        status: false,
        audited: false,
        check: false,
        toEvent: false,
        photos: [],
        trainingDetail: {
          time: 0,
          Comments: "",
          levelOfExp: 0,
          levelOfRiding: 0,
        },
        awards: false,
        offerBuyNow: false,
        nominated: false,
        enroll: false,
        paymentComprobant: ""
      },
      newHDP: {
        horse: "",
        dad: "",
        mom: "",
        image: "",
      },
      newHDH: {
        horse: "",
        image: "",
      },
      menu: false,
      file: "",
      urls: [],
      imgDialog: "",
      imageDialog: false,
      compraWarning: false,
      image: "",
      repStatus: [
        { id: 1, description: "Garañon" },
        { id: 2, description: "Hembra" },
        { id: 3, description: "Castrado" },
      ],
      repStatusSelect: {},
      showImageUploadDialog: false,
      url: "",
      fileCert: null,
      label: "Venta estimada",
      readonly: false,
      disabled: false,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "es-MX",
        prefix: "$",
        suffix: "MXN",
        length: 11,
        precision: 0,
      },
      properties: {
        counter: "11",
        hint: "Cantidad para compra directa y cálculo de pujas predeterminadas",
      },
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    changeGroup(id) {
      this.showCategory = true;
      this.$store.dispatch("categoriesModule/fetchByMaster", id);
    },
    changeCategory(id) {
      this.showSubCategory = true;
      this.$store.dispatch("subCategoriesModule/fetchByMaster", id);
    },
    changeSubCategory(id) {
      this.newOne.subCategory = id;
    },
    changeCountry(master) {
      this.showState = true;
      this.$store.dispatch("statesModule/fetchByMaster", master);
    },
    changeState(master) {
      this.showCity = true;
      this.$store.dispatch("citiesModule/fetchByMaster", master);
    },
    changeCity(master) {
      this.newOne.city = master;
    },
    changeRepStatus(master) {
      this.newOne.repStatus = master;
    },
    async guardar() {
      this.errores = []
      this.newOne.salesman = this.$session.get("salesman");
      this.newOne.ranch = this.$session.get("ranch");
      if (this.newOne.name === "") {
        this.errores.push('Nombre')
      } if (this.photos.length === 0) {
        this.errores.push("Fotografía (mínimo 1)")
      } if (this.newOne.buyNow === "") {
        this.errores.push("Definir un precio estimado")
      } if (this.newOne.subCategory === "") {
        this.errores.push("Clasificación")
      } if (this.newOne.city === "") {
        this.errores.push("Ubicación")
      } if (this.newHDH.image === "") {
        this.errores.push("Certificado médico")
      } if (this.fileCert.type !== "application/pdf"){
        this.errores.push("Certificado debe ser archivo PDF")
      }
      if(this.errores.length > 0 ) {
          this.erroresPendientes = true
        return
      } else {
        await this.$store
          .dispatch("horsesModule/createItem", this.newOne)
          .then(async () => {
            if (this.justSaved != null) {
              this.$session.set("horseProcess", this.justSaved);
              this.$store.dispatch("salesmenModule/updateFirstHorse", this.newOne.salesman);
              await this.guardar4();
              await this.guardar3();
              
            } else {
              alert("valida tu información");
            }
          });
      }
    },
    guardar4() {
      this.newHDP.horse = this.$session.get("horseProcess");
      console.log(this.newHDP)
      this.$store
        .dispatch("hdpModule/createItemExpress", this.newHDP);
    },
    guardar3() {
      this.newHDH.horse = this.$session.get("horseProcess");
      this.$store
        .dispatch("hdhModule/createItemExpress", this.newHDH)
        .then(() => {
          this.goToHorseList();
        });
    },
    goToHorseList() {
      this.$router.push({ name: "HorseSales" });
    },
    cancelDelete() {
      this.confirm = false;
    },
    changeFileImagen() {
      this.url = URL.createObjectURL(this.image);
    },
    changeFileCert() {
      if (this.fileCert.type !== "application/pdf"){
        this.errores.push("Certificado debe ser archivo PDF")
        this.erroresPendientes = true
      } else {
        this.newHDH.image = this.fileCert;
      }
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;

      this.url = canvas.toDataURL();
      this.urls.push(this.url);
      this.photos.push(this.image);

      canvas.toBlob((blob) => {
        this.newOne.photos.push(blob);
        if (this.newOne.photos.length < 1) {
          this.showPhotosWarning = true;
        } else {
          this.showPhotosWarning = false;
        }
      });
      this.initImageUpload();
    },
    validateFiles() {
      if (this.newOne.photos.length < 1) {
        this.showPhotosWarning = true;
      } else {
        this.showPhotosWarning = false;
      }
    },
    clearFile() {
      this.urls = [];
    },
    changeFilePedigree() {
      this.newHDP.image = this.file;
    },
    addToVideos() {
      if (this.toAdd !== "") {
        if(this.toAdd.includes('https://www.youtube.com/watch?v=')){
          this.toAdd.replace("https://www.youtube.com/watch?v=","")
        }
      }
      this.newOne.youtubeVideo.push(this.toAdd);
      this.toAdd = "";
    },
    removeFromVideos(value) {
      this.newOne.youtubeVideo = this.newOne.youtubeVideo.filter(function(
        item
      ) {
        return item !== value;
      });
    },
    changePosition(old_index) {
      let arr = this.urls;
      let new_index = 0;
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      this.urls = arr;
    },
    showImage(photo) {
      this.imgDialog = photo;
      this.imageDialog = true;
    },
    closePreview() {
      this.imgDialog = "";
      this.imageDialog = false;
    },
    initImageUpload() {
      this.showImageUploadDialog = !this.showImageUploadDialog;
      this.image = "";
      this.url = "";
    },
    removeFromArray(index) {
      this.urls.splice(index, 1);
      this.newOne.photos.splice(index, 1);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.horsesModule.loadingStatus,
      item: (state) => state.horsesModule.singleItem,
      justSaved: (state) => state.horsesModule.justSaved,
      groupsData: (state) => state.groupsModule.data,
      categoriesData: (state) => state.categoriesModule.data,
      subCategoriesData: (state) => state.subCategoriesModule.data,
      countriesData: (state) => state.countriesModule.data,
      statesData: (state) => state.statesModule.data,
      citiesData: (state) => state.citiesModule.data,
      singleRanch: (state) => state.ranchsModule.singleItem,
    }),
  },
  components: {
    Loader,
    AdminHeader,
    ImagePreview,
    youtubeVisor,
  },
};
</script>

<style>
.portada {
  border-width: 5px;
  border-style: solid;
  border-color: orange;
}
</style>
