import CRUDServices from '@/services/CRUDServices';
import EventBus from '../../services/events';

export const namespaced = true;

export const state = {
    loadingStatus: false,
    data: [],
    singleItem: {},
    pages: 0,
    total: 0,
    api: '/ranchs/',
    justSaved: ''
}

export const mutations = {
    SET_LOADING_STATUS(state,status){
      state.loadingStatus = status;
    },
    SET_DATA(state, data){
      state.data = data;
    },
    SET_NEW_OBJ(state, item){
      state.data.push(item)
    },
    SET_PAGES(state, pages){
      state.pages = pages
    },
    SET_TOTAL(state, total){
      state.total = total
    },
    SET_SINGLE(state, item){
      state.singleItem = item
    },
    SET_SAVED(state, id){
      state.justSaved = id
    }
}

export const  actions = {
    fetchAll({commit,dispatch}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocs(state.api)
      .then(response => {       
        let docs = [];
        
        response.data.forEach(
          element => {
            if(element.image && !element.default){
              element.image = CRUDServices.getImage(element.image);
            }
            docs.push(element);
          }
        );
        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA',response.data);
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching all ranchs: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    fetchData({state,commit,dispatch},{perPage,page}){
      commit('SET_LOADING_STATUS',true);
      CRUDServices.getDocsPage(state.api+'p/',perPage,page)
      .then(response => {        

        let docs = [];
        
        response.data.docs.forEach(
          element => {
            if(element.image){
              element.image = CRUDServices.getImage(element.image);
            }
            docs.push(element);
          }
        );

        commit('SET_LOADING_STATUS',false);
        commit('SET_DATA', docs);
        commit('SET_PAGES',response.data.totalPages);
        commit('SET_TOTAL',response.data.totalDocs);
        dispatch('countriesModule/fetchAll',null,{root:true});
      }).catch(error =>{
          const notification = {
              type: 'error',
              message: 'There was a problem ferching ranch rada: ' + error.message 
          }
          dispatch('notificationModule/add', notification, {root: true});
      });
    },
    createItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.postItem(state.api,item).then(response => {
        commit('SET_SAVED',response.data.obj);
        commit('SET_NEW_OBJ',item);
        const notification = {
            type: 'success',
            message: 'Rancho capturada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
        commit('SET_LOADING_STATUS',false);
      }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem creating Ranch' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    async fetchItem({state,commit,dispatch,getters},id){
      var item = getters.getDataByID(id);
      if (item){
        commit('SET_SINGLE',item)
        EventBus.$emit('set-single-ranch');
      }else{
        CRUDServices.getItem(state.api,id)
        .then(response => {
          item = response.data;
          if(!item.default)
            item.image = CRUDServices.getImage(item.image);
          commit('SET_SINGLE',item);
          EventBus.$emit('set-single-ranch');
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'There was a problem ferching Ranch item: ' + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
      }
    },
    async fetchDefault({state,commit,dispatch}){
       return CRUDServices.getItem(state.api+'default',0)
        .then(response => {
          commit('SET_SINGLE',response.data);
          EventBus.$emit('set-single-ranch');
        }).catch(error =>{
            const notification = {
                type: 'error',
                message: 'There was a problem ferching Ranch item: ' + error.message 
            }
            dispatch('notificationModule/add', notification, {root: true});
        });
    },
    deleteItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.deleteItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Rancho eliminada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem deleting Ranch' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
    updateItem({state,commit,dispatch},item){
      commit('SET_LOADING_STATUS',true);
      return CRUDServices.updateItem(state.api,item).then(() => {
        commit('SET_LOADING_STATUS',false);
        const notification = {
            type: 'success',
            message: 'Rancho actualizada correctamente'
        }
        dispatch('notificationModule/add', notification, {root: true});
      }).catch(error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem updating Ranch' + error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
      });
    },
}

export const getters = {
    getData(state){
      return state.data;
    },
    getDataByID: state => id => {
      return state.data.find(item => item._id === id);
    },
    dataLength: state =>{
      return state.total
    },
    objSaved: state =>{
      return state.justSaved
    }
  }