<template>
  <div width="100%">
    <AppLoader v-if="loading" :size="100" color="primary" />
    <v-row class="d-flex aling-center justify-center ma-0" no-gutters>
      <v-col cols="12" md="6" :class="isSmall ? 'mb-n16' : 'ma-auto'">
        <v-img
          :aspect-ratio="isSmall ? 1.6 : ''"
          :height="imageHeight"
          src="/images/ehorsesale/login/YeguasyPotrillos-6.jpg"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-10"
        :class="isSmall ? 'mt-n16' : 'ma-auto'"
      >
        <v-card elevation="3">
          <v-card-title class="text-center">Inicia sesión</v-card-title>
          <v-card-subtitle>
            <p>
              ¿Qué tal, te interesa comprar a través de
              <strong> eHorseSale </strong>?
            </p>
            <p>
              A continuación puedes iniciar sesión con tu cuenta, pero si deseas
              puedes
              (
              <router-link
                to="/register/"
                tag="strong"
                class="cta-red cursor-mouse"
              >
                registrarte aquí </router-link
              >)
            </p>
          </v-card-subtitle>
          <v-card-text>
            <v-text-field
              v-model="user.email"
              label="correo electrónico"
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              v-model="user.pass"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Contraseña"
              @click:append="show1 = !show1"
            ></v-text-field>
            <v-alert color="orange" type="info" v-if="showWarning">
              {{ warningText }}</v-alert
            >
            <v-card-actions class="justify-center">
              <v-btn color="primary" @click="regist(user)">
                Iniciar Sesión
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <NotificationContainer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "@/services/events";
import NotificationContainer from "@/components/NotificationContainer";
import AppLoader from "@/components/base/AppLoader.vue";

export default {
  components: {
    NotificationContainer,
    AppLoader,
  },
  data() {
    return {
      user: {
        email: "",
        pass: "",
      },
      show1: false,
      showWarning: false,
      textRule: [(v) => !!v || "Campo requerido"],
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe ser valido",
      ],
      warningText: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.$session.exists() && this.$session.get("type") === "FRN") {
      this.returnHome();
    }
    EventBus.$on("front-user-login", () => {
      this.handleLogin();
    }),
      EventBus.$on("front-user-bad-login", () => {
        this.warningText = "Valida tu correo y contraseña";
        this.showWarning = true;
      });
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async regist(user) {
      user.email = user.email.toLowerCase()
      if (this.validateField(user)) {
        await this.$store.dispatch("frontUserModule/logIn", user);
        if(this.validated)
          this.handleLogin();
        else
          this.handleNotValidated();
      } else {
        this.showWarning = true;
        this.warningText = "Valida tu correo y contraseña";
      }
    },
    returnHome() {
      this.$router.push({ name: "homepaga-us" });
    },
    goToOnboarding() {
      this.$router.push({ name: "person-us" });
    },
    validateField(user) {
      if (user.email) return user.email.length !== 0 && user.pass.length !== 0;
      else return false;
    },
    async handleLogin() {
      this.$session.destroy();
      this.$session.start();
      this.$session.set("jwt", this.auth);
      this.$session.set("type", "FRN");
      this.$session.set("current", this.current);
      // Vue.http.headers.common['Authorization'] = 'Bearer ' + this.auth
      if (this.person === null) {
        this.goToOnboarding();
      } else {
        this.$session.set("person", this.person);
        EventBus.$emit("front-user-fetch");
        this.returnHome();
      }
    },
    handleNotValidated(){
      this.$session.destroy();
      this.showWarning = true;
      this.warningText = "Es requerido validar tu correo electrónico";
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.frontUserModule.loadingStatus,
      auth: (state) => state.frontUserModule.auth,
      current: (state) => state.frontUserModule.current,
      person: (state) => state.frontUserModule.person,
      validated: (state) => state.frontUserModule.validated,
    }),
    isSmall() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
    imageHeight() {
      let value;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          value = "200px";
          break;
        case "sm":
          value = "300px";
          break;
        case "md":
          value = "700px";
          break;
        case "lg":
          value = "800px";
          break;
        case "xl":
          value = "800px";
          break;
      }
      return value;
    },
  },
};
</script>

<style></style>
