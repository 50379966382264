<template>
  <div>
    <v-app-bar color="white" height="80px">
      <div class="d-flex align-center ma-auto">
        <v-img
          alt="eHorseSale logo"
          class="shrink my-2 linkCursor"
          contain
          src="/images/ehorsesale/blacklogo.png"
          max-width="200px"
          @click="goToHome()"
        />
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        class="mt-6 hidden-sm-and-down"
        placeholder="¿Qué estás buscando?"
        filled
        rounded
        dense
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <ul v-if="!user">
        <li @click="hideMenu = !hideMenu">
          <a class="mr-1 d-sm-none d-md-none d-lg-none">
            <v-icon color="black">mdi-account-arrow-right</v-icon>
          </a>
          <a class="d-none d-sm-flex">Iniciar Sesión</a>
        </li>
        <li @click="goToContact()" class="d-none d-sm-flex">
          <a>Contacto</a>
        </li>
      </ul>
      <ul v-else>
        <li v-if="user" @click="hideMenu = !hideMenu">
          <span class="linkCursor">
            <v-icon color="black">mdi-account-circle</v-icon>
            <strong>
              {{ current.name === "" ? "Cuenta de usuario" : current.name }}
            </strong>
          </span>
        </li>
      </ul>
    </v-app-bar>
    <v-sheet
      v-if="hideMenu"
      height="50px"
      class="black d-flex column justify-end"
    >
      <ul v-if="!user" class="black" dark>
        <li @click="goToLogIn()">
          <a class="text-white">Comprador</a>
        </li>
        <li @click="goTo(3)">
          <a class="text-white">Vendedor</a>
        </li>
        <li @click="goTo(4)">
          <a class="text-white">Admin</a>
        </li>
      </ul>
      <ul v-else class="black" dark>
        <li @click="goToProfile()">
          <a class="text-white">Perfil</a>
        </li>
        <li @click="logout()">
          <a class="text-white">Cerrar Sesión</a>
        </li>
      </ul>
    </v-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideMenu: false,
    };
  },
  props: {
    user: Boolean,
    current: {
      email: "",
    },
  },
  methods: {
    goTo(index) {
      if (index == 1) {
        this.$router.replace({ name: "events-us" });
      } else if (index == 3) {
        this.$router.push({ name: "salesManAccess" });
      } else if (index == 4) {
        this.$router.push({ name: "adminAccess" });
      } else {
        this.$router.replace({ name: "homepaga-us" });
      }
    },
    goToRegist() {
      this.$router.push({ name: "register-us" });
    },
    goToRegistSales() {
      this.$router.push({ name: "register-us" });
    },
    goToLogIn() {
      this.$router.push({ name: "login-us" });
    },
    goToHome() {
      this.hideMenu = false;
      this.$router.push({ name: "homepaga-us" });
    },
    goToProfile() {
      this.$router.push({ name: "person-profile-us" });
    },
    goToContact() {
      this.$router.push({ name: "contact-us" });
    },
    logout() {
      this.$session.destroy();
      this.$router.go({ name: "homepaga-us" });
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
}

li {
  float: left;
}

li a {
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  font-weight: bold;
  font-size: medium;
  text-decoration: none;
}

li span {
  display: block;
  color: #df2127;
  text-align: center;
  padding: 16px;
  font-weight: bold;
  font-size: medium;
  text-decoration: none;
}

li a:hover {
  color: #df2127;
}

.noMarginBottom {
  margin-bottom: 0px !important;
}
</style>
