<template>
  <div class="footer-position">
    <div class="d-flex justify-center aling-center">
      <v-btn class="jutify-center" @click="sheetEvent()">
        Más información
        <v-icon>
          {{ "mdi-chevron-" + (opensheet ? "up" : "down") }}
        </v-icon>
      </v-btn>
    </div>
    <div v-if="opensheet">
      <v-sheet color="grey lighten-2 mt-n5 pa-5" width="100%">
        <v-row class="text-center">
          <v-col class="text-center ma-0 pa-1" cols="12" sm="3">
            <strong class="cursor-mouse">Acerca de</strong>
            <ul class="ma-1 pa-0">
              <li class="dark cursor-mouse" @click="goToAbout()">{{$t('global.platformName')}}</li>
            </ul>
          </v-col>
          <v-col class="text-center ma-0 pa-1" cols="12" sm="3">
            <strong> Ayuda </strong>
            <ul class="ma-1 pa-0" @click="goToHelp()">
              <li class="dark cursor-mouse">¿Cómo comprar?</li>
              <li class="dark cursor-mouse">¿Cómo vender?</li>
              <li class="dark cursor-mouse">¿Cómo pago?</li>
              <li class="dark cursor-mouse">Resolución de problemas</li>
            </ul>
          </v-col>
          <v-col class="text-center ma-0 pa-1" cols="12" sm="3">
            <strong> Redes </strong>
            <ul class="ma-1 pa-0">
              <li class="dark cursor-mouse">
                <a
                  class="dark cursor-mouse"
                  href="https://www.facebook.com/eHorseSale"
                >
                  Facebook
                </a>
              </li>
              <li class="dark cursor-mouse">
                <a
                  class="dark cursor-mouse"
                  href="https://www.instagram.com/ehorsesale/"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </v-col>
          <v-col class="text-center ma-0 pa-1" cols="12" sm="3">
            <strong> Mi cuenta </strong>
            <ul class="ma-1 pa-0">
              <li class="dark cursor-mouse" @click="goToRegistSales()">
                Vender
              </li>
              <li class="dark cursor-mouse" @click="goToRegist()">Comprar</li>
            </ul>
          </v-col>
        </v-row>
      </v-sheet>
    </div>
    <v-sheet dark width="100%" class="ma-0 pa-0 text-center" ref="finalSheet">
      <v-row class="pa-2">
        <v-col cols="12" sm="6" md="3">
          <strong class="dark cursor-mouse" @click="goToTerms()">
            Términos y condiciones
          </strong>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <strong class="dark cursor-mouse" @click="goToPrivacy()">
            Politica de privacidad
          </strong>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <strong class="dark cursor-mouse" @click="goToContact()">
            Contactanos
          </strong>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <strong>
            eHorseSale.com 2023
            <br/>
            Servicios internacionales mexicanos SA de CV
          </strong>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opensheet: false,
      options: {
        duration: 1000,
      },
    };
  },
  methods: {
    sheetEvent() {
      this.opensheet = !this.opensheet;
      if (this.opensheet) this.$vuetify.goTo(9999, this.options);
    },
    goToTerms() {
      window.scrollTo(0, 0);
      this.$router.push({ name: "terms-us" });
    },
    goToPrivacy() {
      window.scrollTo(0, 0);
      this.$router.push({ name: "privacy" });
    },
    goToAbout() {
      window.scrollTo(0, 0);
      this.$router.push({ name: "terms-us" });
    },
    goToRegist() {
      window.scrollTo(0, 0);
      this.$router.push({ name: "register-us" });
    },
    goToRegistSales() {
      window.scrollTo(0, 0);
      this.$router.push({ name: "SalesmanRegist" });
    },
    goToContact() {
      window.scrollTo(0, 0);
      this.$router.push({ name: "contact-us" });
    },
    goToHelp(){
      window.scrollTo(0, 0);
      this.$router.push({ name: "help" });
    }
  },
};
</script>

<style scoped>
li {
  list-style: none;
}

.footer-position {
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

a {
  text-decoration: none;
  color: black !important;
}
</style>
