import CRUDServices from '@/services/CRUDServices';

export const namespaced = true;

export const state = {
  event: '',
  api: '/emails/',
}

export const mutations = {
  SET_EVENT(state,event){
    state.event = event;
  },
}

export const  actions = {
  notifyBid({dispatch},item){
    return CRUDServices.postItem(state.api+'notificationBid/',item).then(() => {
    }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem notify bid'+ error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
    });
  },
  notifyFinish({dispatch},item){
    return CRUDServices.postItem(state.api+'notifyFinish/',item).then(() => {
    }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem notify bid'+ error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
    });
  },
  notifySalesMan({dispatch},item){
    return CRUDServices.postItem(state.api+'notifySalesMan/',item).then(() => {
    }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem notify bid'+ error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
    });
  },
  SendStepsBuyer({dispatch},item){
    return CRUDServices.postItem(state.api+'SendStepsBuyer/',item).then(() => {
    }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem notify bid'+ error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
    });
  },
  welcomeSalesman({dispatch},item){
    return CRUDServices.postItem(state.api+'welcomeSalesman/',item).then(() => {
    }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem correo bienvenida'+ error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
    });
  },
  resendWelcomeSalesman({dispatch},item){
    return CRUDServices.postItem(state.api+'resendWelcomeSalesman/',item).then(() => {
    }).catch( error =>{
      const notification = {
        type: 'error',
        message: 'There was a problem correo bienvenida'+ error.message
      }
      dispatch('notificationModule/add', notification, {root: true});
    });
  },
  welcomeFront({dispatch},item){
    return CRUDServices.postItem(state.api+'welcomeFront/',item).then(() => {
    }).catch( error =>{
      const notification = {
        type: 'error',
        message: 'There was a problem correo bienvenida a front user'+ error.message
      }
      dispatch('notificationModule/add', notification, {root: true});
    });
  },
  adminInvitation({dispatch},item){
    return CRUDServices.postItem(state.api+'adminInvitation/',item).then(() => {
    }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem correo bienvenida'+ error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
    });
  },
  notificateAutorization({dispatch},item){
    return CRUDServices.postItem(state.api+'notificateAutorization/',item).then(() => {
    }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem correo bienvenida'+ error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
    });
  },
  recoverSalesman({dispatch},item){
    return CRUDServices.postItem(state.api+'recoverSalesman/',item).then(() => {
    }).catch( error =>{
        const notification = {
            type: 'error',
            message: 'There was a problem correo recoverSalesman'+ error.message
        }
        dispatch('notificationModule/add', notification, {root: true});
    });
  },
}