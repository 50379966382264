<template>
  <v-card class="mx-auto" height="100%">
    <v-card-text>
      <p class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h5 text-center">
        {{ title }}
      </p>
      <p class="text-h5 text--primary text-center" v-if="value !== 'empty'">
        <strong>{{ value }}</strong>
      </p>
      <p class="text-h5 text--primary text-center" v-else>
        <v-progress-circular
          :width="3"
          color="red"
          :value="value2"
        ></v-progress-circular>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "dashboard-card",
  props: {
    value: {
      type: String,
      default: "empty",
    },
    title: String,
  },
  data() {
    return {
      value2: 0,
      interval: {},
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value2 === 100) {
        return (this.value2 = 0);
      }
      this.value2 += 10;
    }, 2000);
  },
};
</script>

<style>
</style>