<template>
    <div>
        <AdminHeader color="black" icon="credit-card-settings-outline" title="Inscripciones"/>
        <Loader v-if="loading" :size="64" />
        <Paginador :dataLength="dataLength" :pages="pages" :CurrentPage="page" @next="next"/>
        <v-card
            elevation="5"
            height="100%"
        >
            <v-card-title>
                <h6>Inscripciones para vendedores</h6>
                <v-spacer></v-spacer>
                <v-btn
                    @click="create()"
                    dark
                    small
                >
                <v-icon left>
                    mdi-plus
                </v-icon>
                    Capturar
                </v-btn>
            </v-card-title>   
            <v-card-text v-if="dataLength > 0">
                <v-simple-table>
                    <template>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-right">
                                    Fecha de publicación
                                </th>     
                                <th class="text-right">
                                    Precio
                                </th>     
                                <th class="text-right">
                                    Inscripciones
                                </th>     
                                <th class="text-right">
                                    Beneficios
                                </th>     
                                <th class="text-right">
                                    Público
                                </th>     
                                <th class="text-right">
                                    Opciones
                                </th>     
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in docs"
                                :key="item._id"
                            >
                                <td>{{ item.name }}</td>
                                <td class="text-right">{{ item.publicationDate | date}}</td>
                                <td class="text-right">{{ item.precio | money}}</td>
                                <td class="text-right">{{ item.creditos }}</td>
                                <td class="text-right">{{ item.features.length }}</td>
                                <td class="text-right">{{ item.public | yesno}}</td>
                                <td class="text-right">
                                    <v-menu
                                        bottom
                                        left
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                        <v-list-item v-on:click="setItemToStore(item._id)">
                                            <v-list-item-title ><v-icon>mdi-lead-pencil</v-icon> Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-on:click="setItemToDelete(item._id)">
                                            <v-list-item-title><v-icon color="red">mdi-trash-can</v-icon> Eliminar</v-list-item-title>
                                        </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-row dense class="mx-auto justify-center aling-center" v-else>
                <EmptyState :white="true"/>
            </v-row>            
        </v-card>
        
        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"                
            >
                <v-card>
                    <v-toolbar
                        dark
                    >
                    <v-btn
                    icon
                    dark
                    @click="dialog = false"
                    >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Formulario Paquete</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            color="amber"
                            @click="guardar();"
                            :disabled="!valid"
                        >
                            <b>
                                {{ label }}
                            </b>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-list
                    three-line
                    subheader
                >
                    <v-list-item>
                        <v-list-item-content class="ma-5">
                            <v-form v-model="valid">
                                <v-text-field
                                    v-model="newOne.name"
                                    :counter="100"
                                    label="Nombre"
                                    :rules="nameRules"
                                    required
                                ></v-text-field>                           
                                <v-text-field
                                    v-model="newOne.description"
                                    :counter="100"
                                    label="Descripción"
                                    required
                                ></v-text-field>     
                                <v-row>
                                    <v-col cols="12" md="4">
                                         <v-text-field
                                            v-model="newOne.creditos"
                                            :counter="100"
                                            label="Inscripciones"
                                            required
                                            type = "number"
                                        ></v-text-field>  
                                    </v-col>    
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            v-model="newOne.precio"
                                            :counter="100"
                                            prefix="$"
                                            suffix="MXN"
                                            label="Precio"
                                            required
                                            type= "number"
                                        ></v-text-field>       
                                    </v-col>    
                                    <!-- <v-col cols="12" md="4">
                                        <v-text-field
                                            v-model="newOne.vigencia"
                                            :counter="100"
                                            label="Vigencia"
                                            required
                                            suffix="días"
                                            type = "number"
                                        ></v-text-field>  
                                    </v-col>     -->
                                </v-row>       
                                <v-row>
                                    <v-col justify="right">
                                        <v-checkbox
                                            v-model="newOne.recommended"
                                            label= "¿Recomendado?"
                                        ></v-checkbox>     
                                    </v-col>    
                                    <v-col justify="left">
                                        <v-checkbox
                                            v-model="newOne.public"
                                            label= "¿Público?"
                                        ></v-checkbox>     
                                    </v-col>    
                                </v-row>    
                                <div v-if="!newOne.public">
                                    <h4>Selecciona los vendedores que quieres incluir a este paquete</h4>
                                    <v-autocomplete
                                        v-model="model"
                                        :items="items"
                                        :loading="isLoading"
                                        :search-input.sync="search"
                                        color="white"
                                        hide-selected
                                        item-text="Description"
                                        item-value="Vendedor"
                                        placeholder="Escribe el correo o nombre del vendedor"
                                        prepend-icon="mdi-account-search"
                                        return-object
                                        @change="addToArray()"
                                        clearable
                                    ></v-autocomplete>
                                    <br>
                                    <br>
                                    <ul>
                                        <v-chip v-for="item in newOne.salesmen" :key="item.name"
                                            class="ma-2"

                                            close
                                            @click:close="removeFromArray(item)"
                                        >
                                            {{item.name + '-' + item.mail}}
                                        </v-chip>                                   
                                    </ul> 
                                </div>    
                                <div>
                                    <h4>Agrega los beneficios de este plan</h4>
                                    <v-text-field
                                        v-model="toAdd"
                                        :counter="100"
                                        label="Beneficios"                                        
                                    ></v-text-field>  
                                    <v-btn
                                        class="mr-4"
                                        @click="addToFeatures()"
                                    >
                                        Agregar a beneficios
                                    </v-btn>
                                    <br>
                                    <br>
                                    <ul>
                                        <v-chip v-for="item in newOne.features" :key="item.name"
                                            class="ma-2"
                                            close
                                            dark
                                            @click:close="removeFromFeatures(item)"
                                        >
                                            {{item}}
                                        </v-chip>                                   
                                    </ul>
                                </div>
                            </v-form>    
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
                v-model="confirm"
                persistent
                max-width="400"
            >
                <v-card>
                <v-card-title class="headline">
                    ¿Deseas eliminar {{newOne.version}}?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    @click="cancelDelete()"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="red"
                        text
                        @click="guardar()"
                    >
                    Eliminar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import EventBus from '../../services/events';
    import EmptyState from '@/components/EmptyState';
    import Loader from '@/components/adminSide/Loader';
    import AdminHeader from '@/components/adminSide/AdminHeader';
    import Paginador from '@/components/adminSide/Paginador';
    
    export default {
        data(){
            return{
                itemsPerPage: 10,
                valid: false,
                nameRules: [
                    v => !!v || 'Nombre requerido',
                    v => v.length < 100 || 'Nombre debe ser menor a 100 caracteres',
                ],
                dialog: false,
                confirm: false,
                newOne: this.createFreshObj(),
                show1: false,
                page: 1,
                eventCrud: 0, // 0 for new, 1 for edit, 2 for delete    ,
                toAdd: '',
                model: null,   
                descriptionLimit: 60,
                entries: [],
                isLoading: false,
                search: null,     
            }
        },
        components:{
            EmptyState,
            AdminHeader,
            Loader,
            Paginador
        },
        created(){
            this.init();
        },
        mounted(){
            EventBus.$on("search-salesmen", data => {
                this.entries = data
            });
        },
        methods:{
            addToArray(){
                this.newOne.salesmen.push(this.model)
                this.model = null
            },
            addToFeatures(){
                this.newOne.features.push(this.toAdd)
                this.toAdd = ''
            },
            removeFromFeatures(value){
                this.newOne.features = this.newOne.features.filter(function(item) {
                    return item !== value
                })
            },
            removeFromArray(value){
                this.newOne.salesmen = this.newOne.salesmen.filter(function(item) {
                    return item !== value
                })
            },
            create(){
                this.createFreshObj();
                this.dialog = true;
                this.eventCrud = 0;
            },
            createFreshObj(){
                // const user = this.$store.state.user;
                return {
                    name: '',
                    description: '',
                    publicationDate: Date.now(),
                    precio: 0,
                    creditos: 0,
                    // vigencia: 0,
                    features: [],
                    status: true,
                    salesmen: [],
                    recommended: false,
                    public: true                    
                }
            },
            init(){
                this.$store.dispatch('membershipsModule/fetchData',{perPage: 5,page: this.page});
                this.newOne.name = "";
            },
            guardar(){             
                this.newOne.publicationDate = Date.now();   
                if(this.eventCrud === 0){// CREATE
                    this.dialog = false;
                    this.$store.dispatch('membershipsModule/createItem',this.newOne).then(() => {
                        this.newOne = this.createFreshObj(); 
                        this.$store.dispatch('membershipsModule/fetchData',{perPage: 5,page: this.pages});                    
                    });
                }else if(this.eventCrud ===1){ //EDIT
                    this.$store.dispatch('membershipsModule/updateItem',this.newOne).then(() =>{
                        this.$store.dispatch('membershipsModule/fetchData',{perPage: 5,page: this.page});
                        this.dialog = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }else{
                    this.$store.dispatch('membershipsModule/deleteItem',this.newOne._id).then(() =>{
                        this.$store.dispatch('membershipsModule/fetchData',{perPage: 5,page: this.page});
                        this.confirm = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }
            },
            next(param){
                this.page = param.page;
                this.$store.dispatch('membershipsModule/fetchData',{perPage: param.itemsPerPage,page: param.page});
            },  
            setItemToStore(id){           
                this.eventCrud = 1;
                this.$store.dispatch('membershipsModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                    this.dialog = true;
                });
            },
            setItemToDelete(id){
                this.eventCrud = 2;
                this.confirm = true;
                this.$store.dispatch('membershipsModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                });
            },
            cancelDelete(){
                this.eventCrud = 0;
                this.confirm = false;
                this.newOne = this.createFreshObj(); 
            }
        },
        computed: {
            label(){
                return this.eventCrud === 0 ? 'Guardar': (this.eventCrud === 1 ? 'Editar' : 'Eliminar' );
            },
            ...mapGetters('membershipsModule',['dataLength']),            
            ...mapState({
                user: state => state.user,
                loading: state => state.membershipsModule.loadingStatus,
                pages: state => state.membershipsModule.pages,
                docs: state => state.membershipsModule.data,
                item: state => state.membershipsModule.singleItem,
                lastItem: state => state.membershipsModule.lastItem,
                salesmen: state => state.salesmenModule.data
            }),
            items () {
                if(this.entries.length < 1)
                    return 
                return this.entries.map(entry => {
                const Description = entry.name.length + entry.mail.length > this.descriptionLimit
                    ? entry.name.slice(0, this.descriptionLimit) + '...'
                    : entry.name + ' - ' + entry.mail
                return Object.assign({}, entry, { Description })
                })
            },
        },
        watch:{
            search (val) {
                // Lazily load input items
                this.$store.dispatch('salesmenModule/search',val).then( () =>{
                    this.entries = this.salesmen
                });
            },
        }
    }
</script>