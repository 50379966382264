<template>
  <div width="100%">
    <AppLoader v-if="loading" :size="100" color="primary" />
    <v-row class="d-flex aling-center justify-center ma-0" no-gutters>
      <v-col
        cols="12"
        md="6"
        :class="isSmall ? 'mb-n16' : 'ma-auto'"
      >
        <v-img
          :aspect-ratio="isSmall ? 1.6 : ''"
          :height="imageHeight"
          src="/images/ehorsesale/login/YeguasyPotrillos-28.jpg"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-10"
        :class="isSmall ? 'mt-n16' : 'ma-auto'"
      >
        <v-card elevation="3">
          <v-card-title class="text-center">Bienvenido a eHorseSale </v-card-title>
          <v-card-subtitle>
            <p>
              Bienvenido a la nueva experiencia de compra y venta de Caballos.
            </p>
            <p>
              <span>
                ¿Ya tienes cuenta?
                (<router-link to="/login" tag="b" class="cta-red cursor-mouse">
                  inicia sesión aquí</router-link
                >
                )
              </span>

            </p>
          </v-card-subtitle>
          <v-card-text>
            <v-form v-model="valid" lazy-validation>
              <v-text-field
                v-model="newUser.name"
                :counter="100"
                label="Nombre"
                :rules="textRule"
                required
              ></v-text-field>
              <v-text-field
                v-model="newUser.email"
                :counter="100"
                :rules="emailRules"
                label="Correo electrónico"
                required
              ></v-text-field>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="newUser.pass"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    counter
                    @click:append="show2 = !show2"
                    :rules="textRule"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="passValidation"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Confimación de password"
                    counter
                    @click:append="show3 = !show3"
                    :rules="textRule"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-alert color="orange" type="info" v-if="showWarning">
              {{ alertText }}</v-alert
            >
            <v-card-actions class="justify-center">
              <v-btn color="primary" @click="regist(newUser)" block>
                {{ labelButton }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <BlackTitle title="Video Tutoriales" />
    <div class="mx-5 mt-5 mb-16">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          class="text-center"
          v-for="(video, i) in videos"
          :key="i"
        >
          <youtubeVisor :video="video.video" :small="true" />
          <h3>{{ video.title }}</h3>
          <p>{{ video.description }}</p>
        </v-col>
        <v-btn color="primary" block @click="goToRoute('help')">
          Ver más...
        </v-btn>
      </v-row>
    </div>
    <NotificationContainer />

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark>
            <v-btn icon dark @click="rechazoTerminos()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Términos y condiciones</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-row class="pa-16">
            <v-list three-line subheader>
              <v-list-item-content>
                <h2>Términos y condiciones</h2>
                <div v-html="lastOne.body"></div>
              </v-list-item-content>
            </v-list>
          </v-row>
          <v-row>
            <v-sheet
              class="dialog-footer 
												mx-auto 
												justify-center 
												aling-center 
												py-2 gray float-end"
              elevation="14"
            >
              <h4 class="mb-0">
                Expreso mi voluntad libremente de sujetarme a los términos y
                condiciones:
              </h4>
              <v-btn
                class="mx-4 mt-0 amber darken-2"
                @click="aceptoTerminos()"
                dark
              >
                <span>
                  Aceptar
                </span>
              </v-btn>
            </v-sheet>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import EventBus from "../../services/events";
import NotificationContainer from "@/components/NotificationContainer";
import { mapState } from "vuex";

import AppLoader from "@/components/base/AppLoader.vue";
import BlackTitle from "@/components/userSide/blackTitle";
import youtubeVisor from "@/components/adminSide/youtubeVisor";

export default {
  components: {
    NotificationContainer,
    AppLoader,
    BlackTitle,
    youtubeVisor,
  },
  data() {
    return {
      videos: [
        {
          video: "K-RuvLpUawE",
          title: "Registro como vendedor.",
          description: "Registro para vendedores.",
        },
        {
          video: "pwVIgJ8LVFc",
          title: "Primer inicio de sesión",
          description:
            "Te acompañamos durante tu primer inicio de sesión en la plataforma",
        },
        {
          video: "oNKLIw0r2n4",
          title: "Registro Express de caballos",
          description:
            "Registro sencillo de caballo con la información básica.",
        },
        {
          video: "7p2eKoIgvpg",
          title: "Registro avanzado caballo",
          description: "Registro completo de caballo.",
        },
        {
          video: "lzVyhnjirS4",
          title: "Perfil de vendedor y compra de Inscripciones",
          description:
            "Te acompañamos durante la actualización de datos personales, y compra de tus primeras inscripciones",
        },
        {
          video: "UakLmJc8z8Q",
          title: "Subir video a youtube",
          description: "Acompañamiento para subir tu primer video a youtube",
        },
      ],
      alertText: "",
      showWarning: false,
      acepto: false,
      dialog: false,
      user: {
        name: "",
        pass: "",
      },
      newUser: {
        name: "",
        email: "",
        pass: "",
      },
      passValidation: "",
      show1: false,
      show2: false,
      show3: false,
      valid: false,
      textRule: [(v) => !!v || "Campo requerido"],
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe ser valido",
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.$session.exists() && this.$session.get("type") === "FRN") {
      this.goToHome();
    }
    EventBus.$on("registerCorrect", () => {
      this.$session.destroy();
      this.goToLogin();
    }),
      EventBus.$on("registerError", () => {
        alert("Revisa tus datos y contraseña");
      });
    this.$store.dispatch("termsModule/getPublicableTerms");
  },
  computed: {
    ...mapState({
      loading: (state) => state.frontUserModule.loadingStatus,
      lastOne: (state) => state.termsModule.lastItem,
    }),
    labelButton() {
      return this.acepto ? "Registrarme" : "Continuar";
    },
    isSmall() {
      return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm";
    },
    imageHeight() {
      let value;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          value = "200px";
          break;
        case "sm":
          value = "300px";
          break;
        case "md":
          value = "700px";
          break;
        case "lg":
          value = "800px";
          break;
        case "xl":
          value = "800px";
          break;
      }
      return value;
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    aceptoTerminos() {
      this.dialog = false;
      this.acepto = true;
      this.showWarning = false;
    },
    rechazoTerminos() {
      this.acepto = false;
      this.dialog = false;
      this.alertText =
        "Para continuar es necesario aceptar los términos y condiciones.";
      this.showWarning = true;
    },
    validate() {
      this.$refs.form.validate();
    },
    async regist(user) {
      if (user.email != "" && user.pass != "" && user.name != "") {
        if (user.pass === this.passValidation) {
          if (this.valid) {
            if (this.acepto) {
              user.from = window.location.origin;
              await this.$store.dispatch("frontUserModule/createFront", user);
            } else {
              this.dialog = true;
            }
          } else {
            this.alertText = "Correo invalido";
            this.showWarning = true;
          }
        } else {
          this.alertText = "Valida tus passwords no estan iguales";
          this.showWarning = true;
        }
      } else {
        this.alertText = "Datos incompletos";
        this.showWarning = true;
      }
    },
    goToLogin() {
      this.$router.push({ name: "login-us" });
    },
  },
};
</script>

<style>
.dialog-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
</style>
