<template>
    <div>
        <AdminHeader color="black" title="Actualización de información" :backButton="true"/>
        <Loader v-if="loading" :size="64" />
        <ImagePreview v-if="imageDialog" :imgDialog="imgDialog" @closePreview="closePreview"/> 
        <v-stepper v-model="e1" class="ma-5">
            <v-stepper-header>
                <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                >
                    Datos generales
                </v-stepper-step>
        
                <v-divider></v-divider>
        
                <v-stepper-step :complete="e1 > 2" step="2">
                    Salud
                </v-stepper-step>

                 <v-divider></v-divider>
        
                <v-stepper-step step="3">
                    Árbol genealógico
                </v-stepper-step>
            </v-stepper-header>
        
            <v-stepper-items>
                <v-stepper-content step="1" class="mx-lg-15 mx-sm-5 px-lg-15">
                <v-card
                    class="mb-12"
                >
                    <v-card-title>Información general del caballo</v-card-title>

                    <v-spacer></v-spacer>

                    <v-list
                        three-line
                        subheader
                    >
                        <v-list-item>
                            <v-list-item-content>
                                <v-form enctype="multipart/form-data" v-model="valid">

                                    <v-text-field
                                        class="my-5"
                                        v-model="newOne.name"
                                        :rules="nameRules"
                                        :counter="100"
                                        label="Nombre Caballo"
                                        required
                                    ></v-text-field> 
                                    <div id="preview" v-if="urls" class="mx-auto justify-center aling-center ma-1" >
                                        <v-row>
                                            <v-col cols="4" md="3" xl="2" v-for="(photo,index) in urls" :key = "photo" class="mx-auto justify-center aling-center" >
                                                <v-btn
                                                    fab
                                                    x-small
                                                    absolute
                                                    @click="removeFromArray(photo,index)" small class="red" dark
                                                >
                                                    <v-icon dark>mdi-trash-can</v-icon>
                                                </v-btn>
                                                <v-img                                                         
                                                    aspect-ratio="1"
                                                    max-height="200px"
                                                    class="ma-2"
                                                    :src="photo" 
                                                    @click="showImage(photo)"
                                                />
                                                <br>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-btn
                                        class="my-5"
                                        @click="initImageUpload()"
                                    >
                                        Cargar imagen
                                    </v-btn>
                                    <v-text-field
                                        class="my-5"
                                        v-model="newOne.shortDescription"
                                        :counter="150"
                                        label="Descripción corta"
                                        required
                                    ></v-text-field>
                                    <v-textarea
                                        class="my-5"
                                        v-model="newOne.description"
                                        :counter="300"
                                        label="Descripción general"
                                        required                                        
                                    ></v-textarea>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                        <v-checkbox
                                            v-model="newOne.nominated"
                                            label="Nominar a TRIPLE CORONA MILLONARIA - Hipódromo Ávalos CUU"
                                        ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row class="my-5">
                                        <v-col cols="12"
                                            md='4'
                                        >
                                            <span>Grupo</span>
                                            <br>
                                            <v-select
                                                v-model="groupSelect"                                    
                                                :items="groupsData"
                                                item-text="name"
                                                item-value="_id"
                                                label="Grupo"
                                                persistent-hint
                                                return-object
                                                single-line
                                                @input="changeGroup(groupSelect._id)"
                                            ></v-select>                                         
                                            <span for="Nombre">* requerido </span>                                     
                                        </v-col>   
                                        <v-col cols="12"
                                            md='4'
                                            v-if="showCategory"
                                        >
                                            <span>Categoría</span>
                                            <br>
                                            <v-select
                                                v-model="categorySelect"                                    
                                                :items="categoriesData"
                                                item-text="name"
                                                item-value="_id"
                                                label="Categoría"
                                                persistent-hint
                                                return-object
                                                single-line
                                                @input="changeCategory(categorySelect._id)"
                                            ></v-select>                                         
                                            <span for="Nombre">* requerido </span>                                     
                                        </v-col>   
                                        <v-col cols="12"
                                            md="4"
                                            v-if="showSubCategory"
                                        >
                                            <span>Subcategoría</span>
                                            <v-select
                                                v-model="subCategorySelect"                                    
                                                :items="subCategoriesData"
                                                item-text="name"
                                                item-value="_id"
                                                label="SubCategoría"
                                                persistent-hint
                                                return-object
                                                single-line
                                                @input="changeSubCategory(subCategorySelect._id)"
                                            ></v-select>
                                            <span for="Nombre">* requerido </span>                                     
                                        </v-col>   
                                    </v-row> 
                                    <v-row class="my-5">
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="newOne.buyNow"
                                                type="number"
                                                :rules="buyRules"
                                                :counter="10"
                                                prefix="$"
                                                suffix="MXN"
                                                label="Venta estimada"
                                                required
                                                hint="Cantidad para compra directa y cálculo de pujas predeterminadas"
                                            ></v-text-field> 
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-checkbox
                                                v-model="newOne.offerBuyNow"
                                                label="¿Ofrecer opción de comprar ahora?"
                                            ></v-checkbox>
                                        </v-col>  
                                    </v-row>
                                    <v-alert
                                        color="orange"
                                        type="info"
                                        v-if="!newOne.offerBuyNow"
                                    > El evento se terminará al culminar el tiempo programado, y el ganador será la última oferta realizada </v-alert>
                                    <v-text-field v-model="toAdd" label="Video de Youtube" prefix="https://youtu.be/">                                        
                                    </v-text-field>
                                    <v-btn
                                        class="mr-4 info"
                                        small
                                        @click="showImage('/images/ehorsesale/help/youtube.png')"
                                    >
                                        ¿Qué es esto?
                                    </v-btn>
                                    <v-btn
                                        class="mr-4"
                                        :disabled="toAdd === ''"
                                        @click="addToVideos()"
                                        small
                                    >
                                        Agregar video
                                    </v-btn>
                                    <br>
                                    <br>
                                    <v-row class="my-5">
                                        <v-col cols="3" v-for="item in newOne.youtubeVideo" :key="item.name">
                                            <youtubeVisor :video="item" :small="true" />
                                            <v-chip 
                                                class="ma-2"
                                                close
                                                small
                                                @click:close="removeFromVideos(item)"
                                            >
                                                {{item}}
                                            </v-chip>   
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-checkbox
                                                v-model="newOne.papers"
                                                label="¿Cuenta con Registro?"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                     <v-row v-if="newOne.papers">
                                        <v-col sm="6">
                                            <v-text-field
                                                v-model="newOne.regist"
                                                :counter="30"
                                                label="Número de Registro"
                                                required
                                            ></v-text-field> 
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field
                                                v-model="newOne.registOrg"
                                                :counter="30"
                                                label="Organización"
                                                required
                                            ></v-text-field> 
                                        </v-col>
                                    </v-row>
                                    <v-row class="my-5">
                                        <v-col md="4" cols="12">
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="newOne.dateOfBirth"
                                                    label="Fecha de nacimiento"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                ref="picker"
                                                v-model="newOne.dateOfBirth"
                                                :max="new Date().toISOString().substr(0, 10)"
                                                min="1950-01-01"
                                                @change="save"
                                                ></v-date-picker>
                                            </v-menu> 
                                        </v-col>
                                        <v-col md="4" cols="6">
                                            <v-text-field
                                                v-model="newOne.size"
                                                type="number"                                            
                                                suffix="cm"
                                                label="Alzada"                                                
                                                hint="Tamaño del caballo"
                                            ></v-text-field> 
                                        </v-col>
                                        <v-col md="4" cols="6">
                                            <v-select
                                                v-model="repStatusSelect"                                    
                                                :items="repStatus"
                                                item-text="description"
                                                item-value="id"
                                                label="Sexo"
                                                persistent-hint
                                                return-object
                                                single-line
                                                @input="changeRepStatus(repStatusSelect.id)"
                                            ></v-select> 
                                        </v-col>
                                    </v-row>
                                                                        <span>Ubicación del caballo</span>
                                    <br>
                                    <span for="Nombre">* requerido </span>                                       
                                    <v-row class="my-5">
                                        
                                        <v-col cols="12" md="4">
                                            <span>País</span>
                                            <v-select
                                                v-model="countrySelect"                                    
                                                :items="countriesData"
                                                item-text="name"
                                                item-value="_id"
                                                label="País"
                                                persistent-hint
                                                return-object
                                                single-line
                                                @input="changeCountry(countrySelect._id)"
                                            ></v-select> 
                                        </v-col>
                                            
                                        <v-col cols="12" md="4" v-if="showState">
                                            <span>Estado</span>
                                             <v-select
                                                v-model="stateSelect"                                    
                                                :items="statesData"
                                                item-text="name"
                                                item-value="_id"
                                                label="Estado"
                                                persistent-hint
                                                return-object
                                                single-line
                                                @input="changeState(stateSelect._id)"
                                            ></v-select> 
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="showCity">
                                            <span>Ciudad</span>
                                            <v-select 
                                            v-model="citySelect"                                    
                                            :items="citiesData"
                                            item-text="name"
                                            item-value="_id"
                                            label="Ciudad"
                                            persistent-hint
                                            return-object
                                            single-line
                                            @input="changeCity(citySelect._id)"
                                        ></v-select>
                                        </v-col>                             
                                    </v-row>  
                                </v-form>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>    
                </v-card>
        
        
                <v-btn text outlined class="mx-5" @click="goToHorseList()">
                    Cancelar
                </v-btn>
                <v-btn text outlined @click="e1 = 3" class="mx-5">
                    Omitir
                </v-btn>
                <v-btn
                    :disabled="!valid"
                    @click="guardar()"
                    class="primary mx-5"
                >
                    Continuar
                </v-btn>
                </v-stepper-content>
        
                <v-stepper-content step="2">
                    <v-card
                    class="mb-12"
                    >
                        <v-card-title>Información de entrenamiento del caballo</v-card-title>

                        <v-spacer></v-spacer>

                        <v-list
                            three-line
                            subheader
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <form>                                
                                        <v-text-field
                                            v-model="newHDT.time"
                                            :counter="100"
                                            label="Años de entrenamiento"
                                            required
                                            type="number"
                                        ></v-text-field> 
                                        <v-text-field
                                            v-model="newHDT.comments"
                                            :counter="100"
                                            label="Comentarios del entrenador"
                                            required
                                        ></v-text-field> 
                                        <h2>Valoración de entretamiento</h2>
                                        <br>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                 Nivel de experiencia requerido por el manejador
                                                <v-rating
                                                    v-model="newHDT.levelOfExp"
                                                    background-color="black"
                                                    color="black"
                                                    large
                                                ></v-rating>

                                            </v-col>
                                            <v-col cols="12" md="6">
                                                Calificación para montar o manejar la disposición del caballo
                                                <v-rating
                                                    v-model="newHDT.levelOfRiding"
                                                    background-color="black"
                                                    color="black"
                                                    large
                                                ></v-rating>
                                            </v-col>
                                        </v-row>
                                        <h2>Valoración de comportamiento</h2>
                                        <br>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                Disposición General
                                                <v-rating
                                                    v-model="newHDT.general"
                                                    background-color="black"
                                                    color="black"
                                                    large
                                                ></v-rating>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                Modales en el puesto
                                                <v-rating
                                                    v-model="newHDT.manners"
                                                    background-color="black"
                                                    color="black"
                                                    large
                                                ></v-rating>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                Comporamiento con otros caballos
                                                <v-rating
                                                    v-model="newHDT.alongWithHorse"
                                                    background-color="black"
                                                    color="black"
                                                    large
                                                ></v-rating>
                                            </v-col>
                                        </v-row>
                                        <h2>Dificultad de manejo </h2>
                                        <br>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                  Dificultad de herrar
                                                <v-rating
                                                    v-model="newHDT.farrier"
                                                    background-color="black"
                                                    color="black"
                                                    large
                                                ></v-rating>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                Dificultad de carga y remolque
                                                <v-rating
                                                    v-model="newHDT.loading"
                                                    background-color="black"
                                                    color="black"
                                                    large
                                                ></v-rating>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                Dificiltad para recorte
                                                <v-rating
                                                    v-model="newHDT.clipping"
                                                    background-color="black"
                                                    color="black"
                                                    large
                                                ></v-rating>
                                            </v-col>
                                        </v-row>
                                    </form>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>    
                    </v-card>
        
                <v-btn text outlined @click="e1 = 3" class="mx-5">
                    Omitir
                </v-btn>
                <v-btn
                    class="primary"
                    @click="guardar2()"
                >
                    Continuar
                </v-btn>
        
                </v-stepper-content>
        
                <v-stepper-content step="3">
                    <v-card class="mb-12">
                        <v-card-title>Contesta de manera honesta la siguiente información</v-card-title>
                        <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <form>
                                            <v-text-field
                                                v-model="newHDH.observations"
                                                :counter="10"
                                                label="Observaciones del veterinario"
                                                required
                                            ></v-text-field> 
                                            <v-row>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.cribber"
                                                        label="¿Aereofagia?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.weaver"
                                                        label="¿Criptorquideo?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.nerved"
                                                        label="¿Transtorno Neurológico?"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.foundered"
                                                        label="¿SobreHueso?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.parrotMouth"
                                                        label="¿Boca de perico?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.majorIS"
                                                        label="¿Cirugía mayor?"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.colic"
                                                        label="¿Laminitis?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.pastLameness"
                                                        label="¿Cojera?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.defects"
                                                        label="¿Transtorno navicular?"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.osteartitis"
                                                        label="¿Osteartitis?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.ceguera"
                                                        label="¿Ceguera?"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <h6 if="showFemaleHealth">Información de Hembras</h6>
                                            <v-row if="showFemaleHealth">
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.aborto"
                                                        label="¿Aborto?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.transtorno"
                                                        label="¿transtorno en el ciclo?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.extirpasion"
                                                        label="¿Extirpación de ovarios?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.cesarea"
                                                        label="¿Cesárea?"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-checkbox
                                                        v-model="newHDH.cuello"
                                                        label="¿Operaciones al cuello uterino?"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            
                                        </form>
                            </v-col>
                            <v-col cols="12" md="6" v-if="hdhData[0]">
                                <pdfViewer :URL="hdhData[0].file" v-if="hdhData[0].file" />
                                <v-row justify="center">
                                    <v-btn class="mx-auto mt-10" color="primary" @click="dialogUpdateCertificado = true">
                                        Actualizar certificado
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>

                        </v-card-text> 
                    </v-card>
        
                <v-btn text outlined @click="e1 = 4" class="mx-5">
                    Omitir
                </v-btn>
                <v-btn
                    class="primary"
                    @click="guardar3()"
                >
                    Continuar
                </v-btn>
                </v-stepper-content>
                 
                <v-stepper-content step="4">
                     <v-card
                        class="mb-12"
                        >
                            <v-card-title>Información del árbol genealógico del caballo</v-card-title>

                            <v-spacer></v-spacer>

                            <v-list
                                three-line
                                subheader
                            >
                                <v-list-item>
                                    <v-list-item-content>
                                        <form enctype="multipart/form-data">
                                            <h4>Documento que respalde esta información</h4>
                                            <v-img 
                                                class="mx-auto justify-center aling-center text-center"
                                                    contain
                                                    :lazy-src="newHDP.file"
                                                    max-height="100%"
                                                    max-width="150px"
                                                    :src="newHDP.file"

                                                    @click="showImage(newHDP.file)"
                                                ></v-img> 
                                            <v-file-input     
                                                v-model="file"                      
                                                counter
                                                accept="image/png, image/jpeg"
                                                show-size
                                                truncate-length="50"
                                                @change="changeFilePedigree()"
                                            ></v-file-input>
                                            <v-row>
                                                <v-col cols="12" sm="2"></v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.dad"
                                                            :counter="50"
                                                            label="Papá"
                                                            required
                                                    ></v-text-field>   
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.granddad1"
                                                            :counter="50"
                                                            label="Abuelo paterno"
                                                            required
                                                    ></v-text-field>  
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandgranddad1"
                                                            :counter="50"
                                                            label="Bisabuelo"
                                                            required
                                                    ></v-text-field>     
                                                </v-col>                                        
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="2"></v-col>
                                                <v-col cols="12" sm="3"></v-col>
                                                <v-col cols="12" sm="3"></v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandgrandmom1"
                                                            :counter="50"
                                                            label="Bisabuela"
                                                            required
                                                    ></v-text-field>     
                                                </v-col>                                        
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="2"></v-col>
                                                <v-col cols="12" sm="3"></v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandmom2"
                                                            :counter="50"
                                                            label="Abuela Paterno"
                                                            required
                                                    ></v-text-field>  
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandgranddad2"
                                                            :counter="50"
                                                            label="Bisabuelo"
                                                            required
                                                    ></v-text-field>     
                                                </v-col>                                        
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="2"></v-col>
                                                <v-col cols="12" sm="3"></v-col>
                                                <v-col cols="12" sm="3"></v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandgrandmom2"
                                                            :counter="50"
                                                            label="Bisabuela"
                                                            required
                                                    ></v-text-field>     
                                                </v-col>                                        
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="2"></v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                        v-model="newHDP.mom"
                                                        :counter="50"
                                                        label="Mamá"
                                                        required
                                                    ></v-text-field>  
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.granddad3"
                                                            :counter="50"
                                                            label="Abuelo Materno"
                                                            required
                                                    ></v-text-field>  
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandgranddad3"
                                                            :counter="50"
                                                            label="Bisabuelo"
                                                            required
                                                    ></v-text-field>     
                                                </v-col>                                        
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="2"></v-col>
                                                <v-col cols="12" sm="3"></v-col>
                                                <v-col cols="12" sm="3"></v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandgrandmom3"
                                                            :counter="50"
                                                            label="Bisabuela"
                                                            required
                                                    ></v-text-field>     
                                                </v-col>                                        
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="2"></v-col>
                                                <v-col cols="12" sm="3"></v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandmom4"
                                                            :counter="50"
                                                            label="Abuela Materna"
                                                            required
                                                    ></v-text-field>  
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandgranddad4"
                                                            :counter="50"
                                                            label="Bisabuelo"
                                                            required
                                                    ></v-text-field>     
                                                </v-col>                                        
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="2"></v-col>
                                                <v-col cols="12" sm="3"></v-col>
                                                <v-col cols="12" sm="3">
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                            v-model="newHDP.grandgrandmom4"
                                                            :counter="50"
                                                            label="Bisabuela"
                                                            required
                                                    ></v-text-field>     
                                                </v-col>                                        
                                            </v-row>                                                                                    
                                        </form>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>    
                        </v-card>
        
                <v-btn text outlined @click="goToHorseList()" class="mx-5">
                    Omitir
                </v-btn>
                <v-btn
                    class="primary"
                    @click="guardar4()"
                >
                    Finalizar
                </v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-dialog
            v-model="showImageUploadDialog"
            fullscreen
            style="z-index:1000000;"
        >        
            <v-card class="mx-auto justify-center aling-center">
            <v-card-title class="headline">
                    Cortar imagen
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="red"
                        @click="showImageUploadDialog = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="black"
                        text
                        @click="crop"
                    >
                        Confirmar
                    </v-btn>
                </v-card-title>
            <v-card-text class="mx-auto justify-center text-center upload-example">
                <h5>Selecciona una imagen</h5>
                <v-file-input     
                    v-model="image"                      
                    counter
                    accept="image/png, image/jpeg, image/bmp"
                    show-size
                    truncate-length="50"                                
                    @change="changeFileImagen()"
                ></v-file-input> 

                <div class="upload-example__cropper-wrapper">
                    <cropper ref="cropper" class="upload-example__cropper" check-orientation :src="url" />                    
                </div>
            </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="erroresPendientes" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">
            Información pendiente:
          </v-card-title>
          <v-card-text class="pa-5">
            <ul>
              <li v-for="(error, index) in errores" :key='index'> {{error}}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary" text @click="erroresPendientes = false"> Continuar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
        <v-dialog v-model="dialogUpdateCertificado" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">
            Actualización de certificado:
          </v-card-title>
          <v-card-text class="pa-5">
            <v-file-input
                v-model="fileCert"
                counter
                show-size
                @change="changeFileCert()"
                hint="Solo archivos .pdf"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogUpdateCertificado = false"> Cancelar </v-btn>
            <v-btn :disabled="!canSaveNewCert" class="primary" @click="guardarCertificado()"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import EventBus from '../../services/events';
import AdminHeader from '@/components/adminSide/AdminHeader';
import Loader from '@/components/adminSide/Loader';
import ImagePreview from '@/components/adminSide/imagePreview';
import youtubeVisor from '@/components/adminSide/youtubeVisor';
import pdfViewer from "@/components/adminSide/pdfViewer";


export default {
    beforeCreate(){
        this.$store.dispatch('horsesModule/fetchItem',this.$route.params.horse).then(()=>{
            this.newOne = this.item;
            this.photos = this.newOne.photos;
            this.urls = this.newOne.photos;
            this.groupSelect = this.newOne.subCategory.category.group._id;
            this.changeGroup(this.newOne.subCategory.category.group._id);
            this.categorySelect = this.newOne.subCategory.category._id
            this.changeCategory(this.newOne.subCategory.category._id);
            this.subCategorySelect = this.newOne.subCategory._id

            this.countrySelect = this.newOne.city.state.country._id
            this.changeCountry(this.newOne.city.state.country._id)

            this.stateSelect = this.newOne.city.state._id
            this.changeState(this.newOne.city.state.country._id)

            this.citySelect = this.newOne.city._id
        });
        this.$store.dispatch('hdhModule/fetchByMaster',this.$route.params.horse);
        this.$store.dispatch('hdpModule/fetchByMaster',this.$route.params.horse);
    },
    created(){
        this.$store.dispatch('horsesModule/fetchItem',this.$route.params.horse).then(()=>{
            this.newOne = this.item;
            this.photos = this.newOne.photos;
            this.urls = this.newOne.photos;
            
        });
        this.$store.dispatch('groupsModule/fetchAll');
        // this.$store.dispatch('categoriesModule/fetchAll');
        this.$store.dispatch('countriesModule/fetchAll');
        this.$store.dispatch('hdhModule/fetchByMaster',this.$route.params.horse).then( ()=>{
            if(this.hdhData.length > 0){
                this.newHDH = this.hdhData[0];
                this.hdhCreate = false;
            }
        });
        this.$store.dispatch('hdpModule/fetchByMaster',this.$route.params.horse).then( ()=>{
            if(this.hdpData.length > 0){
                this.newHDP = this.hdpData[0];
                this.hdpCreate = false;
            }
        });
        this.$store.dispatch('hdpModule/fetchByMaster',this.$route.params.horse);
    },
    mounted(){
         EventBus.$on('horse-fetch-item', () => {
            this.newOne = this.item;
            this.newOne = this.item;
            this.photos = this.newOne.photos;
            this.urls = this.newOne.photos;
        });
        this.$session.set('horseProcess',this.$route.params.horse);     
    },
    data(){
        return{
            erroresPendientes: false,
            canSaveNewCert: false,
            dialogUpdateCertificado: false,
            fileCert: null,
            errores: [],
            valid: false,
            nameRules: [
                v => !!v || 'Nombre requerido',
                v => v.length < 100 || 'Nombre debe ser menor a 100 caracteres',
            ],
            buyRules: [
                v => !!v || 'Valor requerido'
            ],
            showPhotosWarning: false,
            e1: 1,
            toAdd: '', 
            photos: [],
            groupSelect:{},
            categorySelect:{},
            showCategory: false,
            showSubCategory: false,
            subCategorySelect:{},
            countrySelect:{},
            stateSelect:{},
            citySelect:{},
            showState: false,
            showCity: false, 
            newOne: {
                name:'',
                number: '',
                resume: '',
                shortDescription: '',
                description: '',
                youtubeVideo: [],
                buyNow: '',
                papers: false,
                regist: '',
                dateOfBirth: '',
                subCategory: '',
                city: '',
                ranch: {},//Automatic REF
                training: false,
                salesman: {},//Automatic REF
                status: false,
                audited: false,
                check: false,
                toEvent: false,
                photos: [],
                trainingDetail:{
                    time: 0,
                    Comments: '',
                    levelOfExp: 0,
                    levelOfRiding: 0
                },
                awards: false,
            },
            newHDT:{
                horse: {},
                time:  0,
                comments: '',
                levelOfExp: 0,
                levelOfRiding: 0,
                general: 0,
                manners: 0,
                farrier: 0,
                loading: 0,
                clipping: 0,
                alongWithHorse: 0,
            },
            newHDH:{
                horse: {},
                cribber:  false,
                weaver: false,
                nerved:  false,
                foundered: false,
                parrotMouth:  false,
                majorIS:  false,
                colic: false,
                pastLameness: false,
                defects: false,
                osteartitis: false,
                ceguera: false,
                aborto: false,
                transtorno: false,
                extirpasion: false,
                cesarea: false,
                cuello: false,
                observations:''
            },
            newHDP:{
                horse: '',
                dad: '',
                mom: '',
                granddad1: '',
                grandmom2: '',
                granddad3: '',
                grandmom4: '',
                grandgranddad1: '',
                grandgrandmom1: '',
                grandgranddad2: '',
                grandgrandmom2: '',
                grandgranddad3: '',
                grandgrandmom3: '',
                grandgranddad4: '',
                grandgrandmom4: '',
                file: ''            
            },
            menu: false,
            file: '',
            urls: [],
            imgDialog: '',
            imageDialog: false,
            repStatus: [
                { id: 1, description: "Garañon" }, 
                { id: 2, description: "Hembra" }, 
                { id: 3, description: "Castrado" } 
            ],
            repStatusSelect: {},
            showFemaleHealth: false,
            image: '',
            coordinates: {
				width: 0,
				height: 0,
				left: 0,
				top: 0,
			},
            croppingPhoto: false,
            url: '',
            showImageUploadDialog: false,
            hdhCreate: true,
            hdpCreate: true,
        }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    components:{
        AdminHeader,
        Loader,
        ImagePreview,
        youtubeVisor,
        pdfViewer
    },
    methods: {
        closePreview(){
            this.imgDialog = '';
            this.imageDialog = false;
        },
        changeFileImagen(){            
            this.url = URL.createObjectURL(this.image);
        },
        crop() {
            const { coordinates, canvas, } = this.$refs.cropper.getResult();
			this.coordinates = coordinates;

            // this.url = canvas.toDataURL();
            this.urls.push(this.url);
            // this.photos.push(this.image);
			canvas.toBlob(blob => {
                let fileUpdate;
                // this.newOne.photos.push(blob);
                if(this.newOne.photos.length < 1){
                    this.showPhotosWarning = true;
                }else{
                    this.showPhotosWarning = false;
                }

                fileUpdate = { _id: this.newOne._id, image: blob, type: 1};
                this.$store.dispatch('horsesModule/updateSingleImage',fileUpdate);
            });
            this.initImageUpload();
		},
        save (date) {
            this.$refs.menu.save(date)
        },
        changeFileCert() {
            if (this.fileCert.type !== "application/pdf"){
                this.errores.push("Certificado debe ser archivo PDF")
                this.erroresPendientes = true
                this.canSaveNewCert = false
            } else {
                this.canSaveNewCert = true
                this.newHDH.image = this.fileCert;
            }
        },
        changeRepStatus(master){
            this.showFemaleHealth = (master === 2)
            this.newOne.repStatus = master;
        },
        changeGroup(id){
            this.showCategory = true;
            this.$store.dispatch('categoriesModule/fetchByMaster',id);
        },
        changeCategory(id){
            this.showSubCategory = true;
            this.$store.dispatch('subCategoriesModule/fetchByMaster',id);
        },
        changeSubCategory(id){
            this.newOne.subCategory = id;
        },
        changeCountry(master){
            this.showState = true;
            this.$store.dispatch('statesModule/fetchByMaster',master);
        },
        changeState(master){
            this.showCity= true;
            this.$store.dispatch('citiesModule/fetchByMaster',master);
        },
        changeCity(master){
            this.newOne.city = master;
        },async guardar(){
            this.errores = []
            this.newOne.salesman = this.$session.get('salesman');
            this.newOne.ranch = this.$session.get('ranch');
            if (this.newOne.name === "") {
                this.errores.push('Nombre')
            } if (this.photos.length === 0) {
                this.errores.push("Fotografía (mínimo 1)")
            } if (this.newOne.buyNow === "") {
                this.errores.push("Definir un precio estimado")
            } if (this.newOne.subCategory === "") {
                this.errores.push("Clasificación")
            } if (this.newOne.city === "") {
                this.errores.push("Ubicación")
            } if (this.newHDH.image === "") {
                this.errores.push("Certificado médico")
            }
            if(this.errores.length > 0 ) {
                this.erroresPendientes = true
            }
            else{
                await this.$store.dispatch('horsesModule/updateItem',this.newOne).then(()=>{                    
                        this.$session.set('horseProcess',this.$route.params.horse);     
                        this.e1 = 3;
                });
            }
        },async guardar2(){
            this.newHDT.horse = this.$session.get('horseProcess');
            await this.$store.dispatch('hdtModule/createItem',this.newHDT).then(()=>{
                this.e1 = 3
            });
            if(this.newHDP.horse.momento === 4)
                await this.$store.dispatch("horsesModule/updateMomento", 8);
            else
                await this.$store.dispatch("horsesModule/updateMomento", 1);

        },
        async guardar3(){
            this.newHDH.horse = this.$session.get('horseProcess');
            if(this.hdhCreate) {
                await this.$store.dispatch('hdhModule/createItem',this.newHDH).then(()=>{
                    this.e1 = 4
                });
            } else {
                await this.$store.dispatch('hdhModule/updateItem',this.newHDH).then(()=>{
                    this.e1 = 4
                });
            }
            if(this.newHDP.horse.momento === 4)
                await this.$store.dispatch("horsesModule/updateMomento", 8);
            else
                await this.$store.dispatch("horsesModule/updateMomento", 1);
        },
        async guardarCertificado(){
            let updateCert = {
                _id: this.newHDH._id,
                horse: this.$session.get('horseProcess'),
                image: this.newHDH.image
            }
            delete this.newHDH.image
            await this.$store.dispatch('hdhModule/updateFile',updateCert).then(()=>{
                this.$store.dispatch('hdhModule/fetchByMaster',this.$route.params.horse);
                this.dialogUpdateCertificado = false
            });
        },
        async guardar4(){
            this.newHDP.horse = this.$session.get('horseProcess');
             if(this.hdpCreate){
                if(this.newHDP.image)
                    await this.$store.dispatch('hdpModule/createItem',this.newHDP).then(()=>{
                        this.goToHorseList();
                    });
                else{
                    alert("Es requerido agregar un documento que respalde esta información");
                }    
            } else {
                await this.$store.dispatch('hdpModule/updateItem',this.newHDP).then(()=>{
                    this.goToHorseList();
                });
            }
            if(this.newHDP.horse.momento === 4)
                await this.$store.dispatch("horsesModule/updateMomento", 8);
            else
                await this.$store.dispatch("horsesModule/updateMomento", 1);
        },
        goToHorseList(){
            this.$router.push({ name: 'HorseSales'});
        },
        cancelDelete(){
            this.confirm = false;
        },
        changeFile(){
            this.newOne.photos = this.photos;
            if(this.newOne.photos.length < 1){
                this.showPhotosWarning = true;
            }else{
                this.showPhotosWarning = false;
                this.photos.forEach(element => {
                    this.urls.push(URL.createObjectURL(element))
                });
            }
        },
        validateFiles(){
            if(this.newOne.photos.length < 1){
                this.showPhotosWarning = true;
            }else{
                this.showPhotosWarning = false;
            }
        },
        clearFile(){
            this.urls = [];
        },
        changeFilePedigree(){
            this.newHDP.image = this.file;
        },
        addToVideos(){
            this.newOne.youtubeVideo.push(this.toAdd)
            this.toAdd = ''
        },
        removeFromVideos(value){
            this.newOne.youtubeVideo = this.newOne.youtubeVideo.filter(function(item) {
                return item !== value
            })
        },
        showImage(photo){
            this.imgDialog = photo;
            this.imageDialog = true
        },
        changePosition(old_index){
            let arr =  this.urls;
            let new_index = 0;
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            this.urls = arr;
        },
        removeFromArray(photo,index){
            var result = /[^/]*$/.exec(photo)[0];
            let toDelete = {
                _id: this.newOne._id,
                index: result
            }
            this.$store.dispatch('horsesModule/updateRemoveImage',toDelete).then(()=>{                                   
                this.urls.splice(index,1);                
            });
        },
        initImageUpload(){
            this.showImageUploadDialog = !this.showImageUploadDialog;
            this.image = "";
            this.url = "";
        }
    },
    computed:{         
        ...mapState({
            user: state => state.user,
            loading: state => state.horsesModule.loadingStatus,
            item: state => state.horsesModule.singleItem,
            justSaved: state => state.horsesModule.justSaved,
            groupsData: state => state.groupsModule.data,
            categoriesData: state => state.categoriesModule.data,
            subCategoriesData: state => state.subCategoriesModule.data,
            countriesData: state => state.countriesModule.data,
            statesData: state => state.statesModule.data,
            citiesData: state => state.citiesModule.data,
            singleRanch: state => state.ranchsModule.singleItem,
            loadinghdh: state => state.hdhModule.loadingStatus,
            loadinghdp: state => state.hdpModule.loadingStatus,
            hdhData: state => state.hdhModule.data,
            hdpData: state => state.hdpModule.data,
        })
    }
}
</script>

<style>
    .portada{
        border-width: 5px;
        border-style: solid;
        border-color: orange;
    }
</style>