<template>
    <div>
        <AdminHeader color="black" icon="earth-arrow-right" :title="'Vendedores' " :backButton="true"/>
        <Loader v-if="loading" :size="64" />
        <Paginador :dataLength="dataLength" :pages="pages" :CurrentPage="page" @next="next"/>
        <v-card
            elevation="5"
            height="100%"
            class="mb-10"
        >
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn @click="onExportEmails()" dark small class="mx-2">
                    <v-icon left>mdi-file-export</v-icon>
                    Exportar correos
                </v-btn>
                <v-btn @click="onExportList()" dark small>
                    <v-icon left>mdi-file-export</v-icon>
                    Exportar números
                </v-btn>
            </v-card-title>   
            <v-card-subtitle>Listado de vendedores</v-card-subtitle>
            <template>
                <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-left">
                                Apellido Paterno
                            </th>
                            <th class="text-left">
                                Apellido Materno
                            </th>                            
                            <th class="text-left">
                                Observaciones
                            </th>                            
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in docs"
                            :key="item._id"
                        >
                            <td>
                                <v-img
                                    contain
                                    avatar
                                    max-height="50"
                                    max-width="50"
                                    :src="item.image"
                                ></v-img> 

                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.lastnamep }}</td>
                            <td>{{ item.lastnamem }}</td>
                            <td>
                                <v-chip v-if="!item.ine"
                                    color="red"
                                    text-color="white"
                                >
                                    INE (Front)
                                </v-chip>
                                <v-chip v-if="!item.ineBack"
                                    color="red"
                                    text-color="white"
                                >
                                    INE (Trasera)
                                </v-chip>
                                <v-chip v-if="!item.comp"
                                    color="red"
                                    text-color="white"
                                >
                                    Comprobante
                                </v-chip>
                            </td>
                            
                            
                            <td>
                                <v-row 
                                    aling="right"
                                >
                                    <v-icon v-on:click="setItemToStore(item._id)">mdi-lead-pencil</v-icon>
                                    <div class="mx-2"></div>
                                    <v-icon v-on:click="setItemToDelete(item._id)">mdi-trash-can</v-icon>
                                    <div class="mx-2"></div>
                                    <v-icon v-on:click="resend(item)">mdi-email</v-icon>

                                </v-row>
                            </td>
                        </tr>
                    </tbody>
                </template>
                </v-simple-table>
            </template>   
        </v-card>
        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <v-card>
                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-btn
                    icon
                    dark
                    @click="dialog = false"
                    >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Nueva Ciudad</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <v-btn
                        dark
                        text
                        @click="guardar();"
                    >
                        {{ label }}
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-list
                    three-line
                    subheader
                >
                    <v-subheader>Crear nueva Ciudad</v-subheader>
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Formulario para registrar un nueva Ciudad</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-content>
                        <form>
                            <v-text-field
                                v-model="newOne.name"
                                :counter="100"
                                label="Nombre"
                                required
                            ></v-text-field>                           
                        </form>    
                    </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
                v-model="confirm"
                persistent
                max-width="400"
            >
                <v-card>
                <v-card-title class="headline">
                    ¿Deseas eliminar {{newOne.name}}?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    @click="cancelDelete()"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="red"
                        text
                        @click="guardar()"
                    >
                    Eliminar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import Loader from '@/components/adminSide/Loader';
    import AdminHeader from '@/components/adminSide/AdminHeader';
    import Paginador from '@/components/adminSide/Paginador';
    
    
    export default {
        data(){
            return{
                dialog: false,
                confirm: false,
                newOne: this.createFreshObj(),
                show1: false,
                page: 1,
                eventCrud: 0 // 0 for new, 1 for edit, 2 for delete                
            }
        },
        components:{
            AdminHeader,
            Loader,
            Paginador
        },
        created(){
            this.$store.dispatch('salesmenModule/fetchAll');
        },
        beforeMount(){
            this.$store.dispatch('salesmenModule/fetchAll');
        },
        mounted(){
            this.init();
            this.$store.dispatch('salesmenModule/fetchAll');
        },
        methods:{
            async onExportEmails(){
                await this.$store.dispatch("adminSalesModule/exportEmails", {
                    perPage: 10,
                    page: this.page,
                })
                this.downloadCSV('emails.csv', this.exportEmail)
            },
            async onExportList(){
                await this.$store.dispatch("salesmenModule/exportList", {
                    perPage: 10,
                    page: this.page,
                })
                this.downloadCSV('contactos.csv', this.exportList)
            },
            downloadCSV(name, list) {
                const csvContent = this.convertArrayOfObjectsToCSV(list);
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', name);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            },
            convertArrayOfObjectsToCSV(data) {
                const separator = ',';
                const keys = Object.keys(data[0]);
                const csvContent =
                    keys.join(separator) + '\n' +
                    data.map(item => {
                    return keys.map(key => {
                        return item[key];
                    }).join(separator);
                    }).join('\n');
                return csvContent;
            },
            create(){
                this.createFreshObj();
                this.dialog = true;
                this.eventCrud = 0;
            },
            createFreshObj(){
                return {
                    name: '',
                    status: true
                }
            },
            init(){
                this.$store.dispatch('salesmenModule/fetchAll',{perPage: 5,page: this.page});
                this.newOne.name = "";
            },
            guardar(){                       
                if(this.eventCrud === 0){// CREATE
                    this.dialog = false;
                    this.$store.dispatch('salesmenModule/createItem',this.newOne).then(() => {                        
                        this.newOne = this.createFreshObj();                         
                        this.$store.dispatch('salesmenModule/fetchAll',{perPage: 5,page: this.pages});                    
                    });
                }else if(this.eventCrud ===1){ //EDIT
                    this.$store.dispatch('salesmenModule/updateItem',this.newOne).then(() =>{
                        this.$store.dispatch('salesmenModule/fetchAll',{perPage: 5,page: this.page});
                        this.dialog = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }else{
                    this.$store.dispatch('salesmenModule/deleteItem',this.newOne._id).then(() =>{
                        this.$store.dispatch('salesmenModule/fetchAll',{perPage: 5,page: this.page});
                        this.confirm = false;
                        this.eventCrud = 0;
                        this.newOne = this.createFreshObj(); 
                    });
                }
                
            },
            next(param){
                this.page = param.page;
                this.$store.dispatch('salesmenModule/fetchAll',{perPage: param.itemsPerPage,page: param.page});
            },  
            setItemToStore(id){                
                this.eventCrud = 1;
                this.$store.dispatch('salesmenModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                    this.dialog = true;
                });
            },
            setItemToDelete(id){
                this.eventCrud = 2;
                this.confirm = true;
                this.$store.dispatch('salesmenModule/fetchItem',id).then(() =>{
                    this.newOne = this.item;
                });
            },
            cancelDelete(){
                this.eventCrud = 0;
                this.confirm = false;
                this.newOne = this.createFreshObj(); 
            },
            regresar(){
                this.$router.go(-1);
            },
            resend(item){

                let emailData = {
                    salesman: item,
                    id: item._id,
                    location: window.location.origin
                }
                this.$store.dispatch('emailModule/resendWelcomeSalesman',emailData);
            }
        },
        computed: {
            label(){
                return this.eventCrud === 0 ? 'Guardar': (this.eventCrud === 1 ? 'Editar' : 'Eliminar' );
            },
            ...mapGetters('salesmenModule',['dataLength','getSub']),            
            ...mapState({
                user: state => state.user,
                loading: state => state.salesmenModule.loadingStatus,
                pages: state => state.salesmenModule.pages,
                docs: state => state.salesmenModule.data,
                item: state => state.salesmenModule.singleItem,
                master: state => state.ranchsModule.singleItem,
                exportEmail: (state) => state.adminSalesModule.exportEmail,
                exportList: (state) => state.salesmenModule.exportList
            })
        }
    }
</script>