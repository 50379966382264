import CRUDServices from "@/services/CRUDServices";
import EventBus from "../../services/events";

export const namespaced = true;

export const state = {
  loadingStatus: false,
  loadingConfig: false,
  configData: null,
  categoriesData: [],
  topEvents: [],
  subEvents: [],
  articles: [],
  sliderData: {},
  publicidadData: {},
  event: null,
  currentPrice: 0,
  messages: [],
  count: 0,
  myBids: [],
  myEvents: [],
};

export const mutations = {
  SET_LOADING_STATUS(state, status) {
    state.loadingStatus = status;
  },
  SET_LOADING_CONFIG_STATUS(state, status) {
    state.loadingConfig = status;
  },
  SET_CONFIG_DATA(state, data) {
    state.configData = data;
  },
  SET_CATEGORIES_DATA(state, data) {
    state.categoriesData.push(data);
  },
  SET_TOP_EVENTS(state, data) {
    state.topEvents.push(data);
  },
  SET_SUB_EVENTS(state, data) {
    state.subEvents.push(data);
  },
  SET_ARTICLES(state, data) {
    state.articles.push(data);
  },
  INIT_EVENTS(state) {
    state.topEvents = [];
    state.myEvents = [];
  },
  SET_SINGLE(state, item) {
    state.event = item;
  },
  SET_NEW_OBJ(state, item) {
    state.currentPrice = item;
  },
  SET_MY_BIDS(state, items) {
    state.myBids = items;
  },
  SET_MY_EVENTS(state, myEvents) {
    state.myEvents.push(myEvents);
  },
  SOCKET_COUNTER_INCREMENT(state, counter) {
    state.count = counter;
  },
  SOCKET_COUNTER_DECREMENT(state, counter) {
    state.count = counter;
  },
};

export const actions = {
  socket_chatMessage({ dispatch }, data) {
    const notification = {
      type: "socket-message",
      message: data,
    };
    dispatch("notificationModule/add", notification, { root: true });
  },
  socket_bidNot({ dispatch }, data) {
    const notification = {
      type: "socket-message",
      message: " Nueva puja " + data,
    };
    dispatch("notificationModule/add", notification, { root: true });
  },
  async fetchConfig({ commit, dispatch }) {
    commit("SET_LOADING_CONFIG_STATUS", true);
    await CRUDServices.getDocs("/config/")
      .then((response) => {
        let item = response.data[0];
          let photos = [];
          item.photos.forEach(
            photo => {
              photo = CRUDServices.getImage(photo);
              photos.push(photo)
            }
          );
          item.photos = photos;
        commit("SET_CONFIG_DATA", item);
        commit("SET_LOADING_CONFIG_STATUS", false);
      })
      .catch(() => {
        const notification = {
          type: "error",
          message: "Existe un problema al cargar la información general ",
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  fetchArticles({ state, commit, dispatch }) {
    if (state.categoriesData.length === 0) {
      commit("SET_LOADING_STATUS", true);
      CRUDServices.getDocs("/articles/")
        .then((response) => {
          // commit('SET_ARTICLES',response.data);
          response.data.forEach((art) => {
            art.image = CRUDServices.getImage(art.image);
            commit("SET_ARTICLES", art);
          });
          commit("SET_LOADING_STATUS", false);
          EventBus.$emit("home-categories-set");
        })
        .catch(() => {
          const notification = {
            type: "error",
            message: "Existe un problema al cargar la listado de categorías",
          };
          dispatch("notificationModule/add", notification, {
            root: true,
          });
        });
    }
    EventBus.$emit("home-categories-set");
  },
  async fetchCategories({ state, commit, dispatch }) {
    if (state.categoriesData.length === 0) {
      commit("SET_LOADING_STATUS", true);
      await CRUDServices.getDocs("/categories/")
        .then((response) => {
          commit("SET_LOADING_STATUS", false);
          response.data.forEach((cat) => {
            CRUDServices.getDocsByMaster("/subcategories/c/", cat._id)
              .then((response) => {
                cat.subcategories = response.data;
                commit("SET_CATEGORIES_DATA", cat);
              })
              .catch((error) => {
                const notification = {
                  type: "error",
                  message: "Error obteniendo las categorías: " + error.message,
                };
                dispatch("notificationModule/add", notification, {
                  root: true,
                });
              });
          });
          EventBus.$emit("home-categories-set");
        })
        .catch(() => {
          const notification = {
            type: "error",
            message: "Existe un problema al cargar la listado de categorías",
          };
          dispatch("notificationModule/add", notification, {
            root: true,
          });
        });
    }
    //TODO: con el async no debería ser necesario
    EventBus.$emit("home-categories-set");
  },
  async fetchTopEvents({ state, commit, dispatch }) {
    commit("INIT_EVENTS");
    if (state.topEvents.length === 0) {
      commit("SET_LOADING_STATUS", true);
      await CRUDServices.getDocs("/events/")
        .then((response) => {
          let events = response.data;
          events.forEach((event) => {
            let photos = [];
            event.horse.photos.forEach((photo) => {
              photos.push(CRUDServices.getImage(photo));
            });
            event.horse.photos = photos;
            let due = new Date(event.finishDate);
            let init = new Date(event.initialDate);
            event.due = {
              year: due.getFullYear(),
              month: due.getMonth(),
              day: due.getDate(),
              hour: event.finishHour
                ? parseInt(event.finishHour.substring(0, 2))
                : parseInt("19"),
              minutes: event.finishHour
                ? parseInt(event.finishHour.slice(-2))
                : parseInt("00"),
            };
            event.init = {
              year: init.getFullYear(),
              month: init.getMonth(),
              day: init.getDate(),
              hour: event.initialHour
                ? parseInt(event.initialHour.substring(0, 2))
                : parseInt("09"),
              minutes: event.initialHour
                ? parseInt(event.initialHour.slice(-2))
                : parseInt("00"),
            };
            commit("SET_TOP_EVENTS", event);
            EventBus.$emit("events-set");
          });
          commit("SET_LOADING_STATUS", false);
        })
        .catch(() => {
          const notification = {
            type: "error",
            message: "There was a problem ferching events for home",
          };
          dispatch("notificationModule/add", notification, {
            root: true,
          });
        });
    }
  },
  fetchSubEvents({ state, commit, dispatch }, subcat) {
    commit("INIT_EVENTS");
    if (state.topEvents.length === 0) {
      commit("SET_LOADING_STATUS", true);
      CRUDServices.getDocs("/home/get/sub/" + subcat)
        .then((response) => {
          let events = response.data;
          events.forEach((event) => {
            let photos = [];
            event.horse.photos.forEach((photo) => {
              photos.push(CRUDServices.getImage(photo));
            });
            event.horse.photos = photos;
            let due = new Date(event.finishDate);
            let init = new Date(event.initialDate);
            CRUDServices.getDocsByMaster("/eventBids/elast/", event._id)
              .then((response) => {
                event.lastBid = response.data.currentBid;
              })
              .catch((error) => {
                console.debug("error at bid for events", error.message);
              });
            event.due = {
              year: due.getFullYear(),
              month: due.getMonth(),
              day: due.getDate(),
              hour: event.finishHour
                ? parseInt(event.finishHour.substring(0, 2))
                : parseInt("09"),
              minutes: event.finishHour
                ? parseInt(event.finishHour.slice(-2))
                : parseInt("00"),
            };
            event.init = {
                year: init.getFullYear(),
                month: init.getMonth(),
                day: init.getDate(),
                hour: event.initialHour
                  ? parseInt(event.initialHour.substring(0, 2))
                  : parseInt("09"),
                minutes: event.initialHour
                  ? parseInt(event.initialHour.slice(-2))
                  : parseInt("00"),
              };
            commit("SET_TOP_EVENTS", event);
          });
          commit("SET_LOADING_STATUS", false);
        })
        .catch(() => {
          const notification = {
            type: "error",
            message: "There was a problem ferching events for home",
          };
          dispatch("notificationModule/add", notification, {
            root: true,
          });
        });
    }
  },
  async fetchEventDetail({ commit, dispatch }, id) {
    await CRUDServices.getItem("/events/", id)
      .then((response) => {
        let event = {};
        event = response.data;
        let photos = [];
        event.horse.photos.forEach((photo) => {
          photos.push(CRUDServices.getImage(photo));
        });
        if(!event.salesman.ranch.default)
          event.salesman.ranch.image = CRUDServices.getImage(event.salesman.ranch.image);
        event.horse.photos = photos;
        let due = new Date(event.finishDate);
        event.due = {
          year: due.getFullYear(),
          month: due.getMonth(),
          day: due.getDate(),
          hour: event.finishHour
            ? parseInt(event.finishHour.substring(0, 2))
            : parseInt("19"),
          minutes: event.finishHour
            ? parseInt(event.finishHour.slice(-2))
            : parseInt("00"),
        };
        let init = new Date(event.initialDate);
        event.init = {
          year: init.getFullYear(),
          month: init.getMonth(),
          day: init.getDate(),
          hour: event.initialHour
            ? parseInt(event.initialHour.substring(0, 2))
            : parseInt("09"),
          minutes: event.initialHour
            ? parseInt(event.initialHour.slice(-2))
            : parseInt("00"),
        };
        commit("SET_SINGLE", event);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "There was a problem ferching state: " + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  makeBid({ commit, dispatch }, item) {
    commit("SET_LOADING_STATUS", true);
    return CRUDServices.postItem("/eventBids/", item)
      .then(() => {
        commit("SET_NEW_OBJ", item.currentPrice);
        const notification = {
          type: "success",
          message: " bid capturado correctamente",
        };
        dispatch("notificationModule/add", notification, { root: true });
        commit("SET_LOADING_STATUS", false);
        EventBus.$emit("bid-created", item);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "There was a problem creating bid" + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  buyNowBid({ commit, dispatch }, item) {
    commit("SET_LOADING_STATUS", true);
    return CRUDServices.postItem("/eventBids/buyNowBid", item)
      .then(() => {
        commit("SET_NEW_OBJ", item.currentPrice);
        const notification = {
          type: 'socket-message',
          message: "Oferta final para " + item.horse + " - " + item.currentPrice ,
        };
        dispatch("notificationModule/add", notification, { root: true });
        commit("SET_LOADING_STATUS", false);
        EventBus.$emit("bid-created", item);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "There was a problem creating bid" + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  fetchLastBid({ commit }, id) {
    CRUDServices.getDocsByMaster("/eventBids/elast/", id)
      .then((response) => {
        commit("SET_NEW_OBJ", response.data.currentBid);
        EventBus.$emit("event-bid-set", response.data.currentBid);
        console.log(response.data)
      })
      .catch((error) => {
        console.error(error)
      });
  },
  fetchBidByUser({ commit, dispatch }, id) {
    CRUDServices.getDocsByMaster("/eventBids/elast/u/", id)
      .then((response) => {
        commit("SET_MY_BIDS", response.data);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "There was a problem ferching last Bid" + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  fetchEventsByUser({ commit, dispatch }, id) {
    commit("INIT_EVENTS");
    CRUDServices.getDocsByMaster("/events/byUser/", id)
      .then((response) => {
        let events = response.data;
          events.forEach((event) => {
            let photos = [];
            event.horse.photos.forEach((photo) => {
              photos.push(CRUDServices.getImage(photo));
            });
            event.horse.photos = photos;
            let due = new Date(event.finishDate);
            let init = new Date(event.initialDate);
            event.due = {
              year: due.getFullYear(),
              month: due.getMonth(),
              day: due.getDate(),
              hour: event.finishHour
                ? parseInt(event.finishHour.substring(0, 2))
                : parseInt("09"),
              minutes: event.finishHour
                ? parseInt(event.finishHour.slice(-2))
                : parseInt("00"),
            };
            event.init = {
                year: init.getFullYear(),
                month: init.getMonth(),
                day: init.getDate(),
                hour: event.initialHour
                  ? parseInt(event.initialHour.substring(0, 2))
                  : parseInt("09"),
                minutes: event.initialHour
                  ? parseInt(event.initialHour.slice(-2))
                  : parseInt("00"),
              };
              commit("SET_MY_EVENTS", event);
          });
        
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "There was a problem ferching last Bid" + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
  fetchBidByEvents({ commit, dispatch }, id) {
    CRUDServices.getDocsByMaster("/eventBids/e/", id)
      .then((response) => {
        let localData = [];
        response.data.map((item) => {
          if (item.user) {
            if (item.user.image) {
              let image = CRUDServices.getImage(item.user.image);
              item.image = image;
            }
          }
          localData.push(item);
        });
        commit("SET_MY_BIDS", localData);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "There was a problem ferching last Bid" + error.message,
        };
        dispatch("notificationModule/add", notification, { root: true });
      });
  },
};

export const getters = {
  getDataByID: (state) => (id) => {
    return state.topEvents.find((item) => item._id === id);
  },
};
